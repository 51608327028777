/* eslint-disable default-case */
import * as React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, PdfExport, Page, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { SampleBase } from './sample-base';
import { Browser } from '@syncfusion/ej2-base';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';

let GridData = [];
const waitingTime = Browser.userAgent.indexOf('Edg') > -1 ? 7000000000 : 2000000000;

CustomJS.getRemoteData2(CustomJS.APIUrlProd,
    `token=${CustomJS.tokenProd}&azione=referentiTecnici`, 
    'POST', "application/x-www-form-urlencoded", (retData) => {   
        let arrTotali = _.filter(retData, elm => { return elm.NominativoReferente === 'Totale (escluso studio)' || elm.NominativoReferente === 'STUDIO'});
        retData = _.sortBy(_.filter(retData, elm => { return elm.NominativoReferente !== 'Totale (escluso studio)' && elm.NominativoReferente !== 'STUDIO'}),'TMedAssegnAnnoCorr').reverse();
        retData = _.union(retData, arrTotali);
        retData.forEach((elm, idx) => {
            elm.AssegnAnnoCorrSuAnnoPrec = `${elm.AssegnAnnoCorr.toLocaleString('it-IT')} / ${elm.AssegnAnnoPrec.toLocaleString('it-IT')}`;
            elm.TmedAssegnAnnoCorrSuTmax = `${elm.TMedAssegnAnnoCorr.toLocaleString('it-IT')} / ${elm.TMaxAssegnAnnoCorr.toLocaleString('it-IT')}`;
            elm.TmedContrReferAnnoCorrSuTmax = `${elm.TMedContrReferAnnoCorr.toLocaleString('it-IT')} / ${elm.TMaxContrReferAnnoCorr.toLocaleString('it-IT')}`;
            elm.NumCambiPeritoAnnoCorrSuAssegn = `${elm.NumCambiPeritoAnnoCorr.toLocaleString('it-IT')} (${elm.PercCambiPeritoSuAssegn.toLocaleString('it-IT')}%)`;
            elm.NumSinistriTUSsuAperti = `${elm.NumSinistriTUS.toLocaleString('it-IT')} (${elm.PercSinistriTUSsuAperti.toLocaleString('it-IT')}%)`;
            elm.CorrettiDaReferSuTotChiusi = `${elm.PercCorrettiReferSuTotChiusi.toLocaleString('it-IT')}%`;
            elm.CorrettiDaCQSuTotChiusi = `${elm.PercCorrettiCQsuTotChiusi.toLocaleString('it-IT')}%`;
            GridData.push(elm);
        });        
 }, true, false);
 for (let ind=0;ind<=waitingTime;ind++) {}

function headerTemplate(props) {    
    return (<div>
         <span style={{ fontSize: '0.95vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate(props) {       
    let colore = props.column.field === 'NominativoReferente' ? '#133B95' : (props.column.field === 'AssegnAnnoCorrSuAnnoPrec' && props.AssegnAnnoPrec > props.AssegnAnnoCorr) ? 'red' : 'purple';
    return (        
         <div><span style={{ fontSize: '1.0vw', color: colore, fontWeight: 'bold'}}>{props[props.column.field]}</span></div>
    );
}

export default class GridReferentiTecnici extends SampleBase {
    constructor() {
        super(...arguments);  
        this.toolbarOptions = ['ExcelExport', 'PdfExport'];              
        this.data = GridData;         
    }    
    onQueryCellInfo(args) { 
    }    
    onDatabound(args) {       
        for (let ind=0;ind<=4;ind++)
             document.getElementById('GridReferentiTecnici').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
        document.getElementById('TestoDatiAggiornati').innerText =  'Dati aggiornati al ' + new Date().getDate().toString().padStart(2, '0') + '/' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '/' + new Date().getFullYear().toString() + ' alle ore '  + new Date().getHours().toString() + ':' + new Date().getMinutes().toString().padStart(2, '0');            
    }
    onRowDatabound(args) {        
        if (args.data.NominativoReferente.indexOf('Totale') > -1) {
            args.row.className = 'e-row';
            args.row.bgColor = 'lightpink';
        }               
        if (args.data.NominativoReferente.indexOf('STUDIO') > -1) {            
            args.row.className = 'e-row';
            args.row.bgColor = 'gold';            
        }                 
    }
    toolbarClick(args) {        
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
        }
    }
    render() {
        return (<React.Fragment>
                    <GridComponent id="GridReferentiTecnici" dataSource={this.data} ref={grid => this.gridInstance = grid} toolbar={this.toolbarOptions} allowSelection={false} enableHover={false} allowTextWrap={true}
                             allowPaging={false} allowSorting={false} allowFiltering={false} allowExcelExport={true} allowPdfExport={true} queryCellInfo={this.onQueryCellInfo.bind(this)} 
                             dataBound={this.onDatabound.bind(this)} rowDataBound={this.onRowDatabound.bind(this)} toolbarClick={this.toolbarClick.bind(this)} enableAltRow={true}>
                        <ColumnsDirective>                            
                            <ColumnDirective  field='NominativoReferente' headerText='Referente Tecnico/di zona' width='15%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Left' filter={this.Filter} clipMode="EllipsisWithTooltip"></ColumnDirective>
                            <ColumnDirective field='AssegnAnnoCorrSuAnnoPrec' headerText="Assegnati nell'anno/anno precedente" headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='TmedAssegnAnnoCorrSuTmax' headerText="Tmed Assegnati nell'anno/Tmax" headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='NumCambiPeritoAnnoCorrSuAssegn' headerText="N° Cambi Perito nell'anno (su assegn.)" headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='NumSinistriTUSsuAperti' visible={false} headerText='N° Sinistri in TUS (su aperti)' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                        </ColumnsDirective>   
                        <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort]}/>                    
                    </GridComponent>                
        </React.Fragment>);
    }   
}