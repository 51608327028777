/* eslint-disable no-loop-func */
import * as React from "react";
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Tooltip, ColumnSeries, DataLabel } from '@syncfusion/ej2-react-charts';
import { Browser } from '@syncfusion/ej2-base';
// eslint-disable-next-line no-unused-vars
import { fabricColors, bootstrapColors, materialColors, highContrastColors } from './theme-color';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import { SampleBase } from './sample-base';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import $ from 'jquery';

var dataCorrente = new Date();
var dd = String(dataCorrente.getDate()).padStart(2, '0');
var mm = String(dataCorrente.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = dataCorrente.getFullYear();
dataCorrente = yyyy + mm + dd;
let annoPrec = (new Date().getFullYear() - 1).toString();
let dataCorrAnnoPrec = (yyyy - 1).toString() + mm + dd;

let data1 = [];
let data2 = [];
let totChiusiAnnoCorr = 0;
let totChiusiAnnoPrec = 0;
let totChiusiStessoGGAnnoPrec = 0;
export default class ChartSinistriChiusi extends SampleBase {
    // eslint-disable-next-line no-useless-constructor
    constructor() {
        super(...arguments);
        if ((window.matchMedia("(max-width: 850px)")).matches) {
            this.altezza = "250px";
            this.larghezza = "650px";
        }
        else if ((window.matchMedia("(max-width: 1024px)")).matches) {
            this.altezza = "500px";
            this.larghezza = CustomJS.chartWidth;
        }
        else{
            this.altezza = CustomJS.chartHeight;
            this.larghezza = CustomJS.chartWidth;
        }
    }
    state = {
        dataSerie1: data1,
        dataSerie2: data2,
        totChiusiStessoGGAnnoPrec: totChiusiStessoGGAnnoPrec,
        totChiusiAnnoPrec: totChiusiAnnoPrec,
        totChiusiAnnoCorr: totChiusiAnnoCorr
    }

    render() {
        return (<React.Fragment>
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-lg-5 col-md-5 text-center" style={{ marginTop: '5px' }}>
                    <span id='testoChiusiAnnoCorr' style={{ fontSize: '1.5vw', fontWeight: 'bolder', color: '#133B95' }}>CHIUSI ANNO CORRENTE: {this.state.totChiusiAnnoCorr.toLocaleString()}</span>
                </div>
                <div className="col-xs-12 col-sm-12 col-lg-5 col-md-5 text-end" style={{ marginTop: '5px' }}>
                    <span id='testoChiusiAnnoPrec' style={{ fontSize: '1.5vw', fontWeight: 'bolder', color: '#133B95' }}>CHIUSI ANNO PRECEDENTE: {this.state.totChiusiAnnoPrec.toLocaleString()}</span>
                </div>
            </div>
            <div className="row" >
                <ChartComponent id='ChartSinistriChiusi' loaded={this.operazioniDopoCarica.bind(this)} style={{ textAlign: "center" }}
                    className='col-xs-12 col-sm-12 col-lg-12 col-md-12' ref={chartObj => this.chartInstance = chartObj}
                    primaryXAxis={{ valueType: 'Category', interval: 1, majorGridLines: { width: 0 } }}
                    primaryYAxis={{ majorGridLines: { width: 0 }, majorTickLines: { width: 0 }, lineStyle: { width: 0 }, labelStyle: { color: 'transparent' } }}
                    chartArea={{ border: { width: 0 } }} tooltip={{ enable: true }}
                    width={this.larghezza} 
                    height={this.altezza} 
                    title=''
                    legendSettings={{ visible: true }}>
                    <Inject services={[ColumnSeries, Legend, Tooltip, Category, DataLabel]} />
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={this.state.dataSerie1} xName='x' yName='y' name={'Anno ' + annoPrec} type='Column' fill={materialColors[8 % 10]} cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                        </SeriesDirective>
                        <SeriesDirective dataSource={this.state.dataSerie2} xName='x' yName='y' name={'Anno ' + yyyy} type='Column' fill={materialColors[9 % 10]} cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                        </SeriesDirective>
                    </SeriesCollectionDirective>
                </ChartComponent>
            </div>
        </React.Fragment>);
    }
    rendereComplete() {
        this.caricaDati();
    }
    caricaDati() {
        CustomJS.callAPI(`${CustomJS.APIMonitorURLProd}/api/Monitor/portingTutteAPI/chiusi/${annoPrec}0101/${dataCorrente}/mese/[NO]`, '', 'GET', '', retData => {
            for (let ind = 1; ind <= 12; ind++) {
                let MONTH = _.filter(retData, (obj) => { return obj.periodo === yyyy.toString() + ind.toString().padStart(2, '0') || obj.periodo === annoPrec + ind.toString().padStart(2, '0') });
                data1.push({
                    x: ind === 1 ? 'GEN' : ind === 2 ? 'FEB' : ind === 3 ? 'MAR' : ind === 4 ? 'APR' : ind === 5 ? 'MAG' : ind === 6 ? 'GIU'
                        : ind === 7 ? 'LUG' : ind === 8 ? 'AGO' : ind === 9 ? 'SET' : ind === 10 ? 'OTT' : ind === 11 ? 'NOV' : 'DIC', y: MONTH[0]?.chiusi
                });
                data2.push({
                    x: ind === 1 ? 'GEN' : ind === 2 ? 'FEB' : ind === 3 ? 'MAR' : ind === 4 ? 'APR' : ind === 5 ? 'MAG' : ind === 6 ? 'GIU'
                        : ind === 7 ? 'LUG' : ind === 8 ? 'AGO' : ind === 9 ? 'SET' : ind === 10 ? 'OTT' : ind === 11 ? 'NOV' : 'DIC', y: MONTH[1]?.chiusi
                });
                if (ind.toString().padStart(2, '0') === mm) {
                    CustomJS.callAPI(`${CustomJS.APIMonitorURLProd}/api/Monitor/portingTutteAPI/chiusi/${annoPrec}${mm}01/${dataCorrAnnoPrec}/mese/[NO]`, '', 'GET', '', retData2 => {
                        totChiusiStessoGGAnnoPrec = CustomJS.isNullEmpty(retData2[0]?.chiusi) ? 0 : retData2[0]?.chiusi;
                        let ind = new Date().getMonth() + 1;
                        data1.push({
                            x: ind === 1 ? 'GEN' : ind === 2 ? 'FEB' : ind === 3 ? 'MAR' : ind === 4 ? 'APR' : ind === 5 ? 'MAG' : ind === 6 ? 'GIU'
                               : ind === 7 ? 'LUG' : ind === 8 ? 'AGO' : ind === 9 ? 'SET' : ind === 10 ? 'OTT' : ind === 11 ? 'NOV' : 'DIC', y: retData2[0]?.chiusi
                        });
                    }, error => {
                        let messaggio = (_.isString(error) && error.indexOf('Not Found')) > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            position: "top-right",
                            autoClose: 8000,
                            hideProgressBar: false,            
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        })
                    }, true, false);              
                }
            }
            setTimeout(() => {
                data1.forEach((element, idx) => {
                    if (idx !== 12)
                        totChiusiAnnoPrec += isNaN(element.y) ? 0 : element.y;
                });
                data2.forEach((element, idx) => {
                    totChiusiAnnoCorr += isNaN(element.y) ? 0 : element.y;
                });   
                this.chartInstance.series[0].dataSource = data1;
                this.chartInstance.series[1].dataSource = data2;
                document.getElementById('testoLegendaCustom').innerText = `Chiusi da inizio stesso mese e fino a stesso giorno anno precedente : ${totChiusiStessoGGAnnoPrec.toLocaleString()}`;
                document.getElementById('testoChiusiAnnoCorr').innerText = `CHIUSI ANNO CORRENTE: ${totChiusiAnnoCorr.toLocaleString()}`;
                document.getElementById('testoChiusiAnnoPrec').innerText = `CHIUSI ANNO PRECEDENTE: ${totChiusiAnnoPrec.toLocaleString()}`;
            }, 400);
            setTimeout(() => {
                $(`#ChartSinistriChiusi_Series_0_Point_12`).attr('fill', '#133B95');
            }, 1000);
        }, error => {
            let messaggio = (_.isString(error) && error.indexOf('Not Found')) > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            })
        }, true, false);  
    }
    operazioniDopoCarica(args) {
        setTimeout(() => {
            if (document.getElementById('legendaCustom')) return;
            let fontSizeLegCust = '1.0vw';
            $(`<div id="legendaCustom"><span id="testoLegendaCustom" style="font-size:${fontSizeLegCust};font-weight:bold;color:#133B95";>Sinistri chiusi da inizio stesso mese e fino a stesso giorno anno precedente : ${this.state.totChiusiStessoGGAnnoPrec?.toLocaleString()}</span></div>`).appendTo('#e-content-TabMonitor_0');
            let d = document.getElementById('legendaCustom');
            d.style.position = "absolute";
            d.style.left = '0.6vw';
            let topPosLegCust = Browser.userAgent.indexOf('Firefox') > -1 ? '580' : Browser.userAgent.indexOf('OPR') > -1 ? '590' : Browser.userAgent.indexOf('Edg') > -1 ? '585' : '590';
            topPosLegCust = (parseInt(topPosLegCust) - ((1080 - CustomJS.heightScreenResolution) === 0 ? 0 : (1080 - CustomJS.heightScreenResolution) < 0 ? (1080 - CustomJS.heightScreenResolution) : (1080 - CustomJS.heightScreenResolution + 50))).toString();
            d.style.top = Browser.isDevice ? '625px' : topPosLegCust + 'px';
        }, 400);
    }
}