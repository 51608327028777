/* eslint-disable no-extend-native */
/* eslint-disable default-case */
import React, { useRef, useLayoutEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, DetailRow, ExcelExport, PdfExport, Page, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
// eslint-disable-next-line no-unused-vars
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject as Inject2, Legend, Category, Tooltip, LineSeries, DateTime, Trendlines, TrendlinesDirective, TrendlineDirective, ColumnSeries, DataLabel } from '@syncfusion/ej2-react-charts';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
// eslint-disable-next-line no-unused-vars
import { fabricColors, bootstrapColors, materialColors, highContrastColors } from './theme-color';
import { random, isUndefined } from 'underscore';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
// eslint-disable-next-line no-unused-vars
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { SampleBase } from './sample-base';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import moment from "moment";
import $ from 'jquery';
//import GraficoTorta2 from './GraficoTorta2';
import GraficoTortaSync from './GraficoTortaSync';

import LavoriInCorso from '../assets/LavoriInCorso2.jpg';

Array.prototype.sum3 = function (prop) {
    let total = 0
    for (let i = 0, _len = this.length; i < _len; i++) {
        total += this[i][prop];
    }
    return total;
};

var dataCorrente = new Date();
var dd = String(dataCorrente.getDate()).padStart(2, '0');
var mm = String(dataCorrente.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = dataCorrente.getFullYear();
dataCorrente = yyyy + mm + dd;
const dataInizioAnnoPrec = yyyy - 1 + '0101';

function headerTemplate1(props) {
    return (<div>
        <span style={{ fontSize: '0.6vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function headerTemplate2(props) {
    return (<div>
        <span style={{ fontSize: '1.0vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate1(props) {
    let colore = props.column.field === 'rowDescr' ? '#133B95' : 'purple';
    let fontSize1 = '0.5vw';
    let fontSize2 = '0.9vw';
    let renderDiv = props.column.field !== 'rowDescr' ? <div><span style={{ fontSize: fontSize1, color: colore, fontWeight: 'bold' }}>{props[props.column.field]?.substring(0, props[props.column.field]?.indexOf('('))}</span><br /><span style={{ fontSize: fontSize2, color: colore, fontWeight: 'bold' }}>{props[props.column.field]?.substring(props[props.column.field]?.indexOf('('))}</span></div>
        : <div><span style={{ fontSize: '150%', color: colore, fontWeight: 'bold' }}>{props[props.column.field]}</span></div>;
    return (
        renderDiv
    );
}

function fieldTemplate2(props) {
    let colore = props.column.field === 'NominativoPerito' ? '#133B95' : 'purple';
    let fontSize1 = '1.0vw';
    let fontSize2 = '0.95vw';
    let renderDiv = props.column.field !== 'NominativoPerito' ? <div><span style={{ fontSize: fontSize1, color: colore, fontWeight: 'bold' }}>{props[props.column.field]?.substring(0, props[props.column.field]?.indexOf('('))}</span><br /><span style={{ fontSize: fontSize2, color: colore, fontWeight: 'bold' }}>{props[props.column.field]?.substring(props[props.column.field]?.indexOf('('))}</span></div>
        : <div><span style={{ fontSize: '150%', color: colore, fontWeight: 'bold' }}>{props[props.column.field]}</span></div>;
    return (
        renderDiv
    );
}

function fieldTemplate3(props) {
    return (<div>
        <span style={{ fontSize: '1.5vw', color: '#133B95', fontWeight: 'bold' }}>{props[props.column.field]}</span>
    </div>
    );
}
export default class PaginaFatturato extends SampleBase {
    // eslint-disable-next-line no-useless-constructor
    constructor() {
        super(...arguments);
        this.toolbarOptions = ['ExcelExport', 'Search'];
        this.accordHeader = ['FATTURATO ANNO CORRENTE/ANNO PRECEDENTE', 'FATTURATO/BUDGET ANNO CORRENTE'];
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.incrementoProgress = 0;
        this.datiTorta = [];
    }
    state = {
        blocking: false,
        progress: 0,
        dataSerie1: []
    }
    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }
    onQueryCellInfo1(args) {
        args.cell.style.color = args.column.index === 0 ? '#133B95' : 'purple';
        args.cell.style.fontSize = args.column.index === 0 ? '0.9vw' : '0.85vw';
        args.cell.style.fontWeight = 'bold';
        if (args.column.index === 13)
            args.cell.style.backgroundColor = 'gold';
        if (args.cell.innerText === 'NaN' || args.cell.innerText === '+∞')
            args.cell.innerText = '';
        if (args.cell.innerText.indexOf('undefined') > -1)
            args.cell.innerText = '';
        if (args.cell.innerText.indexOf('-') > -1)
            args.cell.style.color = 'red';
        else if (args.data.rowDescr.indexOf('Delta %') > -1 && args.cell.innerText.indexOf('Delta %') === -1 && args.cell.innerText !== '') {
            args.cell.innerText = '+' + args.cell.innerText;
            args.cell.style.color = 'green';
        }
        if (args.cell.innerText === '+')
            args.cell.innerText = '';
    }
    onQueryCellInfo2(args) {
        args.cell.style.color = args.column.index === 0 ? '#133B95' : 'purple';
        args.cell.style.fontSize = '0.9vw';
        args.cell.style.fontWeight = 'bold';
        if (args.column.index === 13)
            args.cell.style.backgroundColor = 'gold';
        if (args.cell.innerText === 'NaN' || args.cell.innerText === '+∞')
            args.cell.innerText = '';
        if (args.cell.innerText.indexOf('undefined') > -1)
            args.cell.innerText = '';
        if (args.cell.innerText.indexOf('-') > -1)
            args.cell.style.color = 'red';
        else if (args.data.rowDescr.indexOf('Delta %') > -1 && args.cell.innerText.indexOf('Delta %') === -1 && args.cell.innerText !== '') {
            args.cell.innerText = '+' + args.cell.innerText;
            args.cell.style.color = 'green';
        }
        if (args.cell.innerText === '+')
            args.cell.innerText = '';
    }
    onDatabound1(args) {
        for (let ind = 0; ind <= 13; ind++)
            this.element.querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
        this.element.querySelectorAll('.e-columnheader')[0].children[13].style.backgroundColor = 'gold';
    }
    cambioVisione1(args) {
        if (args.value === 'andamento') {
            document.getElementById(`divChartBarreFatturato_${this.id.substring(this.id.indexOf('_') + 1)}`).style.display = 'none';
            document.getElementById(`divChartTortaFatturato_${this.id.substring(this.id.indexOf('_') + 1)}`).style.display = 'none';
            document.getElementById(`divChartLineeFatturato_${this.id.substring(this.id.indexOf('_') + 1)}`).style.removeProperty('display');

        }
        else if (args.value === 'puntuale') {
            document.getElementById(`divChartLineeFatturato_${this.id.substring(this.id.indexOf('_') + 1)}`).style.display = 'none';
            document.getElementById(`divChartTortaFatturato_${this.id.substring(this.id.indexOf('_') + 1)}`).style.display = 'none';
            document.getElementById(`divChartBarreFatturato_${this.id.substring(this.id.indexOf('_') + 1)}`).style.removeProperty('display');
        }
        else if (args.value === 'torta') {
            document.getElementById(`divChartLineeFatturato_${this.id.substring(this.id.indexOf('_') + 1)}`).style.display = 'none';
            document.getElementById(`divChartBarreFatturato_${this.id.substring(this.id.indexOf('_') + 1)}`).style.display = 'none';
            document.getElementById(`divChartTortaFatturato_${this.id.substring(this.id.indexOf('_') + 1)}`).style.removeProperty('display');
        }
    }
    cambioVisione2(args) {
        if (args.value === 'andamento') {
            document.getElementById(`divChartBarreFatturatoBudget_${this.id.substring(this.id.indexOf('_') + 1)}`).style.display = 'none';
            document.getElementById(`divChartBarreFatturatoBudget_${this.id.substring(this.id.indexOf('_') + 1)}`).style.display = 'none';
            document.getElementById(`divChartLineeFatturatoBudget_${this.id.substring(this.id.indexOf('_') + 1)}`).style.removeProperty('display');
            document.getElementById(`divChartLineeFatturatoBudget_${this.id.substring(this.id.indexOf('_') + 1)}`).style.removeProperty('display');
        }
        else if (args.value === 'puntuale') {
            document.getElementById(`divChartLineeFatturatoBudget_${this.id.substring(this.id.indexOf('_') + 1)}`).style.display = 'none';
            document.getElementById(`divChartLineeFatturatoBudget_${this.id.substring(this.id.indexOf('_') + 1)}`).style.display = 'none';
            document.getElementById(`divChartBarreFatturatoBudget_${this.id.substring(this.id.indexOf('_') + 1)}`).style.removeProperty('display');
            document.getElementById(`divChartBarreFatturatoBudget_${this.id.substring(this.id.indexOf('_') + 1)}`).style.removeProperty('display');
        }
    }
    accordContent0Render(index, descrCliente) {
        let chartTorta = '';
        if (index === 0)
            chartTorta = <div id={`chartTortaFatturato_0`}></div>
        else
            chartTorta = <></>

        return (<div>
            <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                    <RadioButtonComponent id={`visioneAndamento1_${index.toString()}`} checked={false} label='Confronto degli andamenti' style={{ fontSize: '0.8vw' }} name={`sceltaVisione1_${index.toString()}`} change={this.cambioVisione1} value="andamento"></RadioButtonComponent>
                    <RadioButtonComponent id={`visioneBarre1_${index.toString()}`} checked={true} label='Confronto dei valori puntuali' name={`sceltaVisione1_${index.toString()}`} change={this.cambioVisione1} value="puntuale"></RadioButtonComponent>
                    <RadioButtonComponent id={`visioneTorta1_${index.toString()}`} disabled={index > 0} checked={false} label='Suddivisione Fatturato per Compagnie' name={`sceltaVisione1_${index.toString()}`} change={this.cambioVisione1} value="torta"></RadioButtonComponent>
                </div>
            </div>
            <div className="row" style={{ marginTop: '30px' }}>
                <div className="col-xs-12 col-sm-12 col-lg-10 col-md-10">
                    <span style={{ fontSize: '1.5vw', fontWeight: 'bold', color: '#133B95' }}>ANDAMENTO FATTURATO {descrCliente}</span>
                </div>
            </div>
            <div id={`divChartTortaFatturato_${index.toString()}`} style={{ display: 'none' }} className="row">
                {chartTorta}
            </div>
            <div id={`divChartLineeFatturato_${index.toString()}`} className="row">
                <ChartComponent id={`ChartFatturatoLinee_${index.toString()}`} style={{ textAlign: "center" }}
                    className='col-xs-12 col-sm-12 col-lg-12 col-md-12'
                    primaryXAxis={{
                        valueType: 'DateTime',
                        labelFormat: 'MMMM',
                        intervalType: 'Months',
                        edgeLabelPlacement: 'Shift',
                        majorGridLines: { width: 0 }
                    }}
                    primaryYAxis={{
                        labelFormat: '{value}',
                        rangePadding: 'None',
                        interval: 20000,
                        lineStyle: { width: 0 },
                        minimum: 0,
                        majorTickLines: { width: 0 },
                        minorTickLines: { width: 0 }
                    }}
                    chartArea={{ border: { width: 0 } }}
                    tooltip={{ enable: true }}
                    width={CustomJS.chartWidth}
                    title=''
                    legendSettings={{ visible: true }}>
                    <Inject2 services={[LineSeries, DateTime, Legend, Tooltip, Trendlines]} />
                    <SeriesCollectionDirective>
                        <SeriesDirective xName='x' yName='y' name={`Fatturato ${(yyyy - 1).toString()}`}
                            width={3} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                        </SeriesDirective>
                        <SeriesDirective xName='x' yName='y' name={`Fatturato ${yyyy.toString()}`}
                            width={3} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                        </SeriesDirective>
                    </SeriesCollectionDirective>
                </ChartComponent>
            </div>
            <div id={`divChartBarreFatturato_${index.toString()}`} className="row">
                <ChartComponent id={`ChartFatturatoBarre_${index.toString()}`} style={{ textAlign: "center" }} loaded={this.operazioniDopoCarica}
                    className='col-xs-12 col-sm-12 col-lg-12 col-md-12'
                    primaryXAxis={{ valueType: 'Category', interval: 1, majorGridLines: { width: 0 } }}
                    primaryYAxis={{ majorGridLines: { width: 0 }, majorTickLines: { width: 0 }, lineStyle: { width: 0 }, labelStyle: { color: 'transparent' } }}
                    chartArea={{ border: { width: 0 } }} tooltip={{ enable: true }}
                    width={CustomJS.chartWidth}
                    height={CustomJS.chartHeight} title=''
                    legendSettings={{ visible: true }}>
                    <Inject2 services={[ColumnSeries, Legend, Tooltip, Category, DataLabel]} />
                    <SeriesCollectionDirective>
                        <SeriesDirective xName='x' yName='y' type='Column' name={`Fatturato ${(yyyy - 1).toString()}`}
                            cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                        </SeriesDirective>
                        <SeriesDirective xName='x' yName='y' type='Column' name={`Fatturato ${yyyy.toString()}`}
                            cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                        </SeriesDirective>
                    </SeriesCollectionDirective>
                </ChartComponent>
            </div>
            <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-xs-12 col-sm-12 col-lg-10 col-md-10">
                    <span style={{ fontSize: '1.2vw', fontWeight: 'bold', color: '#133B95' }}>CONFRONTO MENSILE FATTURATO ANNO CORRENTE/ANNO PRECEDENTE</span>
                </div>
            </div>
            <div className='row'>
                <GridComponent id={`GridFatturatoMensile_${index.toString()}`} allowSelection={false} enableHover={false}
                    allowPaging={false} queryCellInfo={this.onQueryCellInfo1.bind(this)} dataBound={this.onDatabound1} enableAltRow={false}
                    allowTextWrap={true}>
                    <ColumnsDirective>
                        <ColumnDirective field='rowDescr' headerText='' width='8%' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='GEN' headerText='GENNAIO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='FEB' headerText='FEBBRAIO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='MAR' headerText='MARZO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='APR' headerText='APRILE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='MAG' headerText='MAGGIO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='GIU' headerText='GIUGNO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='LUG' headerText='LUGLIO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='AGO' headerText='AGOSTO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='SET' headerText='SETTEMBRE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='OTT' headerText='OTTOBRE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='NOV' headerText='NOVEMBRE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='DIC' headerText='DICEMBRE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='TOT' headerText='TOTALI' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                    </ColumnsDirective>
                </GridComponent>
            </div>
        </div>);
    }
    accordContent1Render(index, descrCliente) {
        return (<div>
            <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                    <RadioButtonComponent id={`visioneAndamento2_${index.toString()}`} checked={false} label='Confronto degli andamenti' style={{ fontSize: '0.8vw' }} name={`sceltaVisione2_${index.toString()}`} change={this.cambioVisione2} value="andamento"></RadioButtonComponent>
                    <RadioButtonComponent id={`visioneBarre2_${index.toString()}`} checked={true} label='Confronto dei valori puntuali' name={`sceltaVisione2_${index.toString()}`} change={this.cambioVisione2} value="puntuale"></RadioButtonComponent>
                </div>
            </div>
            <div className="row" style={{ marginTop: '30px' }}>
                <div className="col-xs-12 col-sm-12 col-lg-10 col-md-10">
                    <span style={{ fontSize: '1.5vw', fontWeight: 'bold', color: '#133B95' }}>ANDAMENTO FATTURATO/BUDGET {descrCliente}</span>
                </div>
            </div>
            <div id={`divChartLineeFatturatoBudget_${index.toString()}`} className="row">
                <ChartComponent id={`ChartFatturatoBudgetLinee_${index.toString()}`} style={{ textAlign: "center" }}
                    className='col-xs-12 col-sm-12 col-lg-12 col-md-12'
                    primaryXAxis={{
                        valueType: 'DateTime',
                        labelFormat: 'MMMM',
                        intervalType: 'Months',
                        edgeLabelPlacement: 'Shift',
                        majorGridLines: { width: 0 }
                    }}
                    primaryYAxis={{
                        labelFormat: '{value}',
                        rangePadding: 'None',
                        interval: 20000,
                        lineStyle: { width: 0 },
                        minimum: 0,
                        majorTickLines: { width: 0 },
                        minorTickLines: { width: 0 }
                    }}
                    chartArea={{ border: { width: 0 } }}
                    tooltip={{ enable: true }}
                    width={CustomJS.chartWidth}
                    title=''
                    legendSettings={{ visible: true }}>
                    <Inject2 services={[LineSeries, DateTime, Legend, Tooltip, Trendlines]} />
                    <SeriesCollectionDirective>
                        <SeriesDirective xName='x' yName='y' name={`Budget ${yyyy.toString()}`}
                            width={3} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                        </SeriesDirective>
                        <SeriesDirective xName='x' yName='y' name={`Fatturato ${yyyy.toString()}`}
                            width={3} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                        </SeriesDirective>
                    </SeriesCollectionDirective>
                </ChartComponent>
            </div>
            <div id={`divChartBarreFatturatoBudget_${index.toString()}`} className="row">
                <ChartComponent id={`ChartFatturatoBudgetBarre_${index.toString()}`} style={{ textAlign: "center" }}
                    className='col-xs-12 col-sm-12 col-lg-12 col-md-12'
                    primaryXAxis={{ valueType: 'Category', interval: 1, majorGridLines: { width: 0 } }}
                    primaryYAxis={{ majorGridLines: { width: 0 }, majorTickLines: { width: 0 }, lineStyle: { width: 0 }, labelStyle: { color: 'transparent' } }}
                    chartArea={{ border: { width: 0 } }} tooltip={{ enable: true }}
                    width={CustomJS.chartWidth}
                    height={CustomJS.chartHeight} title=''
                    legendSettings={{ visible: true }}>
                    <Inject2 services={[ColumnSeries, Legend, Tooltip, Category, DataLabel]} />
                    <SeriesCollectionDirective>
                        <SeriesDirective xName='x' yName='y' type='Column' name={`Budget ${yyyy.toString()}`}
                            cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                        </SeriesDirective>
                        <SeriesDirective xName='x' yName='y' type='Column' name={`Fatturato ${yyyy.toString()}`}
                            cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                        </SeriesDirective>
                    </SeriesCollectionDirective>
                </ChartComponent>
            </div>
            <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-xs-12 col-sm-12 col-lg-10 col-md-10">
                    <span style={{ fontSize: '1.2vw', fontWeight: 'bold', color: '#133B95' }}>CONFRONTO MENSILE FATTURATO/BUDGET ANNO CORRENTE</span>
                </div>
            </div>
            <div className='row'>
                <GridComponent id={`GridFatturatoBudgetMensile_${index.toString()}`} allowSelection={false} enableHover={false}
                    allowPaging={false} queryCellInfo={this.onQueryCellInfo2.bind(this)} dataBound={this.onDatabound1} enableAltRow={false}
                    allowTextWrap={true}>
                    <ColumnsDirective>
                        <ColumnDirective field='rowDescr' headerText='' width='8%' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='GEN' headerText='GENNAIO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='FEB' headerText='FEBBRAIO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='MAR' headerText='MARZO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='APR' headerText='APRILE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='MAG' headerText='MAGGIO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='GIU' headerText='GIUGNO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='LUG' headerText='LUGLIO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='AGO' headerText='AGOSTO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='SET' headerText='SETTEMBRE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='OTT' headerText='OTTOBRE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='NOV' headerText='NOVEMBRE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='DIC' headerText='DICEMBRE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        <ColumnDirective field='TOT' headerText='TOTALI' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                    </ColumnsDirective>
                </GridComponent>
            </div>
        </div>);
    }
    accordContent0() {
        return (<React.Fragment>
            <div id='contenutoDinamicoFatturatoAccordion0'></div>
        </React.Fragment>);
    }
    accordContent1() {
        return (<React.Fragment>
            <div id='contenutoDinamicoFatturatoAccordion1'></div>
        </React.Fragment>);
    }
    render() {
        return (<React.Fragment>
            <BlockUi tag='div' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <div className='container-fluid'>
                    <div id='divProgressBar' className='row' style={{ display: 'none' }}>
                        <div className="col-xs-8 col-sm-8 col-lg-8 col-md-8 ms-4 mt-3">
                            <Progress theme={{
                                default: {
                                    symbol: this.state.progress + '%',
                                    trailColor: 'lightblue',
                                    color: 'blue'
                                },
                                active: {
                                    symbol: this.state.progress + '%',
                                    trailColor: 'yellow',
                                    color: 'orange'
                                },
                                success: {
                                    trailColor: 'lime',
                                    color: 'green'
                                }
                            }} percent={this.state.progress} />
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: '30px' }}>
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                            <AccordionComponent id='accordionFatturato' expandMode='Multiple' ref={accord => this.accordInstance = accord}>
                                <AccordionItemsDirective>
                                    <AccordionItemDirective header={this.accordHeader[0]} expanded={true} content={this.accordContent0} iconCss="e-imageAccordion" />
                                    <AccordionItemDirective header={this.accordHeader[1]} expanded={true} content={this.accordContent1} iconCss="e-imageAccordion" />
                                </AccordionItemsDirective>
                            </AccordionComponent>
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    rendereComplete() {
        //document.getElementById('divProgressBar').style.removeProperty('display');  
        // sessionStorage.setItem('MonitorProgressCaricaFatturato', 0);
        // if (sessionStorage.getItem('MonitorProgressCaricaFatturato') === 0)       
        //     document.getElementById('divProgressBar').style.removeProperty('display');  
        // this.progressInterval = setInterval(() => {
        //     this.setState({ progress: sessionStorage.getItem('MonitorProgressCaricaFatturato') });
        //     if (sessionStorage.getItem('MonitorProgressCaricaFatturato') >= 100) {
        //         setTimeout(() => {
        //             document.getElementById('divProgressBar').style.display = 'none'; 
        //         }, 400);
        //         clearInterval(this.progressInterval);                
        //     }
        // }, 500); 
        this.toggleBlocking();
        this.caricaDati();
    }
    caricaDati() {

        let carica1 = () => {
            return new Promise((resolve, reject) => {
                CustomJS.callAPI(`${CustomJS.APIMonitorURLProd}/api/Monitor/leggiClientiFatturato`, '', 'GET', '', retData => {
                    resolve(retData);
                }, error => {
                    reject(error);
                }, true, false);
            });
        };

        let carica2 = () => {
            return new Promise((resolve, reject) => {
                CustomJS.callAPI(`${CustomJS.APIMonitorURLProd}/api/Monitor/leggiClientiBudgetFatturato/${yyyy.toString()}`, '', 'GET', '', retData2 => {
                    resolve(retData2);
                }, error => {
                    reject(error);
                }, true, false);
            });
        };

        let carica3 = () => {
            let Params = {
                "Request": "Documenti",
                "TipoDoc": 2,
                "TipoAnag": "C",
                "DataDocumento_Da": moment(dataInizioAnnoPrec).format('DD/MM/YYYY'),
                "DataDocumento_A": moment(dataCorrente).format('DD/MM/YYYY'),
                "ModOp": 1
            };
            let APIURL = `${CustomJS.APIMyEspertoURLProd}${encodeURIComponent(JSON.stringify(Params))}`;
            let APIHeader = { "Authorization": CustomJS.APIKeyMyEspertoACE }
            return new Promise((resolve, reject) => {
                CustomJS.getRemoteData4(`${APIURL}`, '', 'GET', APIHeader, retData3 => {
                    resolve(retData3);
                }, true, false);
            });
        };

        carica1().then(retData1 => {
            carica2().then(retData2 => {
                carica3().then(retData3 => {
                    let dsFatture = retData3.map(elm => { return { DataDocumento: moment(elm.DataDocumento, 'DD/MM/YYYY').format('YYYYMMDD'), periodo: moment(elm.DataDocumento, 'DD/MM/YYYY').format('YYYYMM'), Cliente: elm.RagSocAnag, PartitaIVA: elm.PartitaIva, ImportoImponibile: elm.TotImponibile }; });
                    let dsClientiFatt = [];
                    retData1.forEach(elm => {
                        if (this.props.sezioniFatturato.indexOf(elm.SEZIONE) > -1) {
                            if (CustomJS.isNullEmpty(elm.DICITURE_FATTURA) && CustomJS.isNullEmpty(elm.PARTITA_IVA))
                                dsClientiFatt.push({
                                    DescrCliente: elm.DESCR_CLIENTE, VisualizzaGrafico: elm.VISUAL_GRAFICO, Sezione: elm.SEZIONE, Ordine: elm.ORDINE, Guid: elm.GUID,
                                    Diciture: _.filter(retData1.map(elm => { return CustomJS.isNullEmpty(elm.PARTITA_IVA) ? elm.DICITURE_FATTURA : null }), elm2 => { return !CustomJS.isNullEmpty(elm2); }),
                                    PartitaIVA: _.filter(retData1.map(elm => { return !CustomJS.isNullEmpty(elm.PARTITA_IVA) ? elm.PARTITA_IVA : null }), elm2 => { return !CustomJS.isNullEmpty(elm2); })
                                })
                            else
                                dsClientiFatt.push({
                                    DescrCliente: elm.DESCR_CLIENTE, Diciture: elm.DICITURE_FATTURA?.indexOf(',') > -1 ? elm.DICITURE_FATTURA.split(',') : elm.DICITURE_FATTURA, Guid: elm.GUID,
                                    PartitaIVA: elm.PARTITA_IVA?.indexOf(',') > -1 ? elm.PARTITA_IVA.split(',') : elm.PARTITA_IVA, VisualizzaGrafico: elm.VISUAL_GRAFICO, Sezione: elm.SEZIONE, Ordine: elm.ORDINE
                                })
                        }
                    });
                    this.incrementoProgress = (100 / (_.filter(dsClientiFatt, elm => { return elm.VisualizzaGrafico === 'S'; }).length)) / 2;
                    _.sortBy(dsClientiFatt, 'Ordine').forEach((elm, idx) => {
                        if (elm.VisualizzaGrafico === 'N')
                            return;
                        sessionStorage.setItem('MonitorProgressCaricaFatturato', parseInt(this.state.progress + parseInt(this.incrementoProgress)));
                        $(`<div id='divStatisticaFatturato${idx}' style='margin-bottom: 100px'></div>`).appendTo('#contenutoDinamicoFatturatoAccordion0');
                        $(`<div id='divStatisticaFatturatoBudget${idx}' style='margin-bottom: 100px'></div>`).appendTo('#contenutoDinamicoFatturatoAccordion1');
                        let data1 = [], data2 = [], data3 = [], data4 = [], data5 = [], data6 = [], GridData1 = [], GridData2 = [], groupedArr1 = [];
                        const dsFattureFiltrato = _.filter(dsFatture, elm2 => {
                            return (_.isArray(elm.PartitaIVA) && !CustomJS.isNullEmpty(_.find(elm.PartitaIVA, elm3 => { return elm3?.indexOf(elm2.PartitaIVA) > -1; })))
                                || (!_.isArray(elm.PartitaIVA) && elm.PartitaIVA?.indexOf(elm2.PartitaIVA) > -1)
                                || (_.isArray(elm.Diciture) && !CustomJS.isNullEmpty(_.find(elm.Diciture, elm3 => { return elm2.Cliente.indexOf(elm3) > -1; })))
                                || (!_.isArray(elm.Diciture) && elm2.Cliente.indexOf(elm.Diciture) > -1)
                        });
                        let dsFattureMeseGiornoAnnoPrec = _.filter(dsFattureFiltrato, elm2 => {
                            return parseInt(elm2.DataDocumento) >= parseInt((yyyy - 1).toString() + mm + '01')
                                && parseInt(elm2.DataDocumento) <= parseInt((yyyy - 1).toString() + mm + dd)
                        });
                        groupedArr1 = _.groupBy(dsFattureFiltrato, 'periodo');
                        for (let ind = 1; ind <= 12; ind++) {
                            let MONTH1 = _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy - 1).toString() + ind.toString().padStart(2, '0') })) });
                            data1.push({
                                x: ind === 1 ? new Date(yyyy, 0, 1) : ind === 2 ? new Date(yyyy, 1, 1) : ind === 3 ? new Date(yyyy, 2, 1) : ind === 4 ? new Date(yyyy, 3, 1)
                                    : ind === 5 ? new Date(yyyy, 4, 1) : ind === 6 ? new Date(yyyy, 5, 1) : ind === 7 ? new Date(yyyy, 6, 1) : ind === 8 ? new Date(yyyy, 7, 1)
                                        : ind === 9 ? new Date(yyyy, 8, 1) : ind === 10 ? new Date(yyyy, 9, 1) : ind === 11 ? new Date(yyyy, 10, 1) : new Date(yyyy, 11, 1), y: MONTH1.length > 0 ? parseFloat(MONTH1[0]?.sum3('ImportoImponibile')).toFixed(0) : 0
                            });
                            data3.push({
                                x: ind === 1 ? 'Gennaio' : ind === 2 ? 'Febbraio' : ind === 3 ? 'Marzo' : ind === 4 ? 'Aprile' : ind === 5 ? 'Maggio' : ind === 6 ? 'Giugno'
                                    : ind === 7 ? 'Luglio' : ind === 8 ? 'Agosto' : ind === 9 ? 'Settembre' : ind === 10 ? 'Ottobre' : ind === 11 ? 'Novembre' : 'Dicembre', y: MONTH1.length > 0 ? parseFloat(MONTH1[0]?.sum3('ImportoImponibile')).toFixed(0) : 0
                            });
                            let MONTH2 = _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + ind.toString().padStart(2, '0') })) });
                            data2.push({
                                x: ind === 1 ? new Date(yyyy, 0, 1) : ind === 2 ? new Date(yyyy, 1, 1) : ind === 3 ? new Date(yyyy, 2, 1) : ind === 4 ? new Date(yyyy, 3, 1)
                                    : ind === 5 ? new Date(yyyy, 4, 1) : ind === 6 ? new Date(yyyy, 5, 1) : ind === 7 ? new Date(yyyy, 6, 1) : ind === 8 ? new Date(yyyy, 7, 1)
                                        : ind === 9 ? new Date(yyyy, 8, 1) : ind === 10 ? new Date(yyyy, 9, 1) : ind === 11 ? new Date(yyyy, 10, 1) : new Date(yyyy, 11, 1), y: MONTH2.length > 0 ? parseFloat(MONTH2[0]?.sum3('ImportoImponibile')).toFixed(0) : 0
                            });
                            data4.push({
                                x: ind === 1 ? 'Gennaio' : ind === 2 ? 'Febbraio' : ind === 3 ? 'Marzo' : ind === 4 ? 'Aprile' : ind === 5 ? 'Maggio' : ind === 6 ? 'Giugno'
                                    : ind === 7 ? 'Luglio' : ind === 8 ? 'Agosto' : ind === 9 ? 'Settembre' : ind === 10 ? 'Ottobre' : ind === 11 ? 'Novembre' : 'Dicembre', y: MONTH2.length > 0 ? parseFloat(MONTH2[0]?.sum3('ImportoImponibile')).toFixed(0) : 0
                            });
                            if (ind.toString().padStart(2, '0') === mm) {
                                let ind2 = new Date().getMonth() + 1;
                                data3.push({
                                    x: ind2 === 1 ? 'Gennaio' : ind2 === 2 ? 'Febbraio' : ind2 === 3 ? 'Marzo' : ind2 === 4 ? 'Aprile' : ind2 === 5 ? 'Maggio' : ind2 === 6 ? 'Giugno'
                                        : ind2 === 7 ? 'Luglio' : ind2 === 8 ? 'Agosto' : ind2 === 9 ? 'Settembre' : ind2 === 10 ? 'Ottobre' : ind2 === 11 ? 'Novembre' : 'Dicembre', y: parseFloat(dsFattureMeseGiornoAnnoPrec?.sum3('ImportoImponibile')).toFixed(0)
                                });
                            }
                            if (!CustomJS.isNullEmpty(_.find(retData2, elm2 => { return elm2.CLIENTE === elm.Guid }))) {
                                data5.push({
                                    x: ind === 1 ? new Date(yyyy, 0, 1) : ind === 2 ? new Date(yyyy, 1, 1) : ind === 3 ? new Date(yyyy, 2, 1) : ind === 4 ? new Date(yyyy, 3, 1)
                                        : ind === 5 ? new Date(yyyy, 4, 1) : ind === 6 ? new Date(yyyy, 5, 1) : ind === 7 ? new Date(yyyy, 6, 1) : ind === 8 ? new Date(yyyy, 7, 1)
                                            : ind === 9 ? new Date(yyyy, 8, 1) : ind === 10 ? new Date(yyyy, 9, 1) : ind === 11 ? new Date(yyyy, 10, 1) : new Date(yyyy, 11, 1), y: parseFloat(_.find(retData2, elm2 => { return elm2.CLIENTE === elm.Guid && elm2.MESE === ind }).BUDGET).toFixed(0)
                                });
                                data6.push({
                                    x: ind === 1 ? 'Gennaio' : ind === 2 ? 'Febbraio' : ind === 3 ? 'Marzo' : ind === 4 ? 'Aprile' : ind === 5 ? 'Maggio' : ind === 6 ? 'Giugno'
                                        : ind === 7 ? 'Luglio' : ind === 8 ? 'Agosto' : ind === 9 ? 'Settembre' : ind === 10 ? 'Ottobre' : ind === 11 ? 'Novembre' : 'Dicembre', y: parseFloat(_.find(retData2, elm2 => { return elm2.CLIENTE === elm.Guid && elm2.MESE === ind }).BUDGET).toFixed(0)
                                });
                            }
                        }
                        let totaleFatturato = (anno, mese) => { let totale = 0; for (let ind = 1; ind <= mese; ind++) { totale += _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === anno.toString() + ind.toString().padStart(2, '0') })) }).length > 0 ? _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === anno.toString() + ind.toString().padStart(2, '0') })) })[0]?.sum3('ImportoImponibile') : 0 } return totale; };
                        let valoreMese = (anno, mese) => { return _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === anno.toString() + mese })) }).length > 0 ? parseFloat(parseFloat(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === anno.toString() + mese })) })[0]?.sum3('ImportoImponibile')).toFixed(0)).toLocaleString('it-IT') : 0 };
                        let valoreMeseNum = (anno, mese) => { return _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === anno.toString() + mese })) }).length > 0 ? parseFloat(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === anno.toString() + mese })) })[0]?.sum3('ImportoImponibile')).toFixed(2) : 0 };
                        let valoreBudgetMese = (mese) => { return parseFloat(_.find(retData2, elm2 => { return elm2.CLIENTE === elm.Guid && elm2.MESE === mese }).BUDGET).toFixed(0) };
                        let totaleBudget = () => { let totale = 0; for (let ind = 1; ind <= 12; ind++) { totale += _.find(retData2, elm2 => { return elm2.CLIENTE === elm.Guid && elm2.MESE === ind }).BUDGET }; return totale; };
                        GridData1.push({
                            rowDescr: `Fatturato ${(yyyy - 1).toString()}`,
                            GEN: valoreMese(yyyy - 1, '01'),
                            FEB: valoreMese(yyyy - 1, '02'),
                            MAR: valoreMese(yyyy - 1, '03'),
                            APR: valoreMese(yyyy - 1, '04'),
                            MAG: valoreMese(yyyy - 1, '05'),
                            GIU: valoreMese(yyyy - 1, '06'),
                            LUG: valoreMese(yyyy - 1, '07'),
                            AGO: valoreMese(yyyy - 1, '08'),
                            SET: valoreMese(yyyy - 1, '09'),
                            OTT: valoreMese(yyyy - 1, '10'),
                            NOV: valoreMese(yyyy - 1, '11'),
                            DIC: valoreMese(yyyy - 1, '12'),
                            TOT: parseFloat(parseFloat(totaleFatturato(yyyy - 1, 12)).toFixed(0)).toLocaleString()
                        });
                        GridData1.push({
                            rowDescr: `Fatturato ${yyyy.toString()}`,
                            GEN: valoreMese(yyyy, '01'),
                            FEB: valoreMese(yyyy, '02'),
                            MAR: valoreMese(yyyy, '03'),
                            APR: valoreMese(yyyy, '04'),
                            MAG: valoreMese(yyyy, '05'),
                            GIU: valoreMese(yyyy, '06'),
                            LUG: valoreMese(yyyy, '07'),
                            AGO: valoreMese(yyyy, '08'),
                            SET: valoreMese(yyyy, '09'),
                            OTT: valoreMese(yyyy, '10'),
                            NOV: valoreMese(yyyy, '11'),
                            DIC: valoreMese(yyyy, '12'),
                            TOT: parseFloat(parseFloat(totaleFatturato(yyyy, 12)).toFixed(0)).toLocaleString()
                        });
                        GridData1.push({
                            rowDescr: `Delta %`,
                            GEN: `${valoreMeseNum(yyyy - 1, '01') === 0 ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '01') - valoreMeseNum(yyyy - 1, '01')) / valoreMeseNum(yyyy - 1, '01')) * 100).toFixed(2)).toLocaleString()}%`,
                            FEB: `${valoreMeseNum(yyyy - 1, '02') === 0 ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '02') - valoreMeseNum(yyyy - 1, '02')) / valoreMeseNum(yyyy - 1, '02')) * 100).toFixed(2)).toLocaleString()}%`,
                            MAR: `${valoreMeseNum(yyyy - 1, '03') === 0 ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '03') - valoreMeseNum(yyyy - 1, '03')) / valoreMeseNum(yyyy - 1, '03')) * 100).toFixed(2)).toLocaleString()}%`,
                            APR: `${valoreMeseNum(yyyy - 1, '04') === 0 ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '04') - valoreMeseNum(yyyy - 1, '04')) / valoreMeseNum(yyyy - 1, '04')) * 100).toFixed(2)).toLocaleString()}%`,
                            MAG: `${valoreMeseNum(yyyy - 1, '05') === 0 ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '05') - valoreMeseNum(yyyy - 1, '05')) / valoreMeseNum(yyyy - 1, '05')) * 100).toFixed(2)).toLocaleString()}%`,
                            GIU: `${valoreMeseNum(yyyy - 1, '06') === 0 ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '06') - valoreMeseNum(yyyy - 1, '06')) / valoreMeseNum(yyyy - 1, '06')) * 100).toFixed(2)).toLocaleString()}%`,
                            LUG: `${valoreMeseNum(yyyy - 1, '07') === 0 ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '07') - valoreMeseNum(yyyy - 1, '07')) / valoreMeseNum(yyyy - 1, '07')) * 100).toFixed(2)).toLocaleString()}%`,
                            AGO: `${valoreMeseNum(yyyy - 1, '08') === 0 ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '08') - valoreMeseNum(yyyy - 1, '08')) / valoreMeseNum(yyyy - 1, '08')) * 100).toFixed(2)).toLocaleString()}%`,
                            SET: `${valoreMeseNum(yyyy - 1, '09') === 0 ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '09') - valoreMeseNum(yyyy - 1, '09')) / valoreMeseNum(yyyy - 1, '09')) * 100).toFixed(2)).toLocaleString()}%`,
                            OTT: `${valoreMeseNum(yyyy - 1, '10') === 0 ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '10') - valoreMeseNum(yyyy - 1, '10')) / valoreMeseNum(yyyy - 1, '10')) * 100).toFixed(2)).toLocaleString()}%`,
                            NOV: `${valoreMeseNum(yyyy - 1, '11') === 0 ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '11') - valoreMeseNum(yyyy - 1, '11')) / valoreMeseNum(yyyy - 1, '11')) * 100).toFixed(2)).toLocaleString()}%`,
                            DEC: `${valoreMeseNum(yyyy - 1, '12') === 0 ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '12') - valoreMeseNum(yyyy - 1, '12')) / valoreMeseNum(yyyy - 1, '12')) * 100).toFixed(2)).toLocaleString()}%`,
                            //TOT: `${parseFloat(parseFloat(((totaleFatturato(yyyy, mm) - totaleFatturato(yyyy-1, mm)) / totaleFatturato(yyyy-1, mm)) * 100).toFixed(2)).toLocaleString()}%` 
                        });
                        GridData1.push({
                            rowDescr: `Delta % Incrementale`,
                            GEN: `${valoreMeseNum(yyyy - 1, '01') === 0 ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '01') - valoreMeseNum(yyyy - 1, '01')) / valoreMeseNum(yyyy - 1, '01')) * 100).toFixed(2)).toLocaleString()}%`,
                            FEB: `${(parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02'))) === 0 ? '100'
                                : parseFloat(parseFloat((((parseFloat(valoreMeseNum(yyyy, '01')) + parseFloat(valoreMeseNum(yyyy, '02'))) - (parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02'))))
                                    / (parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')))) * 100).toFixed(2)).toLocaleString()}%`,
                            MAR: `${(parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03'))) === 0 ? '100'
                                : parseFloat(parseFloat((((parseFloat(valoreMeseNum(yyyy, '01')) + parseFloat(valoreMeseNum(yyyy, '02')) + parseFloat(valoreMeseNum(yyyy, '03'))) - (parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03'))))
                                    / (parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')))) * 100).toFixed(2)).toLocaleString()}%`,
                            APR: `${(parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04'))) === 0 ? '100'
                                : parseFloat(parseFloat((((parseFloat(valoreMeseNum(yyyy, '01')) + parseFloat(valoreMeseNum(yyyy, '02')) + parseFloat(valoreMeseNum(yyyy, '03')) + parseFloat(valoreMeseNum(yyyy, '04'))) - (parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04'))))
                                    / (parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')))) * 100).toFixed(2)).toLocaleString()}%`,
                            MAG: `${(parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')) + parseFloat(valoreMeseNum(yyyy - 1, '05'))) === 0 ? '100'
                                : parseFloat(parseFloat((((parseFloat(valoreMeseNum(yyyy, '01')) + parseFloat(valoreMeseNum(yyyy, '02')) + parseFloat(valoreMeseNum(yyyy, '03')) + parseFloat(valoreMeseNum(yyyy, '04')) + parseFloat(valoreMeseNum(yyyy, '05'))) - (parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')) + parseFloat(valoreMeseNum(yyyy - 1, '05'))))
                                    / (parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')) + parseFloat(valoreMeseNum(yyyy - 1, '05')))) * 100).toFixed(2)).toLocaleString()}%`,
                            GIU: `${(parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')) + parseFloat(valoreMeseNum(yyyy - 1, '05')) + parseFloat(valoreMeseNum(yyyy - 1, '06'))) === 0 ? '100'
                                : parseFloat(parseFloat((((parseFloat(valoreMeseNum(yyyy, '01')) + parseFloat(valoreMeseNum(yyyy, '02')) + parseFloat(valoreMeseNum(yyyy, '03')) + parseFloat(valoreMeseNum(yyyy, '04')) + parseFloat(valoreMeseNum(yyyy, '05')) + parseFloat(valoreMeseNum(yyyy, '06'))) - (parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')) + parseFloat(valoreMeseNum(yyyy - 1, '05')) + parseFloat(valoreMeseNum(yyyy - 1, '06'))))
                                    / (parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')) + parseFloat(valoreMeseNum(yyyy - 1, '05')) + parseFloat(valoreMeseNum(yyyy - 1, '06')))) * 100).toFixed(2)).toLocaleString()}%`,
                            LUG: `${(parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')) + parseFloat(valoreMeseNum(yyyy - 1, '05')) + parseFloat(valoreMeseNum(yyyy - 1, '06')) + parseFloat(valoreMeseNum(yyyy - 1, '07'))) === 0 ? '100'
                                : parseFloat(parseFloat((((parseFloat(valoreMeseNum(yyyy, '01')) + parseFloat(valoreMeseNum(yyyy, '02')) + parseFloat(valoreMeseNum(yyyy, '03')) + parseFloat(valoreMeseNum(yyyy, '04')) + parseFloat(valoreMeseNum(yyyy, '05')) + parseFloat(valoreMeseNum(yyyy, '06')) + parseFloat(valoreMeseNum(yyyy, '07'))) - (parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')) + parseFloat(valoreMeseNum(yyyy - 1, '05')) + parseFloat(valoreMeseNum(yyyy - 1, '06')) + parseFloat(valoreMeseNum(yyyy - 1, '07'))))
                                    / (parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')) + parseFloat(valoreMeseNum(yyyy - 1, '05')) + parseFloat(valoreMeseNum(yyyy - 1, '06')) + parseFloat(valoreMeseNum(yyyy - 1, '07')))) * 100).toFixed(2)).toLocaleString()}%`,
                            AGO: `${(parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')) + parseFloat(valoreMeseNum(yyyy - 1, '05')) + parseFloat(valoreMeseNum(yyyy - 1, '06')) + parseFloat(valoreMeseNum(yyyy - 1, '07')) + parseFloat(valoreMeseNum(yyyy - 1, '08'))) === 0 ? '100'
                                : parseFloat(parseFloat((((parseFloat(valoreMeseNum(yyyy, '01')) + parseFloat(valoreMeseNum(yyyy, '02')) + parseFloat(valoreMeseNum(yyyy, '03')) + parseFloat(valoreMeseNum(yyyy, '04')) + parseFloat(valoreMeseNum(yyyy, '05')) + parseFloat(valoreMeseNum(yyyy, '06')) + parseFloat(valoreMeseNum(yyyy, '07')) + parseFloat(valoreMeseNum(yyyy, '08'))) - (parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')) + parseFloat(valoreMeseNum(yyyy - 1, '05')) + parseFloat(valoreMeseNum(yyyy - 1, '06')) + parseFloat(valoreMeseNum(yyyy - 1, '07')) + parseFloat(valoreMeseNum(yyyy - 1, '08'))))
                                    / (parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')) + parseFloat(valoreMeseNum(yyyy - 1, '05')) + parseFloat(valoreMeseNum(yyyy - 1, '06')) + parseFloat(valoreMeseNum(yyyy - 1, '07')) + parseFloat(valoreMeseNum(yyyy - 1, '08')))) * 100).toFixed(2)).toLocaleString()}%`,
                            SET: `${(parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')) + parseFloat(valoreMeseNum(yyyy - 1, '05')) + parseFloat(valoreMeseNum(yyyy - 1, '06')) + parseFloat(valoreMeseNum(yyyy - 1, '07')) + parseFloat(valoreMeseNum(yyyy - 1, '08')) + parseFloat(valoreMeseNum(yyyy - 1, '09'))) === 0 ? '100'
                                : parseFloat(parseFloat((((parseFloat(valoreMeseNum(yyyy, '01')) + parseFloat(valoreMeseNum(yyyy, '02')) + parseFloat(valoreMeseNum(yyyy, '03')) + parseFloat(valoreMeseNum(yyyy, '04')) + parseFloat(valoreMeseNum(yyyy, '05')) + parseFloat(valoreMeseNum(yyyy, '06')) + parseFloat(valoreMeseNum(yyyy, '07')) + parseFloat(valoreMeseNum(yyyy, '08')) + parseFloat(valoreMeseNum(yyyy, '09'))) - (parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')) + parseFloat(valoreMeseNum(yyyy - 1, '05')) + parseFloat(valoreMeseNum(yyyy - 1, '06')) + parseFloat(valoreMeseNum(yyyy - 1, '07')) + parseFloat(valoreMeseNum(yyyy - 1, '08')) + parseFloat(valoreMeseNum(yyyy - 1, '09'))))
                                    / (parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')) + parseFloat(valoreMeseNum(yyyy - 1, '05')) + parseFloat(valoreMeseNum(yyyy - 1, '06')) + parseFloat(valoreMeseNum(yyyy - 1, '07')) + parseFloat(valoreMeseNum(yyyy - 1, '08')) + parseFloat(valoreMeseNum(yyyy - 1, '09')))) * 100).toFixed(2)).toLocaleString()}%`,
                            OTT: `${(parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')) + parseFloat(valoreMeseNum(yyyy - 1, '05')) + parseFloat(valoreMeseNum(yyyy - 1, '06')) + parseFloat(valoreMeseNum(yyyy - 1, '07')) + parseFloat(valoreMeseNum(yyyy - 1, '08')) + parseFloat(valoreMeseNum(yyyy - 1, '09')) + parseFloat(valoreMeseNum(yyyy - 1, '10'))) === 0 ? '100'
                                : parseFloat(parseFloat((((parseFloat(valoreMeseNum(yyyy, '01')) + parseFloat(valoreMeseNum(yyyy, '02')) + parseFloat(valoreMeseNum(yyyy, '03')) + parseFloat(valoreMeseNum(yyyy, '04')) + parseFloat(valoreMeseNum(yyyy, '05')) + parseFloat(valoreMeseNum(yyyy, '06')) + parseFloat(valoreMeseNum(yyyy, '07')) + parseFloat(valoreMeseNum(yyyy, '08')) + parseFloat(valoreMeseNum(yyyy, '09')) + parseFloat(valoreMeseNum(yyyy, '10'))) - (parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')) + parseFloat(valoreMeseNum(yyyy - 1, '05')) + parseFloat(valoreMeseNum(yyyy - 1, '06')) + parseFloat(valoreMeseNum(yyyy - 1, '07')) + parseFloat(valoreMeseNum(yyyy - 1, '08')) + parseFloat(valoreMeseNum(yyyy - 1, '09')) + parseFloat(valoreMeseNum(yyyy - 1, '10'))))
                                    / (parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')) + parseFloat(valoreMeseNum(yyyy - 1, '05')) + parseFloat(valoreMeseNum(yyyy - 1, '06')) + parseFloat(valoreMeseNum(yyyy - 1, '07')) + parseFloat(valoreMeseNum(yyyy - 1, '08')) + parseFloat(valoreMeseNum(yyyy - 1, '09')) + parseFloat(valoreMeseNum(yyyy - 1, '10')))) * 100).toFixed(2)).toLocaleString()}%`,
                            NOV: `${(parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')) + parseFloat(valoreMeseNum(yyyy - 1, '05')) + parseFloat(valoreMeseNum(yyyy - 1, '06')) + parseFloat(valoreMeseNum(yyyy - 1, '07')) + parseFloat(valoreMeseNum(yyyy - 1, '08')) + parseFloat(valoreMeseNum(yyyy - 1, '09')) + parseFloat(valoreMeseNum(yyyy - 1, '10')) + parseFloat(valoreMeseNum(yyyy - 1, '11'))) === 0 ? '100'
                                : parseFloat(parseFloat((((parseFloat(valoreMeseNum(yyyy, '01')) + parseFloat(valoreMeseNum(yyyy, '02')) + parseFloat(valoreMeseNum(yyyy, '03')) + parseFloat(valoreMeseNum(yyyy, '04')) + parseFloat(valoreMeseNum(yyyy, '05')) + parseFloat(valoreMeseNum(yyyy, '06')) + parseFloat(valoreMeseNum(yyyy, '07')) + parseFloat(valoreMeseNum(yyyy, '08')) + parseFloat(valoreMeseNum(yyyy, '09')) + parseFloat(valoreMeseNum(yyyy, '10')) + parseFloat(valoreMeseNum(yyyy, '11'))) - (parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')) + parseFloat(valoreMeseNum(yyyy - 1, '05')) + parseFloat(valoreMeseNum(yyyy - 1, '06')) + parseFloat(valoreMeseNum(yyyy - 1, '07')) + parseFloat(valoreMeseNum(yyyy - 1, '08')) + parseFloat(valoreMeseNum(yyyy - 1, '09')) + parseFloat(valoreMeseNum(yyyy - 1, '10')) + parseFloat(valoreMeseNum(yyyy - 1, '11'))))
                                    / (parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')) + parseFloat(valoreMeseNum(yyyy - 1, '05')) + parseFloat(valoreMeseNum(yyyy - 1, '06')) + parseFloat(valoreMeseNum(yyyy - 1, '07')) + parseFloat(valoreMeseNum(yyyy - 1, '08')) + parseFloat(valoreMeseNum(yyyy - 1, '09')) + parseFloat(valoreMeseNum(yyyy - 1, '10')) + parseFloat(valoreMeseNum(yyyy - 1, '11')))) * 100).toFixed(2)).toLocaleString()}%`,
                            DEC: `${(parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')) + parseFloat(valoreMeseNum(yyyy - 1, '05')) + parseFloat(valoreMeseNum(yyyy - 1, '06')) + parseFloat(valoreMeseNum(yyyy - 1, '07')) + parseFloat(valoreMeseNum(yyyy - 1, '08')) + parseFloat(valoreMeseNum(yyyy - 1, '09')) + parseFloat(valoreMeseNum(yyyy - 1, '10')) + parseFloat(valoreMeseNum(yyyy - 1, '11')) + parseFloat(valoreMeseNum(yyyy - 1, '12'))) === 0 ? '100'
                                : parseFloat(parseFloat((((parseFloat(valoreMeseNum(yyyy, '01')) + parseFloat(valoreMeseNum(yyyy, '02')) + parseFloat(valoreMeseNum(yyyy, '03')) + parseFloat(valoreMeseNum(yyyy, '04')) + parseFloat(valoreMeseNum(yyyy, '05')) + parseFloat(valoreMeseNum(yyyy, '06')) + parseFloat(valoreMeseNum(yyyy, '07')) + parseFloat(valoreMeseNum(yyyy, '08')) + parseFloat(valoreMeseNum(yyyy, '09')) + parseFloat(valoreMeseNum(yyyy, '10')) + parseFloat(valoreMeseNum(yyyy, '11')) + parseFloat(valoreMeseNum(yyyy, '12'))) - (parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')) + parseFloat(valoreMeseNum(yyyy - 1, '05')) + parseFloat(valoreMeseNum(yyyy - 1, '06')) + parseFloat(valoreMeseNum(yyyy - 1, '07')) + parseFloat(valoreMeseNum(yyyy - 1, '08')) + parseFloat(valoreMeseNum(yyyy - 1, '09')) + parseFloat(valoreMeseNum(yyyy - 1, '10')) + parseFloat(valoreMeseNum(yyyy - 1, '11')) + parseFloat(valoreMeseNum(yyyy - 1, '12'))))
                                    / (parseFloat(valoreMeseNum(yyyy - 1, '01')) + parseFloat(valoreMeseNum(yyyy - 1, '02')) + parseFloat(valoreMeseNum(yyyy - 1, '03')) + parseFloat(valoreMeseNum(yyyy - 1, '04')) + parseFloat(valoreMeseNum(yyyy - 1, '05')) + parseFloat(valoreMeseNum(yyyy - 1, '06')) + parseFloat(valoreMeseNum(yyyy - 1, '07')) + parseFloat(valoreMeseNum(yyyy - 1, '08')) + parseFloat(valoreMeseNum(yyyy - 1, '09')) + parseFloat(valoreMeseNum(yyyy - 1, '10')) + parseFloat(valoreMeseNum(yyyy - 1, '11')) + parseFloat(valoreMeseNum(yyyy - 1, '12')))) * 100).toFixed(2)).toLocaleString()}%`,
                        });
                        if (data5.length > 0) {
                            GridData2.push({
                                rowDescr: `Budget ${yyyy.toString()}`,
                                GEN: parseFloat(valoreBudgetMese(1)).toLocaleString('it-IT'),
                                FEB: parseFloat(valoreBudgetMese(2)).toLocaleString('it-IT'),
                                MAR: parseFloat(valoreBudgetMese(3)).toLocaleString('it-IT'),
                                APR: parseFloat(valoreBudgetMese(4)).toLocaleString('it-IT'),
                                MAG: parseFloat(valoreBudgetMese(5)).toLocaleString('it-IT'),
                                GIU: parseFloat(valoreBudgetMese(6)).toLocaleString('it-IT'),
                                LUG: parseFloat(valoreBudgetMese(7)).toLocaleString('it-IT'),
                                AGO: parseFloat(valoreBudgetMese(8)).toLocaleString('it-IT'),
                                SET: parseFloat(valoreBudgetMese(9)).toLocaleString('it-IT'),
                                OTT: parseFloat(valoreBudgetMese(10)).toLocaleString('it-IT'),
                                NOV: parseFloat(valoreBudgetMese(11)).toLocaleString('it-IT'),
                                DIC: parseFloat(valoreBudgetMese(12)).toLocaleString('it-IT'),
                                TOT: parseFloat(parseFloat(totaleBudget()).toFixed(0)).toLocaleString()
                            });
                            GridData2.push({
                                rowDescr: `Fatturato ${yyyy.toString()}`,
                                GEN: valoreMese(yyyy, '01'),
                                FEB: valoreMese(yyyy, '02'),
                                MAR: valoreMese(yyyy, '03'),
                                APR: valoreMese(yyyy, '04'),
                                MAG: valoreMese(yyyy, '05'),
                                GIU: valoreMese(yyyy, '06'),
                                LUG: valoreMese(yyyy, '07'),
                                AGO: valoreMese(yyyy, '08'),
                                SET: valoreMese(yyyy, '09'),
                                OTT: valoreMese(yyyy, '10'),
                                NOV: valoreMese(yyyy, '11'),
                                DIC: valoreMese(yyyy, '12'),
                                TOT: parseFloat(parseFloat(totaleFatturato(yyyy, 12)).toFixed(0)).toLocaleString()
                            });
                            GridData2.push({
                                rowDescr: `Delta %`,
                                GEN: `${valoreBudgetMese(1) === '0' ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '01') - valoreBudgetMese(1)) / valoreBudgetMese(1)) * 100).toFixed(2)).toLocaleString()}%`,
                                FEB: `${valoreBudgetMese(2) === '0' ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '02') - valoreBudgetMese(2)) / valoreBudgetMese(2)) * 100).toFixed(2)).toLocaleString()}%`,
                                MAR: `${valoreBudgetMese(3) === '0' ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '03') - valoreBudgetMese(3)) / valoreBudgetMese(3)) * 100).toFixed(2)).toLocaleString()}%`,
                                APR: `${valoreBudgetMese(4) === '0' ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '04') - valoreBudgetMese(4)) / valoreBudgetMese(4)) * 100).toFixed(2)).toLocaleString()}%`,
                                MAG: `${valoreBudgetMese(5) === '0' ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '05') - valoreBudgetMese(5)) / valoreBudgetMese(5)) * 100).toFixed(2)).toLocaleString()}%`,
                                GIU: `${valoreBudgetMese(6) === '0' ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '06') - valoreBudgetMese(6)) / valoreBudgetMese(6)) * 100).toFixed(2)).toLocaleString()}%`,
                                LUG: `${valoreBudgetMese(7) === '0' ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '07') - valoreBudgetMese(7)) / valoreBudgetMese(7)) * 100).toFixed(2)).toLocaleString()}%`,
                                AGO: `${valoreBudgetMese(8) === '0' ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '08') - valoreBudgetMese(8)) / valoreBudgetMese(8)) * 100).toFixed(2)).toLocaleString()}%`,
                                SET: `${valoreBudgetMese(9) === '0' ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '09') - valoreBudgetMese(9)) / valoreBudgetMese(9)) * 100).toFixed(2)).toLocaleString()}%`,
                                OTT: `${valoreBudgetMese(10) === '0' ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '10') - valoreBudgetMese(10)) / valoreBudgetMese(10)) * 100).toFixed(2)).toLocaleString()}%`,
                                NOV: `${valoreBudgetMese(11) === '0' ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '11') - valoreBudgetMese(11)) / valoreBudgetMese(11)) * 100).toFixed(2)).toLocaleString()}%`,
                                DEC: `${valoreBudgetMese(12) === '0' ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '12') - valoreBudgetMese(12)) / valoreBudgetMese(12)) * 100).toFixed(2)).toLocaleString()}%`,
                                //TOT: `${parseFloat(parseFloat(((totaleFatturato(yyyy, mm) - totaleFatturato(yyyy-1, mm)) / totaleFatturato(yyyy-1, mm)) * 100).toFixed(2)).toLocaleString()}%` 
                            });
                            GridData2.push({
                                rowDescr: `Delta % Incrementale`,
                                GEN: `${valoreBudgetMese(1) === '0' ? '100' : parseFloat(parseFloat(((valoreMeseNum(yyyy, '01') - valoreBudgetMese(1)) / valoreBudgetMese(1)) * 100).toFixed(2)).toLocaleString()}%`,
                                FEB: `${(parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2))) === 0 ? '100'
                                    : parseFloat(parseFloat((((parseFloat(valoreMeseNum(yyyy, '01')) + parseFloat(valoreMeseNum(yyyy, '02'))) - (parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2))))
                                        / (parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)))) * 100).toFixed(2)).toLocaleString()}%`,
                                MAR: `${(parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3))) === 0 ? '100'
                                    : parseFloat(parseFloat((((parseFloat(valoreMeseNum(yyyy, '01')) + parseFloat(valoreMeseNum(yyyy, '02')) + parseFloat(valoreMeseNum(yyyy, '03'))) - (parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3))))
                                        / (parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)))) * 100).toFixed(2)).toLocaleString()}%`,
                                APR: `${(parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4))) === 0 ? '100'
                                    : parseFloat(parseFloat((((parseFloat(valoreMeseNum(yyyy, '01')) + parseFloat(valoreMeseNum(yyyy, '02')) + parseFloat(valoreMeseNum(yyyy, '03')) + parseFloat(valoreMeseNum(yyyy, '04'))) - (parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4))))
                                        / (parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)))) * 100).toFixed(2)).toLocaleString()}%`,
                                MAG: `${(parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)) + parseFloat(valoreBudgetMese(5))) === 0 ? '100'
                                    : parseFloat(parseFloat((((parseFloat(valoreMeseNum(yyyy, '01')) + parseFloat(valoreMeseNum(yyyy, '02')) + parseFloat(valoreMeseNum(yyyy, '03')) + parseFloat(valoreMeseNum(yyyy, '04')) + parseFloat(valoreMeseNum(yyyy, '05'))) - (parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)) + parseFloat(valoreBudgetMese(5))))
                                        / (parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)) + parseFloat(valoreBudgetMese(5)))) * 100).toFixed(2)).toLocaleString()}%`,
                                GIU: `${(parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)) + parseFloat(valoreBudgetMese(5)) + parseFloat(valoreBudgetMese(6))) === 0 ? '100'
                                    : parseFloat(parseFloat((((parseFloat(valoreMeseNum(yyyy, '01')) + parseFloat(valoreMeseNum(yyyy, '02')) + parseFloat(valoreMeseNum(yyyy, '03')) + parseFloat(valoreMeseNum(yyyy, '04')) + parseFloat(valoreMeseNum(yyyy, '05')) + parseFloat(valoreMeseNum(yyyy, '06'))) - (parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)) + parseFloat(valoreBudgetMese(5)) + parseFloat(valoreBudgetMese(6))))
                                        / (parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)) + parseFloat(valoreBudgetMese(5)) + parseFloat(valoreBudgetMese(6)))) * 100).toFixed(2)).toLocaleString()}%`,
                                LUG: `${(parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)) + parseFloat(valoreBudgetMese(5)) + parseFloat(valoreBudgetMese(6)) + parseFloat(valoreBudgetMese(7))) === 0 ? '100'
                                    : parseFloat(parseFloat((((parseFloat(valoreMeseNum(yyyy, '01')) + parseFloat(valoreMeseNum(yyyy, '02')) + parseFloat(valoreMeseNum(yyyy, '03')) + parseFloat(valoreMeseNum(yyyy, '04')) + parseFloat(valoreMeseNum(yyyy, '05')) + parseFloat(valoreMeseNum(yyyy, '06')) + parseFloat(valoreMeseNum(yyyy, '07'))) - (parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)) + parseFloat(valoreBudgetMese(5)) + parseFloat(valoreBudgetMese(6)) + parseFloat(valoreBudgetMese(7))))
                                        / (parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)) + parseFloat(valoreBudgetMese(5)) + parseFloat(valoreBudgetMese(6)) + parseFloat(valoreBudgetMese(7)))) * 100).toFixed(2)).toLocaleString()}%`,
                                AGO: `${(parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)) + parseFloat(valoreBudgetMese(5)) + parseFloat(valoreBudgetMese(6)) + parseFloat(valoreBudgetMese(7)) + parseFloat(valoreBudgetMese(8))) === 0 ? '100'
                                    : parseFloat(parseFloat((((parseFloat(valoreMeseNum(yyyy, '01')) + parseFloat(valoreMeseNum(yyyy, '02')) + parseFloat(valoreMeseNum(yyyy, '03')) + parseFloat(valoreMeseNum(yyyy, '04')) + parseFloat(valoreMeseNum(yyyy, '05')) + parseFloat(valoreMeseNum(yyyy, '06')) + parseFloat(valoreMeseNum(yyyy, '07')) + parseFloat(valoreMeseNum(yyyy, '08'))) - (parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)) + parseFloat(valoreBudgetMese(5)) + parseFloat(valoreBudgetMese(6)) + parseFloat(valoreBudgetMese(7)) + parseFloat(valoreBudgetMese(8))))
                                        / (parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)) + parseFloat(valoreBudgetMese(5)) + parseFloat(valoreBudgetMese(6)) + parseFloat(valoreBudgetMese(7)) + parseFloat(valoreBudgetMese(8)))) * 100).toFixed(2)).toLocaleString()}%`,
                                SET: `${(parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)) + parseFloat(valoreBudgetMese(5)) + parseFloat(valoreBudgetMese(6)) + parseFloat(valoreBudgetMese(7)) + parseFloat(valoreBudgetMese(8)) + parseFloat(valoreBudgetMese(9))) === 0 ? '100'
                                    : parseFloat(parseFloat((((parseFloat(valoreMeseNum(yyyy, '01')) + parseFloat(valoreMeseNum(yyyy, '02')) + parseFloat(valoreMeseNum(yyyy, '03')) + parseFloat(valoreMeseNum(yyyy, '04')) + parseFloat(valoreMeseNum(yyyy, '05')) + parseFloat(valoreMeseNum(yyyy, '06')) + parseFloat(valoreMeseNum(yyyy, '07')) + parseFloat(valoreMeseNum(yyyy, '08')) + parseFloat(valoreMeseNum(yyyy, '09'))) - (parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)) + parseFloat(valoreBudgetMese(5)) + parseFloat(valoreBudgetMese(6)) + parseFloat(valoreBudgetMese(7)) + parseFloat(valoreBudgetMese(8)) + parseFloat(valoreBudgetMese(9))))
                                        / (parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)) + parseFloat(valoreBudgetMese(5)) + parseFloat(valoreBudgetMese(6)) + parseFloat(valoreBudgetMese(7)) + parseFloat(valoreBudgetMese(8)) + parseFloat(valoreBudgetMese(9)))) * 100).toFixed(2)).toLocaleString()}%`,
                                OTT: `${(parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)) + parseFloat(valoreBudgetMese(5)) + parseFloat(valoreBudgetMese(6)) + parseFloat(valoreBudgetMese(7)) + parseFloat(valoreBudgetMese(8)) + parseFloat(valoreBudgetMese(9)) + parseFloat(valoreBudgetMese(10))) === 0 ? '100'
                                    : parseFloat(parseFloat((((parseFloat(valoreMeseNum(yyyy, '01')) + parseFloat(valoreMeseNum(yyyy, '02')) + parseFloat(valoreMeseNum(yyyy, '03')) + parseFloat(valoreMeseNum(yyyy, '04')) + parseFloat(valoreMeseNum(yyyy, '05')) + parseFloat(valoreMeseNum(yyyy, '06')) + parseFloat(valoreMeseNum(yyyy, '07')) + parseFloat(valoreMeseNum(yyyy, '08')) + parseFloat(valoreMeseNum(yyyy, '09')) + parseFloat(valoreMeseNum(yyyy, '10'))) - (parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)) + parseFloat(valoreBudgetMese(5)) + parseFloat(valoreBudgetMese(6)) + parseFloat(valoreBudgetMese(7)) + parseFloat(valoreBudgetMese(8)) + parseFloat(valoreBudgetMese(9)) + parseFloat(valoreBudgetMese(10))))
                                        / (parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)) + parseFloat(valoreBudgetMese(5)) + parseFloat(valoreBudgetMese(6)) + parseFloat(valoreBudgetMese(7)) + parseFloat(valoreBudgetMese(8)) + parseFloat(valoreBudgetMese(9)) + parseFloat(valoreBudgetMese(10)))) * 100).toFixed(2)).toLocaleString()}%`,
                                NOV: `${(parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)) + parseFloat(valoreBudgetMese(5)) + parseFloat(valoreBudgetMese(6)) + parseFloat(valoreBudgetMese(7)) + parseFloat(valoreBudgetMese(8)) + parseFloat(valoreBudgetMese(9)) + parseFloat(valoreBudgetMese(10)) + parseFloat(valoreBudgetMese(11))) === 0 ? '100'
                                    : parseFloat(parseFloat((((parseFloat(valoreMeseNum(yyyy, '01')) + parseFloat(valoreMeseNum(yyyy, '02')) + parseFloat(valoreMeseNum(yyyy, '03')) + parseFloat(valoreMeseNum(yyyy, '04')) + parseFloat(valoreMeseNum(yyyy, '05')) + parseFloat(valoreMeseNum(yyyy, '06')) + parseFloat(valoreMeseNum(yyyy, '07')) + parseFloat(valoreMeseNum(yyyy, '08')) + parseFloat(valoreMeseNum(yyyy, '09')) + parseFloat(valoreMeseNum(yyyy, '10')) + parseFloat(valoreMeseNum(yyyy, '11'))) - (parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)) + parseFloat(valoreBudgetMese(5)) + parseFloat(valoreBudgetMese(6)) + parseFloat(valoreBudgetMese(7)) + parseFloat(valoreBudgetMese(8)) + parseFloat(valoreBudgetMese(9)) + parseFloat(valoreBudgetMese(10)) + parseFloat(valoreBudgetMese(11))))
                                        / (parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)) + parseFloat(valoreBudgetMese(5)) + parseFloat(valoreBudgetMese(6)) + parseFloat(valoreBudgetMese(7)) + parseFloat(valoreBudgetMese(8)) + parseFloat(valoreBudgetMese(9)) + parseFloat(valoreBudgetMese(10)) + parseFloat(valoreBudgetMese(11)))) * 100).toFixed(2)).toLocaleString()}%`,
                                DEC: `${(parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)) + parseFloat(valoreBudgetMese(5)) + parseFloat(valoreBudgetMese(6)) + parseFloat(valoreBudgetMese(7)) + parseFloat(valoreBudgetMese(8)) + parseFloat(valoreBudgetMese(9)) + parseFloat(valoreBudgetMese(10)) + parseFloat(valoreBudgetMese(11)) + parseFloat(valoreBudgetMese(12))) === 0 ? '100'
                                    : parseFloat(parseFloat((((parseFloat(valoreMeseNum(yyyy, '01')) + parseFloat(valoreMeseNum(yyyy, '02')) + parseFloat(valoreMeseNum(yyyy, '03')) + parseFloat(valoreMeseNum(yyyy, '04')) + parseFloat(valoreMeseNum(yyyy, '05')) + parseFloat(valoreMeseNum(yyyy, '06')) + parseFloat(valoreMeseNum(yyyy, '07')) + parseFloat(valoreMeseNum(yyyy, '08')) + parseFloat(valoreMeseNum(yyyy, '09')) + parseFloat(valoreMeseNum(yyyy, '10')) + parseFloat(valoreMeseNum(yyyy, '11')) + parseFloat(valoreMeseNum(yyyy, '12'))) - (parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)) + parseFloat(valoreBudgetMese(5)) + parseFloat(valoreBudgetMese(6)) + parseFloat(valoreBudgetMese(7)) + parseFloat(valoreBudgetMese(8)) + parseFloat(valoreBudgetMese(9)) + parseFloat(valoreBudgetMese(10)) + parseFloat(valoreBudgetMese(11)) + parseFloat(valoreBudgetMese(12))))
                                        / (parseFloat(valoreBudgetMese(1)) + parseFloat(valoreBudgetMese(2)) + parseFloat(valoreBudgetMese(3)) + parseFloat(valoreBudgetMese(4)) + parseFloat(valoreBudgetMese(5)) + parseFloat(valoreBudgetMese(6)) + parseFloat(valoreBudgetMese(7)) + parseFloat(valoreBudgetMese(8)) + parseFloat(valoreBudgetMese(9)) + parseFloat(valoreBudgetMese(10)) + parseFloat(valoreBudgetMese(11)) + parseFloat(valoreBudgetMese(12)))) * 100).toFixed(2)).toLocaleString()}%`,
                            });
                        }

                        const containerStatFatt = document.getElementById(`divStatisticaFatturato${idx}`);
                        const rootStatFatt = createRoot(containerStatFatt);
                        rootStatFatt.render(<CallbackWrapper callback={() => {
                            document.getElementById(`ChartFatturatoLinee_${idx.toString()}`).ej2_instances[0].series[0].dataSource = data1;
                            document.getElementById(`ChartFatturatoLinee_${idx.toString()}`).ej2_instances[0].series[1].dataSource = data2;
                            document.getElementById(`ChartFatturatoLinee_${idx.toString()}`).ej2_instances[0].series[0].fill = materialColors[4 % 10];
                            document.getElementById(`ChartFatturatoLinee_${idx.toString()}`).ej2_instances[0].series[1].fill = materialColors[5 % 10];
                            document.getElementById(`ChartFatturatoLinee_${idx.toString()}`).ej2_instances[0].primaryYAxis.interval = _.sortBy(_.union(data1, data2), 'y').reverse()[0].y > 250000 ? 25000 : _.sortBy(_.union(data1, data2), 'y').reverse()[0].y > 100000 ? 15000 : _.sortBy(_.union(data1, data2), 'y').reverse()[0].y > 25000 ? 5000 : 1000;
                            document.getElementById(`ChartFatturatoBarre_${idx.toString()}`).ej2_instances[0].series[0].dataSource = data3;
                            document.getElementById(`ChartFatturatoBarre_${idx.toString()}`).ej2_instances[0].series[1].dataSource = data4;
                            document.getElementById(`ChartFatturatoBarre_${idx.toString()}`).ej2_instances[0].series[0].fill = materialColors[4 % 10];
                            document.getElementById(`ChartFatturatoBarre_${idx.toString()}`).ej2_instances[0].series[1].fill = materialColors[5 % 10];
                            document.getElementById(`divChartLineeFatturato_${idx.toString()}`).style.display = 'none';
                            document.getElementById(`GridFatturatoMensile_${idx.toString()}`).ej2_instances[0].dataSource = GridData1;
                            $(`<div id='legendaCustom_${idx.toString()}'><span style="font-size:1.0vw;font-weight:bold;color:#D2691E";>Fatturato da inizio stesso mese e fino a stesso giorno anno precedente : ${parseFloat(parseFloat(dsFattureMeseGiornoAnnoPrec?.sum3('ImportoImponibile')).toFixed(0)).toLocaleString()}</span></div>`).appendTo(`#divChartBarreFatturato_${idx.toString()}`);
                            if (idx > 0) {
                                this.datiTorta.push({ x: elm.DescrCliente, y: parseFloat(GridData1[1].TOT.replaceAll('.', '')), text: `${elm.DescrCliente}: ${parseFloat(GridData1[1].TOT.replaceAll('.', '')).toLocaleString()}`, color: materialColors[random(10) % 10] })
                            }
                        }}>{this.accordContent0Render(idx, elm.DescrCliente)}</CallbackWrapper>);
                        if (data5.length > 0) {
                            const containerStatFattBudg = document.getElementById(`divStatisticaFatturatoBudget${idx}`);
                            const rootStatFattBudg = createRoot(containerStatFattBudg);
                            rootStatFattBudg.render(<CallbackWrapper callback={() => {
                                document.getElementById(`ChartFatturatoBudgetLinee_${idx.toString()}`).ej2_instances[0].series[0].dataSource = data5;
                                document.getElementById(`ChartFatturatoBudgetLinee_${idx.toString()}`).ej2_instances[0].series[1].dataSource = data2;
                                document.getElementById(`ChartFatturatoBudgetLinee_${idx.toString()}`).ej2_instances[0].series[0].fill = materialColors[4 % 10];
                                document.getElementById(`ChartFatturatoBudgetLinee_${idx.toString()}`).ej2_instances[0].series[1].fill = materialColors[5 % 10];
                                document.getElementById(`ChartFatturatoBudgetLinee_${idx.toString()}`).ej2_instances[0].primaryYAxis.interval = _.sortBy(_.union(data1, data2), 'y').reverse()[0].y > 250000 ? 25000 : _.sortBy(_.union(data1, data2), 'y').reverse()[0].y > 100000 ? 15000 : _.sortBy(_.union(data1, data2), 'y').reverse()[0].y > 25000 ? 5000 : 1000;
                                document.getElementById(`ChartFatturatoBudgetBarre_${idx.toString()}`).ej2_instances[0].series[0].dataSource = data6;
                                document.getElementById(`ChartFatturatoBudgetBarre_${idx.toString()}`).ej2_instances[0].series[1].dataSource = data4;
                                document.getElementById(`ChartFatturatoBudgetBarre_${idx.toString()}`).ej2_instances[0].series[0].fill = materialColors[4 % 10];
                                document.getElementById(`ChartFatturatoBudgetBarre_${idx.toString()}`).ej2_instances[0].series[1].fill = materialColors[5 % 10];
                                document.getElementById(`divChartLineeFatturatoBudget_${idx.toString()}`).style.display = 'none';
                                document.getElementById(`GridFatturatoBudgetMensile_${idx.toString()}`).ej2_instances[0].dataSource = GridData2;
                            }}>{this.accordContent1Render(idx, elm.DescrCliente)}</CallbackWrapper>);    
                        }
                    });
                    setTimeout(() => {
                        this.toggleBlocking();
                        var sirTpa = {};
                        var formazione = {};
                        for (let i = 0; i < this.datiTorta.length; i++) {
                            var elm = this.datiTorta[i];
                            if (elm.x.toLowerCase() === 'formazione') {
                                formazione = elm;
                                this.datiTorta.splice(i, 1);
                            }
                        }
                        for (let i = 0; i < this.datiTorta.length; i++) {
                            var elm = this.datiTorta[i];
                            if (elm.x.toLowerCase() === 'sir/tpa') {
                                sirTpa = elm;
                                this.datiTorta.splice(i, 1);
                            }
                        }
                        var nuoviDati = _.sortBy(this.datiTorta, 'y').reverse();
                        nuoviDati.push(formazione);
                        nuoviDati.push(sirTpa);

                        const containerGTorta = document.getElementById('chartTortaFatturato_0');
                        const rootGTorta = createRoot(containerGTorta);
                        //TODO RISOLVERE IL PROBLEMA DEL CARICAMENTO DEL GRAFICO AMCHARTS CHE RALLENTA TUTTO IN MODO INACCETTABILE
                        //rootGTorta.render(<GraficoTorta2 datiFatturato={nuoviDati}></GraficoTorta2>);
                        // rootGTorta.render(<img src={LavoriInCorso} alt='' width='50%' height='auto'/>);
                        rootGTorta.render(<GraficoTortaSync datiFatturato={nuoviDati}></GraficoTortaSync>);

                        //GRAFICO SYNCFUSION
                        // const containerGTorta = document.getElementById('chartTortaFatturato_0');
                        // const rootGTorta = createRoot(containerGTorta);
                        // rootGTorta.render(<CallbackWrapper callback={() => {
                        //     this.pieChartInstance.series[0].dataSource = nuoviDati;
                        // }}><div className='row'>
                        //      <div className='col-xs-12 col-sm-4 offset-sm-4 col-lg-4 offset-lg-4 col-md-4 offset-md-4'>
                        //         <AccumulationChartComponent
                        //             legendSettings={{ visible: false }} ref={pie => this.pieChartInstance = pie}
                        //             enableSmartLabels={true}
                        //             enableAnimation={true}
                        //             center={{ x: '50%', y: '50%' }}
                        //             tooltip={{ enable: true, format: '${point.x} : <b>${point.y}</b>' }}>
                        //             <Inject3 services={[AccumulationLegend, PieSeries, AccumulationTooltip, AccumulationDataLabel]} />
                        //             <AccumulationSeriesCollectionDirective>
                        //                 <AccumulationSeriesDirective xName='x' yName='y'
                        //                     dataLabel={{
                        //                         visible: true, position: 'Outside',
                        //                         connectorStyle: { length: '20px', type: 'Curve' }, name: 'text',
                        //                     }} startAngle='60'>
                        //                 </AccumulationSeriesDirective>
                        //             </AccumulationSeriesCollectionDirective>
                        //         </AccumulationChartComponent></div></div></CallbackWrapper>);
                    }, _.filter(dsClientiFatt, elm => { return elm.VisualizzaGrafico === 'S'; }).length * 1000);
                });
            }).catch((error) => {
                let messaggio = (_.isString(error) && error.indexOf('Not Found')) > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    //onClose: () => toast.dismiss(),
                    position: "top-right",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                })
            });
        }).catch((error) => {
            let messaggio = (_.isString(error) && error.indexOf('Not Found')) > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                //onClose: () => toast.dismiss(),
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            })
        });
    }
    operazioniDopoCarica(args) {
        setTimeout(() => {
            document.getElementById(`${this.id}_Series_0_Point_${parseInt(mm).toString()}`).setAttribute('fill', '#D2691E');
        }, 200);
        setTimeout(() => {
            let d = document.getElementById(`legendaCustom_${this.id.substring(this.id.indexOf('_') + 1)}`);
            //d.style.position = "absolute";
            d.style.left = '1.3vw';
            //d.style.top -= 20;      
        }, 400);
    }
}