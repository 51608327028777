import React, { Component, useEffect } from 'react';
import {setCulture, setCurrencyCode} from '@syncfusion/ej2-base';
import { loadCldr, L10n } from '@syncfusion/ej2-base';
import itNumberData from 'cldr-numbers-full/main/it/numbers.json';
import itTimeZoneData from 'cldr-dates-full/main/it/timeZoneNames.json';
import itCAGregorian from 'cldr-dates-full/main/it/ca-gregorian.json';
import itNumberSystems from 'cldr-core/supplemental/numberingSystems.json';
import itCurrencies from 'cldr-numbers-full/main/it/currencies.json';
import itTranslations from '@syncfusion/ej2-locale/src/it.json';
import { Browser } from '@syncfusion/ej2-base';
import * as CustomJS from './Custom';
import { TabComponent, TabItemDirective, TabItemsDirective, TabAnimationSettings } from '@syncfusion/ej2-react-navigations';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import LogoALLC from './assets/AllConsultingGroupLogo.png';
import LogoDP from './assets/LogoDigitalPraesidium.png';
import ImgTestEnv from './assets/AmbienteTest.png';
import ChartSinistriChiusi from './components/ChartSinistriChiusi';
import ChartSinistriEntratiChiusi from './components/ChartSinistriEntratiChiusi';
import ChartRestituzioniPeriti from './components/ChartRestituzioniPeriti';
import GridTotaliSinistriChiusi from './components/GridTotaliSinistriChiusi';
import GridTotaliSinistriEntratiChiusi from './components/GridTotaliSinistriEntratiChiusi';
import GridReferentiTecnici from './components/GridReferentiTecnici';
import GridReferentiCompagnia from './components/GridReferentiCompagnia';
import GridPeriti from './components/GridPeriti';
import GridSurveyor from './components/GridSurveyor';
import PaginaFormaDiretta from './components/PaginaFormaDiretta';
import GridScaricoCompagnie from './components/GridScaricoCompagnie';
import PaginaObiettivi from './components/PaginaObiettivi';
import PaginaSegreteriaACE from './components/PaginaSegreteriaACE';
import PaginaSegreteriaACS from './components/PaginaSegreteriaACS';
import GridControlloQualita from './components/GridControlloQualita';
import GridControlloReferenti from './components/GridControlloReferenti';
import PaginaSinistriTUS from './components/PaginaSinistriTUS';
import PaginaFatturato from './components/PaginaFatturato';
// import ChartUnipolHome from './components/ChartUnipolHome';
// import GridTotaliUnipolHome from './components/GridTotaliUnipolHome';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import BlockUi from '@availity/block-ui';
import { random, isUndefined } from 'underscore';
import Cookies from 'universal-cookie';

let pageCounter = 0, scrollTabEnabled = false, minutoInizio = 60, primoCaricamento = true, bloccoApplicazione = false;
let scrollTabInterval, scrollPageInterval, scrollAutoInterval;
const tempoCambioTabDef = 120*1000, tempoRicaricaDati = 3600*1000, tempoMinuto = 60*1000, scrollAutoSpeed = 4000, scrollAutoSpeed2 = 50000;
const cookies = new Cookies();
let sezioniFatturato = '';

L10n.load(itTranslations);
setCulture('it');
setCurrencyCode('EUR');
loadCldr(itNumberData, itTimeZoneData, itCAGregorian, itNumberSystems, itCurrencies);

if (cookies.get("AutoMonitor") === 'false' || isUndefined(cookies.get("AutoMonitor"))) {
    if (isUndefined(cookies.get("sipa_monitor"))) {
        bloccoApplicazione = true;
    }
    else {
        let cookieContent = decodeURI(cookies.get("sipa_monitor"));
        let showCookieSplit = cookieContent.split(","); 
        let tokenCheck = showCookieSplit.length > 0 ? showCookieSplit[0] : cookies.get("sipa_monitor"); 
        sezioniFatturato = showCookieSplit.length > 0 ? showCookieSplit[1] : '';
        CustomJS.getRemoteData3(CustomJS.APIUrlProd, 
            { "token": CustomJS.tokenProd, "azione": "checkCookie", "key": tokenCheck },
            'POST', { "Content-Type": "application/x-www-form-urlencoded" }, (retData) => {                           
                if (retData === false)
                    bloccoApplicazione = true;
        }, false, false);
    }
}

//bloccoApplicazione = false;  //solo per test. Commentare in Produzione!!!
//sezioniFatturato = "";   //solo per test. Commentare in Produzione!!!
export default class App extends Component {   
    constructor() {
        super(...arguments);      
        this.headerText = [{ text: "SINISTRI CHIUSI" }, { text: "SINISTRI ENTRATI/CHIUSI" }, { text: "RESTITUZIONI DA PERITI" }, { text: "REFERENTI TECNICI" }, { text: "REFERENTI COMPAGNIA" },
                           { text: "SEGRETERIA ACE" }, { text: "SEGRETERIA ACS" }, { text: "CONTROLLO QUALITÀ" }, { text: "CONTROLLO REFERENTI" }, { text: "PERITI" }, { text: "SURVEYOR" }, 
                           { text: "FORMA DIRETTA" }, { text: "SCARICO COMPAGNIE" }, { text: "OBIETTIVI" }, { text: "SINISTRI IN TUS" }, { text: "FATTURATO" }, { text: "UNIPOL HOME" }]; 
        this.minutoRicarica = minutoInizio;
        this.animationData = ['SlideLeftIn', 'SlideRightIn', 'FadeIn', 'FadeOut', 'FadeZoomIn', 'FadeZoomOut', 'ZoomIn', 'ZoomOut', 'None'];
        this.refreshInterval = null;
        this.minuteInterval = null;
        this.toggleBlocking = this.toggleBlocking.bind(this);
    }  
    state = {
        blocking: false        
    } 
    toggleBlocking() {
        this.setState({blocking: !this.state.blocking});
    }
    content0() {
        return <React.Fragment>
            <ChartSinistriChiusi></ChartSinistriChiusi>
            <div>
                <GridTotaliSinistriChiusi></GridTotaliSinistriChiusi>
            </div>
        </React.Fragment>
    }
    content1() {
        return <React.Fragment>
            <ChartSinistriEntratiChiusi></ChartSinistriEntratiChiusi>       
            <div>
                <GridTotaliSinistriEntratiChiusi></GridTotaliSinistriEntratiChiusi>
            </div>   
            </React.Fragment>;
    }
    content2() {
        return <React.Fragment>
            <ChartRestituzioniPeriti></ChartRestituzioniPeriti>          
            </React.Fragment>;
    }
    content3() {
        return <React.Fragment>
            <GridReferentiTecnici></GridReferentiTecnici>
        </React.Fragment>;
    }
    content4() {
        return <React.Fragment>
            <GridReferentiCompagnia></GridReferentiCompagnia>
        </React.Fragment>;
    }
    content5() {
        return <React.Fragment>
            <PaginaSegreteriaACE></PaginaSegreteriaACE>
        </React.Fragment>;
    }
    content6() {
        return <React.Fragment>
            <PaginaSegreteriaACS></PaginaSegreteriaACS>
        </React.Fragment>;
    }
    content7() {
        return <React.Fragment>
            <GridControlloQualita></GridControlloQualita>
        </React.Fragment>;
    }
    content8() {
        return <React.Fragment>
            <GridControlloReferenti></GridControlloReferenti>
        </React.Fragment>;
    }
    content9() {
        return <React.Fragment>
            <GridPeriti></GridPeriti>
        </React.Fragment>;
    }
    content10() {
        return <React.Fragment>
            <GridSurveyor></GridSurveyor>
        </React.Fragment>;
    }
    content11() {
        return <React.Fragment>
            <PaginaFormaDiretta></PaginaFormaDiretta>
        </React.Fragment>;
    }
    content12() {
        return <React.Fragment>
            <GridScaricoCompagnie></GridScaricoCompagnie>
        </React.Fragment>;
    }  
    content13() {
        return <React.Fragment>
            <PaginaObiettivi></PaginaObiettivi>
        </React.Fragment>;
    }  
    content14() {
        return <React.Fragment>
            <PaginaSinistriTUS></PaginaSinistriTUS>
        </React.Fragment>;
    }  
    content15() {
        return <React.Fragment>
            <PaginaFatturato sezioniFatturato={sezioniFatturato}></PaginaFatturato>
        </React.Fragment>;
    }    
    // content16() {
    //     return <React.Fragment>
    //         <ChartUnipolHome></ChartUnipolHome>       
    //         <div>
    //             <GridTotaliUnipolHome></GridTotaliUnipolHome>
    //         </div>   
    //     </React.Fragment>;
    // }    

    render() {
        if (bloccoApplicazione) {
            return(<React.Fragment>
                <div className='control-section card-control-section basic_card_layout'>
                    <ToastContainer style={{ fontSize: '2.1vw', color: 'purple', fontWeight: 'bold', width: '50%' }}></ToastContainer> 
                    <div className='row'>                                                                                               
                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-3">
                            <img src={LogoALLC} alt='' width='80%' height='auto'/>                                         
                        </div>                                                          
                    </div>
                </div>
            </React.Fragment>);
        }
        return (
            <React.Fragment>   
                <BlockUi tag='div' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>                            
                    <div className='control-section card-control-section basic_card_layout'>  
                       <ToastContainer containerId='toastContainer1' enableMultiContainer={true} style={{ fontSize: '1.0vw', color: 'purple', fontWeight: 'bold', width: '30%' }}></ToastContainer>                  
                       <div className="e-card-resize-container">
                            <div className="card-layout" >
                                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                    <div className="e-card" id="basic_card" >                                        
                                        <div className='row' id='headerResize'>                                                                                               
                                            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-3">
                                                <img id="logoAllC"src={LogoALLC} alt='' width='80%' height='auto'/>                                         
                                            </div>    
                                            <div id="casellaDPLogo" className="col-xs-2 col-sm-2 col-md-2 col-lg-2"> 
                                                <span id="poweradeScritta" style={{ fontSize: '0.9em', verticalAlign: 'top'}}>Powered by&nbsp;&nbsp;</span>  
                                                <img id="imgLogoDp" src={LogoDP} alt='' style={{verticalAlign: 'top'}} width='110' height='auto'/>   
                                            </div>  
                                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3" style={{ paddingTop: '15px'}}>   
                                                <span id='monitorScritta' style={{ fontSize: '2.4vw', fontWeight: 'bolder', color:'#133B95' }}>Monitor</span> 
                                                &nbsp;&nbsp;&nbsp;                     
                                                <span id="relise" style={{ fontSize: '0.6vw', fontWeight: 'bold' }}>Rel. 1.3.8</span>    
                                                {/* <img src={ImgTestEnv} alt='' width='40%' height='auto'/>                                             */}
                                            </div>   
                                            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 text-center"> 
                                                <span id='TestoDatiAggiornati' style={{ fontSize: '0.7vw', fontWeight: 'bold', color:'#133B95'}}></span>  
                                            </div> 
                                            <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">                                                                         
                                                <ButtonComponent id='BtnBloccaPagine' alt='Attiva/Blocca scorrimento automatico pagine' style={{color: 'green'}} onClick={this.clickBottoneBloccaPagine.bind(this)}>Attiva Scorrimento Automatico</ButtonComponent>
                                                <ButtonComponent id='BtnCaricaDati' alt='Ricarica dati Monitor' style={{color: 'green'}}>Ricarica dati Monitor<br/>({this.minutoRicarica.toString()} minuti all' auto ricaricamento)</ButtonComponent>
                                             </div>                                    
                                        </div>
                                        <div className="e-card-content">                                         
                                            <div className='control-section tab-control-section row'>
                                                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>                                            
                                                    <TabComponent id='TabMonitor' cssClass='e-fill' headerPlacement='Top' width='100%' created={this.tabCreated.bind(this)} 
                                                                selected={this.tabSelected.bind(this)} ref={tabComp => this.tabInstance = tabComp} selecting={this.tabSelecting.bind(this)}
                                                                animation={{ previous: { effect: 'FadeZoomIn', duration: 1000, easing: 'ease' }, next: { effect: 'FadeZoomIn', duration: 1000, easing: 'ease' } }}>
                                                        <TabItemsDirective>
                                                            <TabItemDirective header={this.headerText[0]} content={this.content0} width='100%'/>
                                                            <TabItemDirective header={this.headerText[1]} content={this.content1} width='100%'/>
                                                            <TabItemDirective header={this.headerText[2]} content={this.content2} width='100%'/>
                                                            <TabItemDirective header={this.headerText[3]} content={this.content3} width='100%'/>
                                                            <TabItemDirective header={this.headerText[4]} content={this.content4} width='100%'/>
                                                            <TabItemDirective header={this.headerText[5]} content={this.content5} width='100%'/>
                                                            {/* <TabItemDirective header={this.headerText[6]} content={this.content6} width='100%'/> */}
                                                            <TabItemDirective header={this.headerText[7]} content={this.content7} width='100%'/>
                                                            <TabItemDirective header={this.headerText[8]} content={this.content8} width='100%'/>
                                                            <TabItemDirective header={this.headerText[9]} content={this.content9} width='100%'/>
                                                            <TabItemDirective header={this.headerText[10]} content={this.content10} width='100%'/>
                                                            <TabItemDirective header={this.headerText[11]} content={this.content11} width='100%'/>
                                                            <TabItemDirective header={this.headerText[12]} content={this.content12} width='100%'/>
                                                            <TabItemDirective header={this.headerText[13]} content={this.content13} width='100%'/>
                                                            <TabItemDirective header={this.headerText[14]} content={this.content14} width='100%'/>
                                                            <TabItemDirective header={this.headerText[15]} content={this.content15} width='100%'/>
                                                            {/* <TabItemDirective header={this.headerText[16]} content={this.content16} width='100%'/> */}
                                                        </TabItemsDirective>
                                                    </TabComponent>
                                                </div>
                                            </div>                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                    
                    </div>   
                </BlockUi>             
            </React.Fragment>                                        
        );
    }  
    componentDidMount() {   
        if (bloccoApplicazione) {
            toast.warn('ATTENZIONE!! NON SEI ABILITATO/A A VISUALIZZARE IL MONITOR ALL CONSULTING!! L\'APPLICAZIONE E\' VISIBILE SOLO DALL\'INTERNO DEL SISTEMA SIPA...', {
                containerId: 'toastContainer1',
                position: "top-center",
                autoClose: false,                
                hideProgressBar: true,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: false,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }
        else {
            const fnClick = (e) => {
                this.toggleBlocking();              
                cookies.set("AutoMonitor", scrollTabEnabled.toString());       
                window.location.reload();
            };
            document.getElementById('BtnCaricaDati').removeEventListener('click', fnClick);
            document.getElementById('BtnCaricaDati').addEventListener('click', fnClick);     
            if (cookies.get("AutoMonitor") === 'true') {
                scrollTabEnabled = false;  
                document.getElementById('BtnBloccaPagine').click();              
            }   
            if (CustomJS.isNullEmpty(sezioniFatturato))
                this.tabInstance.hideTab(14, true);  
        }
    }
    tabCreated(args) {    
        this.refreshInterval = setInterval(() => {
            document.getElementById('BtnCaricaDati').click();
            this.minutoRicarica = minutoInizio;
        }, tempoRicaricaDati);

        this.minuteInterval = setInterval(() => {
            this.minutoRicarica--;
            if (!Browser.isDevice)
                document.getElementById('BtnCaricaDati').innerHTML = 'Ricarica dati Monitor<br/>(' + this.minutoRicarica.toString() + ' minuti all\' auto ricaricamento)';
        }, tempoMinuto);
    }
    tabSelecting(args) {
        if (args.isSwiped) {
            args.cancel = true;
        }
    }
    tabSelected(args) {        
        pageCounter = args.selectedIndex; 
        if (args.selectedIndex === 0 || args.selectedIndex === 1 || args.selectedIndex === 3 || args.selectedIndex === 7 || args.selectedIndex === 8 || args.selectedIndex === 9 || args.selectedIndex === 10 || args.selectedIndex === 11 || args.selectedIndex === 12 || args.selectedIndex === 13 || args.selectedIndex === 14) {
            if (scrollTabEnabled && document.hasFocus()) {
                setTimeout(() => {               
                    this.scrollAuto((args.selectedIndex === 10 || args.selectedIndex === 14) ? scrollAutoSpeed2 : scrollAutoSpeed);
                    clearInterval(scrollAutoInterval);
                    scrollAutoInterval = null;
                    scrollAutoInterval = setInterval(() => {this.scrollAuto((args.selectedIndex === 10 || args.selectedIndex === 14) ? scrollAutoSpeed2 : scrollAutoSpeed)}, scrollAutoSpeed * 3);     
                }, 200);                
            }
        }   
        else
            clearInterval(scrollAutoInterval);         
        if (args.selectedIndex === 5) {
            if (scrollTabEnabled && document.hasFocus()) {
                setTimeout(() => { window.scrollTo(0,100); }, 200);
                setTimeout(() => { window.scrollTo(0,400); }, 5000);
            }
        }           
        if (args.selectedIndex === 4) {
            clearInterval(scrollPageInterval);
            setTimeout(() => {
                if (document.getElementById('GridReferentiCompagnia').ej2_instances[0].gridPager.ej2_instances[0].currentPage !== 1)
                    document.getElementById('GridReferentiCompagnia').querySelectorAll('.e-first.e-icons.e-icon-first.e-firstpage.e-pager-default')[0].click();
            }, 200);
            scrollPageInterval = setInterval(() => {
                if (scrollTabEnabled && document.hasFocus()) {
                    if (document.getElementById('GridReferentiCompagnia').ej2_instances[0].gridPager.ej2_instances[0].currentPage + 1 <= document.getElementById('GridReferentiCompagnia').ej2_instances[0].gridPager.ej2_instances[0].totalPages)
                        document.getElementById('GridReferentiCompagnia').querySelectorAll('.e-next.e-icons.e-icon-next.e-nextpage.e-pager-default')[0].click();
                    else
                        document.getElementById('GridReferentiCompagnia').querySelectorAll('.e-first.e-icons.e-icon-first.e-firstpage.e-pager-default')[0].click();
                }                
            }, 40*1000);
        }
        else if (args.selectedIndex === 8) {
            clearInterval(scrollPageInterval);
            setTimeout(() => {
                if (document.getElementById('GridPeriti').ej2_instances[0].gridPager.ej2_instances[0].currentPage !== 1)
                    document.getElementById('GridPeriti').querySelectorAll('.e-first.e-icons.e-icon-first.e-firstpage.e-pager-default')[0].click();
            }, 200);
            scrollPageInterval = setInterval(() => {
                if (scrollTabEnabled && document.hasFocus()) {
                    if (document.getElementById('GridPeriti').ej2_instances[0].gridPager.ej2_instances[0].currentPage + 1 <= document.getElementById('GridPeriti').ej2_instances[0].gridPager.ej2_instances[0].totalPages)
                        document.getElementById('GridPeriti').querySelectorAll('.e-next.e-icons.e-icon-next.e-nextpage.e-pager-default')[0].click();
                    else
                        document.getElementById('GridPeriti').querySelectorAll('.e-first.e-icons.e-icon-first.e-firstpage.e-pager-default')[0].click();
                }
            }, 40*1000);
        }
        else if (args.selectedIndex === 9) {
            clearInterval(scrollPageInterval);
            setTimeout(() => {
                if (document.getElementById('GridSurveyor').ej2_instances[0].gridPager.ej2_instances[0].currentPage !== 1)
                    document.getElementById('GridSurveyor').querySelectorAll('.e-first.e-icons.e-icon-first.e-firstpage.e-pager-default')[0].click();
            }, 200);
            scrollPageInterval = setInterval(() => {
                if (scrollTabEnabled && document.hasFocus()) {
                    if (document.getElementById('GridSurveyor').ej2_instances[0].gridPager.ej2_instances[0].currentPage + 1 <= document.getElementById('GridSurveyor').ej2_instances[0].gridPager.ej2_instances[0].totalPages)
                        document.getElementById('GridSurveyor').querySelectorAll('.e-next.e-icons.e-icon-next.e-nextpage.e-pager-default')[0].click();
                    else
                        document.getElementById('GridSurveyor').querySelectorAll('.e-first.e-icons.e-icon-first.e-firstpage.e-pager-default')[0].click();
                }
            }, 40*1000);
        }
        else if (args.selectedIndex === 11) {
            clearInterval(scrollPageInterval);
            setTimeout(() => {
                if (document.getElementById('GridScaricoCompagnie').ej2_instances[0].gridPager.ej2_instances[0].currentPage !== 1)
                    document.getElementById('GridScaricoCompagnie').querySelectorAll('.e-first.e-icons.e-icon-first.e-firstpage.e-pager-default')[0].click();
            }, 200);
            scrollPageInterval = setInterval(() => {
                if (scrollTabEnabled && document.hasFocus()) {
                    if (document.getElementById('GridScaricoCompagnie').ej2_instances[0].gridPager.ej2_instances[0].currentPage + 1 <= document.getElementById('GridScaricoCompagnie').ej2_instances[0].gridPager.ej2_instances[0].totalPages)
                        document.getElementById('GridScaricoCompagnie').querySelectorAll('.e-next.e-icons.e-icon-next.e-nextpage.e-pager-default')[0].click();
                    else
                        document.getElementById('GridScaricoCompagnie').querySelectorAll('.e-first.e-icons.e-icon-first.e-firstpage.e-pager-default')[0].click();
                }
            }, 40*1000);
        }
        else if (args.selectedIndex === 13) {
            clearInterval(scrollPageInterval);
            setTimeout(() => {
                if (document.getElementById('GridSinistriTUS').ej2_instances[0].gridPager.ej2_instances[0].currentPage !== 1)
                    document.getElementById('GridSinistriTUS').querySelectorAll('.e-first.e-icons.e-icon-first.e-firstpage.e-pager-default')[0].click();
            }, 200);
            scrollPageInterval = setInterval(() => {
                if (scrollTabEnabled && document.hasFocus()) {
                    if (document.getElementById('GridSinistriTUS').ej2_instances[0].gridPager.ej2_instances[0].currentPage + 1 <= document.getElementById('GridSinistriTUS').ej2_instances[0].gridPager.ej2_instances[0].totalPages)
                        document.getElementById('GridSinistriTUS').querySelectorAll('.e-next.e-icons.e-icon-next.e-nextpage.e-pager-default')[0].click();
                    else
                        document.getElementById('GridSinistriTUS').querySelectorAll('.e-first.e-icons.e-icon-first.e-firstpage.e-pager-default')[0].click();
                }
            }, 40*1000);
        }
        else
            clearInterval(scrollPageInterval);
        if (scrollTabEnabled) {
            clearInterval(scrollTabInterval);
            scrollTabInterval = null;
            setTimeout(() => {
                scrollTabInterval = setInterval(() => {
                    let tabObj = document.getElementById('TabMonitor');
                    if (document.getElementById('TabMonitor') && document.hasFocus()) {
                        if (pageCounter > 14 || (pageCounter > 13 && CustomJS.isNullEmpty(sezioniFatturato))) 
                            pageCounter = -1;
                        tabObj.ej2_instances[0].select(pageCounter+1);                
                    }
                }, args.selectedIndex === 4 ? (document.getElementById('GridReferentiCompagnia').ej2_instances[0].gridPager.ej2_instances[0].totalPages * 40*1000) :
                   args.selectedIndex === 8 ? (document.getElementById('GridPeriti').ej2_instances[0].gridPager.ej2_instances[0].totalPages * 40*1000) : 
                   args.selectedIndex === 9 ? (document.getElementById('GridSurveyor').ej2_instances[0].gridPager.ej2_instances[0].totalPages * 40*1000) :
                   args.selectedIndex === 10 ? tempoCambioTabDef * 3 :
                   args.selectedIndex === 11 ? (document.getElementById('GridScaricoCompagnie').ej2_instances[0].gridPager.ej2_instances[0].totalPages * 40*1000) :
                   args.selectedIndex === 13 ? (document.getElementById('GridSinistriTUS').ej2_instances[0].gridPager.ej2_instances[0].totalPages * 40*1000) :
                   args.selectedIndex === 14 ? tempoCambioTabDef * 4 : tempoCambioTabDef);
            }, 400);
        }    
    }   
    clickBottoneBloccaPagine(args) {
        if (scrollTabEnabled) {
            clearInterval(scrollTabInterval);
            scrollTabInterval = null;
            clearInterval(scrollPageInterval);
            clearInterval(scrollAutoInterval);
            scrollTabEnabled = false;
            if (document.getElementById('BtnBloccaPagine')) {
                document.getElementById('BtnBloccaPagine').cssClass = 'e-normal';
                document.getElementById('BtnBloccaPagine').style.color = 'green'; 
                document.getElementById('BtnBloccaPagine').innerText = "Attiva Scorrimento Automatico";       
            }     
        }
        else {
            scrollTabInterval = setInterval(() => {
                let tabObj = document.getElementById('TabMonitor');
                if (document.getElementById('TabMonitor') && document.hasFocus()) {
                    if (pageCounter > 14 || (pageCounter > 13 && CustomJS.isNullEmpty(sezioniFatturato))) 
                        pageCounter = -1;
                    tabObj.ej2_instances[0].select(pageCounter+1);                
                }
            }, this.tabInstance.selectedIndex === 4 ? (document.getElementById('GridReferentiCompagnia').ej2_instances[0].gridPager.ej2_instances[0].totalPages * 40*1000) :
               this.tabInstance.selectedIndex === 8 ? (document.getElementById('GridPeriti').ej2_instances[0].gridPager.ej2_instances[0].totalPages * 40*1000) : 
               this.tabInstance.selectedIndex === 9 ? (document.getElementById('GridSurveyor').ej2_instances[0].gridPager.ej2_instances[0].totalPages * 40*1000) :
               this.tabInstance.selectedIndex === 10 ? tempoCambioTabDef * 3 :
               this.tabInstance.selectedIndex === 11 ? (document.getElementById('GridScaricoCompagnie').ej2_instances[0].gridPager.ej2_instances[0].totalPages * 40*1000) :
               this.tabInstance.selectedIndex === 13 ? (document.getElementById('GridSinistriTUS').ej2_instances[0].gridPager.ej2_instances[0].totalPages * 40*1000) :
               this.tabInstance.selectedIndex === 14 ? tempoCambioTabDef * 4 : tempoCambioTabDef);
            scrollTabEnabled = true;
            if (document.getElementById('BtnBloccaPagine')) {
                document.getElementById('BtnBloccaPagine').cssClass = 'e-normal';
                document.getElementById('BtnBloccaPagine').style.color = 'red'; 
                document.getElementById('BtnBloccaPagine').innerText = "Blocca Scorrimento Automatico";      
            }   
            setTimeout(() => {
                toast.warn('ATTENZIONE!! Modalità di scorrimento automatico attivata. Se la gestione del Monitor deve essere manuale, per evitare interferenze a livello operativo, si consiglia di bloccarla, cliccando sul bottone in alto a destra.', {
                    containerId: 'toastContainer1',
                    position: "top-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: false,
                    rtl: false,
                    pauseOnFocusLoss: true
                });    
            }, 400);     
            if (scrollTabEnabled && document.hasFocus() && primoCaricamento) {
                setTimeout(() => {               
                    this.scrollAuto((this.tabInstance.selectedIndex === 10 || this.tabInstance.selectedIndex === 14) ? scrollAutoSpeed2 : scrollAutoSpeed);
                    clearInterval(scrollAutoInterval);
                    scrollAutoInterval = null;
                    scrollAutoInterval = setInterval(() => {this.scrollAuto((this.tabInstance.selectedIndex === 10 || this.tabInstance.selectedIndex === 14) ? scrollAutoSpeed2 : scrollAutoSpeed)}, scrollAutoSpeed * 3);                         
                }, 200);  
                primoCaricamento = false;              
            }     
        }
    }    
    scrollAuto(speed) {
        $('html, body').animate({ scrollTop: $(document).height() - $(window).height() }, speed, function() {
           setTimeout(() => { $(this).animate({ scrollTop: 0 }, speed); }, 5000);
        });
    } 
}