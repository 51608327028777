/* eslint-disable default-case */
import * as React from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Category, ColumnSeries, DataLabel, Tooltip, Legend} from '@syncfusion/ej2-react-charts';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, PdfExport, Page, Filter, Inject as Inject2, Sort } from '@syncfusion/ej2-react-grids';
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { SampleBase } from './sample-base';
import { fabricColors, bootstrapColors, materialColors, highContrastColors } from './theme-color';
import * as CustomJS from '../Custom';
import LavoriInCorso from '../assets/LavoriInCorso2.jpg';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'underscore/underscore-min';
import moment from 'moment';

var dataCorrente = new Date();
var dd = String(dataCorrente.getDate()).padStart(2, '0');
var mm = String(dataCorrente.getMonth() + 1).padStart(2, '0');
var mmNum = parseInt(mm);
var yyyy = dataCorrente.getFullYear();
dataCorrente = yyyy + mm + dd;
let mesiChar = ['GENNAIO', 'FEBBRAIO', 'MARZO', 'APRILE', 'MAGGIO', 'GIUGNO', 'LUGLIO', 'AGOSTO', 'SETTEMBRE', 'OTTOBRE', 'NOVEMBRE', 'DICEMBRE'];
let giorniMese = (mmNum === 1 || mmNum === 3 || mmNum === 5 || mmNum === 7 || mmNum === 8 || mmNum === 10 || mmNum === 12) ? 31 : (mmNum === 4 || mmNum === 6 || mmNum === 9 || mmNum === 11) ? 30 : mmNum === 2 && CustomJS.leapYear ? 29 : 28;  
let data1 = [], data2 = [], data3 = [], dsGriglia = [], dsGriglia2 = [], totaleRestit = 0, totaleChiusi = 0, totaleInChiusura = 0;

function headerTemplate(props) {    
    return (<div>
         <span style={{ fontSize: '0.95vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate(props) {       
    let colore = props.column.field === 'DescrizioneCompagnia' ? '#133B95' : 'purple';
    return (        
         <div><span style={{ fontSize: '1.0vw', color: colore, fontWeight: 'bold'}}>{props[props.column.field]}</span></div>
    );
}

function headerTemplate2(props) {    
    return (<div>
         <span style={{ fontSize: '0.9vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate2(props) {       
    let colore = props.column.field === 'IdSinistro' ? '#133B95' : 'purple';
    return (        
         <div><span style={{ fontSize: '0.8vw', color: colore, fontWeight: 'bold'}}>{props[props.column.field]}</span></div>
    );
}
export default class PaginaSegreteriaACE extends SampleBase {
    constructor() {
        super(...arguments); 
        this.toolbarOptions = ['ExcelExport', 'PdfExport', 'Search'];
        this.toolbarOptions2 = ['ExcelExport', 'Search'];
        this.animationSettings = { effect: 'FadeZoom' };
        //this.accordHeader = ['STATISTICHE GENERALI SEGRETERIA', 'STATISTICHE UTENTI SEGRETERIA']; 
        this.accordHeader = ['STATISTICHE GENERALI SEGRETERIA']; 

        if ((window.matchMedia("(max-width: 850px)")).matches) {
            this.altezza = "250px";
            this.larghezza = "600px";

            this.altezzaDialog = "80%";
            this.altezzaMinDialog = "80%";
            this.larghezzaDialog = "75%";
        }
        else if ((window.matchMedia("(max-width: 1024px)")).matches) {
            this.altezza = "450px";
            this.larghezza = '100%' ;

            this.altezzaDialog = "80%";
            this.altezzaMinDialog = "80%";
            this.larghezzaDialog = "75%";
        }
        else{
            this.altezza = CustomJS.chartHeight;
            this.larghezza = '100%' ;

            this.altezzaDialog = "80%";
            this.altezzaMinDialog = "80%";
            this.larghezzaDialog = "75%";
        }
    }   
    state= {
        showDialog: false,
        dataSerie1: data1,
        dataSerie2: data2,
        dataSerie3: data3,
        totaleChiusi: totaleChiusi,
        totaleRestituiti: totaleRestit,
        totaleInChiusura: totaleInChiusura,
        dsGriglia: dsGriglia,
        dsGriglia2: dsGriglia2   
    } 
    onDatabound(args) {       
        for (let ind=0;ind<=7;ind++)
             document.getElementById('GridSegreteriaACE').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
        document.getElementById('TestoDatiAggiornati').innerText =  'Dati aggiornati al ' + new Date().getDate().toString().padStart(2, '0') + '/' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '/' + new Date().getFullYear().toString() + ' alle ore '  + new Date().getHours().toString() + ':' + new Date().getMinutes().toString().padStart(2, '0');            
    }
    onDatabound2(args) {       
        for (let ind=0;ind<=4;ind++)
             document.getElementById('GridSinistriSospesi').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
    }
    onDatabound3(args) {       
        for (let ind=0;ind<=3;ind++)
             document.getElementById('GridSinistriInChiusura').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
    }
    onRowDatabound(args) {             
        if (args.data.DescrizioneCompagnia.indexOf('TOTALE') > -1) {
            args.row.className = 'e-row';
            args.row.bgColor = 'lightpink';
        }         
    }        
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
    }
    toolbarClick2(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance2.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance2.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance2.csvExport();
                break;
        }
    }
    toolbarClick3(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance3.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance3.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance3.csvExport();
                break;
        }
    }
    tooltipTemplate(props) { 
        let titoloToolTip = props.color === '#70ad47' ? 'Sinistri Chiusi' : props.color === '#dd8abd' ? 'Sinistri Caricati' : 'Sinistri in Chiusura'; 
        let rigaDiCuiSospesi = (props.color === '#70ad47' || props.color === '#dd8abd')  ? '' : <tr><td align='left' style={{ paddingLeft: '3px', fontSize: '0.8vw', color: 'purple', fontWeight: 'bold' }}>(di cui sospesi: {_.find(data2, elm => {return elm.x === props.x})?.diCuiSospesi?.toLocaleString('it-IT')})</td></tr>;
        let rigaOraChiusura = (props.color === '#70ad47' || props.color === '#dd8abd')  ? '' : <tr><td align='left' style={{ paddingLeft: '3px', fontSize: '0.8vw', color: 'purple', fontWeight: 'bold' }}>Ora Ultima Chiusura: {_.find(data2, elm => {return elm.x === props.x})?.oraUltimaChiusura?.substring(0,5)}</td></tr>;
        let rigaOraCaricamento = props.color === '#dd8abd' ? <tr><td align='left' style={{ paddingLeft: '3px', fontSize: '0.8vw', color: 'purple', fontWeight: 'bold' }}>Ora Ultimo Caricamento: {_.find(data3, elm => {return elm.x === props.x})?.oraUltimoCaricamento?.substring(0,5)}</td></tr> : '';
        let testoChiusi = props.color === '#70ad47' ? 'Sinistri Chiusi:' : props.color === '#dd8abd' ? 'Sinistri Caricati:' : 'Sinistri in Chiusura:'; 
        let widthDiv = props.color === '#70ad47' ? '8vw' : '11.5vw';   
        return (<div id="tooltipContainer" className="tooltip-container" style={{ width: widthDiv }}>                              
                <div className="row">
                     <div className="col-lg-12">
                         <span style={{ fontSize: '1.0vw', color: '#133B95', fontWeight: 'bold' }}>{titoloToolTip}</span>
                     </div>
                </div> 
                <div className="row">
                    <hr></hr>
                </div>
                <div className="row">
                     <div className="col-lg-12">
                        <table style={{ width: '100%' }}>
                            <tbody>                                                                         
                                <tr style={{ border: 'none' }}>
                                    <td align='left' style={{ paddingLeft: '3px', fontSize: '0.8vw', color: 'purple', fontWeight: 'bold' }}>Giorno: {props.x}</td>
                                </tr>
                                <tr>
                                    <td align='left' style={{ paddingLeft: '3px', fontSize: '0.8vw', color: props.color , fontWeight: 'bold' }}>{testoChiusi} {props.y}</td>
                                </tr>   
                                {rigaDiCuiSospesi}  
                                {rigaOraChiusura} 
                                {rigaOraCaricamento}                                                         
                            </tbody>
                        </table>
                     </div>                     
                </div> 
            </div>);
    }
    dialogContent() {
        return(<div className="control-section card-control-section basic_card_layout">
            <div className="e-card-resize-container">
                <div className="card-layout">
                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                        <div tabIndex="0" className="e-card" id="main_card">                            
                            <div className="e-card-content">
                                <GridComponent id='GridSinistriSospesi' toolbar={this.toolbarOptions2} allowSelection={false} enableHover={false} allowTextWrap={true} filterSettings={this.filterSettings} enableVirtualization={false} 
                                    allowPaging={true} allowSorting={true} allowFiltering={false} allowExcelExport={true} allowPdfExport={true} pageSettings={{ pageCount: 5, pageSize: 13 }} 
                                    enableAltRow={true} gridLines='Both' toolbarClick={this.toolbarClick2.bind(this)} dataBound={this.onDatabound2.bind(this)} ref={gsp => this.gridInstance2 = gsp}>
                                    <ColumnsDirective>                            
                                        <ColumnDirective field='IdSinistro' headerText="ID Sinistro" width='12%' headerTemplate={headerTemplate2} template={fieldTemplate2} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='NominativoPerito' headerText="Perito" headerTemplate={headerTemplate2} width='15%' template={fieldTemplate2} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='Garanzia' headerText="Garanzia" headerTemplate={headerTemplate2} width='25%' template={fieldTemplate2} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='DataRestituzione' headerText="Data Restituzione" format='dd/MM/yyyy' width='13%' headerTemplate={headerTemplate2} template={fieldTemplate2} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='MotivoSospensione' headerText="Motivo Sospensione" headerTextAlign='Center' headerTemplate={headerTemplate2} template={fieldTemplate2}></ColumnDirective>
                                    </ColumnsDirective>  
                                    <Inject2 services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Page]}/>                    
                                </GridComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
    accordContent0() {
        return (<div className='container-fluid'> 
                    <div className="row">                        
                        <div className="col-xs-12 col-sm-12 col-lg-3 col-md-3" style={{ marginTop: '10px' }}>
                            <span style={{ fontSize: '2.0vw', fontWeight: 'bolder', color:'#133B95'}}>{ mesiChar[parseInt(mm)-1] + ' ' +  yyyy.toString()}</span>
                        </div>  
                        <div className="col-xs-12 col-sm-12 col-lg-3 col-md-3 text-end" style={{ marginTop: '10px' }}>
                            <span style={{ fontSize: '1.6vw', fontWeight: 'bold', color:'#133B95'}}>TOTALE IN CHIUSURA: {this.state.totaleInChiusura.toLocaleString()}</span>
                        </div> 
                        <div className="col-xs-12 col-sm-12 col-lg-3 col-md-3 text-end" style={{ marginTop: '10px' }}>
                            <span style={{ fontSize: '1.6vw', fontWeight: 'bold', color:'#133B95'}}>TOTALE CHIUSI: {this.state.totaleChiusi.toLocaleString()}</span>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-lg-3 col-md-3 text-end" style={{ marginTop: '10px' }}>
                            <span style={{ fontSize: '1.6vw', fontWeight: 'bold', color:'#133B95'}}>TOTALE RESTITUITI: {this.state.totaleRestituiti.toLocaleString()}</span>
                        </div>                 
                    </div> 
                    <div className="row"> 
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                            <ChartComponent id='ChartSegreteriaACE' loaded={this.operazioniDopoCarica.bind(this)} style={{ textAlign: "center" }}                                
                                primaryXAxis={{ valueType: 'Category', interval: 1, majorGridLines: { width: 0 } }} 
                                primaryYAxis={{ majorGridLines: { width: 0 }, majorTickLines: { width: 0 }, lineStyle: { width: 0 }, labelStyle: { color: 'transparent' } }}
                                chartArea={{ border: { width: 0 } }} tooltip={{ enable: true, template: this.tooltipTemplate.bind(this) }} 
                                width={this.larghezza }
                                height={this.altezza} 
                                title=''
                                legendSettings={{ visible: true }}>
                                <Inject services={[ColumnSeries, Legend, Tooltip, Category, DataLabel]}/>
                                <SeriesCollectionDirective>
                                    <SeriesDirective dataSource={this.state.dataSerie3} xName='x' yName='y' name="Sinistri Caricati" type='Column' fill={materialColors[6 % 10]} cornerRadius={{ bottomLeft: 5, bottomRight: 5, topLeft: 5, topRight: 5 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                                    </SeriesDirective>
                                    <SeriesDirective dataSource={this.state.dataSerie1} xName='x' yName='y' name='Sinistri Chiusi' type='Column' fill={materialColors[5 % 10]} cornerRadius={{ bottomLeft: 5, bottomRight: 5, topLeft: 5, topRight: 5 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                                    </SeriesDirective>
                                    <SeriesDirective dataSource={this.state.dataSerie2} xName='x' yName='y' name="Sinistri in Chiusura (aggiornamento all'orario ultima chiusura sinistro da parte della Segreteria)" type='Column' fill={materialColors[4 % 10]} cornerRadius={{ bottomLeft: 5, bottomRight: 5, topLeft: 5, topRight: 5 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                                    </SeriesDirective>                                                  
                                </SeriesCollectionDirective>
                            </ChartComponent>  
                        </div>   
                    </div> 
                    <div className="row">  
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6 mt-5">
                            <span style={{ fontSize: '1.3vw', fontWeight: 'bold', color:'#133B95'}}>DETTAGLIO SINISTRI IN CHIUSURA</span>
                        </div>  
                        <div id="divBottoneVediSospesi"className="col-xs-6 col-sm-6 col-lg-6 col-md-6 text-end mt-5 mb-2">                                                                         
                            <ButtonComponent id='BtnVediSospesi' alt='Visualizza sinistri sospesi' onClick={this.caricaSinistriSospesi.bind(this)} style={{color: '#133B95', fontWeight: 'bold'}}>Visualizza sinistri sospesi</ButtonComponent>
                        </div> 
                    </div>             
                    <div className='row'>
                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>                                            
                            <GridComponent id="GridSinistriInChiusura" ref={grid => this.gridInstance3 = grid} dataSource={this.state.dsGriglia2} toolbar={this.toolbarOptions} allowSelection={false} enableHover={false} allowTextWrap={true}
                                    allowPaging={false} allowSorting={false} allowFiltering={false} allowExcelExport={true} allowPdfExport={true}  
                                    dataBound={this.onDatabound3.bind(this)} toolbarClick={this.toolbarClick3.bind(this)} 
                                    enableAltRow={true} pageSettings={{ pageCount: 4, pageSize: 11, pageSizes: true }}>
                                <ColumnsDirective>                            
                                    <ColumnDirective field='DescrizioneCompagnia' headerText='Compagnia' headerTemplate={headerTemplate} width='25%' template={fieldTemplate} textAlign='Left' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='InChiusuraAC' headerText="Acqua Condotta" format='N2' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='InChiusuraRE' headerText="Rami Elementari" format='N2' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='InChiusuraRC' headerText="Responsabilità Civile" format='N2' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                                </ColumnsDirective>                            
                                <Inject2 services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Page]}/>                    
                            </GridComponent>
                        </div>
                    </div>  
                    <div className="row" style={{ marginTop: '30px' }}>  
                        <div className="col-xs-12 col-sm-6 col-lg-3 col-md-4" style={{ marginTop: '10px' }}>
                            <span style={{ fontSize: '2.0vw', fontWeight: 'bold', color:'#133B95'}}>ANNO {yyyy.toString()}</span>
                        </div>   
                    </div>             
                    <div className='row'>
                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>                                            
                            <GridComponent id="GridSegreteriaACE" dataSource={this.state.dsGriglia} ref={grid => this.gridInstance = grid} toolbar={this.toolbarOptions} allowSelection={false} enableHover={false} allowTextWrap={true}
                                    allowPaging={false} allowSorting={false} allowFiltering={false} allowExcelExport={true} allowPdfExport={true}  
                                    dataBound={this.onDatabound.bind(this)} rowDataBound={this.onRowDatabound.bind(this)} toolbarClick={this.toolbarClick.bind(this)} 
                                    enableAltRow={true} pageSettings={{ pageCount: 4, pageSize: 11, pageSizes: true }}>
                                <ColumnsDirective>                            
                                    <ColumnDirective field='DescrizioneCompagnia' headerText='Compagnia' headerTemplate={headerTemplate} width='15%' template={fieldTemplate} textAlign='Left' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='NumeroSinistriInseriti' headerText='N° Sinistri Inseriti' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='TempoMedioInserimento' headerText="Tempo Medio inserimento" format='N2' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='TempoMaxInserimento' headerText='Tempo Max Inserimento' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective> 
                                    <ColumnDirective field='NumeroSinistriChiusi' headerText='N° Sinistri Chiusi' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective> 
                                    <ColumnDirective field='TempoMedioChiusura' headerText='Tempo Medio Chiusura' format='N2' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective> 
                                    <ColumnDirective field='TempoMaxChiusura' headerText='Tempo Max Chiusura' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective> 
                                    <ColumnDirective field='TempoMedioChiusuraInserimento' headerText='Tempo Medio Chiusura + Inserimento' format='N2' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective> 
                                </ColumnsDirective>                            
                                <Inject2 services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Page]}/>                    
                            </GridComponent>
                        </div>
                    </div>                                                    
                </div>
        );
    }
    accordContent1() { 
        return(<div className="container-fluid">
                    <div className="row" style={{ marginTop: '10px' }}>      
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-center">
                            <img src={LavoriInCorso} alt='' width='50%' height='auto'/>
                        </div>
                    </div>
            </div>);
    }

    render() {
        return (<React.Fragment>     
                    <ToastContainer containerId='toastContainer3' transition={Zoom} style={{ fontSize: '1.1vw', color: 'darkred', fontWeight: 'bold', width: '30%' }}></ToastContainer>
                    <DialogComponent id="vediSospesiDialog" showCloseIcon={true} target='body' animationSettings={this.animationSettings} visible={this.state.showDialog} width={this.larghezzaDialog} height={this.altezzaDialog} minHeight={ this.altezzaMinDialog}
                                                ref={dialog => this.dialogInstance = dialog} header='<span style="color:#133B95;font-weight:bolder;font-size:1.3em;">Visualizzazione Sinistri Sospesi</span>'  isModal={true}
                                                content={this.dialogContent.bind(this)}></DialogComponent>                                 
                    <div className='container-fluid'>
                        <div className='row' style={{ marginTop: '30px' }}>
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                <AccordionComponent id='accordionSegreteriaACE' expandMode='Multiple' ref={accord => this.accordInstance = accord}>
                                    <AccordionItemsDirective>
                                        <AccordionItemDirective header={this.accordHeader[0]} expanded={true} content={this.accordContent0.bind(this)} iconCss="e-imageAccordion" />
                                        {/* <AccordionItemDirective header={this.accordHeader[1]} expanded={true} content={this.accordContent1.bind(this)} iconCss="e-imageAccordion" /> */}
                                    </AccordionItemsDirective>
                                </AccordionComponent> 
                            </div>                                                                                              
                        </div> 
                    </div>
            </React.Fragment>);
    }
    rendereComplete() {
        setTimeout(() => {
            this.caricaDati();
        }, 500);
    }
    operazioniDopoCarica(args) {                
    }
    caricaDati() { 
        CustomJS.getRemoteData2(CustomJS.APIUrlProd,
            `token=${CustomJS.tokenProd}&azione=segreteria&tipo=ACE`, 
            'POST', "application/x-www-form-urlencoded", (retData) => { 
                let dsGrafico = _.filter(retData, elm => { return elm.tipoRiga === 'grafico'; }) ;
                let giorniPass = 0; 
                dsGrafico.forEach((elm, idx) => {                                            
                    data1.push({ x: elm.GiornoMese, y: elm.NumeroSinistriChiusi });  
                    data2.push({ x: elm.GiornoMese, y: 0 });
                    data3.push({ x: elm.GiornoMese, y: 0 });
                    giorniPass = parseInt(elm.GiornoMese); 
                    totaleChiusi += elm.NumeroSinistriChiusi;
                    totaleRestit += elm.NumeroSinistriRestituiti;                                
                });      
                for (let ind=giorniPass+1;ind<=giorniMese;ind++) {
                    data1.push({ x: ind, y: 0 });
                    data2.push({ x: ind, y: 0 });
                    data3.push({ x: ind, y: 0 });
                } 
    
                CustomJS.callAPI(`${CustomJS.APIMonitorURLProd}/api/Monitor/leggiSinistriChiusuraMeseCorrente`, '', 'GET',
                    '', retData => {                    
                        retData.forEach(elm => {
                            let objSel = data2.filter(elm2 => { return elm2.x === elm.GIORNOMESE })[0];
                            objSel.y = elm.NUM_SINISTRI_CHIUSURA;
                            objSel.diCuiSospesi = elm.DI_CUI_SOSPESI;
                            objSel.oraUltimaChiusura = elm.ORA_ULTIMA_CHIUSURA;
                        });
                    }, error => {
                        let messaggio = (_.isString(error) && error.indexOf('Not Found')) > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        toast.warn(messaggio, {
                            //onClose: () => toast.dismiss(),
                            position: "top-right",
                            autoClose: 8000,
                            hideProgressBar: false,            
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        })
                    }
                , true, false);   
    
                CustomJS.callAPI(`${CustomJS.APIMonitorURLProd}/api/Monitor/leggiSinistriCaricatiMeseCorrente`, '', 'GET',
                    '', retData => {                    
                        retData.forEach(elm => {
                            let objSel = data3.filter(elm2 => { return elm2.x === elm.GIORNOMESE })[0];
                            objSel.y = elm.NUM_SINISTRI_CARICATI;
                            objSel.oraUltimoCaricamento = elm.ORA_ULTIMO_CARICAMENTO;
                        });
                    }, error => {
                        let messaggio = (_.isString(error) && error.indexOf('Not Found')) > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        toast.warn(messaggio, {
                            //onClose: () => toast.dismiss(),
                            position: "top-right",
                            autoClose: 8000,
                            hideProgressBar: false,            
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        })
                    }
                , true, false);   
    
                dsGriglia = _.filter(retData, elm => { return elm.tipoRiga === 'tabella'; });
    
                let dsTemp = _.filter(dsGriglia, elm => { return elm.DescrizioneCompagnia !== 'TOTALE'; });
                let dsTemp2 = _.filter(dsGriglia, elm => { return elm.DescrizioneCompagnia === 'TOTALE'; });
                dsTemp.forEach(elm => {
                    elm.TempoMedioChiusuraInserimento = elm.TempoMedioChiusura + elm.TempoMedioInserimento;     
                });
                dsTemp2.forEach(elm => {
                    elm.TempoMedioChiusuraInserimento = elm.TempoMedioChiusura + elm.TempoMedioInserimento;     
                });
                dsGriglia = _.sortBy(dsTemp, 'TempoMedioChiusuraInserimento');
                dsGriglia.push(dsTemp2[0]);
                dsGriglia.forEach((elm, idx) => { 
                    elm.NumeroSinistriInseriti = elm.NumeroSinistriInseriti.toLocaleString('it-IT');
                    elm.TempoMedioInserimento = elm.TempoMedioInserimento.toLocaleString('it-IT');
                    elm.TempoMaxInserimento = elm.TempoMaxInserimento.toLocaleString('it-IT');
                    elm.NumeroSinistriChiusi = elm.NumeroSinistriChiusi.toLocaleString('it-IT');
                    elm.TempoMedioChiusura = elm.TempoMedioChiusura.toLocaleString('it-IT');
                    elm.TempoMaxChiusura = elm.TempoMaxChiusura.toLocaleString('it-IT'); 
                    elm.TempoMedioChiusuraInserimento = elm.TempoMedioChiusuraInserimento.toLocaleString('it-IT');     
                });    

                dsGriglia2 = _.filter(retData, elm => { return elm.tipoRiga === 'tabellaInChiusura'; });

                let objGenerale = _.filter(retData, elm => { return elm.tipoRiga === 'generale'; });
                totaleInChiusura = objGenerale[0].NumeroSinistriInChiusura; 
                
                setTimeout(() => {
                    this.setState({ 
                        dsGriglia: dsGriglia,
                        dsGriglia2: dsGriglia2,
                        dataSerie1: data1, 
                        dataSerie2: data2, 
                        dataSerie3: data3,
                        totaleChiusi: totaleChiusi,
                        totaleRestituiti: totaleRestit,
                        totaleInChiusura: totaleInChiusura
                    });  
                }, 500);
        }, true, false);     
    }
    caricaSinistriSospesi() { 
        let dsGriglia = [];
        CustomJS.callAPI(`${CustomJS.APIMonitorURLProd}/api/Monitor/leggiSinistriSospesiAttuali`, '', 'GET',
            '', retData => {     
                retData.forEach(elm => {
                    let objSin = {
                          IdSinistro: elm.IdSinistro,
                          NominativoPerito: elm.NominativoPerito,
                          Garanzia: elm.Garanzia,
                          DataRestituzione: moment(elm.DataRestituzione).format('DD/MM/YYYY'),
                          MotivoSospensione: elm.MotivoSospensione
                    };
                   dsGriglia.push(objSin);
                });               
                this.gridInstance2.dataSource = dsGriglia;
                this.dialogInstance.show();
            }, error => {
                let messaggio = (_.isString(error) && error.indexOf('Not Found')) > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    //onClose: () => toast.dismiss(),
                    position: "top-right",
                    autoClose: 8000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                })
            }
        , true, false);   
    }
}