/* eslint-disable default-case */
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, PdfExport, DetailRow, CommandColumn, Filter, Edit, Inject, Group, Sort, Page, ColumnChooser } from '@syncfusion/ej2-react-grids';
import { SampleBase } from './sample-base';
import { Browser } from '@syncfusion/ej2-base';
import { SplitButtonComponent, ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import $ from 'jquery';
import BottoneVerdeScuro from '../assets/BottoneVerdeScuro.png';
import BottoneVerdeChiaro from '../assets/BottoneVerdeChiaro.png';
import BottoneGiallo from '../assets/BottoneGiallo.png';
import BottoneArancio from '../assets/BottoneArancio.png';
import BottoneRosso from '../assets/BottoneRosso.png';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let GridData = [];
const waitingTime = Browser.userAgent.indexOf('Edg') > -1 ? 7000000000 : 1000000000;

CustomJS.getRemoteData2(CustomJS.APIUrlProd,
    `token=${CustomJS.tokenProd}&azione=periti`, 
    'POST', "application/x-www-form-urlencoded", (retData) => {   
        retData.forEach((elm, idx) => {
            elm.SinistriApertiSuInChiusura = `${(elm.NumSinistriApertiTipoS + elm.NumSinistriApertiTipoI + elm.NumSinistriApertiTipoC).toString()} / 
                                              ${elm.NumSinistriInChiusura.toString()} / ${elm.NumSinistriControllo} (S=${elm.NumSinistriApertiTipoS.toString()}, I=${elm.NumSinistriApertiTipoI.toString()}, 
                                              C=${elm.NumSinistriApertiTipoC})`;
            elm.MediaMensileSinChiusiAnnoCorr = elm.MediaMensileSinChiusiAnnoCorr.toLocaleString();
            elm.MediaGiorniSinistriApertiNum = elm.MediaGiorniSinistriAperti;
            elm.MediaGiorniSinistriAperti = `${elm.MediaGiorniSinistriAperti.toLocaleString()} (S=${elm.MediaGiorniSinistriApertiTipoS.toLocaleString()}, I=${elm.MediaGiorniSinistriApertiTipoI.toLocaleString()}, 
                                            C=${elm.MediaGiorniSinistriApertiTipoC.toLocaleString()})`;
            elm.MediaGiorniSinistriRestit = `${elm.MediaGiorniSinistriRestit.toLocaleString()} (S=${elm.MediaGiorniSinistriRestitTipoS.toLocaleString()}, I=${elm.MediaGiorniSinistriRestitTipoI.toLocaleString()}, 
                                            C=${elm.MediaGiorniSinistriRestitTipoC.toLocaleString()})`;
            elm.PercScaricoAnnoCorr = `${elm.PercScaricoAnnoCorr.toLocaleString()}%`;
            elm.PercFormaDirettaUnipolSai = CustomJS.isNullEmpty(elm.PercFormaDirettaUnipolSai) ? '' : `${elm.PercFormaDirettaUnipolSai.toLocaleString()}%`;
            elm.PercFormaDirettaGenerali = CustomJS.isNullEmpty(elm.PercFormaDirettaGenerali) ? '' : `${elm.PercFormaDirettaGenerali.toLocaleString()}%`;
            elm.PercFormaDirettaArca = CustomJS.isNullEmpty(elm.PercFormaDirettaArca) ? '' : `${elm.PercFormaDirettaArca.toLocaleString()}%`;
            elm.SommaTMaxSinistriScaduti = elm.SommaTMaxSinistriScaduti.toLocaleString();
            elm.MediaTMaxSinistriScaduti = elm.MediaTMaxSinistriScaduti.toLocaleString();
            elm.GiorniTUS = elm.GiorniTUS.toLocaleString();
            elm.NumSinistriTipoSFra1e5K = elm.NumSinistriTipoSFra1e5K.toLocaleString();
            elm.MediaSinistriTipoSFra1e5K = elm.MediaSinistriTipoSFra1e5K.toLocaleString();
            elm.Differenza = elm.Differenza.toLocaleString();
            GridData.push(elm);
        });  
        GridData = _.sortBy(GridData, 'MediaGiorniSinistriApertiNum').reverse();  
        let elmTotale = _.find(GridData, (elm) => { return elm.NominativoPerito === 'STUDIO' || elm.NominativoPerito === 'TOTALI'; });
        GridData = _.filter(GridData, (elm) => { return elm.NominativoPerito !== 'STUDIO' && elm.NominativoPerito !== 'TOTALI'; });   
        let GridDataTemp = GridData;
        GridData = [];
        GridDataTemp.forEach((elm,idx) => {            
            if (idx % 9 === 0) {
                GridData.push(elmTotale);
                GridData.push(elm);
            }
            else
                GridData.push(elm);
        });        
}, true, false);
for (let ind=0;ind<=waitingTime;ind++) {}

function headerTemplate(props) {    
    return (<div>
         <span style={{ fontSize: '0.9vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate(props) {          
    let colore = props.column.field === 'NominativoPerito' ? '#133B95' : 'purple';     
    let valoreUnipolSai = CustomJS.isNullEmpty(props.PercFormaDirettaUnipolSai) ? ''  : <><span style={{ fontSize: '0.9vw', color: colore, fontWeight: 'bold'}}>{props.PercFormaDirettaUnipolSai}</span>&nbsp;<span style={{ fontSize: '0.6vw', color: colore, fontWeight: 'bold'}}>(UNIPOLSAI)</span></>;   
    let valoreGenerali = CustomJS.isNullEmpty(props.PercFormaDirettaGenerali) ? '' : <><span style={{ fontSize: '0.9vw', color: colore, fontWeight: 'bold'}}>{props.PercFormaDirettaGenerali}</span>&nbsp;<span style={{ fontSize: '0.6vw', color: colore, fontWeight: 'bold'}}>(GENERALI)</span></>;
    let valoreArca = CustomJS.isNullEmpty(props.PercFormaDirettaArca) ? '' : <><span style={{ fontSize: '0.9vw', color: colore, fontWeight: 'bold'}}>{props.PercFormaDirettaArca}</span>&nbsp;<span style={{ fontSize: '0.6vw', color: colore, fontWeight: 'bold'}}>(ARCA)</span></>;
    let colonnaFD = <>{valoreUnipolSai}<br/>{valoreGenerali}<br/>{valoreArca}</>;    
    let renderDiv = (props.column.field === 'SinistriApertiSuInChiusura' || props.column.field === 'MediaGiorniSinistriAperti' || props.column.field === 'MediaGiorniSinistriRestit') ? <div><span style={{ fontSize: '1.05vw', color: colore, fontWeight: 'bold'}}>{props[props.column.field].substring(0, props[props.column.field].indexOf('('))}</span><br/><span style={{ fontSize: '0.7vw', color: colore, fontWeight: 'bold'}}>{props[props.column.field].substring(props[props.column.field].indexOf('('))}</span></div>
                    : (props.NominativoPerito === 'TOTALI' && props.column.field === 'PercFormaDiretta') ? <div><span style={{ fontSize: '0.95vw', color: colore, fontWeight: 'bold'}}>{props.PercFormaDirettaUnipolSai}</span><br/><span style={{ fontSize: '0.65vw', color: colore, fontWeight: 'bold'}}>(su base annua)</span></div>
                    : props.column.field === 'PercFormaDiretta' ? colonnaFD : <div><span style={{ fontSize: '1.0vw', color: colore, fontWeight: 'bold'}}>{props[props.column.field]}</span></div>;
    return (        
         renderDiv
    );
}

function fieldTemplate2(props) { 
    return (
        <div><span style={{ fontSize: '1.0vw', color: '#133B95', fontWeight: 'bold'}}>{props[props.column.field]}</span></div>
    );

}
export default class GridPeriti extends SampleBase {
    constructor() {
        super(...arguments);  
        this.toolbarOptions = ['ExcelExport', 'PdfExport', 'Search'];        
        this.toolbarOptions2 = ['ExcelExport', '    ', { template: this.chkAbilitatiCToolbar.bind(this) }, 'Search'];      
        this.data = GridData;   
        this.pageSettings = {pageSize : 10, pageCount : 10};   
        this.pageSettings2 = {pageSize : 13, pageCount : 10};   
        this.animationSettings = { effect: 'FadeZoom' };  
        this.originalDS = [];

        if ((window.matchMedia("(max-width: 850px)")).matches) {
            this.altezza = "auto";
            this.larghezza = "auto";
        }
        else if ((window.matchMedia("(max-width: 1050px)")).matches) {
            this.altezza = "auto";
            this.larghezza ="auto";
        }
        else{
            this.altezza = "auto";
            this.larghezza = "auto";
        }
    }    
    state = {
        showDialog1: false, 
        dsInfoPeriti: [],
    } 

    onQueryCellInfo(args) { 
        if (args.column.field === 'Differenza') {
            args.cell.style.color = 'purple';
            args.cell.style.fontSize = '1.1vw';
            args.cell.style.fontWeight = 'bold';
        }
        if (args.column.field === 'Differenza' && args.data.PercDifferenza < -0.15 && args.data.Differenza !== '0') {
            args.cell.style.color = 'whitesmoke';
            args.cell.bgColor = '#00802B';
        }  
        else if (args.column.field === 'Differenza' && args.data.PercDifferenza < -0.05 && args.data.Differenza !== '0') {            
            args.cell.bgColor = '#4DFF4D';
        }  
        else if (args.column.field === 'Differenza' && args.data.PercDifferenza < 0.05 && args.data.Differenza !== '0') {            
            args.cell.bgColor = 'yellow';
        }  
        else if (args.column.field === 'Differenza' && args.data.PercDifferenza <= 0.15 && args.data.Differenza !== '0') {  
            args.cell.bgColor = '#FF9900';
        }  
        else if (args.column.field === 'Differenza' && args.data.PercDifferenza > 0.15 && args.data.Differenza !== '0') {           
            args.cell.style.color = 'whitesmoke';            
            args.cell.bgColor = '#B30000';
        }  
    }    
    onDatabound(args) {       
        for (let ind=0;ind<=13;ind++)
             document.getElementById('GridPeriti').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
        document.getElementById('TestoDatiAggiornati').innerText =  `Dati aggiornati al ${new Date().getDate().toString().padStart(2, '0')}/${(new Date().getMonth() + 1).toString().padStart(2, '0')}/${new Date().getFullYear().toString()} alle ore ${new Date().getHours().toString()}:${new Date().getMinutes().toString().padStart(2, '0')}`;            
    }
    onCreated(args) {
        setTimeout(() => {
             $(`<div id="legendaCustom2" style="margin-top:7px;"><img src=${BottoneVerdeScuro} alt="" width="25" height="25"/><span id="testoLegendaCustom1" style="font-size:17px;font-weight:bold;color:#133B95;">&nbsp;&lt; -15%</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src=${BottoneVerdeChiaro} alt="" width="25" height="25"/><span id="testoLegendaCustom2" style="font-size:17px;font-weight:bold;color:#133B95;">&nbsp;&lt; -5%</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src=${BottoneGiallo} alt="" width="25" height="25"/><span id="testoLegendaCustom3" style="font-size:17px;font-weight:bold;color:#133B95;">&nbsp;-5% &lt; N &lt; 5%</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src=${BottoneArancio} alt="" width="25" height="25"/><span id="testoLegendaCustom4" style="font-size:17px;font-weight:bold;color:#133B95;">&nbsp;&gt; 5%</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src=${BottoneRosso} alt="" width="25" height="25"/><span id="testoLegendaCustom5" style="font-size:17px;font-weight:bold;color:#133B95;">&nbsp;&gt; 15%</span></div>`).appendTo('#GridPeriti .e-toolbar-center');       
             document.getElementById('GridPeriti').ej2_instances[0].refresh();
        }, 150);
    }
    chkAbilitatiCToolbar() { 
        return (
            <CheckBoxComponent id='chkAbilitatiC' label='Visualizza solo Periti Abilitati C' style={{ marginLeft: '30px'}} change={this.onChangeChkAbilitatiC.bind(this)} />
        );   
    }
    onChangeChkAbilitatiC(args) {
        if (args.checked === true)
            this.gridInstance2.dataSource = this.gridInstance2.dataSource.filter(elm => { return elm.teamComplessi === 1 });
        else
            this.gridInstance2.dataSource = this.originalDS;
        this.gridInstance2.dataSource.refresh();
    }
    onRowDatabound(args) {  
        if (args.data.NominativoPerito.indexOf('STUDIO') > -1 || args.data.NominativoPerito.indexOf('TOTALI') > -1) {
            args.row.className = 'e-row';
            args.row.bgColor = 'lightpink'; 
        }
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
        }
    }
    toolbarClick2(args) {
        const excelExportProps = {
            includeHiddenColumn: true
        };
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance2.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance2.excelExport(excelExportProps);
                break;
        }
    }
    btnInfoPeriti(){
        ++this.childKey;
        this.dialogInstance1.header = 'INFO UTENTI';   
        this.dialogInstance1.width = '85%';
        this.dialogInstance1.height = '85%';
        this.dialogInstance1.minHeight = '85%';
        this.dialogInstance1.beforeOpen = (args) => {
            const root = createRoot(document.getElementById('defaultDialogContentPeriti')); 
            root.unmount();
        };
        this.dialogInstance1.open = (args) => {
            const root = createRoot(document.getElementById('defaultDialogContentPeriti')); 
            root.render(this.infoUtenti());
        }
        this.dialogInstance1.show();                                                            
    }
    infoUtenti(args){
        return(<React.Fragment>
            <div className='container-fluid'> 
                <div className='row'>  
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'> 
                        <GridComponent id="GridInfoPeriti" height={this.altezza} width={this.larghezza} dataSource={this.state.dsInfoPeriti} ref={ grid => this.gridInstance2 = grid } toolbar={this.toolbarOptions2} toolbarClick={this.toolbarClick2.bind(this)} 
                            editSettings={this.editSettings} allowSelection={false} enableHover={true} showColumnMenu={false} gridLines='Both' allowExcelExport={true} allowPdfExport={true} pageSettings={this.pageSettings2}
                            allowTextWrap={true} allowPaging={true} allowSorting={true} allowFiltering={false} enableAltRow={true} enablePersistence={false}>
                            <ColumnsDirective>
                                <ColumnDirective field='Sede' headerText='Sede' width='30%' headerTemplate={headerTemplate} template={fieldTemplate2} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='Nominativo' headerText="Nominativo" width='20%'  headerTemplate={headerTemplate} template={fieldTemplate2} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='Telefono' headerText="Cellulare" width='20%' headerTemplate={headerTemplate} template={fieldTemplate2} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='TelefonoInterno' headerText="Telefono Interno" visible={false}></ColumnDirective>
                                <ColumnDirective field='AbilitatoOSE' headerText="Abilitato OSE" visible={false}></ColumnDirective>
                                <ColumnDirective field='SoloSurveyor' headerText="Solo Surveyor" visible={false}></ColumnDirective>
                                <ColumnDirective field='AbilitatoC' headerText="Abilitato C" visible={false}></ColumnDirective>
                                <ColumnDirective columns={[{ field: 'ClasseRE', headerText: 'RE', textAlign: 'Center', visible: false }, 
                                                           { field: 'ClasseAC', headerText: 'AC', textAlign: 'Center', visible: false },
                                                           { field: 'ClasseF', headerText: 'F', textAlign: 'Center', visible: false },
                                                           { field: 'ClasseRCT', headerText: 'RCT', textAlign: 'Center', visible: false }]} headerText='CLASSE' textAlign='Center' visible={false}></ColumnDirective>
                                {/* <ColumnDirective field='AutorizzCellDann' headerText="Autorizza Lasciare Cellulare Al Danneggiato" headerTemplate={headerTemplate} template={fieldTemplate2} textAlign='Center' clipMode="EllipsisWithTooltip" visible={false}></ColumnDirective>
                                <ColumnDirective field='ufficio' headerText="Ufficio" headerTemplate={headerTemplate} template={fieldTemplate2} textAlign='Center' visible={false}></ColumnDirective> */}
                                <ColumnDirective field='Email' headerText="Email" width='30%'  headerTemplate={headerTemplate} template={fieldTemplate2} textAlign='Center'></ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, DetailRow, CommandColumn, Sort, Group, Page]} />
                        </GridComponent>  
                    </div>
                </div>
            </div>
        </React.Fragment>);
    }

    render() {
        return (<React.Fragment>
                    <DialogComponent id="defaultDialogPeriti" showCloseIcon={true} target='body' animationSettings={this.animationSettings} visible={this.state.showDialog1} 
                        ref={dialog => this.dialogInstance1 = dialog} isModal={true} height='80%' width='90%' minHeight='80%'>   
                            <div id='defaultDialogContentPeriti'></div>                         
                    </DialogComponent> 
                    <div className='row'>
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-end" style={{ marginTop: '20px', marginBottom: '20px'}}>
                        <ProgressButtonComponent id='btnInfoPeriti' content="INFO UTENTI" enableProgress duration='1000' type='button' spinSettings={{ position: 'Center' }}
                            animationSettings={{effect: 'SlideRight'}} cssClass="e-fill e-warning" onClick={this.btnInfoPeriti.bind(this)}></ProgressButtonComponent> 
                        </div>
                    </div>
                    <GridComponent id="GridPeriti" dataSource={this.data} ref={grid => this.gridInstance = grid} toolbar={this.toolbarOptions} allowSelection={false} enableHover={false} allowTextWrap={true} created={this.onCreated.bind(this)}
                             allowPaging={true} allowSorting={false} allowFiltering={false} allowExcelExport={true} allowPdfExport={true} queryCellInfo={this.onQueryCellInfo.bind(this)} pageSettings={{ pageCount: 5, pageSize: 10, pageSizes: ['10', '50', 'Tutti'] }}
                             dataBound={this.onDatabound.bind(this)} rowDataBound={this.onRowDatabound.bind(this)} toolbarClick={this.toolbarClick.bind(this)} enableAltRow={true} gridLines='Both'>
                        <ColumnsDirective>                            
                            <ColumnDirective field='NominativoPerito' headerText='Perito' width='10%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center' filter={this.Filter} clipMode="EllipsisWithTooltip"></ColumnDirective>
                            <ColumnDirective field='SinistriApertiSuInChiusura' headerText="Sinistri Aperti / In Chiusura / In Controllo (S, I, C)" width='11%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='NumSinistriChiusiMeseCorr' headerText="Sinistri Chiusi Mese Corrente" width='7%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='MediaMensileSinChiusiAnnoCorr' headerText="Media Mensile Sinistri Chiusi Anno Corrente" headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='MediaGiorniSinistriAperti' headerText="Media Giorni Sinistri Aperti" headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='MediaGiorniSinistriRestit' headerText='Media Giorni Sinistri Restituiti' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='PercScaricoAnnoCorr' headerText='% Scarico Su Anno Corrente' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='PercFormaDiretta' headerText='% Forma Diretta (semestrale)' width='10%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='SommaTMaxSinistriScaduti' visible={false} headerText='Somma TMax Sinistri Scaduti' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='MediaTMaxSinistriScaduti' visible={false} headerText='Media TMax Sinistri Scaduti' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='GiorniTUS' headerText='Giorni in TUS' format='N0' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='NumSinistriTipoSFra1e5K' headerText='Numero Sinistri "S" Definiti fra 1 e 5.000 €' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='MediaSinistriTipoSFra1e5K' headerText='Media Sinistri "S" Definiti fra 1 e 5.000 €' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='Differenza' headerText='Differenza (Euro)' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                        </ColumnsDirective>   
                        <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Page]}/>                    
                    </GridComponent>                
            </React.Fragment>);
    }  
    componentDidMount() {  
        this.caricaDati();  
    } 
    caricaDati(){
        CustomJS.callAPI(`${CustomJS.APIMonitorURLProd}/api/Monitor/leggiInfoUtenti`, '', 'GET', '', retData => {
            this.originalDS = retData;
            this.setState({dsInfoPeriti:retData});
        }, error => {
            let messaggio = (_.isString(error) && error.indexOf('Not Found')) > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                //onClose: () => toast.dismiss(),
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            })
        }, true, false);
    }
}