/* eslint-disable default-case */
/* eslint-disable no-extend-native */
import * as React from 'react';
import { createRoot } from 'react-dom/client'
import { CallbackWrapper } from 'react-callback';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, DetailRow, CommandColumn, PdfExport, Page, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective,  Legend, Category, Tooltip, LineSeries, DateTime, Trendlines, TrendlinesDirective, TrendlineDirective, ColumnSeries, DataLabel } from '@syncfusion/ej2-react-charts';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { SampleBase } from './sample-base';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import '../Custom.css';
import CurvaObiettivi from '../assets/CurvaObiettivi2024.json';
import BlockUi from '@availity/block-ui';
import { random, isUndefined } from 'underscore';
Array.prototype.sum = function (prop, tipo) {
    let total = 0
    for ( let i = 0, _len = this.length; i < _len; i++ ) {
        if (this[i].Raggruppamento === tipo)
            total += this[i][prop];
    }
    return total;
}

Array.prototype.mediaPonderataMedie = function (campoMedia, campoNumerosita, tipo) {
    let mediaTotale = 0, pesoTotale = 0, numerositaTotale = 0;
    for ( var i = 0, _len = this.length; i < _len; i++ ) {
        if (this[i].Raggruppamento === tipo){
            pesoTotale += (this[i][campoMedia] * this[i][campoNumerosita]);
            //media = Math.round(((media * numerosita) + (this[i][campoMedia] * this[i][campoNumerosita]) / (numerosita + this[i][campoNumerosita])) * 100) / 100;
            numerositaTotale += this[i][campoNumerosita]; 
        }
    }   
    mediaTotale = numerositaTotale === 0 ? 0 : pesoTotale / numerositaTotale;
    return Math.round(mediaTotale * 100) / 100;
}

let periti = [];
var dataCorrente = new Date();
var dd = String(dataCorrente.getDate()).padStart(2, '0');
var mm = String(dataCorrente.getMonth() + 1).padStart(2, '0');
var yyyy = dataCorrente.getFullYear();
dataCorrente = yyyy.toString() + mm + dd;
let mesiChar = ['GENNAIO', 'FEBBRAIO', 'MARZO', 'APRILE', 'MAGGIO', 'GIUGNO', 'LUGLIO', 'AGOSTO', 'SETTEMBRE', 'OTTOBRE', 'NOVEMBRE', 'DICEMBRE'];
let ObietMeseAC = 0;
let ObietMeseRE = 0;
let ObietMeseRC = 0;
let GridData1 = [], GridData2 = [], ddlData = [];
let data1 = [], data2 = [], ObietMese = [], data3 = [], data4 = [];
    
function calcolaSinistriObiettivo(sinistriEntrati, ramo) {
    if (sinistriEntrati === 0)
        return 0;
    for (let ind=1;;ind++) {
        let scaricoSimul = Math.floor(((ind / sinistriEntrati) * 100));
        if ((scaricoSimul === parseInt(ObietMeseRE) && ramo === 'RE')
            || (scaricoSimul === parseInt(ObietMeseRC) && ramo === 'RC')
            || (scaricoSimul === parseInt(ObietMeseAC) && ramo === 'AC'))
            return ind;   
        else if ((scaricoSimul >= parseInt(ObietMeseRE) && ramo === 'RE')
                || (scaricoSimul >= parseInt(ObietMeseRC) && ramo === 'RC')
                || (scaricoSimul >= parseInt(ObietMeseAC) && ramo === 'AC')) 
                return ind;             
    }
}
        
function headerTemplate(props) { 
    return (<div>
        <span style={{ fontSize: '1.0vw', fontWeight: 'bolder' }}>{props.headerText}</span>
   </div>);
}

export default class PaginaObiettivi extends SampleBase {
    constructor() {
        super(...arguments);  
        this.toolbarOptions = ['ExcelExport', { template: this.ddlPeritiToolbar.bind(this) }, '    ', { template: this.chkPeritiObiettToolbar.bind(this) }, '    ', { template: this.chkPeritiNoObiettToolbar.bind(this) }]; 
        this.toolbarOptions2 = ['ExcelExport', { template: this.ddlPeritiToolbar2.bind(this) }, '    ', { template: this.chkPeritiObiettToolbar2.bind(this) }, '    ', { template: this.chkPeritiNoObiettToolbar2.bind(this) }, 'Search']; 
        this.childGrid = {
            queryString: 'IdCompagnia',
            allowPaging: true,
            allowSelection: false,
            enableHover: false,
            pageSettings: { pageSize: 10, pageCount: 5 },
            toolbar: [ 'ExcelExport', 'Search' ],
            columns: [
                { field: 'NominativoPerito', headerText: 'Nominativo Perito', headerTemplate: headerTemplate, textAlign: 'Center' },
                { headerText: 'Acqua Condotta', headerTemplate: headerTemplate, textAlign: 'Center', 
                    columns: [{ field: 'ScarTotSinAC', headerText: 'Scarico Totale', headerTemplate: headerTemplate, textAlign: 'Center' }, 
                              { field: 'NumSinistriMancantiAC', headerText: 'Sinistri Mancanti', headerTemplate: headerTemplate, textAlign: 'Center' },
                              { field: 'TempoMedioScaricoAC', headerText: 'Tempo Medio Scarico', headerTemplate: headerTemplate, textAlign: 'Center'}]},
                { headerText: 'Rami Elementari', headerTemplate: headerTemplate, textAlign: 'Center', 
                    columns: [{ field: 'ScarTotSinRE', headerText: 'Scarico Totale', headerTemplate: headerTemplate, textAlign: 'Center' }, 
                              { field: 'NumSinistriMancantiRE', headerText: 'Sinistri Mancanti', headerTemplate: headerTemplate, textAlign: 'Center' },
                              { field: 'TempoMedioScaricoRE', headerText: 'Tempo Medio Scarico', headerTemplate: headerTemplate, textAlign: 'Center'}]},
                { headerText: 'Responsabilità Civile', headerTemplate: headerTemplate, textAlign: 'Center', 
                    columns: [{ field: 'ScarTotSinRC', headerText: 'Scarico Totale', headerTemplate: headerTemplate, textAlign: 'Center' }, 
                            { field: 'NumSinistriMancantiRC', headerText: 'Sinistri Mancanti', headerTemplate: headerTemplate, textAlign: 'Center' },
                            { field: 'TempoMedioScaricoRC', headerText: 'Tempo Medio Scarico', headerTemplate: headerTemplate, textAlign: 'Center'}]}          
            ], 
            queryCellInfo: this.onQueryCellInfo2.bind(this),
            dataBound: this.onDatabound2
        };
        this.childGrid2 = {
            queryString: 'DescrArea',
            allowPaging: true,
            allowSelection: false,
            enableHover: false,
            pageSettings: { pageSize: 5, pageCount: 5 },
            toolbar: [ 'ExcelExport', 'Search' ],
            columns: [
                { field: 'NominativoPerito', headerText: 'Nominativo Perito', headerTemplate: headerTemplate, textAlign: 'Center' },
                { headerText: 'Acqua Condotta', headerTemplate: headerTemplate, textAlign: 'Center', 
                    columns: [{ field: 'ScarTotSinAC', headerText: 'Scarico Totale', headerTemplate: headerTemplate, textAlign: 'Center' }, 
                              { field: 'NumSinistriMancantiAC', headerText: 'Sinistri Mancanti', headerTemplate: headerTemplate, textAlign: 'Center' },
                              { field: 'TempoMedioScaricoAC', headerText: 'Tempo Medio Scarico', headerTemplate: headerTemplate, textAlign: 'Center'}]},
                { headerText: 'Rami Elementari', headerTemplate: headerTemplate, textAlign: 'Center', 
                    columns: [{ field: 'ScarTotSinRE', headerText: 'Scarico Totale', headerTemplate: headerTemplate, textAlign: 'Center' }, 
                              { field: 'NumSinistriMancantiRE', headerText: 'Sinistri Mancanti', headerTemplate: headerTemplate, textAlign: 'Center' },
                              { field: 'TempoMedioScaricoRE', headerText: 'Tempo Medio Scarico', headerTemplate: headerTemplate, textAlign: 'Center'}]},
                { headerText: 'Responsabilità Civile', headerTemplate: headerTemplate, textAlign: 'Center', 
                    columns: [{ field: 'ScarTotSinRC', headerText: 'Scarico Totale', headerTemplate: headerTemplate, textAlign: 'Center' }, 
                            { field: 'NumSinistriMancantiRC', headerText: 'Sinistri Mancanti', headerTemplate: headerTemplate, textAlign: 'Center' },
                            { field: 'TempoMedioScaricoRC', headerText: 'Tempo Medio Scarico', headerTemplate: headerTemplate, textAlign: 'Center'}]}          
            ], 
            queryCellInfo: this.onQueryCellInfo2.bind(this),
            dataBound: this.onDatabound2
        };
        this.commands = [{ type: "Vedi Situazione Aree Compagnia", buttonOption: { iconCss: "fas fa-chart-area fa-10x", cssClass: "e-details" }}];
        this.ddlFields = { text: 'Text', value: 'Value' };
        this.mesi = [{Text: mesiChar[0], Value: '01'}, {Text: mesiChar[1], Value: '02'}, {Text: mesiChar[2], Value: '03'}, {Text: mesiChar[3], Value: '04'}, 
                    {Text: mesiChar[4], Value: '05'}, {Text: mesiChar[5], Value: '06'}, {Text: mesiChar[6], Value: '07'}, {Text: mesiChar[7], Value: '08'}, 
                    {Text: mesiChar[8], Value: '09'}, {Text: mesiChar[9], Value: '10'}, {Text: mesiChar[10], Value: '11'}, {Text: mesiChar[11], Value: '12'}];
        this.ddlMesiFiltr = _.filter(this.mesi, elm => { return parseInt(elm.Value) <= parseInt(mm) }); 
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toggleBlocking2 = this.toggleBlocking2.bind(this);
        this.animationSettings = { effect: 'FadeZoom' };  
    }  
    state = {
        blocking2: false,  
        blocking3: false,  
        showDialog1: false,  
        meseAtt: mesiChar[parseInt(mm)-1], 
        ObietMeseACatt: ObietMeseAC,
        ObietMeseREatt: ObietMeseRE,
        ObietMeseRCatt: ObietMeseRC  
    }   
    toggleBlocking() {
        this.setState({blocking2: !this.state.blocking2});
    }
    toggleBlocking2() {
        this.setState({blocking3: !this.state.blocking3});
    }
    onQueryCellInfo(args) {
        args.cell.style.color = args.column.index === 0 ? '#133B95' : 'purple';
        args.cell.style.fontSize = args.column.index === 0 ? '1.3vw' : '1.0vw';
        args.cell.style.fontWeight = 'bold'; 
        if (args.column.index === 2 ||  args.column.index === 3 ||  args.column.index === 4 ||  args.column.index === 6 ||  args.column.index === 7 ||  args.column.index === 8 ||  args.column.index === 10 ||  args.column.index === 11 ||  args.column.index === 12)
            args.cell.innerText = parseFloat(args.cell.innerText).toLocaleString('it-IT'); 
        if (args.column.index === 2 ||  args.column.index === 6 ||  args.column.index === 10) {
            args.cell.innerText = args.cell.innerText + '%' ;
        }
        if (args.cell.innerText === 'NaN' || args.cell.innerText === '%' || args.cell.innerText === 'NaN%')
            args.cell.innerText = '';  
        if ((args.column.index === 3 || args.column.index === 7|| args.column.index === 11) && (parseInt(args.cell.innerText) > 0)) {    
            args.cell.style.color = 'whitesmoke';  
            args.cell.bgColor = '#00802B';          
        }
        if ((args.column.index === 3 ||  args.column.index === 7 || args.column.index === 11) && (parseInt(args.cell.innerText) < 0)) {    
            args.cell.style.color = 'whitesmoke';
            args.cell.bgColor = '#DC143C';
        }     
    }    
    onQueryCellInfo2(args) {
        args.cell.style.color = args.column.index === 0 ? '#133B95' : 'purple';
        args.cell.style.fontSize = '1.0vw';
        args.cell.style.fontWeight = 'bold';   
        if (args.column.index === 2 ||  args.column.index === 3 ||  args.column.index === 4 ||  args.column.index === 6 ||  args.column.index === 7 ||  args.column.index === 8 ||  args.column.index === 10 ||  args.column.index === 11 ||  args.column.index === 12)
            args.cell.innerText = parseFloat(args.cell.innerText).toLocaleString('it-IT');  
        if (args.column.index === 2 ||  args.column.index === 6 ||  args.column.index === 10)
            args.cell.innerText = args.cell.innerText + '%' ; 
        if (args.cell.innerText === 'NaN' || args.cell.innerText === '%' || args.cell.innerText === 'NaN%')
            args.cell.innerText = ''; 
        if ((args.column.index === 3 || args.column.index === 7|| args.column.index === 11) && (parseInt(args.cell.innerText) > 0)) {    
            args.cell.style.color = 'whitesmoke';    
            args.cell.bgColor = '#00802B';
        }  
        if ((args.column.index === 3 ||  args.column.index === 7 || args.column.index === 11) && (parseInt(args.cell.innerText) < 0)) {    
            args.cell.style.color = 'whitesmoke';
            args.cell.bgColor = '#DC143C';
        }                                   
    }     
    onQueryCellInfo3(args) {
        args.cell.style.color = args.column.index === 0 ? '#133B95' : 'purple';
        args.cell.style.fontSize = args.column.index === 0 ? '0.9vw' : '1.0vw';
        args.cell.style.fontWeight = 'bold';   
        if (args.column.index === 2 ||  args.column.index === 3 ||  args.column.index === 4 ||  args.column.index === 6 ||  args.column.index === 7 ||  args.column.index === 8 ||  args.column.index === 10 ||  args.column.index === 11 ||  args.column.index === 12)
            args.cell.innerText = parseFloat(args.cell.innerText).toLocaleString('it-IT');  
        if (args.column.index === 2 ||  args.column.index === 6 ||  args.column.index === 10)
            args.cell.innerText = args.cell.innerText + '%' ; 
        if (args.cell.innerText === 'NaN' || args.cell.innerText === '%' || args.cell.innerText === 'NaN%')
            args.cell.innerText = ''; 
        if ((args.column.index === 3 || args.column.index === 7|| args.column.index === 11) && (parseInt(args.cell.innerText) > 0)) {    
            args.cell.style.color = 'whitesmoke';    
            args.cell.bgColor = '#00802B';
        }  
        if ((args.column.index === 3 ||  args.column.index === 7 || args.column.index === 11) && (parseInt(args.cell.innerText) < 0)) {    
            args.cell.style.color = 'whitesmoke';
            args.cell.bgColor = '#DC143C';
        }                                   
    }     
    onDatabound(args) {       
        for (let ind=0;ind<=1;ind++)
             document.getElementById('GridObiettivi').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';  
        document.getElementById('GridObiettivi').querySelectorAll('.e-columnheader')[1].children[0].style.backgroundColor = 'Linen';
        document.getElementById('GridObiettivi').querySelectorAll('.e-columnheader')[0].children[2].style.backgroundColor = 'PaleTurquoise'; 
        document.getElementById('GridObiettivi').querySelectorAll('.e-columnheader')[0].children[3].style.backgroundColor = 'LightGreen'; 
        document.getElementById('GridObiettivi').querySelectorAll('.e-columnheader')[0].children[4].style.backgroundColor = 'LightPink'; 
        for (let ind=1;ind<=3;ind++)            
             document.getElementById('GridObiettivi').querySelectorAll('.e-columnheader')[1].children[ind].style.backgroundColor = 'PaleTurquoise';   
        for (let ind=4;ind<=6;ind++)            
             document.getElementById('GridObiettivi').querySelectorAll('.e-columnheader')[1].children[ind].style.backgroundColor = 'LightGreen';      
        for (let ind=7;ind<=9;ind++)            
             document.getElementById('GridObiettivi').querySelectorAll('.e-columnheader')[1].children[ind].style.backgroundColor = 'LightPink';   
        document.getElementById('GridObiettivi').querySelectorAll('.e-columnheader')[0].children[5].style.backgroundColor = 'Linen';      
        document.getElementById('TestoDatiAggiornati').innerText =  'Dati aggiornati al ' + new Date().getDate().toString().padStart(2, '0') + '/' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '/' + new Date().getFullYear().toString() + ' alle ore '  + new Date().getHours().toString() + ':' + new Date().getMinutes().toString().padStart(2, '0');         
    }    
    onDatabound2(args) {  
        this.element.querySelectorAll('.e-columnheader')[0].children[0].style.backgroundColor = 'Linen';  
        this.element.querySelectorAll('.e-columnheader')[0].children[1].style.backgroundColor = 'PaleTurquoise'; 
        this.element.querySelectorAll('.e-columnheader')[0].children[2].style.backgroundColor = 'LightGreen'; 
        this.element.querySelectorAll('.e-columnheader')[0].children[3].style.backgroundColor = 'LightPink'; 
        for (let ind=0;ind<=2;ind++)            
             this.element.querySelectorAll('.e-columnheader')[1].children[ind].style.backgroundColor = 'PaleTurquoise';   
        for (let ind=3;ind<=5;ind++)            
             this.element.querySelectorAll('.e-columnheader')[1].children[ind].style.backgroundColor = 'LightGreen';      
        for (let ind=6;ind<=8;ind++)            
            this.element.querySelectorAll('.e-columnheader')[1].children[ind].style.backgroundColor = 'LightPink';         
    }  
    onDatabound3(args) {       
        for (let ind=0;ind<=1;ind++)
             document.getElementById('GridObiettiviAree').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';  
        document.getElementById('GridObiettiviAree').querySelectorAll('.e-columnheader')[1].children[0].style.backgroundColor = 'Linen';
        document.getElementById('GridObiettiviAree').querySelectorAll('.e-columnheader')[0].children[2].style.backgroundColor = 'PaleTurquoise'; 
        document.getElementById('GridObiettiviAree').querySelectorAll('.e-columnheader')[0].children[3].style.backgroundColor = 'LightGreen'; 
        document.getElementById('GridObiettiviAree').querySelectorAll('.e-columnheader')[0].children[4].style.backgroundColor = 'LightPink'; 
        for (let ind=1;ind<=3;ind++)            
             document.getElementById('GridObiettiviAree').querySelectorAll('.e-columnheader')[1].children[ind].style.backgroundColor = 'PaleTurquoise';   
        for (let ind=4;ind<=6;ind++)            
             document.getElementById('GridObiettiviAree').querySelectorAll('.e-columnheader')[1].children[ind].style.backgroundColor = 'LightGreen';      
        for (let ind=7;ind<=9;ind++)            
             document.getElementById('GridObiettiviAree').querySelectorAll('.e-columnheader')[1].children[ind].style.backgroundColor = 'LightPink';   
    }    
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
                case 'e-excelexport':
                    this.gridInstance.excelExport();
                    break;
                }
    }
    toolbarClick2(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance2.pdfExport();
                break;
                case 'e-excelexport':
                    this.gridInstance2.excelExport();
                    break;
                }
    }
    onRowDatabound(args) {       
        const filter = args.data.IdCompagnia;
        const childrecord = new DataManager(this.childGrid.dataSource)
        .executeLocal(new Query().where("IdCompagnia", "equal", filter, true));
        if (childrecord.length === 0) {
            args.row.querySelector('td').innerHTML=" ";
            args.row.querySelector('td').className = "e-customizedExpandcell";
        }
    }
    onRowDatabound2(args) {       
        const filter = args.data.DescrArea;
        const childrecord = new DataManager(this.childGrid.dataSource)
        .executeLocal(new Query().where("DescrArea", "equal", filter, true));
        if (childrecord.length === 0) {
            args.row.querySelector('td').innerHTML=" ";
            args.row.querySelector('td').className = "e-customizedExpandcell";
        }
    }
    onCommandClick(args){
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-chart-area') > -1) {
            this.dialogInstance.header = `<span style='font-size: 2vw;font-weight: bold; color: #133B95'>SITUAZIONE OBIETTIVI PER AREE COMPAGNIA ${args.rowData.DescrCompagnia}</span>`;  
            this.dialogInstance.width = '90%';
            this.dialogInstance.height = '90%';
            this.dialogInstance.minHeight = '90%';
            this.dialogInstance.buttons = []; 
            this.dialogInstance.beforeOpen = (args2) => {
                const container = document.getElementById('defaultDialogContentObiettivi');
                const root = createRoot(container); 
                root.unmount();
            };
            this.dialogInstance.open = (args2) => { 
                const container = document.getElementById('defaultDialogContentObiettivi');
                const root = createRoot(container); 
                root.render(<CallbackWrapper callback={() => {
                    this.caricaDati2(args.rowData.IdCompagnia);
                }}>{this.GrigliaObiettiviAreeRender()}</CallbackWrapper>); 
            };
            this.dialogInstance.show();   
        }
    }
    ddlPeritiToolbar(args) {                
        return (
            <DropDownListComponent id='ddlPeriti' fields={this.ddlFields} ref={ ddlObj => this.ddlInstance = ddlObj } dataSource={this.periti} change={this.onChangePerito.bind(this)} allowFiltering={true} filterType='Contains'
                    style={{width: '20vw'}} placeholder="Selezionare un perito da visualizzare" floatLabelType='always' popupHeight="250px" cssClass='e-outline' />
        );        
    }
    ddlPeritiToolbar2(args) {                
        return (
            <DropDownListComponent id='ddlPeriti2' fields={this.ddlFields} ref={ ddlObj => this.ddlInstance3 = ddlObj } dataSource={this.periti} change={this.onChangePerito2.bind(this)} allowFiltering={true} filterType='Contains'
                    style={{width: '20vw'}} placeholder="Selezionare un perito da visualizzare" floatLabelType='always' popupHeight="250px" cssClass='e-outline' />
        );        
    }
    chkPeritiObiettToolbar(args) { 
        return (
            <CheckBoxComponent id='chkPeritiObiett' label='Visualizza periti CON obiettivo raggiunto' style={{ marginLeft: '30px'}} change={this.onChangeChkPeritiObiett} />        
        );   
    }
    chkPeritiObiettToolbar2(args) { 
        return (
            <CheckBoxComponent id='chkPeritiObiett2' label='Visualizza periti CON obiettivo raggiunto' style={{ marginLeft: '30px'}} change={this.onChangeChkPeritiObiett2} />        
        );   
    }
    chkPeritiNoObiettToolbar(args) { 
        return (
            <CheckBoxComponent id='chkPeritiNoObiett' label='Visualizza periti SENZA obiettivo raggiunto' change={this.onChangeChkPeritiNoObiett} />       
        );   
    }
    chkPeritiNoObiettToolbar2(args) { 
        return (
            <CheckBoxComponent id='chkPeritiNoObiett2' label='Visualizza periti SENZA obiettivo raggiunto' change={this.onChangeChkPeritiNoObiett2} />       
        );   
    }
    onChangePerito(args) {
        let arrFiltrato = [];        
        if (CustomJS.isNullEmpty(args.value))
            arrFiltrato = JSON.parse(localStorage.getItem("MonitorDSObiettiviSecondoLiv"));
        else
            arrFiltrato = _.filter(JSON.parse(localStorage.getItem("MonitorDSObiettiviSecondoLiv")), elm => { return elm.IdPerito.toString() === args.value; });
        document.getElementById('GridObiettivi').ej2_instances[0].childGrid.dataSource = arrFiltrato; 
        document.getElementById('GridObiettivi').ej2_instances[0].refresh(); 
        setTimeout(() => {
            if (!CustomJS.isNullEmpty(args.value))
                document.getElementById('GridObiettivi').querySelectorAll('td.e-detailrowcollapse').forEach(elm => { 
                   elm.click();
                });
            document.getElementById('chkPeritiObiett').ej2_instances[0].checked = false;
            document.getElementById('chkPeritiObiett').ej2_instances[0].disabled = !CustomJS.isNullEmpty(args.value);
            document.getElementById('chkPeritiNoObiett').ej2_instances[0].checked = false;
            document.getElementById('chkPeritiNoObiett').ej2_instances[0].disabled = !CustomJS.isNullEmpty(args.value);
        }, 400);        
    }
    onChangePerito2(args) {
        let arrFiltrato = [];        
        if (CustomJS.isNullEmpty(args.value))
            arrFiltrato = JSON.parse(localStorage.getItem("MonitorDSObiettiviAreeSecondoLiv"));
        else
            arrFiltrato = _.filter(JSON.parse(localStorage.getItem("MonitorDSObiettiviAreeSecondoLiv")), elm => { return elm.IdPerito.toString() === args.value; });
        document.getElementById('GridObiettiviAree').ej2_instances[0].childGrid.dataSource = arrFiltrato; 
        document.getElementById('GridObiettiviAree').ej2_instances[0].refresh(); 
        setTimeout(() => {
            if (!CustomJS.isNullEmpty(args.value))
                document.getElementById('GridObiettiviAree').querySelectorAll('td.e-detailrowcollapse').forEach(elm => { 
                   elm.click();
                });
            document.getElementById('chkPeritiObiett2').ej2_instances[0].checked = false;
            document.getElementById('chkPeritiObiett2').ej2_instances[0].disabled = !CustomJS.isNullEmpty(args.value);
            document.getElementById('chkPeritiNoObiett2').ej2_instances[0].checked = false;
            document.getElementById('chkPeritiNoObiett2').ej2_instances[0].disabled = !CustomJS.isNullEmpty(args.value);
        }, 400);        
    }
    onChangeChkPeritiObiett(args) {
        let arrFiltrato = [];
        if (!args.checked)
            arrFiltrato = JSON.parse(localStorage.getItem("MonitorDSObiettiviSecondoLiv"));
        else
            arrFiltrato = _.filter(JSON.parse(localStorage.getItem("MonitorDSObiettiviSecondoLiv")), elm => { return parseInt(elm.NumSinistriMancantiAC) > 0 || parseInt(elm.NumSinistriMancantiRE) > 0 || parseInt(elm.NumSinistriMancantiRC) > 0; });
        document.getElementById('GridObiettivi').ej2_instances[0].childGrid.dataSource = arrFiltrato; 
        document.getElementById('GridObiettivi').ej2_instances[0].refresh(); 
        setTimeout(() => {
            if (args.checked)
            document.getElementById('GridObiettivi').querySelectorAll('td.e-detailrowcollapse').forEach(elm => { 
                elm.click();
            });
            document.getElementById('ddlPeriti').ej2_instances[0].enabled = !args.checked;
            document.getElementById('chkPeritiNoObiett').ej2_instances[0].disabled = args.checked;
        }, 400);     
    }
    onChangeChkPeritiObiett2(args) {
        let arrFiltrato = [];
        if (!args.checked)
            arrFiltrato = JSON.parse(localStorage.getItem("MonitorDSObiettiviAreeSecondoLiv"));
        else
            arrFiltrato = _.filter(JSON.parse(localStorage.getItem("MonitorDSObiettiviAreeSecondoLiv")), elm => { return parseInt(elm.NumSinistriMancantiAC) > 0 || parseInt(elm.NumSinistriMancantiRE) > 0 || parseInt(elm.NumSinistriMancantiRC) > 0; });
        document.getElementById('GridObiettiviAree').ej2_instances[0].childGrid.dataSource = arrFiltrato; 
        document.getElementById('GridObiettiviAree').ej2_instances[0].refresh(); 
        setTimeout(() => {
            if (args.checked)
                document.getElementById('GridObiettiviAree').querySelectorAll('td.e-detailrowcollapse').forEach(elm => { 
                    elm.click();
                });
            document.getElementById('ddlPeriti2').ej2_instances[0].enabled = !args.checked;
            document.getElementById('chkPeritiNoObiett2').ej2_instances[0].disabled = args.checked;
        }, 400);     
    }
    onChangeChkPeritiNoObiett(args) {
        let arrFiltrato = [];
        if (!args.checked)
            arrFiltrato = JSON.parse(localStorage.getItem("MonitorDSObiettiviSecondoLiv"));
        else
            arrFiltrato = _.filter(JSON.parse(localStorage.getItem("MonitorDSObiettiviSecondoLiv")), elm => { return parseInt(elm.NumSinistriMancantiAC) < 0 && parseInt(elm.NumSinistriMancantiRE) < 0 && parseInt(elm.NumSinistriMancantiRC) < 0; });
        document.getElementById('GridObiettivi').ej2_instances[0].childGrid.dataSource = arrFiltrato; 
        document.getElementById('GridObiettivi').ej2_instances[0].refresh(); 
        setTimeout(() => {
            if (args.checked)
            document.getElementById('GridObiettivi').querySelectorAll('td.e-detailrowcollapse').forEach(elm => { 
                elm.click();
            });
            document.getElementById('ddlPeriti').ej2_instances[0].enabled = !args.checked;
            document.getElementById('chkPeritiObiett').ej2_instances[0].disabled = args.checked;
        }, 400);     
    }
    onChangeChkPeritiNoObiett2(args) {
        let arrFiltrato = [];
        if (!args.checked)
            arrFiltrato = JSON.parse(localStorage.getItem("MonitorDSObiettiviAreeSecondoLiv"));
        else
            arrFiltrato = _.filter(JSON.parse(localStorage.getItem("MonitorDSObiettiviAreeSecondoLiv")), elm => { return parseInt(elm.NumSinistriMancantiAC) < 0 && parseInt(elm.NumSinistriMancantiRE) < 0 && parseInt(elm.NumSinistriMancantiRC) < 0; });
        document.getElementById('GridObiettiviAree').ej2_instances[0].childGrid.dataSource = arrFiltrato; 
        document.getElementById('GridObiettiviAree').ej2_instances[0].refresh(); 
        setTimeout(() => {
            if (args.checked)
                document.getElementById('GridObiettiviAree').querySelectorAll('td.e-detailrowcollapse').forEach(elm => { 
                    elm.click();
                });
            document.getElementById('ddlPeriti2').ej2_instances[0].enabled = !args.checked;
            document.getElementById('chkPeritiObiett2').ej2_instances[0].disabled = args.checked;
        }, 400);     
    }
    onChangeMese(args) {
        this.setState({
            meseAtt: args.itemData.Text,
            ObietMeseACatt: _.find(CurvaObiettivi.CurvaObiettivi, elm => { return elm.RAMO === 'AC'})[yyyy.toString() + args.value],
            ObietMeseREatt: _.find(CurvaObiettivi.CurvaObiettivi, elm => { return elm.RAMO === 'RE'})[yyyy.toString() + args.value],
            ObietMeseRCatt: _.find(CurvaObiettivi.CurvaObiettivi, elm => { return elm.RAMO === 'RC'})[yyyy.toString() + args.value]
        });
        setTimeout(() => {
            this.caricaDati();
            this.ddlInstance.enabled = true;
            this.ddlInstance.value = '';
            document.getElementById('chkPeritiObiett').ej2_instances[0].checked = false;
            document.getElementById('chkPeritiNoObiett').ej2_instances[0].checked = false;
        }, 300);        
    }
    GrigliaObiettiviAreeRender() {
        ++this.childKey;
        return(<React.Fragment>
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content">
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <BlockUi tag='div' id='blockUI2' blocking={this.state.blocking3} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}> 
                                                    <GridComponent id="GridObiettiviAree" key={this.childKey} ref={grid => this.gridInstance2 = grid} toolbar={this.toolbarOptions2} childGrid={this.childGrid2} allowSelection={false} enableHover={false} allowTextWrap={true}  
                                                        allowPaging={false} allowSorting={false} allowFiltering={false} allowExcelExport={true} allowPdfExport={true} queryCellInfo={this.onQueryCellInfo3.bind(this)} pageSettings={{ pageCount: 5, pageSize: 10, pageSizes: ['10', '50', 'Tutti'] }}
                                                        dataBound={this.onDatabound3.bind(this)} toolbarClick={this.toolbarClick2.bind(this)} rowDataBound={this.onRowDatabound2} enableAltRow={true} gridLines='Both' hierarchyPrintMode='All' height='600px'>
                                                            <ColumnsDirective>
                                                                <ColumnDirective field='DescrArea' headerText='Area' headerTemplate={headerTemplate} textAlign='Center' width='20%'></ColumnDirective>
                                                                <ColumnDirective columns={[{ field: 'ScarTotSinAC', headerText: 'Scarico Totale', headerTemplate: headerTemplate, textAlign: 'Center' }, 
                                                                                        { field: 'NumSinistriMancantiAC', headerText: 'Sinistri Mancanti', headerTemplate: headerTemplate, textAlign:'Center' },
                                                                                        { field: 'TempoMedioScaricoAC', headerText: 'Tempo Medio Scarico', headerTemplate: headerTemplate, textAlign: 'Center'}]} headerText='Acqua Condotta' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                                                <ColumnDirective columns={[{ field: 'ScarTotSinRE', headerText: 'Scarico Totale', headerTemplate: headerTemplate, textAlign: 'Center' }, 
                                                                                        { field: 'NumSinistriMancantiRE', headerText: 'Sinistri Mancanti', headerTemplate: headerTemplate, textAlign:'Center' },
                                                                                        { field: 'TempoMedioScaricoRE', headerText: 'Tempo Medio Scarico', headerTemplate: headerTemplate, textAlign: 'Center'}]} headerText='Rami Elementari' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                                                <ColumnDirective columns={[{ field: 'ScarTotSinRC', headerText: 'Scarico Totale', headerTemplate: headerTemplate, textAlign: 'Center' }, 
                                                                                        { field: 'NumSinistriMancantiRC', headerText: 'Sinistri Mancanti', headerTemplate: headerTemplate, textAlign:'Center' },
                                                                                        { field: 'TempoMedioScaricoRC', headerText: 'Tempo Medio Scarico', headerTemplate: headerTemplate, textAlign: 'Center'}]} headerText='Responsabilità Civile' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                                            </ColumnsDirective>
                                                        <Inject services={[Toolbar, DetailRow, ExcelExport, CommandColumn, PdfExport, Filter, Sort, Page]}/>                    
                                                    </GridComponent>  
                                                </BlockUi>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>);
    }

    render() {
        return (<React.Fragment>
            <div className='container-fluid'>   
                <DialogComponent id="defaultDialogObiettivi" showCloseIcon={true} target='body' animationSettings={this.animationSettings} visible={this.state.showDialog1} 
                        ref={dialog => this.dialogInstance = dialog} isModal={true} height='90%' width='90%' minHeight='90%'>   
                            <div id='defaultDialogContentObiettivi'></div>                         
                </DialogComponent> 
                <div className='row'>
                    <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3 ml-2" style={{ marginTop: '15px', marginBottom: '15px' }}>
                        <span style={{ fontSize: '2.0vw', fontWeight: 'bolder', color:'#133B95'}}>{ this.state.meseAtt + ' ' +  yyyy.toString()}</span>
                    </div> 
                    <div className="col-xs-12 col-sm-2 col-lg-9 col-md-9 text-center" style={{ marginTop: '15px', marginBottom: '15px'}}>
                        <span id='testoChiusiAnnoCorr' style={{ fontSize: '1.5vw', fontWeight: 'bolder', color:'#133B95'}}>OBIETTIVO MENSILE AC: {this.state.ObietMeseACatt}%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OBIETTIVO MENSILE RE: {this.state.ObietMeseREatt}%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OBIETTIVO MENSILE RC: {this.state.ObietMeseRCatt}% </span>
                    </div> 
                </div> 
                <div className='row'>
                    <div className="col-xs-12 col-sm-12 offset-lg-9 col-lg-3 offset-md-7 col-md-5">
                        <DropDownListComponent id='ddlMesi' fields={this.ddlFields} ref={ ddlObj => this.ddlInstance2 = ddlObj } enabled={false} dataSource={this.ddlMesiFiltr} change={this.onChangeMese.bind(this)}
                             placeholder="Selezionare un mese da visualizzare" floatLabelType='always' popupHeight="250px" cssClass='e-outline'></DropDownListComponent>
                    </div>
                </div> 
                <div className='row'>
                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                        <BlockUi tag='div' id='blockUI1' blocking={this.state.blocking2} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}> 
                            <GridComponent id="GridObiettivi" ref={grid => this.gridInstance = grid} toolbar={this.toolbarOptions} childGrid={this.childGrid} allowSelection={false} enableHover={false} allowTextWrap={true} commandClick={this.onCommandClick.bind(this)} 
                                allowPaging={false} allowSorting={false} allowFiltering={false} allowExcelExport={true} allowPdfExport={true} queryCellInfo={this.onQueryCellInfo.bind(this)} pageSettings={{ pageCount: 5, pageSize: 10, pageSizes: ['10', '50', 'Tutti'] }}
                                dataBound={this.onDatabound.bind(this)} toolbarClick={this.toolbarClick.bind(this)} rowDataBound={this.onRowDatabound} enableAltRow={true} gridLines='Both' hierarchyPrintMode='All'>
                                    <ColumnsDirective>
                                        <ColumnDirective field='DescrCompagnia' headerText='Compagnia' headerTemplate={headerTemplate} textAlign='Center' width='20%'></ColumnDirective>
                                        <ColumnDirective columns={[{ field: 'ScarTotSinAC', headerText: 'Scarico Totale', headerTemplate: headerTemplate, textAlign: 'Center' }, 
                                                                   { field: 'NumSinistriMancantiAC', headerText: 'Sinistri Mancanti', headerTemplate: headerTemplate, textAlign:'Center' },
                                                                   { field: 'TempoMedioScaricoAC', headerText: 'Tempo Medio Scarico', headerTemplate: headerTemplate, textAlign: 'Center'}]} headerText='Acqua Condotta' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective columns={[{ field: 'ScarTotSinRE', headerText: 'Scarico Totale', headerTemplate: headerTemplate, textAlign: 'Center' }, 
                                                                   { field: 'NumSinistriMancantiRE', headerText: 'Sinistri Mancanti', headerTemplate: headerTemplate, textAlign:'Center' },
                                                                   { field: 'TempoMedioScaricoRE', headerText: 'Tempo Medio Scarico', headerTemplate: headerTemplate, textAlign: 'Center'}]} headerText='Rami Elementari' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective columns={[{ field: 'ScarTotSinRC', headerText: 'Scarico Totale', headerTemplate: headerTemplate, textAlign: 'Center' }, 
                                                                   { field: 'NumSinistriMancantiRC', headerText: 'Sinistri Mancanti', headerTemplate: headerTemplate, textAlign:'Center' },
                                                                   { field: 'TempoMedioScaricoRC', headerText: 'Tempo Medio Scarico', headerTemplate: headerTemplate, textAlign: 'Center'}]} headerText='Responsabilità Civile' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='Comandi' headerText="" commands={this.commands} width='3%'></ColumnDirective>
                                    </ColumnsDirective>
                                <Inject services={[Toolbar, DetailRow, ExcelExport, CommandColumn, PdfExport, Filter, Sort, Page]}/>                    
                            </GridComponent>  
                        </BlockUi>
                    </div>
                </div>
                <div className="row"  style={{ marginTop: '50px', marginBottom: '20px', display: 'none' }}>   
                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                        <span style={{ fontSize: '1.6vw', fontWeight: 'bold', color:'#133B95'}}>Confronto andamento obiettivi/risultati sinistri RE</span>
                    </div>               
                </div> 
                <div className='row' style={{ display: 'none' }}>
                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                        <ChartComponent id='ChartObiettRE' style={{ textAlign: "center" }}
                            className='col-xs-12 col-sm-12 col-lg-12 col-md-12'
                            ref={chart => {this.chartInstance1 = chart}}
                            primaryXAxis={{
                                valueType: 'DateTime',
                                labelFormat: 'MMMM',
                                intervalType: 'Months',
                                edgeLabelPlacement: 'Shift',
                                majorGridLines: { width: 0 }                
                            }}
                            primaryYAxis={{
                                labelFormat: '{value}',
                                rangePadding: 'None',                                
                                interval: 10,
                                lineStyle: { width: 0 },                                
                                minimum: 0,
                                majorTickLines: { width: 0 },
                                minorTickLines: { width: 0 }
                            }}
                            chartArea={{ border: { width: 0 } }}
                            tooltip={{ enable: true }}
                            title='' 
                            width='97%'
                            legendSettings={{ visible: true }}>
                            <Inject services={[LineSeries, DateTime, Legend, Tooltip, Trendlines]} />
                            <SeriesCollectionDirective>
                                <SeriesDirective xName='x' yName='y' name={`Obiettivi mensili RE`}
                                    width={3} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                                </SeriesDirective>
                                <SeriesDirective xName='x' yName='y' name={`Risultati mensili RE`}
                                    width={3} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                                </SeriesDirective>
                            </SeriesCollectionDirective>
                        </ChartComponent>
                    </div>
                </div>
                <div className='row'  style={{ marginTop: '50px', marginBottom: '20px', display: 'none' }}>                       
                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                        <span style={{ fontSize: '1.6vw', fontWeight: 'bold', color:'#133B95'}}>Confronto andamento obiettivi/risultati sinistri RC</span>
                    </div>        
                </div>    
                <div className='row' style={{ display: 'none' }}>
                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                        <ChartComponent id='ChartObiettRC' style={{ textAlign: "center"}}
                            className='col-xs-12 col-sm-12 col-lg-12 col-md-12'
                            ref={chart => {this.chartInstance1 = chart}}
                            primaryXAxis={{
                                valueType: 'DateTime',
                                labelFormat: 'MMMM',
                                intervalType: 'Months',
                                edgeLabelPlacement: 'Shift',
                                majorGridLines: { width: 0 }                
                            }}
                            primaryYAxis={{
                                labelFormat: '{value}',
                                rangePadding: 'None',                                
                                interval: 10,
                                lineStyle: { width: 0 },                                
                                minimum: 0,
                                majorTickLines: { width: 0 },
                                minorTickLines: { width: 0 }
                            }}
                            chartArea={{ border: { width: 0 } }}
                            tooltip={{ enable: true }}
                            width='97%'
                            title='' 
                            legendSettings={{ visible: true }}>
                            <Inject services={[LineSeries, DateTime, Legend, Tooltip, Trendlines]} />
                            <SeriesCollectionDirective>
                                <SeriesDirective xName='x' yName='y' name={`Obiettivi mensili RC`}
                                    width={3} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                                </SeriesDirective>
                                <SeriesDirective xName='x' yName='y' name={`Risultati mensili RC`}
                                    width={3} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                                </SeriesDirective>
                            </SeriesCollectionDirective>
                        </ChartComponent>
                    </div>
                </div>
            </div>
        </React.Fragment>);
    }      
    componentDidMount() {
        CustomJS.getRemoteData2(CustomJS.APIUrlProd,
            `token=${CustomJS.tokenProd}&azione=compagnie`, 
            'POST', "application/x-www-form-urlencoded", (retData) => {  
                ddlData = retData;     
                this.ddlInstance2.value = mm;           
        }, true, false);    
    }
    caricaDati() {
        GridData1 = []; GridData2 = []; data1 = []; data2 = []; data3 = []; data4 = []; periti = [];
        let dataFineEstr = '';
        if (this.ddlInstance2.value === mm)
            dataFineEstr = dataCorrente;
        else { 
            const meseEstr = this.ddlInstance2.value;
            const giorno = (mm === '02') ? CustomJS.leapYear(yyyy.toString()) ? '29' : '28' : (meseEstr === '01' || meseEstr === '03' || meseEstr === '05' || meseEstr === '07' || meseEstr === '08' || meseEstr === '10' || meseEstr === '12') ? '31' : '30';            
            dataFineEstr = yyyy.toString() + this.ddlInstance2.value + giorno;
        }
        ObietMeseAC = _.find(CurvaObiettivi.CurvaObiettivi, elm => { return elm.RAMO === 'AC'})[yyyy.toString() + this.ddlInstance2.value]; 
        ObietMeseRE = _.find(CurvaObiettivi.CurvaObiettivi, elm => { return elm.RAMO === 'RE'})[yyyy.toString() + this.ddlInstance2.value]; 
        ObietMeseRC = _.find(CurvaObiettivi.CurvaObiettivi, elm => { return elm.RAMO === 'RC'})[yyyy.toString() + this.ddlInstance2.value];
        this.toggleBlocking();
        CustomJS.getRemoteData2(CustomJS.APIUrlProd,
            `token=${CustomJS.tokenProd}&azione=scaricoCompagnie&raggruppamento=rercac&dataDa=${yyyy.toString()}0101&dataA=${dataFineEstr}`, 
            'POST', "application/x-www-form-urlencoded", (retData) => {  
                debugger; 
                let  groupedArr = _.groupBy(retData, 'IdCompagnia');
                let NumChiusiAC = 0, NumEntratiAC = 0, NumChiusiRE = 0, NumEntratiRE = 0, NumChiusiRC = 0, NumEntratiRC = 0;
                let TempoMedioScarAC = 0, TempoMedioScarRE = 0, TempoMedioScarRC = 0, PesoParzialeAC = 0, PesoParzialeRE = 0, PesoParzialeRC = 0;
                Object.values(groupedArr).forEach((elm, idx) => {
                    NumChiusiAC = elm.sum('NumSinistriChiusi', 'AC');
                    NumEntratiAC = elm.sum('NumSinistriEntrati', 'AC');
                    NumChiusiRE = elm.sum('NumSinistriChiusi', 'RE');
                    NumEntratiRE = elm.sum('NumSinistriEntrati', 'RE');
                    NumChiusiRC = elm.sum('NumSinistriChiusi', 'RC');
                    NumEntratiRC = elm.sum('NumSinistriEntrati', 'RC');
                    TempoMedioScarAC = elm.mediaPonderataMedie('GiorniMediRientro', 'NumSinistriChiusi', 'AC');
                    TempoMedioScarRE = elm.mediaPonderataMedie('GiorniMediRientro', 'NumSinistriChiusi', 'RE');
                    TempoMedioScarRC = elm.mediaPonderataMedie('GiorniMediRientro', 'NumSinistriChiusi', 'RC');
                    GridData1.push({
                        IdCompagnia: Object.keys(groupedArr)[idx],
                        DescrCompagnia:_.find(ddlData, (obj) => { return obj.IdCompagnia.toString() === Object.keys(groupedArr)[idx]; })?.DescrizioneCompagnia,
                        ScarTotSinAC: ((NumChiusiAC / NumEntratiAC) * 100) < 100 && ((NumChiusiAC / NumEntratiAC) * 100) > 0 
                                        && (((NumChiusiAC / NumEntratiAC) * 100) - Math.floor(((NumChiusiAC / NumEntratiAC) * 100))) !== 0
                                    ? ((NumChiusiAC / NumEntratiAC) * 100).toFixed(2)
                                    : ((NumChiusiAC / NumEntratiAC) * 100).toFixed(0),
                        NumSinistriMancantiAC: NumChiusiAC - calcolaSinistriObiettivo(NumEntratiAC, 'AC'),
                        TempoMedioScaricoAC: TempoMedioScarAC,
                        ScarTotSinRE: ((NumChiusiRE / NumEntratiRE) * 100) < 100 && ((NumChiusiRE / NumEntratiRE) * 100) > 0 
                                        && (((NumChiusiRE / NumEntratiRE) * 100) - Math.floor(((NumChiusiRE / NumEntratiRE) * 100))) !== 0
                                    ? ((NumChiusiRE / NumEntratiRE) * 100).toFixed(2)
                                    : ((NumChiusiRE / NumEntratiRE) * 100).toFixed(0),
                        NumSinistriMancantiRE: NumChiusiRE - calcolaSinistriObiettivo(NumEntratiRE, 'RE'),
                        TempoMedioScaricoRE: TempoMedioScarRE,
                        ScarTotSinRC: ((NumChiusiRC / NumEntratiRC) * 100) < 100 && ((NumChiusiRC / NumEntratiRC) * 100) > 0 
                                        && (((NumChiusiRC / NumEntratiRC) * 100) - Math.floor(((NumChiusiRC / NumEntratiRC) * 100))) !== 0
                                    ? ((NumChiusiRC / NumEntratiRC) * 100).toFixed(2)
                                    : ((NumChiusiRC / NumEntratiRC) * 100).toFixed(0), 
                        NumSinistriMancantiRC: NumChiusiRC - calcolaSinistriObiettivo(NumEntratiRC, 'RC'),
                        TempoMedioScaricoRC: TempoMedioScarRC
                    }); 
                });    
                GridData1 = _.sortBy(GridData1, obj => { return obj.NumSinistriMancantiAC + obj.NumSinistriMancantiRE + obj.NumSinistriMancantiRC; });
                let sortedArr = _.sortBy(retData, ['IdPerito', 'IdCompagnia']);
                sortedArr = _.filter(sortedArr, obj => { return obj.NominativoPerito !== "TOTALI" });
                let salvaIdPerito = '', salvaIdCompagnia = '', salvaNomePerito = '';
                NumChiusiAC = 0; NumEntratiAC = 0; PesoParzialeAC = 0;
                NumChiusiRE = 0; NumEntratiRE = 0; PesoParzialeRE = 0;
                NumChiusiRC = 0; NumEntratiRC = 0; PesoParzialeRC = 0;
                sortedArr.forEach((elm) => { 
                    if ((!CustomJS.isNullEmpty(salvaIdPerito) && elm.IdPerito !== salvaIdPerito) || (!CustomJS.isNullEmpty(salvaIdCompagnia) && elm.IdCompagnia !== salvaIdCompagnia)) {
                        GridData2.push({
                            IdCompagnia: salvaIdCompagnia,
                            IdPerito: salvaIdPerito,
                            NominativoPerito: salvaNomePerito,  
                            ScarTotSinAC: ((NumChiusiAC / NumEntratiAC) * 100) < 100 && ((NumChiusiAC / NumEntratiAC) * 100) > 0 
                                            && (((NumChiusiAC / NumEntratiAC) * 100) - Math.floor(((NumChiusiAC / NumEntratiAC) * 100))) !== 0
                                            ? ((NumChiusiAC / NumEntratiAC) * 100).toFixed(2)
                                            : ((NumChiusiAC / NumEntratiAC) * 100).toFixed(0),
                            NumSinistriMancantiAC: NumChiusiAC - calcolaSinistriObiettivo(NumEntratiAC, 'AC'),
                            TempoMedioScaricoAC: NumChiusiAC === 0 ? 0 : parseFloat(PesoParzialeAC / NumChiusiAC).toFixed(2),  
                            ScarTotSinRE: ((NumChiusiRE / NumEntratiRE) * 100) < 100 && ((NumChiusiRE / NumEntratiRE) * 100) > 0 
                                            && (((NumChiusiRE / NumEntratiRE) * 100) - Math.floor(((NumChiusiRE / NumEntratiRE) * 100))) !== 0
                                            ? ((NumChiusiRE / NumEntratiRE) * 100).toFixed(2)
                                            : ((NumChiusiRE / NumEntratiRE) * 100).toFixed(0),
                            NumSinistriMancantiRE: NumChiusiRE - calcolaSinistriObiettivo(NumEntratiRE, 'RE'),
                            TempoMedioScaricoRE: NumChiusiRE === 0 ? 0 : parseFloat(PesoParzialeRE / NumChiusiRE).toFixed(2),
                            ScarTotSinRC: ((NumChiusiRC / NumEntratiRC) * 100) < 100 && ((NumChiusiRC / NumEntratiRC) * 100) > 0 
                                            && (((NumChiusiRC / NumEntratiRC) * 100) - Math.floor(((NumChiusiRC / NumEntratiRC) * 100))) !== 0
                                                            ? ((NumChiusiRC / NumEntratiRC) * 100).toFixed(2)
                                                            : ((NumChiusiRC / NumEntratiRC) * 100).toFixed(0),
                            NumSinistriMancantiRC: NumChiusiRC - calcolaSinistriObiettivo(NumEntratiRC, 'RC'),
                            TempoMedioScaricoRC: NumChiusiRC === 0 ? 0 : parseFloat(PesoParzialeRC / NumChiusiRC).toFixed(2)
                        });  
                        NumChiusiAC = 0; NumEntratiAC = 0; PesoParzialeAC = 0;
                        NumChiusiRE = 0; NumEntratiRE = 0; PesoParzialeRE = 0;
                        NumChiusiRC = 0; NumEntratiRC = 0; PesoParzialeRC = 0;
                    }
                    if (elm.Raggruppamento === 'AC') {
                        NumChiusiAC += elm.NumSinistriChiusi;
                        NumEntratiAC += elm.NumSinistriEntrati;
                        PesoParzialeAC += (elm.GiorniMediRientro * elm.NumSinistriChiusi);
                    }
                    else if (elm.Raggruppamento === 'RE') {
                        NumChiusiRE += elm.NumSinistriChiusi;
                        NumEntratiRE += elm.NumSinistriEntrati;
                        PesoParzialeRE += (elm.GiorniMediRientro * elm.NumSinistriChiusi);
                    }
                    else if (elm.Raggruppamento === 'RC') {
                        NumChiusiRC += elm.NumSinistriChiusi;
                        NumEntratiRC += elm.NumSinistriEntrati;
                        PesoParzialeRC += (elm.GiorniMediRientro * elm.NumSinistriChiusi);
                    }  
                    salvaIdPerito = elm.IdPerito;
                    salvaIdCompagnia = elm.IdCompagnia;   
                    salvaNomePerito = elm.NominativoPerito;             
                    periti.push({ Value: elm.IdPerito.toString(), Text: elm.NominativoPerito });               
                });             
                GridData2.push({
                    IdCompagnia: salvaIdCompagnia,
                    IdPerito: salvaIdPerito,
                    NominativoPerito: salvaNomePerito,  
                    ScarTotSinAC: ((NumChiusiAC / NumEntratiAC) * 100) < 100 && ((NumChiusiAC / NumEntratiAC) * 100) > 0 
                                    && (((NumChiusiAC / NumEntratiAC) * 100) - Math.floor(((NumChiusiAC / NumEntratiAC) * 100))) !== 0
                                    ? ((NumChiusiAC / NumEntratiAC) * 100).toFixed(2)
                                    : ((NumChiusiAC / NumEntratiAC) * 100).toFixed(0),
                    NumSinistriMancantiAC: NumChiusiAC - calcolaSinistriObiettivo(NumEntratiAC, 'AC'),
                    TempoMedioScaricoAC: NumChiusiAC === 0 ? 0 : parseFloat(PesoParzialeAC / NumChiusiAC).toFixed(2),  
                    ScarTotSinRE: ((NumChiusiRE / NumEntratiRE) * 100) < 100 && ((NumChiusiRE / NumEntratiRE) * 100) > 0 
                                    && (((NumChiusiRE / NumEntratiRE) * 100) - Math.floor(((NumChiusiRE / NumEntratiRE) * 100))) !== 0
                                    ? ((NumChiusiRE / NumEntratiRE) * 100).toFixed(2)
                                    : ((NumChiusiRE / NumEntratiRE) * 100).toFixed(0),
                    NumSinistriMancantiRE: NumChiusiRE - calcolaSinistriObiettivo(NumEntratiRE, 'RE'),
                    TempoMedioScaricoRE: NumChiusiRE === 0 ? 0 : parseFloat(PesoParzialeRE / NumChiusiRE).toFixed(2),
                    ScarTotSinRC: ((NumChiusiRC / NumEntratiRC) * 100) < 100 && ((NumChiusiRC / NumEntratiRC) * 100) > 0 
                                    && (((NumChiusiRC / NumEntratiRC) * 100) - Math.floor(((NumChiusiRC / NumEntratiRC) * 100))) !== 0
                                    ? ((NumChiusiRC / NumEntratiRC) * 100).toFixed(2)
                                    : ((NumChiusiRC / NumEntratiRC) * 100).toFixed(0), 
                    NumSinistriMancantiRC: NumChiusiRC - calcolaSinistriObiettivo(NumEntratiRC, 'RC'),
                    TempoMedioScaricoRC: NumChiusiRC === 0 ? 0 : parseFloat(PesoParzialeRC / NumChiusiRC).toFixed(2)
                });   
                GridData2 = _.sortBy(GridData2, obj => { return obj.NumSinistriMancantiAC + obj.NumSinistriMancantiRE + obj.NumSinistriMancantiRC; }); 
                localStorage.setItem("MonitorDSObiettiviSecondoLiv", JSON.stringify(GridData2));               
                periti.unshift({ Value: '', Text: '' }); 
                periti = _.uniq(periti, elm => elm.Value);  
                document.getElementById('GridObiettivi').ej2_instances[0].dataSource = GridData1;
                document.getElementById('GridObiettivi').ej2_instances[0].childGrid.dataSource = GridData2; 
                document.getElementById('GridObiettivi').ej2_instances[0].refresh();  
                document.getElementById('ddlPeriti').ej2_instances[0].dataSource = periti;
                this.toggleBlocking();
        }, true, false);
        
        CustomJS.getRemoteData2(CustomJS.APIUrlProd,
            `token=${CustomJS.tokenProd}&azione=scaricoCompagnie&raggruppamento=rerc-mese`, 
            'POST', "application/x-www-form-urlencoded", (retData) => { 
                retData = _.filter(retData, elm => { return elm.NominativoPerito !== 'TOTALI' });
                let groupedArr = _.groupBy(retData, 'periodo');
                let calcoloCumulMese = (meseFinale, raggr) => { 
                    let totEntrati = 0, totChiusi = 0;
                    for (let ind=0;ind<=meseFinale;ind++) {
                        totEntrati += !_.isArray(Object.values(groupedArr)[ind]) ? 0 : Object.values(groupedArr)[ind].sum('NumSinistriEntrati', raggr);
                        totChiusi += !_.isArray(Object.values(groupedArr)[ind]) ? 0 : Object.values(groupedArr)[ind].sum('NumSinistriChiusi', raggr);
                    }  
                    return meseFinale > new Date().getMonth() ? 0 : parseFloat(totChiusi/totEntrati*100).toFixed(2)
                }
                for (let ind=1; ind<=12;ind++) {  
                    ObietMese = _.find(CurvaObiettivi.CurvaObiettivi, elm => { return elm.RAMO === 'RE'})[yyyy.toString() + ind.toString().padStart(2,'0')];
                    data1.push({ x: ind === 1 ? new Date(yyyy, 0, 1) : ind === 2 ? new Date(yyyy, 1, 1)  : ind === 3 ? new Date(yyyy, 2, 1)  : ind === 4 ? new Date(yyyy, 3, 1)
                                : ind === 5 ? new Date(yyyy, 4, 1)  : ind === 6 ? new Date(yyyy, 5, 1) : ind === 7 ? new Date(yyyy, 6, 1)  : ind === 8 ? new Date(yyyy, 7, 1)  
                                : ind === 9 ? new Date(yyyy, 8, 1)  : ind === 10 ? new Date(yyyy, 9, 1)  : ind === 11 ? new Date(yyyy, 10, 1)  : new Date(yyyy, 11, 1), y: ObietMese  }); 
                    data2.push({ x: ind === 1 ? new Date(yyyy, 0, 1) : ind === 2 ? new Date(yyyy, 1, 1) : ind === 3 ? new Date(yyyy, 2, 1) : ind === 4 ? new Date(yyyy, 3, 1) : ind === 5 ? new Date(yyyy, 4, 1) : ind === 6 ? new Date(yyyy, 5, 1) 
                                : ind === 7 ? new Date(yyyy, 6, 1) : ind === 8 ? new Date(yyyy, 7, 1) : ind === 9 ? new Date(yyyy, 8, 1) : ind === 10 ? new Date(yyyy, 9, 1) : ind === 11 ? new Date(yyyy, 10, 1) : new Date(yyyy, 11, 1), y: calcoloCumulMese(ind-1, 'RE') }); 
                }  
                for (let ind=1; ind<=12;ind++) {  
                    ObietMese = _.find(CurvaObiettivi.CurvaObiettivi, elm => { return elm.RAMO === 'RC'})[yyyy.toString() + ind.toString().padStart(2,'0')];
                    data3.push({ x: ind === 1 ? new Date(yyyy, 0, 1) : ind === 2 ? new Date(yyyy, 1, 1)  : ind === 3 ? new Date(yyyy, 2, 1)  : ind === 4 ? new Date(yyyy, 3, 1)
                                : ind === 5 ? new Date(yyyy, 4, 1)  : ind === 6 ? new Date(yyyy, 5, 1) : ind === 7 ? new Date(yyyy, 6, 1)  : ind === 8 ? new Date(yyyy, 7, 1)  
                                : ind === 9 ? new Date(yyyy, 8, 1)  : ind === 10 ? new Date(yyyy, 9, 1)  : ind === 11 ? new Date(yyyy, 10, 1)  : new Date(yyyy, 11, 1), y: ObietMese  }); 
                    data4.push({ x: ind === 1 ? new Date(yyyy, 0, 1) : ind === 2 ? new Date(yyyy, 1, 1) : ind === 3 ? new Date(yyyy, 2, 1) : ind === 4 ? new Date(yyyy, 3, 1) : ind === 5 ? new Date(yyyy, 4, 1) : ind === 6 ? new Date(yyyy, 5, 1) 
                                : ind === 7 ? new Date(yyyy, 6, 1) : ind === 8 ? new Date(yyyy, 7, 1) : ind === 9 ? new Date(yyyy, 8, 1) : ind === 10 ? new Date(yyyy, 9, 1) : ind === 11 ? new Date(yyyy, 10, 1) : new Date(yyyy, 11, 1), y: calcoloCumulMese(ind-1, 'RC') }); 
                }   
                data2 = _.filter(data2, elm => { return elm.y > 0 });
                data4 = _.filter(data4, elm => { return elm.y > 0 });
                document.getElementById('ChartObiettRE').ej2_instances[0].series[0].dataSource = data1;
                document.getElementById('ChartObiettRE').ej2_instances[0].series[1].dataSource = data2;
                document.getElementById('ChartObiettRC').ej2_instances[0].series[0].dataSource = data3;
                document.getElementById('ChartObiettRC').ej2_instances[0].series[1].dataSource = data4;
        }, true, false);
    }   
    caricaDati2(IdCompagnia) {
        GridData1 = []; GridData2 = []; data1 = []; data2 = []; data3 = []; data4 = []; periti = [];
        let dataFineEstr = '';
        if (this.ddlInstance2.value === mm)
            dataFineEstr = dataCorrente;
        else { 
            const meseEstr = this.ddlInstance2.value;
            const giorno = (mm === '02') ? CustomJS.leapYear(yyyy.toString()) ? '29' : '28' : (meseEstr === '01' || meseEstr === '03' || meseEstr === '05' || meseEstr === '07' || meseEstr === '08' || meseEstr === '10' || meseEstr === '12') ? '31' : '30';            
            dataFineEstr = yyyy.toString() + this.ddlInstance2.value + giorno;
        }
        ObietMeseAC = _.find(CurvaObiettivi.CurvaObiettivi, elm => { return elm.RAMO === 'AC'})[yyyy.toString() + this.ddlInstance2.value]; 
        ObietMeseRE = _.find(CurvaObiettivi.CurvaObiettivi, elm => { return elm.RAMO === 'RE'})[yyyy.toString() + this.ddlInstance2.value]; 
        ObietMeseRC = _.find(CurvaObiettivi.CurvaObiettivi, elm => { return elm.RAMO === 'RC'})[yyyy.toString() + this.ddlInstance2.value];
        this.toggleBlocking2();
        CustomJS.getRemoteData2(CustomJS.APIUrlProd,
            `token=${CustomJS.tokenProd}&azione=scaricoCompagnie&raggruppamento=rercac&dataDa=${yyyy.toString()}0101&dataA=${dataFineEstr}`, 
            'POST', "application/x-www-form-urlencoded", (retData) => {  
                debugger; 
                let groupedArr = [];
                retData = _.filter(retData, elm => { return elm.IdCompagnia === IdCompagnia && !CustomJS.isNullEmpty(elm.Area)});
                groupedArr = _.groupBy(retData, 'Area');
                let NumChiusiAC = 0, NumEntratiAC = 0, NumChiusiRE = 0, NumEntratiRE = 0, NumChiusiRC = 0, NumEntratiRC = 0;
                let TempoMedioScarAC = 0, TempoMedioScarRE = 0, TempoMedioScarRC = 0, PesoParzialeAC = 0, PesoParzialeRE = 0, PesoParzialeRC = 0;
                Object.values(groupedArr).forEach((elm, idx) => {
                    NumChiusiAC = elm.sum('NumSinistriChiusi', 'AC');
                    NumEntratiAC = elm.sum('NumSinistriEntrati', 'AC');
                    NumChiusiRE = elm.sum('NumSinistriChiusi', 'RE');
                    NumEntratiRE = elm.sum('NumSinistriEntrati', 'RE');
                    NumChiusiRC = elm.sum('NumSinistriChiusi', 'RC');
                    NumEntratiRC = elm.sum('NumSinistriEntrati', 'RC');
                    TempoMedioScarAC = elm.mediaPonderataMedie('GiorniMediRientro', 'NumSinistriChiusi', 'AC');
                    TempoMedioScarRE = elm.mediaPonderataMedie('GiorniMediRientro', 'NumSinistriChiusi', 'RE');
                    TempoMedioScarRC = elm.mediaPonderataMedie('GiorniMediRientro', 'NumSinistriChiusi', 'RC');
                    GridData1.push({
                        DescrArea: !CustomJS.isNullEmpty(IdCompagnia) ? Object.keys(groupedArr)[idx] : '',
                        ScarTotSinAC: ((NumChiusiAC / NumEntratiAC) * 100) < 100 && ((NumChiusiAC / NumEntratiAC) * 100) > 0 
                                        && (((NumChiusiAC / NumEntratiAC) * 100) - Math.floor(((NumChiusiAC / NumEntratiAC) * 100))) !== 0
                                    ? ((NumChiusiAC / NumEntratiAC) * 100).toFixed(2)
                                    : ((NumChiusiAC / NumEntratiAC) * 100).toFixed(0),
                        NumSinistriMancantiAC: NumChiusiAC - calcolaSinistriObiettivo(NumEntratiAC, 'AC'),
                        TempoMedioScaricoAC: TempoMedioScarAC,
                        ScarTotSinRE: ((NumChiusiRE / NumEntratiRE) * 100) < 100 && ((NumChiusiRE / NumEntratiRE) * 100) > 0 
                                        && (((NumChiusiRE / NumEntratiRE) * 100) - Math.floor(((NumChiusiRE / NumEntratiRE) * 100))) !== 0
                                    ? ((NumChiusiRE / NumEntratiRE) * 100).toFixed(2)
                                    : ((NumChiusiRE / NumEntratiRE) * 100).toFixed(0),
                        NumSinistriMancantiRE: NumChiusiRE - calcolaSinistriObiettivo(NumEntratiRE, 'RE'),
                        TempoMedioScaricoRE: TempoMedioScarRE,
                        ScarTotSinRC: ((NumChiusiRC / NumEntratiRC) * 100) < 100 && ((NumChiusiRC / NumEntratiRC) * 100) > 0 
                                        && (((NumChiusiRC / NumEntratiRC) * 100) - Math.floor(((NumChiusiRC / NumEntratiRC) * 100))) !== 0
                                    ? ((NumChiusiRC / NumEntratiRC) * 100).toFixed(2)
                                    : ((NumChiusiRC / NumEntratiRC) * 100).toFixed(0), 
                        NumSinistriMancantiRC: NumChiusiRC - calcolaSinistriObiettivo(NumEntratiRC, 'RC'),
                        TempoMedioScaricoRC: TempoMedioScarRC
                    }); 
                });    
                GridData1 = _.sortBy(GridData1, obj => { return obj.NumSinistriMancantiAC + obj.NumSinistriMancantiRE + obj.NumSinistriMancantiRC; });
                let sortedArr = _.sortBy(_.sortBy(retData, elm => { return elm.IdPerito }), elm2 => { return elm2.Area });
                sortedArr = _.filter(sortedArr, obj => { return obj.NominativoPerito !== "TOTALI" });
                let salvaIdPerito = '', salvaDescrArea = '', salvaNomePerito = '';
                NumChiusiAC = 0; NumEntratiAC = 0; PesoParzialeAC = 0;
                NumChiusiRE = 0; NumEntratiRE = 0; PesoParzialeRE = 0;
                NumChiusiRC = 0; NumEntratiRC = 0; PesoParzialeRC = 0;
                sortedArr.forEach((elm) => { 
                    if ((!CustomJS.isNullEmpty(salvaIdPerito) && elm.IdPerito !== salvaIdPerito) || (!CustomJS.isNullEmpty(salvaDescrArea) && elm.Area !== salvaDescrArea)) {
                        GridData2.push({
                            DescrArea: salvaDescrArea,
                            IdPerito: salvaIdPerito,
                            NominativoPerito: salvaNomePerito,  
                            ScarTotSinAC: ((NumChiusiAC / NumEntratiAC) * 100) < 100 && ((NumChiusiAC / NumEntratiAC) * 100) > 0 
                                            && (((NumChiusiAC / NumEntratiAC) * 100) - Math.floor(((NumChiusiAC / NumEntratiAC) * 100))) !== 0
                                            ? ((NumChiusiAC / NumEntratiAC) * 100).toFixed(2)
                                            : ((NumChiusiAC / NumEntratiAC) * 100).toFixed(0),
                            NumSinistriMancantiAC: NumChiusiAC - calcolaSinistriObiettivo(NumEntratiAC, 'AC'),
                            TempoMedioScaricoAC: NumChiusiAC === 0 ? 0 : parseFloat(PesoParzialeAC / NumChiusiAC).toFixed(2),  
                            ScarTotSinRE: ((NumChiusiRE / NumEntratiRE) * 100) < 100 && ((NumChiusiRE / NumEntratiRE) * 100) > 0 
                                            && (((NumChiusiRE / NumEntratiRE) * 100) - Math.floor(((NumChiusiRE / NumEntratiRE) * 100))) !== 0
                                            ? ((NumChiusiRE / NumEntratiRE) * 100).toFixed(2)
                                            : ((NumChiusiRE / NumEntratiRE) * 100).toFixed(0),
                            NumSinistriMancantiRE: NumChiusiRE - calcolaSinistriObiettivo(NumEntratiRE, 'RE'),
                            TempoMedioScaricoRE: NumChiusiRE === 0 ? 0 : parseFloat(PesoParzialeRE / NumChiusiRE).toFixed(2),
                            ScarTotSinRC: ((NumChiusiRC / NumEntratiRC) * 100) < 100 && ((NumChiusiRC / NumEntratiRC) * 100) > 0 
                                            && (((NumChiusiRC / NumEntratiRC) * 100) - Math.floor(((NumChiusiRC / NumEntratiRC) * 100))) !== 0
                                                            ? ((NumChiusiRC / NumEntratiRC) * 100).toFixed(2)
                                                            : ((NumChiusiRC / NumEntratiRC) * 100).toFixed(0),
                            NumSinistriMancantiRC: NumChiusiRC - calcolaSinistriObiettivo(NumEntratiRC, 'RC'),
                            TempoMedioScaricoRC: NumChiusiRC === 0 ? 0 : parseFloat(PesoParzialeRC / NumChiusiRC).toFixed(2)
                        });  
                        NumChiusiAC = 0; NumEntratiAC = 0; PesoParzialeAC = 0;
                        NumChiusiRE = 0; NumEntratiRE = 0; PesoParzialeRE = 0;
                        NumChiusiRC = 0; NumEntratiRC = 0; PesoParzialeRC = 0;
                    }
                    if (elm.Raggruppamento === 'AC') {
                        NumChiusiAC += elm.NumSinistriChiusi;
                        NumEntratiAC += elm.NumSinistriEntrati;
                        PesoParzialeAC += (elm.GiorniMediRientro * elm.NumSinistriChiusi);
                    }
                    else if (elm.Raggruppamento === 'RE') {
                        NumChiusiRE += elm.NumSinistriChiusi;
                        NumEntratiRE += elm.NumSinistriEntrati;
                        PesoParzialeRE += (elm.GiorniMediRientro * elm.NumSinistriChiusi);
                    }
                    else if (elm.Raggruppamento === 'RC') {
                        NumChiusiRC += elm.NumSinistriChiusi;
                        NumEntratiRC += elm.NumSinistriEntrati;
                        PesoParzialeRC += (elm.GiorniMediRientro * elm.NumSinistriChiusi);
                    }  
                    salvaIdPerito = elm.IdPerito;
                    salvaDescrArea = elm.Area;   
                    salvaNomePerito = elm.NominativoPerito;             
                    periti.push({ Value: elm.IdPerito.toString(), Text: elm.NominativoPerito });               
                });             
                GridData2.push({
                    DescrArea: salvaDescrArea,
                    IdPerito: salvaIdPerito,
                    NominativoPerito: salvaNomePerito,  
                    ScarTotSinAC: ((NumChiusiAC / NumEntratiAC) * 100) < 100 && ((NumChiusiAC / NumEntratiAC) * 100) > 0 
                                    && (((NumChiusiAC / NumEntratiAC) * 100) - Math.floor(((NumChiusiAC / NumEntratiAC) * 100))) !== 0
                                    ? ((NumChiusiAC / NumEntratiAC) * 100).toFixed(2)
                                    : ((NumChiusiAC / NumEntratiAC) * 100).toFixed(0),
                    NumSinistriMancantiAC: NumChiusiAC - calcolaSinistriObiettivo(NumEntratiAC, 'AC'),
                    TempoMedioScaricoAC: NumChiusiAC === 0 ? 0 : parseFloat(PesoParzialeAC / NumChiusiAC).toFixed(2),  
                    ScarTotSinRE: ((NumChiusiRE / NumEntratiRE) * 100) < 100 && ((NumChiusiRE / NumEntratiRE) * 100) > 0 
                                    && (((NumChiusiRE / NumEntratiRE) * 100) - Math.floor(((NumChiusiRE / NumEntratiRE) * 100))) !== 0
                                    ? ((NumChiusiRE / NumEntratiRE) * 100).toFixed(2)
                                    : ((NumChiusiRE / NumEntratiRE) * 100).toFixed(0),
                    NumSinistriMancantiRE: NumChiusiRE - calcolaSinistriObiettivo(NumEntratiRE, 'RE'),
                    TempoMedioScaricoRE: NumChiusiRE === 0 ? 0 : parseFloat(PesoParzialeRE / NumChiusiRE).toFixed(2),
                    ScarTotSinRC: ((NumChiusiRC / NumEntratiRC) * 100) < 100 && ((NumChiusiRC / NumEntratiRC) * 100) > 0 
                                    && (((NumChiusiRC / NumEntratiRC) * 100) - Math.floor(((NumChiusiRC / NumEntratiRC) * 100))) !== 0
                                    ? ((NumChiusiRC / NumEntratiRC) * 100).toFixed(2)
                                    : ((NumChiusiRC / NumEntratiRC) * 100).toFixed(0), 
                    NumSinistriMancantiRC: NumChiusiRC - calcolaSinistriObiettivo(NumEntratiRC, 'RC'),
                    TempoMedioScaricoRC: NumChiusiRC === 0 ? 0 : parseFloat(PesoParzialeRC / NumChiusiRC).toFixed(2)
                });   
                GridData2 = _.sortBy(GridData2, obj => { return obj.NumSinistriMancantiAC + obj.NumSinistriMancantiRE + obj.NumSinistriMancantiRC; }); 
                localStorage.setItem("MonitorDSObiettiviAreeSecondoLiv", JSON.stringify(GridData2));               
                periti.unshift({ Value: '', Text: '' }); 
                periti = _.uniq(periti, elm => elm.Value);  
                document.getElementById('GridObiettiviAree').ej2_instances[0].dataSource = GridData1;
                document.getElementById('GridObiettiviAree').ej2_instances[0].childGrid.dataSource = GridData2; 
                document.getElementById('GridObiettiviAree').ej2_instances[0].refresh();  
                document.getElementById('ddlPeriti2').ej2_instances[0].dataSource = periti;
                this.toggleBlocking2();
        }, true, false);       
    }   
}
