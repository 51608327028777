/* eslint-disable no-useless-constructor */
import * as React from "react";
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Tooltip, ColumnSeries, DataLabel } from '@syncfusion/ej2-react-charts';
import { Browser } from '@syncfusion/ej2-base';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import { fabricColors, bootstrapColors, materialColors, highContrastColors } from './theme-color';
import { SampleBase } from './sample-base';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import $ from 'jquery';

var dataCorrente = new Date();
var dd = String(dataCorrente.getDate()).padStart(2, '0');
var mm = String(dataCorrente.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = dataCorrente.getFullYear();
dataCorrente = yyyy + mm + dd;

let data1 = [];
let data2 = [];
let mediaGiorniChiusura = 0.00, mediaGiorniChiusuraS = 0.00, mediaGiorniChiusuraI = 0.00, mediaGiorniChiusuraC = 0.00;
let totaleAperti = 0, totaleApertiS = 0, totaleApertiI = 0, totaleApertiC = 0, totaleApertiAnnoCorr = 0, totaleApertiAnnoCorrS = 0, totaleApertiAnnoCorrI = 0,
    totaleApertiAnnoCorrC = 0, totaleChiusiEntratiAnnoCorr = 0;

export default class ChartSinistriEntratiChiusi extends SampleBase {
    constructor() {
        super(...arguments);
        if ((window.matchMedia("(max-width: 850px)")).matches) {
            this.altezza = "250px";
            this.larghezza = "650px";
        }
        else if ((window.matchMedia("(max-width: 1024px)")).matches) {
            this.altezza = "500px";
            this.larghezza = CustomJS.chartWidth2;
        }
        else{
            this.altezza = CustomJS.chartHeight;
            this.larghezza = CustomJS.chartWidth2;
        }

    }
    state = {
        dataSerie1: data1,
        dataSerie2: data2,
        totaleAperti: totaleAperti,
        totaleApertiS: totaleApertiS,
        totaleApertiI: totaleApertiI,
        totaleApertiC: totaleApertiC,
        mediaGiorniChiusura: mediaGiorniChiusura,
        mediaGiorniChiusuraS: mediaGiorniChiusuraS,
        mediaGiorniChiusuraI: mediaGiorniChiusuraI,
        mediaGiorniChiusuraC: mediaGiorniChiusuraC,
        totaleApertiAnnoCorr: totaleApertiAnnoCorr,
        totaleApertiAnnoCorrS: totaleApertiAnnoCorrS,
        totaleApertiAnnoCorrI: totaleApertiAnnoCorrI,
        totaleApertiAnnoCorrC: totaleApertiAnnoCorrC,
        totaleChiusiEntratiAnnoCorr: totaleChiusiEntratiAnnoCorr
    }

    render() {
        return (<React.Fragment>
            <div className="row" id="rowScritteEntrChius">
                <div className="col-xs-12 col-sm-12 col-lg-5 col-md-5" id="apertiDiv" style={{ marginTop: '5px' }}>
                    <span style={{ fontSize: '1.5vw', fontWeight: 'bolder', color: '#133B95' }}>APERTI: {this.state.totaleAperti.toLocaleString()} (S:&nbsp;{this.state.totaleApertiS.toLocaleString()}&nbsp;&nbsp;&nbsp;I:&nbsp;{this.state.totaleApertiI.toLocaleString()}&nbsp;&nbsp;&nbsp;C:&nbsp;{this.state.totaleApertiC.toLocaleString()})<br /><span style={{ fontSize: '1.2vw', fontWeight: 'bolder', color: '#133B95' }}>di cui nell'anno corrente: {this.state.totaleApertiAnnoCorr.toLocaleString()} (S:&nbsp;{this.state.totaleApertiAnnoCorrS?.toLocaleString()}&nbsp;&nbsp;&nbsp;I:&nbsp;{this.state.totaleApertiAnnoCorrI?.toLocaleString()}&nbsp;&nbsp;&nbsp;C:&nbsp;{this.state.totaleApertiAnnoCorrC?.toLocaleString()})</span></span>
                </div>
                <div id="mediaGiorniChiusuraDiv" className="col-xs-12 col-sm-12 col-lg-7 col-md-7 text-end" style={{ marginTop: '5px' }}>
                    <span style={{ fontSize: '1.5vw', fontWeight: 'bolder', color: '#133B95' }}>MEDIA GIORNI CHIUSURA STUDIO: {this.state.mediaGiorniChiusura.toLocaleString()}&nbsp;(S:&nbsp;{this.state.mediaGiorniChiusuraS.toLocaleString()}&nbsp;&nbsp;&nbsp;I:&nbsp;{this.state.mediaGiorniChiusuraI.toLocaleString()}&nbsp;&nbsp;&nbsp;C:&nbsp;{this.state.mediaGiorniChiusuraC.toLocaleString()})</span>
                </div>
            </div>
            <div className="row">
                <ChartComponent id='ChartSinistriEntratiChiusi' loaded={this.operazioniDopoCarica.bind(this)}  className='col-xs-12 col-sm-12 col-lg-12 col-md-12' 
                    style={{ textAlign: "center",  height: "5%" }} primaryXAxis={{ valueType: 'Category', interval: 1, majorGridLines: { width: 0 } }}
                    primaryYAxis={{ majorGridLines: { width: 0 }, majorTickLines: { width: 0 }, lineStyle: { width: 0 }, labelStyle: { color: 'transparent' } }}
                    chartArea={{ border: { width: 0 } }} tooltip={{ enable: true }} ref={chartObj => this.chartInstance = chartObj}
                    width={this.larghezza}
                    height={this.altezza} 
                    title=''
                    legendSettings={{ visible: true }}>
                    <Inject services={[ColumnSeries, Legend, Tooltip, Category, DataLabel]} />
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={this.state.dataSerie1} xName='x' yName='y' name='Sinistri Entrati' type='Column' fill={materialColors[5 % 10]} cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                        </SeriesDirective>
                        <SeriesDirective dataSource={this.state.dataSerie2} xName='x' yName='y' name='Sinistri Chiusi' type='Column' fill={materialColors[4 % 10]} cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                        </SeriesDirective>
                    </SeriesCollectionDirective>
                </ChartComponent>
            </div>
        </React.Fragment>);
    }
    rendereComplete() {
        this.caricaDati();
    }
    caricaDati() {
        CustomJS.callAPI(`${CustomJS.APIMonitorURLProd}/api/Monitor/portingTutteAPI/tutti/${yyyy.toString()}0101/${dataCorrente}/mese/[NO]`, '', 'GET', '', retData => {
            let retData2 = [];
            retData.forEach(elm => {
                if (elm.tipo === 1) {
                    retData2.push({ 
                        periodo: elm.periodo,
                        entrati: elm.EntratiChiusi,
                    });    
                }
                else {
                    _.find(retData2, elm2 => { return elm2.periodo === elm.periodo }).chiusi = elm.EntratiChiusi;
                }
            });
            for (let ind = 1; ind <= 12; ind++) {
                let MONTH = _.filter(retData2, (obj) => { return obj.periodo === yyyy.toString() + ind.toString().padStart(2, '0') });
                data1.push({
                    x: ind === 1 ? 'GEN' : ind === 2 ? 'FEB' : ind === 3 ? 'MAR' : ind === 4 ? 'APR' : ind === 5 ? 'MAG' : ind === 6 ? 'GIU'
                        : ind === 7 ? 'LUG' : ind === 8 ? 'AGO' : ind === 9 ? 'SET' : ind === 10 ? 'OTT' : ind === 11 ? 'NOV' : 'DIC', y: MONTH[0]?.entrati
                });
                data2.push({
                    x: ind === 1 ? 'GEN' : ind === 2 ? 'FEB' : ind === 3 ? 'MAR' : ind === 4 ? 'APR' : ind === 5 ? 'MAG' : ind === 6 ? 'GIU'
                        : ind === 7 ? 'LUG' : ind === 8 ? 'AGO' : ind === 9 ? 'SET' : ind === 10 ? 'OTT' : ind === 11 ? 'NOV' : 'DIC', y: MONTH[0]?.chiusi
                });
            }
            CustomJS.callAPI(`${CustomJS.APIMonitorURLProd}/api/Monitor/portingTutteAPI/chiusi/${yyyy.toString()}0101/${dataCorrente}/mese/true`, '', 'GET', '', retData => {
                for (let ind = 1; ind <= 12; ind++) {
                    let MONTH = _.filter(retData, (obj) => { return obj.periodo === yyyy.toString() + ind.toString().padStart(2, '0') });
                    data2.push({
                        x: ind === 1 ? 'GEN' : ind === 2 ? 'FEB' : ind === 3 ? 'MAR' : ind === 4 ? 'APR' : ind === 5 ? 'MAG' : ind === 6 ? 'GIU'
                            : ind === 7 ? 'LUG' : ind === 8 ? 'AGO' : ind === 9 ? 'SET' : ind === 10 ? 'OTT' : ind === 11 ? 'NOV' : 'DIC', y: MONTH[0]?.chiusi
                    });
                    totaleChiusiEntratiAnnoCorr += isNaN(MONTH[0]?.chiusi) ? 0 : MONTH[0]?.chiusi;
                }
            }, error => {
                let messaggio = (_.isString(error) && error.indexOf('Not Found')) > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    position: "top-right",
                    autoClose: 8000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, false);  
        }, error => {
            let messaggio = (_.isString(error) && error.indexOf('Not Found')) > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, false);  

        CustomJS.getRemoteData3(CustomJS.APIUrlProd,
            { "token": CustomJS.tokenProd, "azione": "mediaGiorniChiusura" },
            'POST', { "Content-Type": "application/x-www-form-urlencoded" }, (retData2) => {
                mediaGiorniChiusura = retData2[0].mediaGiorniChiusura;
                mediaGiorniChiusuraS = retData2[0].mediaGiorniChiusuraS;
                mediaGiorniChiusuraI = retData2[0].mediaGiorniChiusuraI;
                mediaGiorniChiusuraC = retData2[0].mediaGiorniChiusuraC;
                this.setState({
                    mediaGiorniChiusura: mediaGiorniChiusura,
                    mediaGiorniChiusuraS: mediaGiorniChiusuraS,
                    mediaGiorniChiusuraI: mediaGiorniChiusuraI,
                    mediaGiorniChiusuraC: mediaGiorniChiusuraC
                });
        }, true, false);
    
        CustomJS.getRemoteData3(CustomJS.APIUrlProd,
            { "token": CustomJS.tokenProd, "azione": "aperti", "frequenza": "sempre", "raggruppaGrado": "true" },
            'POST', { "Content-Type": "application/x-www-form-urlencoded" }, (retData2) => {
                totaleApertiS = _.find(retData2, elm => { return elm.Grado === 'S' })?.aperti;
                totaleApertiI = _.find(retData2, elm => { return elm.Grado === 'I' })?.aperti;
                totaleApertiC = _.find(retData2, elm => { return elm.Grado === 'C' })?.aperti;;
                totaleAperti = totaleApertiS + totaleApertiI + totaleApertiC;
                this.setState({
                    totaleApertiS: totaleApertiS,
                    totaleApertiI: totaleApertiI,
                    totaleApertiC: totaleApertiC,
                    totaleAperti: totaleAperti
                });
        }, true, false);
    
        CustomJS.getRemoteData3(CustomJS.APIUrlProd,
            { "token": CustomJS.tokenProd, "azione": "aperti", "dataDa": `${yyyy.toString()}0101`, "dataA": `${dataCorrente}`, "frequenza": "sempre", "raggruppaGrado": "true" },
            'POST', { "Content-Type": "application/x-www-form-urlencoded" }, (retData2) => {
                totaleApertiAnnoCorrS = CustomJS.isNullEmpty(_.find(retData2, elm => { return elm.Grado === 'S' })) ? 0 : _.find(retData2, elm => { return elm.Grado === 'S' })?.aperti;
                totaleApertiAnnoCorrI = CustomJS.isNullEmpty(_.find(retData2, elm => { return elm.Grado === 'I' })) ? 0 : _.find(retData2, elm => { return elm.Grado === 'I' })?.aperti;
                totaleApertiAnnoCorrC = CustomJS.isNullEmpty(_.find(retData2, elm => { return elm.Grado === 'C' })) ? 0 : _.find(retData2, elm => { return elm.Grado === 'C' })?.aperti;
                totaleApertiAnnoCorr = totaleApertiAnnoCorrS + totaleApertiAnnoCorrI + totaleApertiAnnoCorrC;
                this.setState({
                    totaleApertiAnnoCorrS: totaleApertiAnnoCorrS,
                    totaleApertiAnnoCorrI: totaleApertiAnnoCorrI,
                    totaleApertiAnnoCorrC: totaleApertiAnnoCorrC,
                    totaleApertiAnnoCorr: totaleApertiAnnoCorr
                });
        }, true, false);   
        
        setTimeout(() => {
            this.chartInstance.series[0].dataSource = data1;
            this.chartInstance.series[1].dataSource = data2;
        }, 600);
    }
    operazioniDopoCarica(args) {   
        setTimeout(() => {
            if (document.getElementById('legendaCustom2')) return;
            let fontSizeLegCust = '1.0vw';
            $(`<div id="legendaCustom2"><span id="testoLegendaCustom2" style="font-size:${fontSizeLegCust};font-weight:bold;color:#D2691E";>Sinistri chiusi, entrati nell&#39;anno corrente : ${totaleChiusiEntratiAnnoCorr?.toLocaleString()}</span></div>`).appendTo('#e-content-TabMonitor_1');
            let d = document.getElementById('legendaCustom2');
            d.style.position = "absolute";
            d.style.left = '0.6vw';
            let topPosLegCust = Browser.userAgent.indexOf('Firefox') > -1 ? '620' : Browser.userAgent.indexOf('OPR') > -1 ? '630' : Browser.userAgent.indexOf('Edg') > -1 ? '630' : '615';
            topPosLegCust = (parseInt(topPosLegCust) - ((1080 - CustomJS.heightScreenResolution) === 0 ? 0 : (1080 - CustomJS.heightScreenResolution) < 0 ? (1080 - CustomJS.heightScreenResolution) : (1080 - CustomJS.heightScreenResolution + 50))).toString();
            d.style.top = Browser.isDevice ? '660px' : topPosLegCust + 'px';        
        }, 700);
        setTimeout(() => {
            for (let ind = 0; ind <= parseInt(mm) - 1; ind++)
                $(`#ChartSinistriEntratiChiusi_Series_1_Point_${(ind + 12).toString()}`).attr('fill', '#D2691E');
        }, 1300);
    }
}