import React from 'react';
import { createRoot } from 'react-dom/client'
import App from './App';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './errorBoundary';
import 'bootstrap/dist/css/bootstrap.css';
import 'underscore/underscore-min';
import './Custom.css';
import './BlockUI.css';
import './assets/fontawesome/css/all.min.css';
import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF5cXmpCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH9ednVXQ2ddUEZzWEQ=');

const root = createRoot(document.getElementById('rootElement')); 
root.render(<ErrorBoundary><App /></ErrorBoundary>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();