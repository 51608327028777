/* eslint-disable default-case */
import * as React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, PdfExport, Page, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { SampleBase } from './sample-base';
import { Browser } from '@syncfusion/ej2-base';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';

let GridData = [], numSinDisponCorrez = 0, numSinCorretti = 0, numSinCorrettiAnnoCorr = 0, NumCorrezAperteSinistriGiaVisionatiDaCorr = 0;
const waitingTime = Browser.userAgent.indexOf('Edg') > -1 ? 2700000000 : 2000000000;

CustomJS.getRemoteData2(CustomJS.APIUrlProd,
    `token=${CustomJS.tokenProd}&azione=controlloReferenti`, 
    'POST', "application/x-www-form-urlencoded", (retData) => {   
        retData.forEach((elm, idx) => {  
            elm.TMaxAnno = `${elm.TMaxAnno.toString()} Giorni`;  
            elm.TMedioCaricoAnno = `${elm.TMedioCaricoAnnoGiorni.toString()}gg ${elm.TMedioCaricoAnnoOre.toString()}h ${elm.TMedioCaricoAnnoMinuti.toString()}'`;   
            elm.TMedioAnno = `${elm.TMedioAnnoGiorni.toString()}gg ${elm.TMedioAnnoOre.toString()}h ${elm.TMedioAnnoMinuti.toString()}'`;
            elm.TMedioTotaleAnnoNum = parseInt(`${elm.TMedioTotaleAnnoGiorni.toString()}${elm.TMedioTotaleAnnoOre.toString().padStart(2,'0')}${elm.TMedioTotaleAnnoMinuti.toString().padStart(2,'0')}`);
            elm.TMedioTotaleAnno = `${elm.TMedioTotaleAnnoGiorni.toString()}gg ${elm.TMedioTotaleAnnoOre.toString()}h ${elm.TMedioTotaleAnnoMinuti.toString()}'`;
            GridData.push(elm);            
        });   
        GridData = _.sortBy(GridData, 'TMedioTotaleAnnoNum').reverse();  
        let elmTotale = _.find(GridData, (elm) => { return elm.NominativoReferente === 'Totali'; });
        numSinDisponCorrez = elmTotale.NumCorrezioni + elmTotale.NumCorrezioniInCorso;
        GridData = _.filter(GridData, (elm) => { return elm.NominativoReferente !== 'Totali'; });   
        GridData.push(elmTotale);    
 }, true, false);

 for (let ind=0;ind<=waitingTime;ind++) {}

function headerTemplate(props) {    
    return (<div>
         <span style={{ fontSize: '0.95vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate(props) {       
    let colore = props.column.field === 'NominativoReferente' ? '#133B95' : (props.column.field === 'AssegnAnnoCorrSuAnnoPrec' && props.AssegnAnnoPrec > props.AssegnAnnoCorr) ? 'red' : 'purple';
    return (        
         <div><span style={{ fontSize: '1.0vw', color: colore, fontWeight: 'bold'}}>{props[props.column.field]}</span></div>
    );
}

export default class GridControlloReferenti extends SampleBase {
    constructor() {
        super(...arguments);  
        this.toolbarOptions = ['ExcelExport', 'PdfExport', 'Search'];
        this.data = GridData;
        this.numSinDisponCorrez = numSinDisponCorrez;
        this.numSinCorretti = numSinCorretti;
        this.numSinCorrettiAnnoCorr = numSinCorrettiAnnoCorr;
        this.NumCorrezAperteSinistriGiaVisionatiDaCorr = NumCorrezAperteSinistriGiaVisionatiDaCorr;
    }    
    onQueryCellInfo(args) { 
    }    
    onDatabound(args) {       
        for (let ind=0;ind<=8;ind++)
             document.getElementById('GridControlloReferenti').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
        document.getElementById('TestoDatiAggiornati').innerText =  'Dati aggiornati al ' + new Date().getDate().toString().padStart(2, '0') + '/' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '/' + new Date().getFullYear().toString() + ' alle ore '  + new Date().getHours().toString() + ':' + new Date().getMinutes().toString().padStart(2, '0');            
    }
    onRowDatabound(args) {             
        if (args.data.NominativoReferente.indexOf('Totali') > -1) {
            args.row.className = 'e-row';
            args.row.bgColor = 'lightpink';
        }         
    }        
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
        }
    }
    render() {
        return (<React.Fragment>  
                    <div className="e-card-resize-container">
                        <div className='row'>
                            <div className="card-layout" >
                                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                    <div className="e-card" id="basic_card2" >
                                        <div className="e-card-header">
                                            <div className="e-card-header-caption">
                                                <div className="e-card-header-title" style={{fontSize: '1.2vw', fontWeight: 'bolder', color:'#133B95'}}>Sinistri disponibili alla correzione: {numSinDisponCorrez.toLocaleString()}</div>
                                            </div>
                                        </div>                                                    
                                        <div className="e-card-content">                                         
                                            <div className='control-section row'>
                                                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>                   
                                                    <GridComponent id="GridControlloReferenti" dataSource={this.data} ref={grid => this.gridInstance = grid} toolbar={this.toolbarOptions} allowSelection={false} enableHover={false} allowTextWrap={true}
                                                            allowPaging={false} allowSorting={false} allowFiltering={false} allowExcelExport={true} allowPdfExport={true} queryCellInfo={this.onQueryCellInfo.bind(this)} 
                                                            dataBound={this.onDatabound.bind(this)} rowDataBound={this.onRowDatabound.bind(this)} toolbarClick={this.toolbarClick.bind(this)} 
                                                            enableAltRow={true} pageSettings={{ pageCount: 4, pageSize: 11, pageSizes: true }}>
                                                        <ColumnsDirective>                            
                                                            <ColumnDirective field='NominativoReferente' headerText='Referente' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Left' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                            <ColumnDirective field='NumSinistri' headerText='N° Sinistri' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                            <ColumnDirective field='NumCorrezioni' headerText="N° Richieste di correzione" headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                                                            <ColumnDirective field='NumCorrezioniInCorso' headerText='N° Sinistri in correzione' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective> 
                                                            <ColumnDirective field='TMaxAttuali' headerText='T Max Attuali' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective> 
                                                            <ColumnDirective field='TMaxAnno' headerText='T Max Anno' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective> 
                                                            <ColumnDirective field='TMedioCaricoAnno' headerText='T Medio in richiesta correzione' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective> 
                                                            <ColumnDirective field='TMedioAnno' headerText='T Medio Sinistri in correzione' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective> 
                                                            <ColumnDirective field='TMedioTotaleAnno' headerText='T Medio totale Anno' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective> 
                                                        </ColumnsDirective>                           
                                                        <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Page]}/>                    
                                                    </GridComponent>  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                                              
            </React.Fragment>);
    }
}