import * as React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Inject } from '@syncfusion/ej2-react-grids';
import { SampleBase } from './sample-base';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';

var dataCorrente = new Date();
var dd = String(dataCorrente.getDate()).padStart(2, '0');
let mm = String(dataCorrente.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = dataCorrente.getFullYear();
dataCorrente = yyyy + mm + dd;
let GridData = [];

function headerTemplate(props) {    
    return (<div>
         <span style={{ fontSize: '0.9vw', fontWeight: 'bolder'}}>{props.headerText}</span>
    </div>);
}

export default class GridTotaliSinistriEntratiChiusi extends SampleBase {    
    // eslint-disable-next-line no-useless-constructor
    constructor() {
        super(...arguments);
    } 
    state = {
        GridData: GridData
    }
    onQueryCellInfo(args) {
        args.cell.style.color = args.column.index === 0 ? 'black' : 'purple';
        args.cell.style.fontSize = '1.0vw';
        args.cell.style.fontWeight = 'bold'; 
        if (args.data.rowDescr.indexOf('%') > -1 && args.cell.innerText.indexOf('%') === -1)
            args.cell.innerText = parseFloat(args.cell.innerText).toLocaleString();
        if (args.column.index === parseInt(mm))             
            args.cell.style.backgroundColor = 'gold';     
        if (args.cell.innerText === 'NaN')
            args.cell.innerText = '';    
        if (args.cell.innerText.indexOf('-') > -1)
            args.cell.style.color = 'red';   
        else if (args.data.rowDescr === 'DIFF.' && args.cell.innerText !== 'DIFF.' && args.cell.innerText !== '')         
            args.cell.innerText = '+' + args.cell.innerText;          
    }    
    onDatabound(args) {         
        for (let ind=0;ind<=12;ind++)
             document.getElementById('GridTotaliSinistriEntratiChiusi').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';       
        document.getElementById('GridTotaliSinistriEntratiChiusi').querySelectorAll('.e-columnheader')[0].children[parseInt(mm)].style.backgroundColor = 'gold';       
        document.getElementById('TestoDatiAggiornati').innerText =  'Dati aggiornati al ' + new Date().getDate().toString().padStart(2, '0') + '/' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '/' + new Date().getFullYear().toString() + ' alle ore '  + new Date().getHours().toString() + ':' + new Date().getMinutes().toString().padStart(2, '0');             
    }

    render() {
        return (<React.Fragment>
                    <GridComponent id="GridTotaliSinistriEntratiChiusi" dataSource={this.state.GridData} ref={grid => this.gridInstance = grid} allowSelection={false} enableHover={false} 
                            allowPaging={false} queryCellInfo={this.onQueryCellInfo.bind(this)} dataBound={this.onDatabound.bind(this)} enableAltRow={false} allowTextWrap={true}>
                        <ColumnsDirective>
                            <ColumnDirective field='rowDescr' headerText='' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='GEN' headerText='GENNAIO' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='FEB' headerText='FEBBRAIO' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='MAR' headerText='MARZO' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='APR' headerText='APRILE' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='MAG' headerText='MAGGIO' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='GIU' headerText='GIUGNO' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='LUG' headerText='LUGLIO' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='AGO' headerText='AGOSTO' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='SET' headerText='SETTEMBRE' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='OTT' headerText='OTTOBRE' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='NOV' headerText='NOVEMBRE' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='DIC' headerText='DICEMBRE' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                        </ColumnsDirective>                        
                    </GridComponent>                    
            </React.Fragment>);
    }    
    rendereComplete() {
        this.caricaDati();
    }
    caricaDati() {
        CustomJS.getRemoteData2(CustomJS.APIUrlProd,
            `token=${CustomJS.tokenProd}&azione=tutti&dataDa=${yyyy.toString()}0101&dataA=${dataCorrente}&frequenza=mese`,
            'POST', "application/x-www-form-urlencoded", (retData) => {                  
                GridData.push({ rowDescr: 'Entrati', GEN: retData[0]?.entrati, FEB: retData[1]?.entrati, MAR: retData[2]?.entrati, APR: retData[3]?.entrati,
                                MAG: retData[4]?.entrati, GIU: retData[5]?.entrati, LUG: retData[6]?.entrati, AGO: retData[7]?.entrati, SET: retData[8]?.entrati, 
                                OTT: retData[9]?.entrati, NOV: retData[10]?.entrati, DIC: retData[11]?.entrati});          
                GridData.push({ rowDescr: 'Chiusi', GEN: retData[0]?.chiusi, FEB: retData[1]?.chiusi, MAR: retData[2]?.chiusi, APR: retData[3]?.chiusi,
                                MAG: retData[4]?.chiusi, GIU: retData[5]?.chiusi, LUG: retData[6]?.chiusi, AGO: retData[7]?.chiusi, SET: retData[8]?.chiusi, 
                                OTT: retData[9]?.chiusi, NOV: retData[10]?.chiusi, DIC: retData[11]?.chiusi});  
                GridData.push({ rowDescr: 'DIFF.', GEN: retData[0]?.chiusi - retData[0]?.entrati, FEB: retData[1]?.chiusi - retData[1]?.entrati, 
                                MAR: retData[2]?.chiusi - retData[2]?.entrati, APR: retData[3]?.chiusi - retData[3]?.entrati,
                                MAG: retData[4]?.chiusi - retData[4]?.entrati, GIU: retData[5]?.chiusi - retData[5]?.entrati, 
                                LUG: retData[6]?.chiusi - retData[6]?.entrati, AGO: retData[7]?.chiusi - retData[7]?.entrati, 
                                SET: retData[8]?.chiusi - retData[8]?.entrati, OTT: retData[9]?.chiusi - retData[9]?.entrati,
                                NOV: retData[10]?.chiusi - retData[10]?.entrati, DIC: retData[11]?.chiusi - retData[11]?.entrati});         
                GridData.push({ rowDescr: '%', 
                                GEN: parseFloat((retData[0]?.chiusi / retData[0]?.entrati) * 100).toFixed(2), 
                                FEB: parseFloat(((retData[0]?.chiusi + retData[1]?.chiusi) / (retData[0]?.entrati + retData[1]?.entrati)) * 100).toFixed(2), 
                                MAR: parseFloat(((retData[0]?.chiusi + retData[1]?.chiusi + retData[2]?.chiusi) / (retData[0]?.entrati + retData[1]?.entrati + retData[2]?.entrati)) * 100).toFixed(2), 
                                APR: parseFloat(((retData[0]?.chiusi + retData[1]?.chiusi + retData[2]?.chiusi + retData[3]?.chiusi)                                             
                                                / (retData[0]?.entrati + retData[1]?.entrati + retData[2]?.entrati + retData[3]?.entrati)) * 100).toFixed(2),
                                MAG: parseFloat(((retData[0]?.chiusi + retData[1]?.chiusi + retData[2]?.chiusi + retData[3]?.chiusi + retData[4]?.chiusi)
                                                / (retData[0]?.entrati + retData[1]?.entrati + retData[2]?.entrati + retData[3]?.entrati + retData[4]?.entrati)) * 100).toFixed(2),
                                GIU: parseFloat(((retData[0]?.chiusi + retData[1]?.chiusi + retData[2]?.chiusi + retData[3]?.chiusi + retData[4]?.chiusi + retData[5]?.chiusi)                                             
                                                / (retData[0]?.entrati + retData[1]?.entrati + retData[2]?.entrati + retData[3]?.entrati + retData[4]?.entrati + retData[5]?.entrati)) * 100).toFixed(2), 
                                LUG: parseFloat(((retData[0]?.chiusi + retData[1]?.chiusi + retData[2]?.chiusi + retData[3]?.chiusi + retData[4]?.chiusi + retData[5]?.chiusi + retData[6]?.chiusi) 
                                                / (retData[0]?.entrati + retData[1]?.entrati + retData[2]?.entrati + retData[3]?.entrati + retData[4]?.entrati + retData[5]?.entrati + retData[6]?.entrati)) * 100).toFixed(2), 
                                AGO: parseFloat(((retData[0]?.chiusi + retData[1]?.chiusi + retData[2]?.chiusi + retData[3]?.chiusi + retData[4]?.chiusi + retData[5]?.chiusi + retData[6]?.chiusi + retData[7]?.chiusi) 
                                                / (retData[0]?.entrati + retData[1]?.entrati + retData[2]?.entrati + retData[3]?.entrati + retData[4]?.entrati + retData[5]?.entrati + retData[6]?.entrati + retData[7]?.entrati)) * 100).toFixed(2), 
                                SET: parseFloat(((retData[0]?.chiusi + retData[1]?.chiusi + retData[2]?.chiusi + retData[3]?.chiusi + retData[4]?.chiusi + retData[5]?.chiusi + retData[6]?.chiusi + retData[7]?.chiusi + retData[8]?.chiusi) 
                                                / (retData[0]?.entrati + retData[1]?.entrati + retData[2]?.entrati + retData[3]?.entrati + retData[4]?.entrati + retData[5]?.entrati + retData[6]?.entrati + retData[7]?.entrati + retData[8]?.entrati)) * 100).toFixed(2), 
                                OTT: parseFloat(((retData[0]?.chiusi + retData[1]?.chiusi + retData[2]?.chiusi + retData[3]?.chiusi + retData[4]?.chiusi + retData[5]?.chiusi + retData[6]?.chiusi + retData[7]?.chiusi + retData[8]?.chiusi + retData[9]?.chiusi) 
                                                / (retData[0]?.entrati + retData[1]?.entrati + retData[2]?.entrati + retData[3]?.entrati + retData[4]?.entrati + retData[5]?.entrati + retData[6]?.entrati + retData[7]?.entrati + retData[8]?.entrati + retData[9]?.entrati)) * 100).toFixed(2),
                                NOV: parseFloat(((retData[0]?.chiusi + retData[1]?.chiusi + retData[2]?.chiusi + retData[3]?.chiusi + retData[4]?.chiusi + retData[5]?.chiusi + retData[6]?.chiusi + retData[7]?.chiusi + retData[8]?.chiusi + retData[9]?.chiusi + retData[10]?.chiusi) 
                                                / (retData[0]?.entrati + retData[1]?.entrati + retData[2]?.entrati + retData[3]?.entrati + retData[4]?.entrati + retData[5]?.entrati + retData[6]?.entrati + retData[7]?.entrati + retData[8]?.entrati + retData[9]?.entrati + retData[10]?.entrati)) * 100).toFixed(2), 
                                DIC: parseFloat(((retData[0]?.chiusi + retData[1]?.chiusi + retData[2]?.chiusi + retData[3]?.chiusi + retData[4]?.chiusi + retData[5]?.chiusi + retData[6]?.chiusi + retData[7]?.chiusi + retData[8]?.chiusi + retData[9]?.chiusi + retData[10]?.chiusi + retData[11]?.chiusi) 
                                                / (retData[0]?.entrati + retData[1]?.entrati + retData[2]?.entrati + retData[3]?.entrati + retData[4]?.entrati + retData[5]?.entrati + retData[6]?.entrati + retData[7]?.entrati + retData[8]?.entrati + retData[9]?.entrati + retData[10]?.entrati + retData[11]?.entrati)) * 100).toFixed(2)});
                CustomJS.getRemoteData2(CustomJS.APIUrlProd,
                    `token=${CustomJS.tokenProd}&azione=tutti&dataDa=${yyyy.toString()}0101&dataA=${dataCorrente}&frequenza=mese&chiusiEntratiAnnoCorrente=true`,
                    'POST', "application/x-www-form-urlencoded", (retData) => {                  
                        GridData.push({ rowDescr: '% anno corrente', 
                                        GEN: parseFloat((retData[0]?.chiusi / retData[0]?.entrati) * 100).toFixed(2), 
                                        FEB: parseFloat(((retData[0]?.chiusi + retData[1]?.chiusi) / (retData[0]?.entrati + retData[1]?.entrati)) * 100).toFixed(2), 
                                        MAR: parseFloat(((retData[0]?.chiusi + retData[1]?.chiusi + retData[2]?.chiusi) / (retData[0]?.entrati + retData[1]?.entrati + retData[2]?.entrati)) * 100).toFixed(2), 
                                        APR: parseFloat(((retData[0]?.chiusi + retData[1]?.chiusi + retData[2]?.chiusi + retData[3]?.chiusi)                                             
                                                        / (retData[0]?.entrati + retData[1]?.entrati + retData[2]?.entrati + retData[3]?.entrati)) * 100).toFixed(2),
                                        MAG: parseFloat(((retData[0]?.chiusi + retData[1]?.chiusi + retData[2]?.chiusi + retData[3]?.chiusi + retData[4]?.chiusi)
                                                        / (retData[0]?.entrati + retData[1]?.entrati + retData[2]?.entrati + retData[3]?.entrati + retData[4]?.entrati)) * 100).toFixed(2),
                                        GIU: parseFloat(((retData[0]?.chiusi + retData[1]?.chiusi + retData[2]?.chiusi + retData[3]?.chiusi + retData[4]?.chiusi + retData[5]?.chiusi)                                             
                                                        / (retData[0]?.entrati + retData[1]?.entrati + retData[2]?.entrati + retData[3]?.entrati + retData[4]?.entrati + retData[5]?.entrati)) * 100).toFixed(2), 
                                        LUG: parseFloat(((retData[0]?.chiusi + retData[1]?.chiusi + retData[2]?.chiusi + retData[3]?.chiusi + retData[4]?.chiusi + retData[5]?.chiusi + retData[6]?.chiusi) 
                                                        / (retData[0]?.entrati + retData[1]?.entrati + retData[2]?.entrati + retData[3]?.entrati + retData[4]?.entrati + retData[5]?.entrati + retData[6]?.entrati)) * 100).toFixed(2), 
                                        AGO: parseFloat(((retData[0]?.chiusi + retData[1]?.chiusi + retData[2]?.chiusi + retData[3]?.chiusi + retData[4]?.chiusi + retData[5]?.chiusi + retData[6]?.chiusi + retData[7]?.chiusi) 
                                                        / (retData[0]?.entrati + retData[1]?.entrati + retData[2]?.entrati + retData[3]?.entrati + retData[4]?.entrati + retData[5]?.entrati + retData[6]?.entrati + retData[7]?.entrati)) * 100).toFixed(2), 
                                        SET: parseFloat(((retData[0]?.chiusi + retData[1]?.chiusi + retData[2]?.chiusi + retData[3]?.chiusi + retData[4]?.chiusi + retData[5]?.chiusi + retData[6]?.chiusi + retData[7]?.chiusi + retData[8]?.chiusi) 
                                                        / (retData[0]?.entrati + retData[1]?.entrati + retData[2]?.entrati + retData[3]?.entrati + retData[4]?.entrati + retData[5]?.entrati + retData[6]?.entrati + retData[7]?.entrati + retData[8]?.entrati)) * 100).toFixed(2), 
                                        OTT: parseFloat(((retData[0]?.chiusi + retData[1]?.chiusi + retData[2]?.chiusi + retData[3]?.chiusi + retData[4]?.chiusi + retData[5]?.chiusi + retData[6]?.chiusi + retData[7]?.chiusi + retData[8]?.chiusi + retData[9]?.chiusi) 
                                                        / (retData[0]?.entrati + retData[1]?.entrati + retData[2]?.entrati + retData[3]?.entrati + retData[4]?.entrati + retData[5]?.entrati + retData[6]?.entrati + retData[7]?.entrati + retData[8]?.entrati + retData[9]?.entrati)) * 100).toFixed(2),
                                        NOV: parseFloat(((retData[0]?.chiusi + retData[1]?.chiusi + retData[2]?.chiusi + retData[3]?.chiusi + retData[4]?.chiusi + retData[5]?.chiusi + retData[6]?.chiusi + retData[7]?.chiusi + retData[8]?.chiusi + retData[9]?.chiusi + retData[10]?.chiusi) 
                                                        / (retData[0]?.entrati + retData[1]?.entrati + retData[2]?.entrati + retData[3]?.entrati + retData[4]?.entrati + retData[5]?.entrati + retData[6]?.entrati + retData[7]?.entrati + retData[8]?.entrati + retData[9]?.entrati + retData[10]?.entrati)) * 100).toFixed(2), 
                                        DIC: parseFloat(((retData[0]?.chiusi + retData[1]?.chiusi + retData[2]?.chiusi + retData[3]?.chiusi + retData[4]?.chiusi + retData[5]?.chiusi + retData[6]?.chiusi + retData[7]?.chiusi + retData[8]?.chiusi + retData[9]?.chiusi + retData[10]?.chiusi + retData[11]?.chiusi) 
                                                        / (retData[0]?.entrati + retData[1]?.entrati + retData[2]?.entrati + retData[3]?.entrati + retData[4]?.entrati + retData[5]?.entrati + retData[6]?.entrati + retData[7]?.entrati + retData[8]?.entrati + retData[9]?.entrati + retData[10]?.entrati + retData[11]?.entrati)) * 100).toFixed(2)});
                        document.getElementById('GridTotaliSinistriEntratiChiusi').ej2_instances[0].dataSource = GridData;
                        document.getElementById('GridTotaliSinistriEntratiChiusi').ej2_instances[0].refresh();                                       
                }, true, false);
        }, true, false);      
    }
} 