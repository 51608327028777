import * as React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Inject } from '@syncfusion/ej2-react-grids';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import { SampleBase } from './sample-base';
import { Browser } from '@syncfusion/ej2-base';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';

var dataCorrente = new Date();
var dd = String(dataCorrente.getDate()).padStart(2, '0');
let mm = String(dataCorrente.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = dataCorrente.getFullYear();
dataCorrente = yyyy + mm + dd;
let annoPrec = (new Date().getFullYear() - 1).toString();
let GridData = [];
const waitingTime = Browser.userAgent.indexOf('Firefox') > -1 ? 1500000000 : Browser.userAgent.indexOf('Edg') > -1 ? 1000000000 : 1000000000;

function headerTemplate(props) {    
    return (<div>
         <span style={{ fontSize: '0.9vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

export default class GridTotaliSinistriChiusi extends SampleBase {
    constructor() {
        super(...arguments);                
        this.data = GridData;         
    }    
    onQueryCellInfo(args) {            
        args.cell.style.color = args.column.index === 0 ? 'black' : 'purple';   
        args.cell.style.fontSize = '1.0vw';
        args.cell.style.fontWeight = 'bold';   
        if (args.data.rowDescr === '%' && args.cell.innerText !== '%')
            args.cell.innerText = parseFloat(args.cell.innerText).toLocaleString();     
        if (args.column.index === parseInt(mm))             
            args.cell.style.backgroundColor = 'gold';     
        if (args.cell.innerText === 'NaN')
            args.cell.innerText = '';    
        if (args.cell.innerText.indexOf('-') > -1)           
            args.cell.style.color = 'red';    
        else if ((args.data.rowDescr === 'DIFF.' || args.data.rowDescr === '%') && args.cell.innerText !== 'DIFF.' && args.cell.innerText !== '%' && args.cell.innerText !== '')         
            args.cell.innerText = '+' + args.cell.innerText; 
        if (args.cell.innerText === '+DIFF.')
            args.cell.innerText = 'DIFF.';
        if (args.cell.innerText === '+')   
            args.cell.innerText = '';        
    }    
    onDatabound(args) {       
        for (let ind=0;ind<=12;ind++)
             document.getElementById('GridTotaliSinistriChiusi').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
        document.getElementById('GridTotaliSinistriChiusi').querySelectorAll('.e-columnheader')[0].children[parseInt(mm)].style.backgroundColor = 'gold'; 
        document.getElementById('TestoDatiAggiornati').innerText =  'Dati aggiornati al ' + new Date().getDate().toString().padStart(2, '0') + '/' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '/' + new Date().getFullYear().toString() + ' alle ore '  + new Date().getHours().toString() + ':' + new Date().getMinutes().toString().padStart(2, '0');             
    }

    render() {
        return (<React.Fragment>
                    <GridComponent id="GridTotaliSinistriChiusi" dataSource={this.data} ref={grid => this.gridInstance = grid} allowSelection={false} enableHover={false} 
                            allowPaging={false} queryCellInfo={this.onQueryCellInfo.bind(this)} dataBound={this.onDatabound.bind(this)} enableAltRow={false}>
                        <ColumnsDirective>
                            <ColumnDirective field='rowDescr' headerText='' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='GEN' headerText='GENNAIO' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='FEB' headerText='FEBBRAIO' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='MAR' headerText='MARZO' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='APR' headerText='APRILE' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='MAG' headerText='MAGGIO' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='GIU' headerText='GIUGNO' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='LUG' headerText='LUGLIO' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='AGO' headerText='AGOSTO' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='SET' headerText='SETTEMBRE' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='OTT' headerText='OTTOBRE' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='NOV' headerText='NOVEMBRE' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='DIC' headerText='DICEMBRE' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                        </ColumnsDirective>                        
                    </GridComponent>  
                </React.Fragment>);
    }   
    rendereComplete() {
        this.caricaDati();
    } 
    caricaDati() {
        CustomJS.callAPI(`${CustomJS.APIMonitorURLProd}/api/Monitor/portingTutteAPI/chiusi/${annoPrec}0101/${dataCorrente}/mese/[NO]`, '', 'GET', '', retData => {
            let arrAnnoprec = _.filter(retData, (obj) => { return obj.periodo.indexOf(annoPrec) > -1; });
            GridData.push({ rowDescr: annoPrec, GEN: arrAnnoprec[0]?.chiusi, FEB: arrAnnoprec[1]?.chiusi, MAR: arrAnnoprec[2]?.chiusi, APR: arrAnnoprec[3]?.chiusi,
                            MAG: arrAnnoprec[4]?.chiusi, GIU: arrAnnoprec[5]?.chiusi, LUG: arrAnnoprec[6]?.chiusi, AGO: arrAnnoprec[7]?.chiusi, SET: arrAnnoprec[8]?.chiusi, 
                            OTT: arrAnnoprec[9]?.chiusi, NOV: arrAnnoprec[10]?.chiusi, DIC: arrAnnoprec[11]?.chiusi});  
            let arrAnnoCorr = _.filter(retData, (obj) => { return obj.periodo.indexOf(yyyy) > -1; });
            GridData.push({ rowDescr: yyyy, GEN: arrAnnoCorr[0]?.chiusi, FEB: arrAnnoCorr[1]?.chiusi, MAR: arrAnnoCorr[2]?.chiusi, APR: arrAnnoCorr[3]?.chiusi,
                            MAG: arrAnnoCorr[4]?.chiusi, GIU: arrAnnoCorr[5]?.chiusi, LUG: arrAnnoCorr[6]?.chiusi, AGO: arrAnnoCorr[7]?.chiusi, SET: arrAnnoCorr[8]?.chiusi, 
                            OTT: arrAnnoCorr[9]?.chiusi, NOV: arrAnnoCorr[10]?.chiusi, DIC: arrAnnoCorr[11]?.chiusi});  
            GridData.push({ rowDescr: 'DIFF.', GEN: arrAnnoCorr[0]?.chiusi - arrAnnoprec[0]?.chiusi, FEB: arrAnnoCorr[1]?.chiusi - arrAnnoprec[1]?.chiusi, 
                            MAR: arrAnnoCorr[2]?.chiusi - arrAnnoprec[2]?.chiusi, APR: arrAnnoCorr[3]?.chiusi - arrAnnoprec[3]?.chiusi,
                            MAG: arrAnnoCorr[4]?.chiusi - arrAnnoprec[4]?.chiusi, GIU: arrAnnoCorr[5]?.chiusi - arrAnnoprec[5]?.chiusi, 
                            LUG: arrAnnoCorr[6]?.chiusi - arrAnnoprec[6]?.chiusi, AGO: arrAnnoCorr[7]?.chiusi - arrAnnoprec[7]?.chiusi, 
                            SET: arrAnnoCorr[8]?.chiusi - arrAnnoprec[8]?.chiusi, OTT: arrAnnoCorr[9]?.chiusi - arrAnnoprec[9]?.chiusi,
                            NOV: arrAnnoCorr[10]?.chiusi - arrAnnoprec[10]?.chiusi, DIC: arrAnnoCorr[11]?.chiusi - arrAnnoprec[11]?.chiusi});         
            GridData.push({ rowDescr: '%', 
                            GEN: parseFloat(((arrAnnoCorr[0]?.chiusi - arrAnnoprec[0]?.chiusi) / arrAnnoprec[0]?.chiusi) * 100).toFixed(2), 
                            FEB: parseFloat((((arrAnnoCorr[0]?.chiusi + arrAnnoCorr[1]?.chiusi) - (arrAnnoprec[0]?.chiusi + arrAnnoprec[1]?.chiusi)) 
                                            / (arrAnnoprec[0]?.chiusi + arrAnnoprec[1]?.chiusi)) * 100).toFixed(2), 
                            MAR: parseFloat((((arrAnnoCorr[0]?.chiusi + arrAnnoCorr[1]?.chiusi + arrAnnoCorr[2]?.chiusi) 
                                            - (arrAnnoprec[0]?.chiusi + arrAnnoprec[1]?.chiusi + arrAnnoprec[2]?.chiusi)) 
                                            / (arrAnnoprec[0]?.chiusi + arrAnnoprec[1]?.chiusi + arrAnnoprec[2]?.chiusi)) * 100).toFixed(2), 
                            APR: parseFloat((((arrAnnoCorr[0]?.chiusi + arrAnnoCorr[1]?.chiusi + arrAnnoCorr[2]?.chiusi + arrAnnoCorr[3]?.chiusi) 
                                            - (arrAnnoprec[0]?.chiusi + arrAnnoprec[1]?.chiusi + arrAnnoprec[2]?.chiusi + arrAnnoprec[3]?.chiusi)) 
                                            / (arrAnnoprec[0]?.chiusi + arrAnnoprec[1]?.chiusi + arrAnnoprec[2]?.chiusi + arrAnnoprec[3]?.chiusi)) * 100).toFixed(2),
                            MAG: parseFloat((((arrAnnoCorr[0]?.chiusi + arrAnnoCorr[1]?.chiusi + arrAnnoCorr[2]?.chiusi + arrAnnoCorr[3]?.chiusi + arrAnnoCorr[4]?.chiusi) 
                                            - (arrAnnoprec[0]?.chiusi + arrAnnoprec[1]?.chiusi + arrAnnoprec[2]?.chiusi + arrAnnoprec[3]?.chiusi + arrAnnoprec[4]?.chiusi)) 
                                            / (arrAnnoprec[0]?.chiusi + arrAnnoprec[1]?.chiusi + arrAnnoprec[2]?.chiusi + arrAnnoprec[3]?.chiusi + arrAnnoprec[4]?.chiusi)) * 100).toFixed(2),
                            GIU: parseFloat((((arrAnnoCorr[0]?.chiusi + arrAnnoCorr[1]?.chiusi + arrAnnoCorr[2]?.chiusi + arrAnnoCorr[3]?.chiusi + arrAnnoCorr[4]?.chiusi + arrAnnoCorr[5]?.chiusi) 
                                            - (arrAnnoprec[0]?.chiusi + arrAnnoprec[1]?.chiusi + arrAnnoprec[2]?.chiusi + arrAnnoprec[3]?.chiusi + arrAnnoprec[4]?.chiusi + arrAnnoprec[5]?.chiusi)) 
                                            / (arrAnnoprec[0]?.chiusi + arrAnnoprec[1]?.chiusi + arrAnnoprec[2]?.chiusi + arrAnnoprec[3]?.chiusi + arrAnnoprec[4]?.chiusi + arrAnnoprec[5]?.chiusi)) * 100).toFixed(2), 
                            LUG: parseFloat((((arrAnnoCorr[0]?.chiusi + arrAnnoCorr[1]?.chiusi + arrAnnoCorr[2]?.chiusi + arrAnnoCorr[3]?.chiusi + arrAnnoCorr[4]?.chiusi + arrAnnoCorr[5]?.chiusi + arrAnnoCorr[6]?.chiusi) 
                                            - (arrAnnoprec[0]?.chiusi + arrAnnoprec[1]?.chiusi + arrAnnoprec[2]?.chiusi + arrAnnoprec[3]?.chiusi + arrAnnoprec[4]?.chiusi + arrAnnoprec[5]?.chiusi + arrAnnoprec[6]?.chiusi)) 
                                            / (arrAnnoprec[0]?.chiusi + arrAnnoprec[1]?.chiusi + arrAnnoprec[2]?.chiusi + arrAnnoprec[3]?.chiusi + arrAnnoprec[4]?.chiusi + arrAnnoprec[5]?.chiusi + arrAnnoprec[6]?.chiusi)) * 100).toFixed(2), 
                            AGO: parseFloat((((arrAnnoCorr[0]?.chiusi + arrAnnoCorr[1]?.chiusi + arrAnnoCorr[2]?.chiusi + arrAnnoCorr[3]?.chiusi + arrAnnoCorr[4]?.chiusi + arrAnnoCorr[5]?.chiusi + arrAnnoCorr[6]?.chiusi + arrAnnoCorr[7]?.chiusi) 
                                            - (arrAnnoprec[0]?.chiusi + arrAnnoprec[1]?.chiusi + arrAnnoprec[2]?.chiusi + arrAnnoprec[3]?.chiusi + arrAnnoprec[4]?.chiusi + arrAnnoprec[5]?.chiusi + arrAnnoprec[6]?.chiusi + arrAnnoprec[7]?.chiusi)) 
                                            / (arrAnnoprec[0]?.chiusi + arrAnnoprec[1]?.chiusi + arrAnnoprec[2]?.chiusi + arrAnnoprec[3]?.chiusi + arrAnnoprec[4]?.chiusi + arrAnnoprec[5]?.chiusi + arrAnnoprec[6]?.chiusi + arrAnnoprec[7]?.chiusi)) * 100).toFixed(2), 
                            SET: parseFloat((((arrAnnoCorr[0]?.chiusi + arrAnnoCorr[1]?.chiusi + arrAnnoCorr[2]?.chiusi + arrAnnoCorr[3]?.chiusi + arrAnnoCorr[4]?.chiusi + arrAnnoCorr[5]?.chiusi + arrAnnoCorr[6]?.chiusi + arrAnnoCorr[7]?.chiusi + arrAnnoCorr[8]?.chiusi) 
                                            - (arrAnnoprec[0]?.chiusi + arrAnnoprec[1]?.chiusi + arrAnnoprec[2]?.chiusi + arrAnnoprec[3]?.chiusi + arrAnnoprec[4]?.chiusi + arrAnnoprec[5]?.chiusi + arrAnnoprec[6]?.chiusi + arrAnnoprec[7]?.chiusi + arrAnnoprec[8]?.chiusi)) 
                                            / (arrAnnoprec[0]?.chiusi + arrAnnoprec[1]?.chiusi + arrAnnoprec[2]?.chiusi + arrAnnoprec[3]?.chiusi + arrAnnoprec[4]?.chiusi + arrAnnoprec[5]?.chiusi + arrAnnoprec[6]?.chiusi + arrAnnoprec[7]?.chiusi + arrAnnoprec[8]?.chiusi)) * 100).toFixed(2), 
                            OTT: parseFloat((((arrAnnoCorr[0]?.chiusi + arrAnnoCorr[1]?.chiusi + arrAnnoCorr[2]?.chiusi + arrAnnoCorr[3]?.chiusi + arrAnnoCorr[4]?.chiusi + arrAnnoCorr[5]?.chiusi + arrAnnoCorr[6]?.chiusi + arrAnnoCorr[7]?.chiusi + arrAnnoCorr[8]?.chiusi + arrAnnoCorr[9]?.chiusi) 
                                            - (arrAnnoprec[0]?.chiusi + arrAnnoprec[1]?.chiusi + arrAnnoprec[2]?.chiusi + arrAnnoprec[3]?.chiusi + arrAnnoprec[4]?.chiusi + arrAnnoprec[5]?.chiusi + arrAnnoprec[6]?.chiusi + arrAnnoprec[7]?.chiusi + arrAnnoprec[8]?.chiusi + arrAnnoprec[9]?.chiusi)) 
                                            / (arrAnnoprec[0]?.chiusi + arrAnnoprec[1]?.chiusi + arrAnnoprec[2]?.chiusi + arrAnnoprec[3]?.chiusi + arrAnnoprec[4]?.chiusi + arrAnnoprec[5]?.chiusi + arrAnnoprec[6]?.chiusi + arrAnnoprec[7]?.chiusi + arrAnnoprec[8]?.chiusi + arrAnnoprec[9]?.chiusi)) * 100).toFixed(2),
                            NOV: parseFloat((((arrAnnoCorr[0]?.chiusi + arrAnnoCorr[1]?.chiusi + arrAnnoCorr[2]?.chiusi + arrAnnoCorr[3]?.chiusi + arrAnnoCorr[4]?.chiusi + arrAnnoCorr[5]?.chiusi + arrAnnoCorr[6]?.chiusi + arrAnnoCorr[7]?.chiusi + arrAnnoCorr[8]?.chiusi + arrAnnoCorr[9]?.chiusi + arrAnnoCorr[10]?.chiusi) 
                                            - (arrAnnoprec[0]?.chiusi + arrAnnoprec[1]?.chiusi + arrAnnoprec[2]?.chiusi + arrAnnoprec[3]?.chiusi + arrAnnoprec[4]?.chiusi + arrAnnoprec[5]?.chiusi + arrAnnoprec[6]?.chiusi + arrAnnoprec[7]?.chiusi + arrAnnoprec[8]?.chiusi + arrAnnoprec[9]?.chiusi + arrAnnoprec[10]?.chiusi)) 
                                            / (arrAnnoprec[0]?.chiusi + arrAnnoprec[1]?.chiusi + arrAnnoprec[2]?.chiusi + arrAnnoprec[3]?.chiusi + arrAnnoprec[4]?.chiusi + arrAnnoprec[5]?.chiusi + arrAnnoprec[6]?.chiusi + arrAnnoprec[7]?.chiusi + arrAnnoprec[8]?.chiusi + arrAnnoprec[9]?.chiusi + arrAnnoprec[10]?.chiusi)) * 100).toFixed(2), 
                            DIC: parseFloat((((arrAnnoCorr[0]?.chiusi + arrAnnoCorr[1]?.chiusi + arrAnnoCorr[2]?.chiusi + arrAnnoCorr[3]?.chiusi + arrAnnoCorr[4]?.chiusi + arrAnnoCorr[5]?.chiusi + arrAnnoCorr[6]?.chiusi + arrAnnoCorr[7]?.chiusi + arrAnnoCorr[8]?.chiusi + arrAnnoCorr[9]?.chiusi + arrAnnoCorr[10]?.chiusi + arrAnnoCorr[11]?.chiusi) 
                                            - (arrAnnoprec[0]?.chiusi + arrAnnoprec[1]?.chiusi + arrAnnoprec[2]?.chiusi + arrAnnoprec[3]?.chiusi + arrAnnoprec[4]?.chiusi + arrAnnoprec[5]?.chiusi + arrAnnoprec[6]?.chiusi + arrAnnoprec[7]?.chiusi + arrAnnoprec[8]?.chiusi + arrAnnoprec[9]?.chiusi + arrAnnoprec[10]?.chiusi + arrAnnoprec[11]?.chiusi)) 
                                            / (arrAnnoprec[0]?.chiusi + arrAnnoprec[1]?.chiusi + arrAnnoprec[2]?.chiusi + arrAnnoprec[3]?.chiusi + arrAnnoprec[4]?.chiusi + arrAnnoprec[5]?.chiusi + arrAnnoprec[6]?.chiusi + arrAnnoprec[7]?.chiusi + arrAnnoprec[8]?.chiusi + arrAnnoprec[9]?.chiusi + arrAnnoprec[10]?.chiusi + arrAnnoprec[11]?.chiusi)) * 100).toFixed(2)});                                    
            document.getElementById('GridTotaliSinistriChiusi').ej2_instances[0].dataSource = GridData;
            document.getElementById('GridTotaliSinistriChiusi').ej2_instances[0].refresh();
        }, error => {
            let messaggio = (_.isString(error) && error.indexOf('Not Found')) > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            })
        }, true, false);      
    }
}