/* eslint-disable default-case */
import * as React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, PdfExport, Page, Filter, Inject, Sort, Group, Aggregate, AggregateColumnsDirective, 
         AggregateColumnDirective, AggregateDirective, AggregatesDirective } from '@syncfusion/ej2-react-grids';
import { SampleBase } from './sample-base';
import { Browser } from '@syncfusion/ej2-base';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';

let GridData = [];
const waitingTime = Browser.userAgent.indexOf('Edg') > -1 ? 7000000000 : 2000000000;

CustomJS.getRemoteData2(CustomJS.APIUrlProd,
    `token=${CustomJS.tokenProd}&azione=referentiCompagnia`, 
    'POST', "application/x-www-form-urlencoded", (retData) => {   
        retData.forEach((elm, idx) => {
            elm.AssegnAnnoCorrSuAnnoPrec = `${elm.AssegnAnnoCorr.toLocaleString('it-IT')} / ${elm.AssegnAnnoPrec.toLocaleString('it-IT')}`;            
            elm.NumSinistriTUSsuAperti = `${elm.NumSinistriTUS.toLocaleString('it-IT')} (${elm.PercSinistriTUSsuAperti.toLocaleString()}%)`;     
            elm.PercentCorrettiCQeCR = `${elm.PercentCorrettiCQeCR.toLocaleString('it-IT')}%`;  
            elm.PercentCorrettiCQoCR = `${elm.PercentCorrettiCQoCR.toLocaleString('it-IT')}%`;      
            GridData.push(elm);
        });        
 }, true, false);
 for (let ind=0;ind<=waitingTime;ind++) {}

function headerTemplate(props) {    
    return (<div>
         <span style={{ fontSize: '0.95vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate(props) {       
    let colore = props.column.field === 'NominativoReferente' ? '#133B95' : (props.column.field === 'AssegnAnnoCorrSuAnnoPrec' && props.AssegnAnnoPrec > props.AssegnAnnoCorr) ? 'red' : 'purple';
    return (        
         <div><span style={{ fontSize: '1.0vw', color: colore, fontWeight: 'bold'}}>{props[props.column.field]}</span></div>
    );
}

export default class GridReferentiTecnici extends SampleBase {
    constructor() {
        super(...arguments);  
        this.toolbarOptions = ['ExcelExport', 'PdfExport', 'Search'];              
        this.data = GridData;    
        this.groupOptions = { showDropArea: false, showUngroupButton: false, columns: ['NominativoReferente'], captionTemplate: this.groupHeaderTemplate.bind(this) };     
    }    
    onQueryCellInfo(args) { 
    }    
    onDatabound(args) {       
        for (let ind=0;ind<=6;ind++)
             document.getElementById('GridReferentiCompagnia').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
        document.getElementById('TestoDatiAggiornati').innerText =  'Dati aggiornati al ' + new Date().getDate().toString().padStart(2, '0') + '/' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '/' + new Date().getFullYear().toString() + ' alle ore '  + new Date().getHours().toString() + ':' + new Date().getMinutes().toString().padStart(2, '0');            
    }
    onRowDatabound(args) {   
        if (args.data.DescrizioneCompagnia.indexOf('Totale Referente') > -1)
            args.row.bgColor = 'lightgray';   
        if (args.data.NominativoReferente.indexOf('TOTALE') > -1)
            args.row.bgColor = 'gold';          
    }
    groupFooterSum1(props) {        
        return (<span>{props.Sum}</span>);
    }
    groupHeaderTemplate(props) {       
        debugger;         
        return ( props.items[0].NominativoReferente === 'TOTALE' ? <span style={{ fontSize: '1.1vw', fontWeight: 'bold', color:'#133B95' }}>{props.items[0].NominativoReferente}</span> :
                  <span style={{ fontSize: '1.2vw', fontWeight: 'bold', color:'#133B95' }}>{props.items[0].NominativoReferente}</span>
            );
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
    }
    render() {
        return (<React.Fragment>                
                    <GridComponent id="GridReferentiCompagnia" dataSource={this.data} ref={grid => this.gridInstance = grid} toolbar={this.toolbarOptions} allowSelection={false} enableHover={false} allowTextWrap={true}
                                allowPaging={true} allowSorting={false} allowFiltering={false} allowExcelExport={true} allowPdfExport={true} queryCellInfo={this.onQueryCellInfo.bind(this)} 
                                dataBound={this.onDatabound.bind(this)} rowDataBound={this.onRowDatabound.bind(this)} toolbarClick={this.toolbarClick.bind(this)} 
                                groupSettings={this.groupOptions} allowGrouping={true} enableAltRow={false} pageSettings={{ pageCount: 5, pageSize: 11, pageSizes: false }}>
                        <ColumnsDirective>                            
                            <ColumnDirective field='NominativoReferente' headerText='Referente di Compagnia' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center' filter={this.Filter} clipMode="EllipsisWithTooltip"></ColumnDirective>
                            <ColumnDirective field='DescrizioneCompagnia' headerText='' width='30%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Left' filter={this.Filter} clipMode="EllipsisWithTooltip"></ColumnDirective>
                            <ColumnDirective field='AssegnAnnoCorrSuAnnoPrec' headerText="Assegnati nell'anno/anno precedente" headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='NumSinistriTUSsuAperti' visible={false} headerText='N° Sinistri in TUS (su aperti)' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='PercentCorrettiCQeCR' headerText='Perc. corretti da CQ e CR' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='PercentCorrettiCQoCR' headerText='Perc. corretti da CQ o CR' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                        </ColumnsDirective>                           
                        <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Group, Page]}/>                    
                    </GridComponent>                
            </React.Fragment>);
    }
}