/* eslint-disable default-case */
import * as React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, PdfExport, Page, Filter, DetailRow, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { SampleBase } from './sample-base';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var dataCorrente = new Date();
var dd = String(dataCorrente.getDate()).padStart(2, '0');
var mm = String(dataCorrente.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = dataCorrente.getFullYear();
dataCorrente = yyyy + mm + dd;

function headerTemplate(props) {    
    return (<div>
         <span style={{ fontSize: '0.9vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

export default class GridSurveyor extends SampleBase {
    constructor() {
        super(...arguments);  
        this.childGrid = {
            queryString: 'IdSurveyor',
            allowPaging: true,
            pageSettings: { pageSize: 15, pageCount: 10 },
            toolbar: [ 'Search' ],
            columns: [
                { field: 'IdSinistro', headerText: 'ID Sinistro', headerTemplate: headerTemplate, textAlign: 'Center', width: '33%' },
                { field: 'NominativoPeritoPrincipale', headerText: 'Nominativo Perito', headerTemplate: headerTemplate, width: '34%' },
                { field: 'StatoSinistro', headerText: 'Stato Sinistro', headerTemplate: headerTemplate, width: '33%' },
            ],
            queryCellInfo: this.onQueryCellInfo2.bind(this),
            dataBound: this.onDatabound2,
        };
        this.toolbarOptions = ['ExcelExport', 'Search']; 
        this.CompagnieTemp = ['103', '105', '107', '108', '109', '110', '113', '116', '120', '121', '123', '124', '127', '128', '129', '130', '131', '132', '136', '148', '149', '150'];             
    }    
    onQueryCellInfo(args) {
        args.cell.style.color = args.column.index === 0 ? '#133B95' : 'purple';
        args.cell.style.fontSize = '1.0vw';
        args.cell.style.fontWeight = 'bold';                 
    }    
    onQueryCellInfo2(args) {
        args.cell.style.color = args.column.index === 0 ? '#133B95' : 'purple';
        args.cell.style.fontSize = '1.0vw';
        args.cell.style.fontWeight = 'bold';                 
    }   
    onDatabound(args) {         
        for (let ind=0;ind<=6;ind++)
             document.getElementById('GridSurveyor').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
    }
    onDatabound2(args) {  
        for (let ind=0;ind<=2;ind++)
             this.element.querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';       
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
        }
    }

    render() {
        return (<React.Fragment>
                    <GridComponent id="GridSurveyor" ref={grid => this.gridInstance = grid} toolbar={this.toolbarOptions} childGrid={this.childGrid} allowSelection={false} enableHover={false} 
                            allowPaging={true} queryCellInfo={this.onQueryCellInfo.bind(this)} dataBound={this.onDatabound.bind(this)} allowFiltering={false} pageSettings={{ pageCount: 5, pageSize: 15, pageSizes: ['15', '30', 'Tutti'] }}
                            allowTextWrap={true} toolbarClick={this.toolbarClick.bind(this)} allowExcelExport={true} allowPdfExport={true} hierarchyPrintMode='All'>     
                        <ColumnsDirective>
                            <ColumnDirective field='NominativoSurveyor' headerText='Surveyor' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='NumeroIncarichiAperti' headerText='Numero Incarichi Aperti' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='MediaGiorniChiusura' headerText='Media Giorni Chiusura' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='MediaGiorniAperti' headerText='Media Giorni Aperti' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='NumeroIncarichiChiusiSettCorr' headerText='Incarichi Chiusi Settimana Corrente' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='PercScaricoSuAssegnati' headerText='Perc. Scarico (su totale assegnati)' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                        </ColumnsDirective>       
                        <Inject services={[Toolbar, DetailRow, ExcelExport, PdfExport, Page, Sort]}/>                
                    </GridComponent>
            </React.Fragment>);
    }  
    componentDidMount() {    
        this.caricaDati();
    } 
    caricaDati() {
        let dsGriglia = [];
        CustomJS.callAPI(`${CustomJS.APIMonitorURLProd}/api/Monitor/leggiSituazioneSurveyor`, '', 'GET', '', retData => { 
            retData.forEach(elm => { 
                elm.IdSurveyor = elm.IdSurveyor.toString();
                elm.TotaleIncarichi = elm.TotaleIncarichi?.toLocaleString();
                elm.NumeroIncarichiAperti = elm.NumeroIncarichiAperti?.toLocaleString();
                elm.MediaGiorniChiusura = CustomJS.isNullEmpty(elm.MediaGiorniChiusura) ? '' : parseFloat(parseFloat(elm.MediaGiorniChiusura).toFixed(2))?.toLocaleString();
                elm.MediaGiorniAperti = CustomJS.isNullEmpty(elm.MediaGiorniAperti) ? '' : parseFloat(parseFloat(elm.MediaGiorniAperti).toFixed(2))?.toLocaleString();
                elm.NumeroIncarichiChiusiSettCorr = elm.NumeroIncarichiChiusiSettCorr?.toLocaleString();
                elm.PercScaricoSuAssegnati = CustomJS.isNullEmpty(elm.PercScaricoSuAssegnati) ? '' : `${parseFloat(parseFloat(elm.PercScaricoSuAssegnati).toFixed(2))?.toLocaleString()}%`;
            });
            this.gridInstance.dataSource = retData;
            CustomJS.getRemoteData2(CustomJS.APIUrlProd,
                `token=${CustomJS.tokenProd}&azione=secondoPeritoSurveyor&dataDa=${yyyy}0101&dataA=${dataCorrente}`, 
                'POST', "application/x-www-form-urlencoded", (retData2) => {
                    let groupedData = _.groupBy(_.filter(retData2, elm => { return elm.SecondoPeritoSurveyor === 'Surveyor'}), 'IdSecondoPeritoSurveyor');            
                    Object.values(groupedData).forEach((elm, idx) => {                    
                        elm.forEach(elm2 => { 
                            let objSecondoLiv = { IdSurveyor: Object.keys(groupedData)[idx].toString(), 
                                                  IdSinistro: elm2.IdSinistro,
                                                  NominativoPeritoPrincipale: elm2.NominativoPeritoPrincipale,
                                                  StatoSinistro: elm2.StatoSinistroSurveyor };
                            dsGriglia.push(objSecondoLiv);
                        });   
                        this.gridInstance.childGrid.dataSource = dsGriglia;
                    }); 
                    this.gridInstance.refresh();
            }, true, false);    
        }, error => {
            let messaggio = (_.isString(error) && error.indexOf('Not Found')) > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                //onClose: () => toast.dismiss(),
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            })
        }, true, false);  
    }    
}