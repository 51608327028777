import React, { useRef, useState, useLayoutEffect } from 'react';
import * as ReactDOM from "react-dom";
import {
    AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective,
    Inject, AccumulationLegend, PieSeries, AccumulationTooltip, AccumulationDataLabel
} from '@syncfusion/ej2-react-charts';


function GraficoTortaSync(props) {
    const textRender = (args) => {
        var perc = args.point.percentage.toLocaleString();
        args.text = args.point.x + "  ( " +  perc + "% )";
    };

    const tooltipRender = (args) => {
        args.text = `${args.data.pointX}: ${args.point.y.toLocaleString()} €`;
    };

    return (
        <>
            <div className='torta-fatturato'>
                <AccumulationChartComponent width='1200px' height='700px' center={{ x: '70%', y: '50%' }} id='pie-chart' enableAnimation={true}
                    legendSettings={{ visible: true, position: 'Right', margin: { left: 200 }, }}
                    enableSmartLabels={true} textRender={textRender}
                    tooltip={{ enable: true}} tooltipRender={tooltipRender}>
                    <Inject services={[AccumulationLegend, PieSeries, AccumulationTooltip, AccumulationDataLabel]} />
                    <AccumulationSeriesCollectionDirective>
                        <AccumulationSeriesDirective radius='250px' explodeAll={true} dataSource={props.datiFatturato} xName='x' yName='y' explode={true} explodeOffset='10%'
                            dataLabel={{
                                visible: true, position: 'Inside', name: 'text', font: { fontWeight: '600', size: '13px' }
                            }} >
                        </AccumulationSeriesDirective>
                    </AccumulationSeriesCollectionDirective>
                </AccumulationChartComponent>;
            </div>
        </>
    );
}

export default GraficoTortaSync;