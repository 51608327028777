import React from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Category, ColumnSeries, DataLabel, Tooltip } from '@syncfusion/ej2-react-charts';
import { fabricColors, bootstrapColors, materialColors, highContrastColors } from './theme-color';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import { SampleBase } from './sample-base';
import * as CustomJS from '../Custom';
import { random } from 'underscore';
import _ from 'underscore/underscore-min';

var dataCorrente = new Date();
var dd = String(dataCorrente.getDate()).padStart(2, '0');
var mm = String(dataCorrente.getMonth() + 1).padStart(2, '0');
//dd = '30'; //solo test - da rimuovere
//mm = '04'; //solo test - da rimuovere
var mmNum = parseInt(mm);
var yyyy = dataCorrente.getFullYear();
//yyyy = 2019; //solo test - da rimuovere
dataCorrente = yyyy + mm + dd;
let mesiChar = ['GENNAIO', 'FEBBRAIO', 'MARZO', 'APRILE', 'MAGGIO', 'GIUGNO', 'LUGLIO', 'AGOSTO', 'SETTEMBRE', 'OTTOBRE', 'NOVEMBRE', 'DICEMBRE'];
let giorniMese = (mmNum === 1 || mmNum === 3 || mmNum === 5 || mmNum === 7 || mmNum === 8 || mmNum === 10 || mmNum === 12) ? 31 : (mmNum === 4 || mmNum === 6 || mmNum === 9 || mmNum === 11) ? 30 : mmNum === 2 && CustomJS.leapYear ? 29 : 28;
let data1 = [];
let totaleRestit = 0;

let themes = ['material', 'bootstrap'];
let selectedTheme = themes[random(1)];
let rndColorNr = random(giorniMese);

let labelRender = (args) => {
    if (selectedTheme && selectedTheme.indexOf('fabric') > -1) {
        args.fill = fabricColors[rndColorNr % 10];
    }
    else if (selectedTheme === 'material') {
        args.fill = materialColors[rndColorNr % 10];
    }
    else if (selectedTheme === 'highcontrast') {
        args.fill = highContrastColors[rndColorNr % 10];
    }
    else if (selectedTheme === 'bootstrap') {
        args.fill = bootstrapColors[rndColorNr % 10];
    }
};

export default class ChartRestituzioniPeriti extends SampleBase {
    constructor() {
        super(...arguments);
        if ((window.matchMedia("(max-width: 850px)")).matches) {
            this.altezza = "250px";
            this.larghezza = "650px";
        }
        else if ((window.matchMedia("(max-width: 1024px)")).matches) {
            this.altezza = "500px";
            this.larghezza = CustomJS.chartWidth3;
        }
        else{
            this.altezza = CustomJS.chartHeight2;
            this.larghezza = CustomJS.chartWidth3;
        }
    }
    state = {
        data1: []
    }

    render() {
        return (<React.Fragment>
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-lg-5 col-md-5" style={{ marginLeft: '50px', marginTop: '10px' }}>
                    <span style={{ fontSize: '2.0vw', fontWeight: 'bolder', color: '#133B95' }}>{mesiChar[parseInt(mm) - 1] + ' ' + yyyy.toString()}</span>
                </div>
                <div className="col-xs-12 col-sm-12 col-lg-6 col-md-6 text-end" style={{ marginTop: '10px' }}>
                    <span style={{ fontSize: '2.0vw', fontWeight: 'bold', color: '#133B95' }}>TOTALE RESTITUITI: {totaleRestit.toLocaleString()}</span>
                </div>
            </div>
            <div className="row">
                <ChartComponent id='ChartRestituzionePeriti' style={{ textAlign: "center" }}
                    className='col-xs-12 col-sm-12 col-lg-12 col-md-12'
                    primaryXAxis={{
                        valueType: 'Category', interval: 1, majorGridLines: { width: 0 },
                        labelStyle: { color: '#133B95', fontWeight: 'bolder' }
                    }}
                    primaryYAxis={{ majorGridLines: { width: 0 }, majorTickLines: { width: 0 }, lineStyle: { width: 0 }, labelStyle: { color: 'transparent' } }}
                    chartArea={{ border: { width: 0 } }}
                    loaded={this.operazioniDopoCarica.bind(this)}
                    title=''
                    legendSettings={{ visible: false }}
                    width={this.larghezza}
                    height={this.altezza}
                    tooltip={{ enable: true }}
                    pointRender={labelRender}>
                    <Inject services={[ColumnSeries, DataLabel, Category, Tooltip]} />
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={this.state.data1} type='Column' xName='x' width={2} yName='y'
                            cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }}
                            marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                        </SeriesDirective>
                    </SeriesCollectionDirective>
                </ChartComponent>
            </div>

        </React.Fragment>)
    }
    rendereComplete() {
        this.caricaDati();
    }
    caricaDati() {
        CustomJS.callAPI(`${CustomJS.APIMonitorURLProd}/api/Monitor/portingTutteAPI/restituiti/${yyyy.toString()}${mm}01/${dataCorrente}/giorno/[NO]`, '', 'GET', '', retData => {
            let giorniPass = 0;
            retData.forEach((elm, idx) => {
                data1.push({ x: parseInt(elm.periodo.substr(6, 2)), y: elm?.restituiti });
                giorniPass = parseInt(elm.periodo.substr(6, 2));
                totaleRestit += elm?.restituiti;
            });
            let progrAtteso = 1;
            data1.forEach((elm,idx) => {
                if (elm.x === progrAtteso)
                    progrAtteso++;
                else if (elm.x > progrAtteso)
                    data1.splice(idx, 0, {x: progrAtteso, y: 0});
            });
            for (let ind = giorniPass + 1; ind <= giorniMese; ind++) {
                data1.push({ x: ind, y: 0 });
            }
            this.setState({ data1: data1 });
        }, error => {
            let messaggio = (_.isString(error) && error.indexOf('Not Found')) > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            })
        }, true, false);  
    }
    operazioniDopoCarica(args) {
        let satsAndSunds = CustomJS.getSaturdaysAndSundays();
        satsAndSunds[0].forEach(elm => {
            setTimeout(() => {
                if (document.getElementById(`ChartRestituzionePeriti_Series_0_Point_${(elm - 1).toString()}`))
                    document.getElementById(`ChartRestituzionePeriti_Series_0_Point_${(elm - 1).toString()}`).attributes.fill.value = 'crimson';
            }, 100);
        });
        satsAndSunds[1].forEach(elm => {
            setTimeout(() => {
                if (document.getElementById(`ChartRestituzionePeriti_Series_0_Point_${(elm - 1).toString()}`))
                    document.getElementById(`ChartRestituzionePeriti_Series_0_Point_${(elm - 1).toString()}`).attributes.fill.value = 'crimson';
            }, 100);
        });

        let arrGiorniFerialiMese = [];
        for (let ind = 1; ind <= giorniMese; ind++)
            arrGiorniFerialiMese.push(ind);
        arrGiorniFerialiMese = _.difference(arrGiorniFerialiMese, satsAndSunds[0], satsAndSunds[1]);
        arrGiorniFerialiMese.forEach(elm => {
            setTimeout(() => { document.getElementById(`ChartRestituzionePeriti_Series_0_Point_${(elm - 1).toString()}`).attributes.fill.value = 'DodgerBlue'; }, 100);
        });

    }
}