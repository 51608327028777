/* eslint-disable no-extend-native */
/* eslint-disable default-case */
import * as React from "react";
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, DetailRow, ExcelExport, PdfExport, Page, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
// eslint-disable-next-line no-unused-vars
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject as Inject2, Legend, Category, Tooltip, LineSeries, DateTime, Trendlines, TrendlinesDirective, TrendlineDirective, ColumnSeries, DataLabel } from '@syncfusion/ej2-react-charts';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
// eslint-disable-next-line no-unused-vars
import { fabricColors, bootstrapColors, materialColors, highContrastColors } from './theme-color';
import { random, isUndefined } from 'underscore';
// eslint-disable-next-line no-unused-vars
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { SampleBase } from './sample-base';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import moment from "moment";
import Cookies from 'universal-cookie';

Array.prototype.sum2 = function (prop, index) {
    let total = 0
    for ( let i = 0, _len = this.length; i < _len; i++ ) {
        if (CustomJS.isNullEmpty(index))
            total += this[i][prop];
        else
            total += isNaN(this[i][Object.keys(this[i])[index]]) ? 0 : parseInt(this[i][Object.keys(this[i])[index]]);
    }
    return total;
}

var dataCorrente = new Date();
var dd = String(dataCorrente.getDate()).padStart(2, '0');
var mm = String(dataCorrente.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = dataCorrente.getFullYear();
dataCorrente = yyyy + mm + dd;
const dataInizio2AnniPrec = yyyy-2 + '0101';
const dataInizioMesePrec = parseInt(mm) === 1 ? `${(yyyy-1).toString()}1201` : `${yyyy.toString()}${(mm-1).toString().padStart(2, '0')}01`;
const dataInizio3MesiPrec = parseInt(mm) <= 3 ? `${(yyyy - 1).toString()}${(12-(3-mm)).toString().padStart(2, '0')}01` : `${yyyy.toString()}${(mm-3).toString().padStart(2, '0')}01`;
const cookies = new Cookies();

function headerTemplate1(props) {    
    return (<div>
         <span style={{ fontSize: '0.6vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}
function headerTemplate2(props) {    
    return (<div>
         <span style={{ fontSize: '1.0vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate1(props) {
    let colore = props.column.field === 'rowDescr' ? '#133B95' : 'purple';  
    let fontSize1 = '0.5vw';  
    let fontSize2 = '0.9vw';
    let renderDiv = props.column.field !== 'rowDescr' ? <div><span style={{ fontSize: fontSize1, color: colore, fontWeight: 'bold'}}>{props[props.column.field]?.substring(0, props[props.column.field]?.indexOf('('))}</span><br/><span style={{ fontSize: fontSize2, color: colore, fontWeight: 'bold'}}>{props[props.column.field]?.substring(props[props.column.field]?.indexOf('('))}</span></div>
                    : <div><span style={{ fontSize: '150%', color: colore, fontWeight: 'bold'}}>{props[props.column.field]}</span></div>;
    return (        
         renderDiv
    );
}

function fieldTemplate2(props) {
    let colore = props.column.field === 'NominativoPerito' ? '#133B95' : 'purple';  
    let fontSize1 = '1.0vw';  
    let fontSize2 = '0.95vw';
    let renderDiv = props.column.field !== 'NominativoPerito' ? <div><span style={{ fontSize: fontSize1, color: colore, fontWeight: 'bold'}}>{props[props.column.field]?.substring(0, props[props.column.field]?.indexOf('('))}</span><br/><span style={{ fontSize: fontSize2, color: colore, fontWeight: 'bold'}}>{props[props.column.field]?.substring(props[props.column.field]?.indexOf('('))}</span></div>
                    : <div><span style={{ fontSize: '150%', color: colore, fontWeight: 'bold'}}>{props[props.column.field]}</span></div>;
    return (        
         renderDiv
    );
}

function fieldTemplate3(props) {
    return (<div>
        <span style={{ fontSize: '1.5vw', color: '#133B95', fontWeight: 'bold'}}>{props[props.column.field]}</span>
    </div>
    );
}
export default class PaginaFormaDiretta extends SampleBase {
    // eslint-disable-next-line no-useless-constructor
    constructor() {
        super(...arguments); 
        this.toolbarOptions = ['ExcelExport', 'Search'];              
        this.accordHeader = ['SINISTRI GESTITI/INCARICHI GESTITI', 'SINISTRI CHIUSI/INCARICHI CHIUSI', 'PERITI E SQUADRE TEAM LEADER (MENSILE)', 'PERITI E SQUADRE TEAM LEADER (TRIMESTRALE)'];   
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.loaderType = ['BallTriangle', 'Bars', 'Circles', 'Grid', 'Puff', 'Rings', 'TailSpin', 'ThreeDots', 'Oval', 'MutatingDots', 'RevolvingDot'];
        this.childGrid = {
            queryString: 'IdTeamLeader',
            allowPaging: false,
            allowSelection: false,
            enableHover: false,
            pageSettings: { pageSize: 10, pageCount: 5 },
            columns: [],
            dataBound: this.onDatabound5
        };
    }
    state= {
        blocking1: false        
    }
    toggleBlocking() {
        this.setState({blocking1: !this.state.blocking1});
    }
    onQueryCellInfo1(args) {            
        args.cell.style.color = args.column.index === 0 ? '#133B95' : 'purple';   
        args.cell.style.fontSize = '0.9vw';
        args.cell.style.fontWeight = 'bold';   
        if (args.data.rowDescr === 'Delta %' && args.cell.innerText !== 'Delta %')
            args.cell.innerText = parseFloat(args.cell.innerText).toLocaleString('it-IT'); 
        if (args.column.index === 13)             
            args.cell.style.backgroundColor = 'gold'; 
        if (args.cell.innerText === 'NaN' || args.cell.innerText === '+∞')
            args.cell.innerText = ''; 
        if (args.cell.innerText.indexOf('undefined') > -1)           
            args.cell.innerText = '';              
        if (args.cell.innerText.indexOf('-') > -1)           
            args.cell.style.color = 'red';    
        else if (args.data.rowDescr.indexOf('Delta %') > -1 && args.cell.innerText.indexOf('Delta %') === -1 && args.cell.innerText !== '') {       
            args.cell.innerText = '+' + args.cell.innerText;  
            args.cell.style.color = 'green';
        }       
        if (args.cell.innerText === '+')   
            args.cell.innerText = '';        
    }    
    onQueryCellInfo2(args) {      
        args.cell.style.color = args.column.index === 0 ? '#133B95' : 'purple';   
        args.cell.style.fontSize = '0.9vw';
        args.cell.style.fontWeight = 'bold';   
        if (args.data.rowDescr === 'Delta %' && args.cell.innerText !== 'Delta %')
            args.cell.innerText = parseFloat(args.cell.innerText).toLocaleString('it-IT'); 
        if (args.column.index === 13)             
            args.cell.style.backgroundColor = 'gold'; 
        if (args.cell.innerText === 'NaN' || args.cell.innerText === '+∞')
            args.cell.innerText = ''; 
        if (args.cell.innerText.indexOf('undefined') > -1)           
            args.cell.innerText = '';              
        if (args.cell.innerText.indexOf('-') > -1)           
            args.cell.style.color = 'red';    
        else if (args.data.rowDescr.indexOf('Delta %') > -1 && args.cell.innerText.indexOf('Delta %') === -1 && args.cell.innerText !== '') {       
            args.cell.innerText = '+' + args.cell.innerText;  
            args.cell.style.color = 'green';
        }       
        if (args.cell.innerText === '+')   
            args.cell.innerText = '';        
    }    
    onDatabound1(args) {       
        for (let ind=0;ind<=13;ind++)
             document.getElementById('GridSinistriIncarichiGestitiMensili').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';    
        document.getElementById('GridSinistriIncarichiGestitiMensili').querySelectorAll('.e-columnheader')[0].children[13].style.backgroundColor = 'gold';        
    }
    onDatabound2(args) {       
        for (let ind=0;ind<=13;ind++)
             document.getElementById('GridSinistriIncarichiChiusiMensili').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';    
        document.getElementById('GridSinistriIncarichiChiusiMensili').querySelectorAll('.e-columnheader')[0].children[13].style.backgroundColor = 'gold';        
    }
    onDatabound3(args) {       
        for (let ind=0;ind<=2;ind++)
             document.getElementById('GridSituazionePeritiMensile').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
        for (let ind=0;ind<=7;ind++)
             document.getElementById('GridSituazionePeritiMensile').querySelectorAll('.e-columnheader')[1].children[ind].style.backgroundColor = 'Linen';
    }
    onDatabound4(args) {    
        for (let ind=0;ind<=1;ind++)
             document.getElementById('GridSquadreTeamLeaderMensile').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
        if (cookies.get("AutoMonitor") === 'true')
            setTimeout(() => {
                this.gridInstance4.detailExpandAll();
            }, 200);   
    }
    onDatabound5(args) {  
        for (let ind=0;ind<=2;ind++)
             this.element.querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';    
        for (let ind=0;ind<=7;ind++)
             this.element.querySelectorAll('.e-columnheader')[1].children[ind].style.backgroundColor = 'Linen';  
    }  
    onDatabound6(args) {       
        for (let ind=0;ind<=2;ind++)
             document.getElementById('GridSituazionePeritiTrimestrale').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
        for (let ind=0;ind<=7;ind++)
             document.getElementById('GridSituazionePeritiTrimestrale').querySelectorAll('.e-columnheader')[1].children[ind].style.backgroundColor = 'Linen';
    }
    onDatabound7(args) {    
        for (let ind=0;ind<=1;ind++)
             document.getElementById('GridSquadreTeamLeaderTrimestrale').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
        if (cookies.get("AutoMonitor") === 'true')
            setTimeout(() => {
                this.gridInstance6.detailExpandAll();
            }, 200);   
    }
    onRowDatabound(args) {  
        if (args.data.NominativoPerito.indexOf('TOTALI') > -1) {
            args.row.className = 'e-row';
            args.row.bgColor = 'lightpink'; 
        }
    }
    onToolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance3.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance3.excelExport();
                break;
        }
    }
    onToolbarClick2(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance4.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance4.excelExport();
                break;
        }
    }
    cambioVisione1(args) {
        if (args.value === 'andamento') {
            document.getElementById('divChartBarre1').style.display = 'none';
            document.getElementById('divChartBarre2').style.display = 'none';
            document.getElementById('divChartLinee1').style.removeProperty('display');     
            document.getElementById('divChartLinee2').style.removeProperty('display');      
        }
        else if (args.value === 'puntuale') {
            document.getElementById('divChartLinee1').style.display = 'none';
            document.getElementById('divChartLinee2').style.display = 'none';
            document.getElementById('divChartBarre1').style.removeProperty('display');  
            document.getElementById('divChartBarre2').style.removeProperty('display');  
        }
    }
    cambioVisione2(args) {
        if (args.value === 'andamento') {
            document.getElementById('divChartBarre3').style.display = 'none';
            document.getElementById('divChartBarre4').style.display = 'none';
            document.getElementById('divChartLinee3').style.removeProperty('display');     
            document.getElementById('divChartLinee4').style.removeProperty('display');      
        }
        else if (args.value === 'puntuale') {
            document.getElementById('divChartLinee3').style.display = 'none';
            document.getElementById('divChartLinee4').style.display = 'none';
            document.getElementById('divChartBarre3').style.removeProperty('display');  
            document.getElementById('divChartBarre4').style.removeProperty('display');  
        }
    }
    accordContent0Render() {
        return (<div>
                    <div className="row" style={{ marginTop: '10px' }}>      
                        <div className="col-xs-6 col-sm-6 col-md-2 col-lg-2"> 
                            <RadioButtonComponent id='visioneAndamento1' checked={true} label='Confronto degli andamenti' style={{ fontSize: '0.8vw'}} name='sceltaVisione1' change={this.cambioVisione1.bind(this)} value="andamento"></RadioButtonComponent>  
                            <RadioButtonComponent id='visioneBarre1' checked={false} label='Confronto dei valori puntuali' name='sceltaVisione1' change={this.cambioVisione1.bind(this)} value="puntuale"></RadioButtonComponent> 
                        </div>  
                    </div>    
                    <div className="row" style={{ marginTop: '30px' }}>      
                        <div className="col-xs-12 col-sm-12 col-lg-10 col-md-10">
                            <span style={{ fontSize: '1.5vw', fontWeight: 'bold', color:'#133B95'}}>SINISTRI GESTITI</span>
                        </div>  
                    </div>       
                    <div id='divChartLinee1' className="row">                 
                        <ChartComponent id='ChartSinistriGestitiLinee' style={{ textAlign: "center" }}
                            className='col-xs-12 col-sm-12 col-lg-12 col-md-12'
                            ref={chart => {this.chartInstance1 = chart}}
                            primaryXAxis={{
                                valueType: 'DateTime',
                                labelFormat: 'MMMM',
                                intervalType: 'Months',
                                edgeLabelPlacement: 'Shift',
                                majorGridLines: { width: 0 }                
                            }}
                            primaryYAxis={{
                                labelFormat: '{value}',
                                rangePadding: 'None',                                
                                interval: 100,
                                lineStyle: { width: 0 },                                
                                minimum: 0,
                                majorTickLines: { width: 0 },
                                minorTickLines: { width: 0 }
                            }}
                            chartArea={{ border: { width: 0 } }}
                            tooltip={{ enable: true }}
                            width={CustomJS.chartWidth}
                            title='' loaded={this.operazioniDopoCarica.bind(this)}
                            legendSettings={{ visible: true }}>
                            <Inject2 services={[LineSeries, DateTime, Legend, Tooltip, Trendlines]} />
                            <SeriesCollectionDirective>
                                <SeriesDirective xName='x' yName='y' name={`Sinistri Gestiti in FD ${(yyyy - 2).toString()}`}
                                    width={3} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                                </SeriesDirective>
                                <SeriesDirective xName='x' yName='y' name={`Sinistri Gestiti in FD ${(yyyy - 1).toString()}`}
                                    width={3} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                                </SeriesDirective>
                                <SeriesDirective xName='x' yName='y' name={`Sinistri Gestiti in FD ${yyyy.toString()}`}
                                    width={3} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                                </SeriesDirective>
                            </SeriesCollectionDirective>
                        </ChartComponent>  
                    </div>  
                    <div id='divChartBarre1' className="row">                 
                        <ChartComponent id='ChartSinistriGestitiBarre' style={{ textAlign: "center" }} loaded={this.operazioniDopoCarica.bind(this)}
                            className='col-xs-12 col-sm-12 col-lg-12 col-md-12' ref={chart => {this.chartInstance3 = chart}}
                            primaryXAxis={{ valueType: 'Category', interval: 1, majorGridLines: { width: 0 } }} 
                            primaryYAxis={{ majorGridLines: { width: 0 }, majorTickLines: { width: 0 }, lineStyle: { width: 0 }, labelStyle: { color: 'transparent' } }}
                            chartArea={{ border: { width: 0 } }} tooltip={{ enable: true }} 
                            width={CustomJS.chartWidth} 
                            height={CustomJS.chartHeight} title=''
                            legendSettings={{ visible: true }}>
                            <Inject2 services={[ColumnSeries, Legend, Tooltip, Category, DataLabel]}/>
                            <SeriesCollectionDirective>
                                <SeriesDirective xName='x' yName='y' type='Column' name={`Sinistri Gestiti in FD ${(yyyy - 2).toString()}`} 
                                     cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                                </SeriesDirective>
                                <SeriesDirective xName='x' yName='y' type='Column' name={`Sinistri Gestiti in FD ${(yyyy - 1).toString()}`}
                                     cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                                </SeriesDirective>  
                                <SeriesDirective xName='x' yName='y' type='Column' name={`Sinistri Gestiti in FD ${yyyy.toString()}`}
                                     cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                                </SeriesDirective>                       
                            </SeriesCollectionDirective>
                        </ChartComponent>      
                    </div>   
                    <div className="row" style={{ marginTop: '30px' }}>      
                        <div className="col-xs-12 col-sm-12 col-lg-10 col-md-10">
                            <span style={{ fontSize: '1.5vw', fontWeight: 'bold', color:'#133B95'}}>INCARICHI GESTITI</span>
                        </div>  
                    </div>       
                    <div id='divChartLinee2' className="row">                 
                        <ChartComponent id='ChartIncarichiGestitiLinee' style={{ textAlign: "center" }}
                            className='col-xs-12 col-sm-12 col-lg-12 col-md-12'
                            ref={chart => {this.chartInstance2 = chart}}
                            primaryXAxis={{
                                valueType: 'DateTime',
                                labelFormat: 'MMMM',
                                intervalType: 'Months',
                                edgeLabelPlacement: 'Shift',
                                majorGridLines: { width: 0 }                
                            }}
                            primaryYAxis={{
                                labelFormat: '{value}',
                                rangePadding: 'None',                                
                                interval: 100,
                                lineStyle: { width: 0 },                                
                                minimum: 0,
                                majorTickLines: { width: 0 },
                                minorTickLines: { width: 0 }
                            }}
                            chartArea={{ border: { width: 0 } }}
                            tooltip={{ enable: true }}
                            width={CustomJS.chartWidth}
                            title='' loaded={this.operazioniDopoCarica.bind(this)}
                            legendSettings={{ visible: true }}>
                            <Inject2 services={[LineSeries, DateTime, Legend, Tooltip, Trendlines]} />
                            <SeriesCollectionDirective>
                                <SeriesDirective xName='x' yName='y' name={`Incarichi Gestiti in FD ${(yyyy - 2).toString()}`}
                                    width={3} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                                </SeriesDirective>
                                <SeriesDirective xName='x' yName='y' name={`Incarichi Gestiti in FD ${(yyyy - 1).toString()}`}
                                    width={3} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                                </SeriesDirective>
                                <SeriesDirective xName='x' yName='y' name={`Incarichi Gestiti in FD ${yyyy.toString()}`}
                                    width={3} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                                </SeriesDirective>
                            </SeriesCollectionDirective>
                        </ChartComponent>  
                    </div>
                    <div id='divChartBarre2' className="row">                 
                        <ChartComponent id='ChartIncarichiGestitiBarre' style={{ textAlign: "center" }} loaded={this.operazioniDopoCarica.bind(this)}
                            className='col-xs-12 col-sm-12 col-lg-12 col-md-12' ref={chart => {this.chartInstance4 = chart}}
                            primaryXAxis={{ valueType: 'Category', interval: 1, majorGridLines: { width: 0 } }} 
                            primaryYAxis={{ majorGridLines: { width: 0 }, majorTickLines: { width: 0 }, lineStyle: { width: 0 }, labelStyle: { color: 'transparent' } }}
                            chartArea={{ border: { width: 0 } }} tooltip={{ enable: true }} 
                            width={CustomJS.chartWidth} 
                            height={CustomJS.chartHeight} title=''
                            legendSettings={{ visible: true }}>
                            <Inject2 services={[ColumnSeries, Legend, Tooltip, Category, DataLabel]}/>
                            <SeriesCollectionDirective>
                                <SeriesDirective xName='x' yName='y' type='Column' name={`Incarichi Gestiti in FD ${(yyyy - 2).toString()}`} 
                                     cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                                </SeriesDirective>
                                <SeriesDirective xName='x' yName='y' type='Column' name={`Incarichi Gestiti in FD ${(yyyy - 1).toString()}`}
                                     cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                                </SeriesDirective>  
                                <SeriesDirective xName='x' yName='y' type='Column' name={`Incarichi Gestiti in FD ${yyyy.toString()}`}
                                     cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                                </SeriesDirective>                       
                            </SeriesCollectionDirective>
                        </ChartComponent>      
                    </div>
                    <div className="row" style={{ marginTop: '10px' }}>      
                        <div className="col-xs-12 col-sm-12 col-lg-10 col-md-10">
                            <span style={{ fontSize: '1.2vw', fontWeight: 'bold', color:'#133B95'}}>SITUAZIONE MENSILE SINISTRI/INCARICHI GESTITI</span>
                        </div>  
                    </div>       
                    <div className='row'>
                        <GridComponent id="GridSinistriIncarichiGestitiMensili" ref={grid => this.gridInstance1 = grid} allowSelection={false} enableHover={false} 
                                allowPaging={false} queryCellInfo={this.onQueryCellInfo1.bind(this)} dataBound={this.onDatabound1.bind(this)} enableAltRow={false} 
                                allowTextWrap={true}>
                            <ColumnsDirective>
                                <ColumnDirective field='rowDescr' headerText='' width='8%' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='GEN' headerText='GENNAIO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='FEB' headerText='FEBBRAIO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='MAR' headerText='MARZO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='APR' headerText='APRILE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='MAG' headerText='MAGGIO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='GIU' headerText='GIUGNO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='LUG' headerText='LUGLIO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='AGO' headerText='AGOSTO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='SET' headerText='SETTEMBRE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='OTT' headerText='OTTOBRE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='NOV' headerText='NOVEMBRE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='DIC' headerText='DICEMBRE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='TOT' headerText='TOTALI' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                            </ColumnsDirective>                        
                        </GridComponent>
                    </div>
        </div>);
    }
    accordContent1Render() {
        return (<div>
            <div className="row" style={{ marginTop: '10px' }}>      
                <div className="col-xs-6 col-sm-6 col-md-2 col-lg-2"> 
                    <RadioButtonComponent id='visioneAndamento2' checked={true} label='Confronto degli andamenti' style={{ fontSize: '0.8vw'}} name='sceltaVisione2' change={this.cambioVisione2.bind(this)} value="andamento"></RadioButtonComponent>  
                    <RadioButtonComponent id='visioneBarre2' checked={false} label='Confronto dei valori puntuali' name='sceltaVisione2' change={this.cambioVisione2.bind(this)} value="puntuale"></RadioButtonComponent> 
                </div>  
            </div>    
            <div className="row" style={{ marginTop: '30px' }}>      
                <div className="col-xs-12 col-sm-12 col-lg-10 col-md-10">
                    <span style={{ fontSize: '1.5vw', fontWeight: 'bold', color:'#133B95'}}>SINISTRI CHIUSI</span>
                </div>  
            </div>       
            <div id='divChartLinee3' className="row">                 
                <ChartComponent id='ChartSinistriChiusiLinee' style={{ textAlign: "center" }}
                    className='col-xs-12 col-sm-12 col-lg-12 col-md-12'
                    ref={chart => {this.chartInstance5 = chart}}
                    primaryXAxis={{
                        valueType: 'DateTime',
                        labelFormat: 'MMMM',
                        intervalType: 'Months',
                        edgeLabelPlacement: 'Shift',
                        majorGridLines: { width: 0 }                
                    }}
                    primaryYAxis={{
                        labelFormat: '{value}',
                        rangePadding: 'None',                                
                        interval: 100,
                        lineStyle: { width: 0 },                                
                        minimum: 0,
                        majorTickLines: { width: 0 },
                        minorTickLines: { width: 0 }
                    }}
                    chartArea={{ border: { width: 0 } }}
                    tooltip={{ enable: true }}
                    width={CustomJS.chartWidth}
                    title='' loaded={this.operazioniDopoCarica.bind(this)}
                    legendSettings={{ visible: true }}>
                    <Inject2 services={[LineSeries, DateTime, Legend, Tooltip, Trendlines]} />
                    <SeriesCollectionDirective>
                        <SeriesDirective xName='x' yName='y' name={`Sinistri Chiusi in FD ${(yyyy - 2).toString()}`}
                            width={3} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                        </SeriesDirective>
                        <SeriesDirective xName='x' yName='y' name={`Sinistri Chiusi in FD ${(yyyy - 1).toString()}`}
                            width={3} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                        </SeriesDirective>
                        <SeriesDirective xName='x' yName='y' name={`Sinistri Chiusi in FD ${yyyy.toString()}`}
                            width={3} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                        </SeriesDirective>
                    </SeriesCollectionDirective>
                </ChartComponent>  
            </div>  
            <div id='divChartBarre3' className="row">                 
                <ChartComponent id='ChartSinistriChiusiBarre' style={{ textAlign: "center" }} loaded={this.operazioniDopoCarica.bind(this)}
                    className='col-xs-12 col-sm-12 col-lg-12 col-md-12' ref={chart => {this.chartInstance7 = chart}}
                    primaryXAxis={{ valueType: 'Category', interval: 1, majorGridLines: { width: 0 } }} 
                    primaryYAxis={{ majorGridLines: { width: 0 }, majorTickLines: { width: 0 }, lineStyle: { width: 0 }, labelStyle: { color: 'transparent' } }}
                    chartArea={{ border: { width: 0 } }} tooltip={{ enable: true }} 
                    width={CustomJS.chartWidth} 
                    height={CustomJS.chartHeight} title=''
                    legendSettings={{ visible: true }}>
                    <Inject2 services={[ColumnSeries, Legend, Tooltip, Category, DataLabel]}/>
                    <SeriesCollectionDirective>
                        <SeriesDirective xName='x' yName='y' type='Column' name={`Sinistri Chiusi in FD ${(yyyy - 2).toString()}`} 
                             cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                        </SeriesDirective>
                        <SeriesDirective xName='x' yName='y' type='Column' name={`Sinistri Chiusi in FD ${(yyyy - 1).toString()}`}
                             cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                        </SeriesDirective>  
                        <SeriesDirective xName='x' yName='y' type='Column' name={`Sinistri Chiusi in FD ${yyyy.toString()}`}
                             cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                        </SeriesDirective>                       
                    </SeriesCollectionDirective>
                </ChartComponent>      
            </div>   
            <div className="row" style={{ marginTop: '30px' }}>      
                <div className="col-xs-12 col-sm-12 col-lg-10 col-md-10">
                    <span style={{ fontSize: '1.5vw', fontWeight: 'bold', color:'#133B95'}}>INCARICHI CHIUSI</span>
                </div>  
            </div>       
            <div id='divChartLinee4' className="row">                 
                <ChartComponent id='ChartIncarichiChiusiLinee' style={{ textAlign: "center" }}
                    className='col-xs-12 col-sm-12 col-lg-12 col-md-12'
                    ref={chart => {this.chartInstance6 = chart}}
                    primaryXAxis={{
                        valueType: 'DateTime',
                        labelFormat: 'MMMM',
                        intervalType: 'Months',
                        edgeLabelPlacement: 'Shift',
                        majorGridLines: { width: 0 }                
                    }}
                    primaryYAxis={{
                        labelFormat: '{value}',
                        rangePadding: 'None',                                
                        interval: 100,
                        lineStyle: { width: 0 },                                
                        minimum: 0,
                        majorTickLines: { width: 0 },
                        minorTickLines: { width: 0 }
                    }}
                    chartArea={{ border: { width: 0 } }}
                    tooltip={{ enable: true }}
                    width={CustomJS.chartWidth}
                    title='' loaded={this.operazioniDopoCarica.bind(this)}
                    legendSettings={{ visible: true }}>
                    <Inject2 services={[LineSeries, DateTime, Legend, Tooltip, Trendlines]} />
                    <SeriesCollectionDirective>
                        <SeriesDirective xName='x' yName='y' name={`Incarichi Chiusi in FD ${(yyyy - 2).toString()}`}
                            width={3} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                        </SeriesDirective>
                        <SeriesDirective xName='x' yName='y' name={`Incarichi Chiusi in FD ${(yyyy - 1).toString()}`}
                            width={3} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                        </SeriesDirective>
                        <SeriesDirective xName='x' yName='y' name={`Incarichi Chiusi in FD ${yyyy.toString()}`}
                            width={3} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                        </SeriesDirective>
                    </SeriesCollectionDirective>
                </ChartComponent>  
            </div>
            <div id='divChartBarre4' className="row">                 
                <ChartComponent id='ChartIncarichiChiusiBarre' style={{ textAlign: "center" }} loaded={this.operazioniDopoCarica.bind(this)}
                    className='col-xs-12 col-sm-12 col-lg-12 col-md-12' ref={chart => {this.chartInstance8 = chart}}
                    primaryXAxis={{ valueType: 'Category', interval: 1, majorGridLines: { width: 0 } }} 
                    primaryYAxis={{ majorGridLines: { width: 0 }, majorTickLines: { width: 0 }, lineStyle: { width: 0 }, labelStyle: { color: 'transparent' } }}
                    chartArea={{ border: { width: 0 } }} tooltip={{ enable: true }} 
                    width={CustomJS.chartWidth} 
                    height={CustomJS.chartHeight} title=''
                    legendSettings={{ visible: true }}>
                    <Inject2 services={[ColumnSeries, Legend, Tooltip, Category, DataLabel]}/>
                    <SeriesCollectionDirective>
                        <SeriesDirective xName='x' yName='y' type='Column' name={`Incarichi Chiusi in FD ${(yyyy - 2).toString()}`} 
                             cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                        </SeriesDirective>
                        <SeriesDirective xName='x' yName='y' type='Column' name={`Incarichi Chiusi in FD ${(yyyy - 1).toString()}`}
                             cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                        </SeriesDirective>  
                        <SeriesDirective xName='x' yName='y' type='Column' name={`Incarichi Chiusi in FD ${yyyy.toString()}`}
                             cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                        </SeriesDirective>                       
                    </SeriesCollectionDirective>
                </ChartComponent>      
            </div>
            <div className="row" style={{ marginTop: '10px' }}>      
                <div className="col-xs-12 col-sm-12 col-lg-10 col-md-10">
                    <span style={{ fontSize: '1.2vw', fontWeight: 'bold', color:'#133B95'}}>SITUAZIONE MENSILE SINISTRI/INCARICHI CHIUSI</span>
                </div>  
            </div>       
            <div className='row'>
                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                    <GridComponent id="GridSinistriIncarichiChiusiMensili" ref={grid => this.gridInstance2 = grid} allowSelection={false} enableHover={false} 
                            allowPaging={false} queryCellInfo={this.onQueryCellInfo2.bind(this)} dataBound={this.onDatabound2.bind(this)} enableAltRow={false} 
                            allowTextWrap={true}>
                        <ColumnsDirective>
                            <ColumnDirective field='rowDescr' headerText='' width='8%' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='GEN' headerText='GENNAIO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='FEB' headerText='FEBBRAIO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='MAR' headerText='MARZO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='APR' headerText='APRILE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='MAG' headerText='MAGGIO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='GIU' headerText='GIUGNO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='LUG' headerText='LUGLIO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='AGO' headerText='AGOSTO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='SET' headerText='SETTEMBRE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='OTT' headerText='OTTOBRE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='NOV' headerText='NOVEMBRE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='DIC' headerText='DICEMBRE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='TOT' headerText='TOTALI' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                        </ColumnsDirective>                        
                    </GridComponent>
                </div>
            </div>
        </div>);
    }
    accordContent2Render() {
        return (<div>
                    <div className="row" style={{ marginTop: '30px' }}>      
                        <div className="col-xs-12 col-sm-12 col-lg-10 col-md-10">
                            <span style={{ fontSize: '1.3vw', fontWeight: 'bold', color:'#133B95'}}>SITUAZIONE GENERALE PERITI</span><span style={{ fontSize: '0.8vw', fontWeight: 'bold', color:'#133B95'}}>&nbsp;&nbsp;(sinistri pervenuti dal {moment(dataInizioMesePrec).format('DD/MM/YYYY')} ad oggi)</span>
                        </div>  
                    </div>  
                    <div className="row" style={{ marginTop: '5px' }}>
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                            <GridComponent id="GridSituazionePeritiMensile" ref={grid => this.gridInstance3 = grid} allowSelection={false} enableHover={false} 
                                    allowPaging={true} enableAltRow={true} pageSettings={{ pageCount: 5, pageSize: 10, pageSizes: ['10', '50', 'Tutti'] }} rowDataBound={this.onRowDatabound.bind(this)}
                                    toolbar={this.toolbarOptions} allowTextWrap={true} allowExcelExport={true} dataBound={this.onDatabound3.bind(this)} toolbarClick={this.onToolbarClick.bind(this)}>
                            <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Page]}/>                    
                            </GridComponent>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: '30px' }}>      
                        <div className="col-xs-12 col-sm-12 col-lg-10 col-md-10">
                            <span style={{ fontSize: '1.3vw', fontWeight: 'bold', color:'#133B95'}}>SITUAZIONE SQUADRE TEAM LEADER</span><span style={{ fontSize: '0.8vw', fontWeight: 'bold', color:'#133B95'}}>&nbsp;&nbsp;(sinistri pervenuti dal {moment(dataInizioMesePrec).format('DD/MM/YYYY')} ad oggi)</span>
                        </div>  
                    </div>  
                    <div className="row" style={{ marginTop: '5px' }}>
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                            <GridComponent id="GridSquadreTeamLeaderMensile" ref={grid => this.gridInstance4 = grid} toolbar={this.toolbarOptions} childGrid={this.childGrid} allowSelection={false} enableHover={false} allowTextWrap={true} 
                                allowPaging={false} allowSorting={false} allowFiltering={false} allowExcelExport={true} allowPdfExport={true} pageSettings={{ pageCount: 5, pageSize: 10, pageSizes: ['10', '50', 'Tutti'] }}
                                dataBound={this.onDatabound4.bind(this)} toolbarClick={this.onToolbarClick2.bind(this)} enableAltRow={true} gridLines='Both' hierarchyPrintMode='All'>
                                    <ColumnsDirective>
                                        <ColumnDirective field='NomeSquadraTeamLeader' headerText='Nome Squadra e Team Leader' headerTemplate={headerTemplate2} template={fieldTemplate3}></ColumnDirective>
                                    </ColumnsDirective>
                                <Inject services={[Toolbar, DetailRow, ExcelExport, PdfExport, Filter, Sort, Page]}/>                    
                            </GridComponent>  
                        </div>
                    </div>
            </div>);   
    }
    accordContent3Render() {
        return (<div>
                    <div className="row" style={{ marginTop: '30px' }}>      
                        <div className="col-xs-12 col-sm-12 col-lg-10 col-md-10">
                            <span style={{ fontSize: '1.3vw', fontWeight: 'bold', color:'#133B95'}}>SITUAZIONE GENERALE PERITI</span><span style={{ fontSize: '0.8vw', fontWeight: 'bold', color:'#133B95'}}>&nbsp;&nbsp;(sinistri pervenuti dal {moment(dataInizio3MesiPrec).format('DD/MM/YYYY')} ad oggi)</span>
                        </div>  
                    </div>  
                    <div className="row" style={{ marginTop: '5px' }}>
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                            <GridComponent id="GridSituazionePeritiTrimestrale" ref={grid => this.gridInstance5 = grid} allowSelection={false} enableHover={false} 
                                    allowPaging={true} enableAltRow={true} pageSettings={{ pageCount: 5, pageSize: 10, pageSizes: ['10', '50', 'Tutti'] }} rowDataBound={this.onRowDatabound.bind(this)}
                                    toolbar={this.toolbarOptions} allowTextWrap={true} allowExcelExport={true} dataBound={this.onDatabound6.bind(this)} toolbarClick={this.onToolbarClick.bind(this)}>
                            <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Page]}/>                    
                            </GridComponent>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: '30px' }}>      
                        <div className="col-xs-12 col-sm-12 col-lg-10 col-md-10">
                            <span style={{ fontSize: '1.3vw', fontWeight: 'bold', color:'#133B95'}}>SITUAZIONE SQUADRE TEAM LEADER</span><span style={{ fontSize: '0.8vw', fontWeight: 'bold', color:'#133B95'}}>&nbsp;&nbsp;(sinistri pervenuti dal {moment(dataInizio3MesiPrec).format('DD/MM/YYYY')} ad oggi)</span>
                        </div>  
                    </div>  
                    <div className="row" style={{ marginTop: '5px' }}>
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                            <GridComponent id="GridSquadreTeamLeaderTrimestrale" ref={grid => this.gridInstance6 = grid} toolbar={this.toolbarOptions} childGrid={this.childGrid} allowSelection={false} enableHover={false} allowTextWrap={true} 
                                allowPaging={false} allowSorting={false} allowFiltering={false} allowExcelExport={true} allowPdfExport={true} pageSettings={{ pageCount: 5, pageSize: 10, pageSizes: ['10', '50', 'Tutti'] }}
                                dataBound={this.onDatabound7.bind(this)} toolbarClick={this.onToolbarClick2.bind(this)} enableAltRow={true} gridLines='Both' hierarchyPrintMode='All'>
                                    <ColumnsDirective>
                                        <ColumnDirective field='NomeSquadraTeamLeader' headerText='Nome Squadra e Team Leader' headerTemplate={headerTemplate2} template={fieldTemplate3}></ColumnDirective>
                                    </ColumnsDirective>
                                <Inject services={[Toolbar, DetailRow, ExcelExport, PdfExport, Filter, Sort, Page]}/>                    
                            </GridComponent>  
                        </div>
                    </div>
            </div>);   
    }
    accordContent0() {
        return (<React.Fragment>
                    <div id='contenutoDinamicoFormaDirettaAccordion0'></div>
                </React.Fragment>);
    }
    accordContent1() {
        return (<React.Fragment>
                    <div id='contenutoDinamicoFormaDirettaAccordion1'></div>
                </React.Fragment>);
    }
    accordContent2() {
        return (<React.Fragment>
                    <div id='contenutoDinamicoFormaDirettaAccordion2'></div>
        </React.Fragment>);
    }
    accordContent3() {
        return (<React.Fragment>
                    <div id='contenutoDinamicoFormaDirettaAccordion3'></div>
        </React.Fragment>);
    }
    render() { 
        return (<React.Fragment> 
                    <BlockUi tag='div' blocking={this.state.blocking1} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}> 
                        <div className='container-fluid'>
                            <div className='row' style={{ marginTop: '30px' }}>
                                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                    <AccordionComponent id='accordionFormaDiretta' expandMode='Multiple' ref={accord => this.accordInstance = accord}>
                                        <AccordionItemsDirective>
                                            <AccordionItemDirective header={this.accordHeader[0]} expanded={true} content={this.accordContent0} iconCss="e-imageAccordion" />
                                            <AccordionItemDirective header={this.accordHeader[1]} expanded={true} content={this.accordContent1} iconCss="e-imageAccordion" />
                                            <AccordionItemDirective header={this.accordHeader[2]} expanded={true} content={this.accordContent2} iconCss="e-imageAccordion" />
                                            <AccordionItemDirective header={this.accordHeader[3]} expanded={true} content={this.accordContent3} iconCss="e-imageAccordion" />
                                        </AccordionItemsDirective>
                                    </AccordionComponent> 
                                </div>                                                                                              
                            </div> 
                        </div>
                    </BlockUi>
                </React.Fragment>);
    } 
    rendereComplete() {   
        this.toggleBlocking();
        this.caricaDati();
    }
    caricaDati() {

        let carica1 = () => {
            return new Promise((resolve, reject) => {
                CustomJS.callAPI(`${CustomJS.APIMonitorURLProd}/api/Monitor/leggiSinistriIncarichiFDTotali/${dataInizio2AnniPrec}/${dataCorrente}`, '', 'GET', '', retData => {                    
                    resolve(retData);
                }, error => {
                    reject(error);
                }, true, false);  
            });
        };
 
        let carica2 = () => {
            return new Promise((resolve, reject) => {
                CustomJS.callAPI(`${CustomJS.APIMonitorURLProd}/api/Monitor/leggiSinistriIncarichiFDChiusi/${dataInizio2AnniPrec}/${dataCorrente}`, '', 'GET', '', retData2 => {                    
                    resolve(retData2);
                }, error => {
                    reject(error);
                }, true, false);  
            });
        };

        let carica3 = () => {
            return new Promise((resolve, reject) => {
                CustomJS.callAPI(`${CustomJS.APIMonitorURLProd}/api/Monitor/leggiSituazionePeritiSquadreTL/${dataInizioMesePrec}/${dataCorrente}`, '', 'GET', '', retData3 => { 
                    resolve(retData3);
                }, error => {
                    reject(error);
                }, true, false);  
            });
        };

        let carica4 = () => {
            return new Promise((resolve, reject) => {
                CustomJS.callAPI(`${CustomJS.APIMonitorURLProd}/api/Monitor/leggiSituazionePeritiSquadreTL/${dataInizio3MesiPrec}/${dataCorrente}`, '', 'GET', '', retData4 => { 
                    resolve(retData4);
                }, error => {
                    reject(error);
                }, true, false);  
            });
        };

        carica1().then(retData1 => { 
            let data1 = [], data2 = [], data3 = [], data4 = [], data5 = [], data6 = [], data7 = [], data8 = [], data9 = [], data10 = [], data11 = [], data12 = [],
            GridData1 = [], groupedArr1 = [];
            groupedArr1 = _.groupBy(retData1, 'periodo');
            for (let ind=1; ind<=12;ind++) {  
                let MONTH1 = _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + ind.toString().padStart(2,'0') }))});
                data1.push({ x: ind === 1 ? new Date(yyyy, 0, 1) : ind === 2 ? new Date(yyyy, 1, 1)  : ind === 3 ? new Date(yyyy, 2, 1)  : ind === 4 ? new Date(yyyy, 3, 1)
                            : ind === 5 ? new Date(yyyy, 4, 1)  : ind === 6 ? new Date(yyyy, 5, 1) : ind === 7 ? new Date(yyyy, 6, 1)  : ind === 8 ? new Date(yyyy, 7, 1)  
                            : ind === 9 ? new Date(yyyy, 8, 1)  : ind === 10 ? new Date(yyyy, 9, 1)  : ind === 11 ? new Date(yyyy, 10, 1)  : new Date(yyyy, 11, 1) , y: _.uniq(MONTH1[0], elm => elm.id_sinistro).length }); 
                data4.push({ x: ind === 1 ? 'Gennaio' : ind === 2 ? 'Febbraio' : ind === 3 ? 'Marzo' : ind === 4 ? 'Aprile' : ind === 5 ? 'Maggio' : ind === 6 ? 'Giugno' 
                            : ind === 7 ? 'Luglio' : ind === 8 ? 'Agosto' : ind === 9 ? 'Settembre' : ind === 10 ? 'Ottobre' : ind === 11 ? 'Novembre' : 'Dicembre', y: _.uniq(MONTH1[0], elm => elm.id_sinistro).length }); 
                let MONTH2 = _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + ind.toString().padStart(2,'0') }))});
                data2.push({ x: ind === 1 ? new Date(yyyy, 0, 1) : ind === 2 ? new Date(yyyy, 1, 1)  : ind === 3 ? new Date(yyyy, 2, 1)  : ind === 4 ? new Date(yyyy, 3, 1)
                            : ind === 5 ? new Date(yyyy, 4, 1)  : ind === 6 ? new Date(yyyy, 5, 1) : ind === 7 ? new Date(yyyy, 6, 1)  : ind === 8 ? new Date(yyyy, 7, 1)  
                            : ind === 9 ? new Date(yyyy, 8, 1)  : ind === 10 ? new Date(yyyy, 9, 1)  : ind === 11 ? new Date(yyyy, 10, 1)  : new Date(yyyy, 11, 1) , y: _.uniq(MONTH2[0], elm => elm.id_sinistro).length }); 
                data5.push({ x: ind === 1 ? 'Gennaio' : ind === 2 ? 'Febbraio' : ind === 3 ? 'Marzo' : ind === 4 ? 'Aprile' : ind === 5 ? 'Maggio' : ind === 6 ? 'Giugno' 
                            : ind === 7 ? 'Luglio' : ind === 8 ? 'Agosto' : ind === 9 ? 'Settembre' : ind === 10 ? 'Ottobre' : ind === 11 ? 'Novembre' : 'Dicembre', y: _.uniq(MONTH2[0], elm => elm.id_sinistro).length }); 
                let MONTH3 = _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + ind.toString().padStart(2,'0') }))});
                data3.push({ x: ind === 1 ? new Date(yyyy, 0, 1) : ind === 2 ? new Date(yyyy, 1, 1)  : ind === 3 ? new Date(yyyy, 2, 1)  : ind === 4 ? new Date(yyyy, 3, 1)
                            : ind === 5 ? new Date(yyyy, 4, 1)  : ind === 6 ? new Date(yyyy, 5, 1) : ind === 7 ? new Date(yyyy, 6, 1)  : ind === 8 ? new Date(yyyy, 7, 1)  
                            : ind === 9 ? new Date(yyyy, 8, 1)  : ind === 10 ? new Date(yyyy, 9, 1)  : ind === 11 ? new Date(yyyy, 10, 1)  : new Date(yyyy, 11, 1) , y: _.uniq(MONTH3[0], elm => elm.id_sinistro).length }); 
                data6.push({ x: ind === 1 ? 'Gennaio' : ind === 2 ? 'Febbraio' : ind === 3 ? 'Marzo' : ind === 4 ? 'Aprile' : ind === 5 ? 'Maggio' : ind === 6 ? 'Giugno' 
                            : ind === 7 ? 'Luglio' : ind === 8 ? 'Agosto' : ind === 9 ? 'Settembre' : ind === 10 ? 'Ottobre' : ind === 11 ? 'Novembre' : 'Dicembre', y: _.uniq(MONTH3[0], elm => elm.id_sinistro).length }); 
                let MONTH4 = _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + ind.toString().padStart(2,'0') }))});
                data7.push({ x: ind === 1 ? new Date(yyyy, 0, 1) : ind === 2 ? new Date(yyyy, 1, 1)  : ind === 3 ? new Date(yyyy, 2, 1)  : ind === 4 ? new Date(yyyy, 3, 1)
                            : ind === 5 ? new Date(yyyy, 4, 1)  : ind === 6 ? new Date(yyyy, 5, 1) : ind === 7 ? new Date(yyyy, 6, 1)  : ind === 8 ? new Date(yyyy, 7, 1)  
                            : ind === 9 ? new Date(yyyy, 8, 1)  : ind === 10 ? new Date(yyyy, 9, 1)  : ind === 11 ? new Date(yyyy, 10, 1)  : new Date(yyyy, 11, 1) , y: MONTH4[0]?.length }); 
                data10.push({ x: ind === 1 ? 'Gennaio' : ind === 2 ? 'Febbraio' : ind === 3 ? 'Marzo' : ind === 4 ? 'Aprile' : ind === 5 ? 'Maggio' : ind === 6 ? 'Giugno' 
                            : ind === 7 ? 'Luglio' : ind === 8 ? 'Agosto' : ind === 9 ? 'Settembre' : ind === 10 ? 'Ottobre' : ind === 11 ? 'Novembre' : 'Dicembre', y: MONTH4[0]?.length }); 
                let MONTH5 = _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + ind.toString().padStart(2,'0') }))});
                data8.push({ x: ind === 1 ? new Date(yyyy, 0, 1) : ind === 2 ? new Date(yyyy, 1, 1)  : ind === 3 ? new Date(yyyy, 2, 1)  : ind === 4 ? new Date(yyyy, 3, 1)
                            : ind === 5 ? new Date(yyyy, 4, 1)  : ind === 6 ? new Date(yyyy, 5, 1) : ind === 7 ? new Date(yyyy, 6, 1)  : ind === 8 ? new Date(yyyy, 7, 1)  
                            : ind === 9 ? new Date(yyyy, 8, 1)  : ind === 10 ? new Date(yyyy, 9, 1)  : ind === 11 ? new Date(yyyy, 10, 1)  : new Date(yyyy, 11, 1) , y: MONTH5[0]?.length }); 
                data11.push({ x: ind === 1 ? 'Gennaio' : ind === 2 ? 'Febbraio' : ind === 3 ? 'Marzo' : ind === 4 ? 'Aprile' : ind === 5 ? 'Maggio' : ind === 6 ? 'Giugno' 
                            : ind === 7 ? 'Luglio' : ind === 8 ? 'Agosto' : ind === 9 ? 'Settembre' : ind === 10 ? 'Ottobre' : ind === 11 ? 'Novembre' : 'Dicembre', y: MONTH5[0]?.length }); 
                let MONTH6 = _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + ind.toString().padStart(2,'0') }))});
                data9.push({ x: ind === 1 ? new Date(yyyy, 0, 1) : ind === 2 ? new Date(yyyy, 1, 1)  : ind === 3 ? new Date(yyyy, 2, 1)  : ind === 4 ? new Date(yyyy, 3, 1)
                            : ind === 5 ? new Date(yyyy, 4, 1)  : ind === 6 ? new Date(yyyy, 5, 1) : ind === 7 ? new Date(yyyy, 6, 1)  : ind === 8 ? new Date(yyyy, 7, 1)  
                            : ind === 9 ? new Date(yyyy, 8, 1)  : ind === 10 ? new Date(yyyy, 9, 1)  : ind === 11 ? new Date(yyyy, 10, 1)  : new Date(yyyy, 11, 1) , y: MONTH6[0]?.length }); 
                data12.push({ x: ind === 1 ? 'Gennaio' : ind === 2 ? 'Febbraio' : ind === 3 ? 'Marzo' : ind === 4 ? 'Aprile' : ind === 5 ? 'Maggio' : ind === 6 ? 'Giugno' 
                            : ind === 7 ? 'Luglio' : ind === 8 ? 'Agosto' : ind === 9 ? 'Settembre' : ind === 10 ? 'Ottobre' : ind === 11 ? 'Novembre' : 'Dicembre', y: MONTH6[0]?.length }); 
            } 
            let totaleSinistriGestiti = (anno, mese) => { let totale = 0; for (let ind=1; ind<=mese;ind++) { totale += _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === anno.toString() + ind.toString().padStart(2,'0') }))})[0] , elm => elm.id_sinistro).length; } return totale; };
            let totaleIncarichiGestiti = (anno, mese) => { let totale = 0; for (let ind=1; ind<=mese;ind++) { totale += CustomJS.isNullEmpty(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === anno.toString() + ind.toString().padStart(2,'0') }))})[0])? 0 :
                                                                                                                _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === anno.toString() + ind.toString().padStart(2,'0') }))})[0].length; } return totale; };
            GridData1.push({ rowDescr: `Sinistri Gestiti FD ${(yyyy-2).toString()}`,
                            GEN: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '01' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                            FEB: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '02' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                            MAR: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '03' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                            APR: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '04' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                            MAG: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '05' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                            GIU: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '06' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                            LUG: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '07' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                            AGO: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '08' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                            SET: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '09' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                            OTT: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '10' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                            NOV: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '11' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                            DIC: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '12' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                            TOT: totaleSinistriGestiti(yyyy-2, 12).toLocaleString() });  
            GridData1.push({ rowDescr: `Sinistri Gestiti FD ${(yyyy-1).toString()}`,
                            GEN: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '01' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                            FEB: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '02' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                            MAR: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '03' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                            APR: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '04' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                            MAG: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '05' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                            GIU: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '06' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                            LUG: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '07' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                            AGO: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '08' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                            SET: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '09' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                            OTT: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '10' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                            NOV: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '11' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                            DIC: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '12' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                            TOT: totaleSinistriGestiti(yyyy-1, 12).toLocaleString() });   
            GridData1.push({ rowDescr: `Sinistri Gestiti FD ${yyyy.toString()}`,
                            GEN: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '01' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                            FEB: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '02' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                            MAR: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '03' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                            APR: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '04' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                            MAG: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '05' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                            GIU: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '06' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                            LUG: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '07' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                            AGO: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '08' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                            SET: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '09' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                            OTT: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '10' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                            NOV: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '11' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                            DIC: _.uniq(_.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '12' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                            TOT: totaleSinistriGestiti(yyyy, 12).toLocaleString() });   
            GridData1.push({ rowDescr: `Incarichi Gestiti FD ${(yyyy-2).toString()}`,
                            GEN: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '01' }))})[0]?.length.toLocaleString('it-IT'), 
                            FEB: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '02' }))})[0]?.length.toLocaleString('it-IT'), 
                            MAR: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '03' }))})[0]?.length.toLocaleString('it-IT'),
                            APR: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '04' }))})[0]?.length.toLocaleString('it-IT'), 
                            MAG: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '05' }))})[0]?.length.toLocaleString('it-IT'),
                            GIU: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '06' }))})[0]?.length.toLocaleString('it-IT'), 
                            LUG: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '07' }))})[0]?.length.toLocaleString('it-IT'),
                            AGO: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '08' }))})[0]?.length.toLocaleString('it-IT'),
                            SET: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '09' }))})[0]?.length.toLocaleString('it-IT'),
                            OTT: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '10' }))})[0]?.length.toLocaleString('it-IT'),
                            NOV: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '11' }))})[0]?.length.toLocaleString('it-IT'), 
                            DIC: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '12' }))})[0]?.length.toLocaleString('it-IT'), 
                            TOT: totaleIncarichiGestiti(yyyy-2, 12).toLocaleString() });  
            GridData1.push({ rowDescr: `Incarichi Gestiti FD ${(yyyy-1).toString()}`,
                            GEN: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '01' }))})[0]?.length.toLocaleString('it-IT'), 
                            FEB: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '02' }))})[0]?.length.toLocaleString('it-IT'), 
                            MAR: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '03' }))})[0]?.length.toLocaleString('it-IT'),
                            APR: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '04' }))})[0]?.length.toLocaleString('it-IT'), 
                            MAG: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '05' }))})[0]?.length.toLocaleString('it-IT'),
                            GIU: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '06' }))})[0]?.length.toLocaleString('it-IT'), 
                            LUG: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '07' }))})[0]?.length.toLocaleString('it-IT'),
                            AGO: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '08' }))})[0]?.length.toLocaleString('it-IT'),
                            SET: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '09' }))})[0]?.length.toLocaleString('it-IT'),
                            OTT: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '10' }))})[0]?.length.toLocaleString('it-IT'),
                            NOV: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '11' }))})[0]?.length.toLocaleString('it-IT'), 
                            DIC: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '12' }))})[0]?.length.toLocaleString('it-IT'), 
                            TOT: totaleIncarichiGestiti(yyyy-1, 12).toLocaleString() });  
            GridData1.push({ rowDescr: `Incarichi Gestiti FD ${yyyy.toString()}`,
                            GEN: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '01' }))})[0]?.length.toLocaleString('it-IT'), 
                            FEB: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '02' }))})[0]?.length.toLocaleString('it-IT'), 
                            MAR: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '03' }))})[0]?.length.toLocaleString('it-IT'),
                            APR: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '04' }))})[0]?.length.toLocaleString('it-IT'), 
                            MAG: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '05' }))})[0]?.length.toLocaleString('it-IT'),
                            GIU: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '06' }))})[0]?.length.toLocaleString('it-IT'), 
                            LUG: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '07' }))})[0]?.length.toLocaleString('it-IT'),
                            AGO: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '08' }))})[0]?.length.toLocaleString('it-IT'),
                            SET: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '09' }))})[0]?.length.toLocaleString('it-IT'),
                            OTT: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '10' }))})[0]?.length.toLocaleString('it-IT'),
                            NOV: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '11' }))})[0]?.length.toLocaleString('it-IT'), 
                            DIC: _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '12' }))})[0]?.length.toLocaleString('it-IT'), 
                            TOT: totaleIncarichiGestiti(yyyy, 12).toLocaleString() }); 
            GridData1.push({ rowDescr: `Delta % Sinistri Gestiti ${yyyy.toString()}/${(yyyy-1).toString()}`, TOT: `${parseFloat(parseFloat(((totaleSinistriGestiti(yyyy, mm) - totaleSinistriGestiti(yyyy-1, mm)) / totaleSinistriGestiti(yyyy-1, mm)) * 100).toFixed(2)).toLocaleString()}%` });
            GridData1.push({ rowDescr: `Delta % Sinistri Gestiti ${yyyy.toString()}/${(yyyy-2).toString()}`, TOT: `${parseFloat(parseFloat(((totaleSinistriGestiti(yyyy, mm) - totaleSinistriGestiti(yyyy-2, mm)) / totaleSinistriGestiti(yyyy-2, mm)) * 100).toFixed(2)).toLocaleString()}%` });
            GridData1.push({ rowDescr: `Delta % Incarichi Gestiti ${yyyy.toString()}/${(yyyy-1).toString()}`, TOT: `${parseFloat(parseFloat(((totaleIncarichiGestiti(yyyy, mm) - totaleIncarichiGestiti(yyyy-1, mm)) / totaleIncarichiGestiti(yyyy-1, mm)) * 100).toFixed(2)).toLocaleString()}%` });
            GridData1.push({ rowDescr: `Delta % Incarichi Gestiti ${yyyy.toString()}/${(yyyy-2).toString()}`, TOT: `${parseFloat(parseFloat(((totaleIncarichiGestiti(yyyy, mm) - totaleIncarichiGestiti(yyyy-2, mm)) / totaleIncarichiGestiti(yyyy-2, mm)) * 100).toFixed(2)).toLocaleString()}%` });
            
            const containerFormaDirettaAccord0 = document.getElementById('contenutoDinamicoFormaDirettaAccordion0');
            const rootFormaDirettaAccord0 = createRoot(containerFormaDirettaAccord0);
            rootFormaDirettaAccord0.render(<CallbackWrapper callback={() => {
                this.chartInstance1.series[0].dataSource = data1;
                this.chartInstance1.series[1].dataSource = data2; 
                this.chartInstance1.series[2].dataSource = data3; 
                this.chartInstance1.series[0].fill = materialColors[4 % 10];
                this.chartInstance1.series[1].fill = materialColors[5 % 10];
                this.chartInstance1.series[2].fill = materialColors[6 % 10];
                this.chartInstance2.series[0].dataSource = data7;
                this.chartInstance2.series[1].dataSource = data8; 
                this.chartInstance2.series[2].dataSource = data9; 
                this.chartInstance2.series[0].fill = materialColors[4 % 10];
                this.chartInstance2.series[1].fill = materialColors[5 % 10];
                this.chartInstance2.series[2].fill = materialColors[6 % 10];
                this.chartInstance3.series[0].dataSource = data4;
                this.chartInstance3.series[1].dataSource = data5; 
                this.chartInstance3.series[2].dataSource = data6; 
                this.chartInstance3.series[0].fill = materialColors[4 % 10];
                this.chartInstance3.series[1].fill = materialColors[5 % 10];
                this.chartInstance3.series[2].fill = materialColors[6 % 10];
                this.chartInstance4.series[0].dataSource = data10;
                this.chartInstance4.series[1].dataSource = data11; 
                this.chartInstance4.series[2].dataSource = data12; 
                this.chartInstance4.series[0].fill = materialColors[4 % 10];
                this.chartInstance4.series[1].fill = materialColors[5 % 10];
                this.chartInstance4.series[2].fill = materialColors[6 % 10];
                document.getElementById('divChartBarre1').style.display = 'none';
                document.getElementById('divChartBarre2').style.display = 'none';
                this.gridInstance1.dataSource = GridData1;
            }}>{this.accordContent0Render()}</CallbackWrapper>);

            carica2().then(retData2 => { 
                let data13 = [], data14 = [], data15 = [], data16 = [], data17 = [], data18 = [], data19 = [], data20 = [], data21 = [], data22 = [], data23 = [], data24 = [],
                GridData2 = [], groupedArr2 = [];
                groupedArr2 = _.groupBy(retData2, 'periodo');
                for (let ind=1; ind<=12;ind++) {  
                    let MONTH1 = _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + ind.toString().padStart(2,'0') }))});
                    data13.push({ x: ind === 1 ? new Date(yyyy, 0, 1) : ind === 2 ? new Date(yyyy, 1, 1)  : ind === 3 ? new Date(yyyy, 2, 1)  : ind === 4 ? new Date(yyyy, 3, 1)
                                : ind === 5 ? new Date(yyyy, 4, 1)  : ind === 6 ? new Date(yyyy, 5, 1) : ind === 7 ? new Date(yyyy, 6, 1)  : ind === 8 ? new Date(yyyy, 7, 1)  
                                : ind === 9 ? new Date(yyyy, 8, 1)  : ind === 10 ? new Date(yyyy, 9, 1)  : ind === 11 ? new Date(yyyy, 10, 1)  : new Date(yyyy, 11, 1) , y: _.uniq(MONTH1[0], elm => elm.id_sinistro).length }); 
                    data16.push({ x: ind === 1 ? 'Gennaio' : ind === 2 ? 'Febbraio' : ind === 3 ? 'Marzo' : ind === 4 ? 'Aprile' : ind === 5 ? 'Maggio' : ind === 6 ? 'Giugno' 
                                : ind === 7 ? 'Luglio' : ind === 8 ? 'Agosto' : ind === 9 ? 'Settembre' : ind === 10 ? 'Ottobre' : ind === 11 ? 'Novembre' : 'Dicembre', y: _.uniq(MONTH1[0], elm => elm.id_sinistro).length }); 
                    let MONTH2 = _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + ind.toString().padStart(2,'0') }))});
                    data14.push({ x: ind === 1 ? new Date(yyyy, 0, 1) : ind === 2 ? new Date(yyyy, 1, 1)  : ind === 3 ? new Date(yyyy, 2, 1)  : ind === 4 ? new Date(yyyy, 3, 1)
                                : ind === 5 ? new Date(yyyy, 4, 1)  : ind === 6 ? new Date(yyyy, 5, 1) : ind === 7 ? new Date(yyyy, 6, 1)  : ind === 8 ? new Date(yyyy, 7, 1)  
                                : ind === 9 ? new Date(yyyy, 8, 1)  : ind === 10 ? new Date(yyyy, 9, 1)  : ind === 11 ? new Date(yyyy, 10, 1)  : new Date(yyyy, 11, 1) , y: _.uniq(MONTH2[0], elm => elm.id_sinistro).length }); 
                    data17.push({ x: ind === 1 ? 'Gennaio' : ind === 2 ? 'Febbraio' : ind === 3 ? 'Marzo' : ind === 4 ? 'Aprile' : ind === 5 ? 'Maggio' : ind === 6 ? 'Giugno' 
                                : ind === 7 ? 'Luglio' : ind === 8 ? 'Agosto' : ind === 9 ? 'Settembre' : ind === 10 ? 'Ottobre' : ind === 11 ? 'Novembre' : 'Dicembre', y: _.uniq(MONTH2[0], elm => elm.id_sinistro).length }); 
                    let MONTH3 = _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + ind.toString().padStart(2,'0') }))});
                    data15.push({ x: ind === 1 ? new Date(yyyy, 0, 1) : ind === 2 ? new Date(yyyy, 1, 1)  : ind === 3 ? new Date(yyyy, 2, 1)  : ind === 4 ? new Date(yyyy, 3, 1)
                                : ind === 5 ? new Date(yyyy, 4, 1)  : ind === 6 ? new Date(yyyy, 5, 1) : ind === 7 ? new Date(yyyy, 6, 1)  : ind === 8 ? new Date(yyyy, 7, 1)  
                                : ind === 9 ? new Date(yyyy, 8, 1)  : ind === 10 ? new Date(yyyy, 9, 1)  : ind === 11 ? new Date(yyyy, 10, 1)  : new Date(yyyy, 11, 1) , y: _.uniq(MONTH3[0], elm => elm.id_sinistro).length }); 
                    data18.push({ x: ind === 1 ? 'Gennaio' : ind === 2 ? 'Febbraio' : ind === 3 ? 'Marzo' : ind === 4 ? 'Aprile' : ind === 5 ? 'Maggio' : ind === 6 ? 'Giugno' 
                                : ind === 7 ? 'Luglio' : ind === 8 ? 'Agosto' : ind === 9 ? 'Settembre' : ind === 10 ? 'Ottobre' : ind === 11 ? 'Novembre' : 'Dicembre', y: _.uniq(MONTH3[0], elm => elm.id_sinistro).length }); 
                    let MONTH4 = _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + ind.toString().padStart(2,'0') }))});
                    data19.push({ x: ind === 1 ? new Date(yyyy, 0, 1) : ind === 2 ? new Date(yyyy, 1, 1)  : ind === 3 ? new Date(yyyy, 2, 1)  : ind === 4 ? new Date(yyyy, 3, 1)
                                : ind === 5 ? new Date(yyyy, 4, 1)  : ind === 6 ? new Date(yyyy, 5, 1) : ind === 7 ? new Date(yyyy, 6, 1)  : ind === 8 ? new Date(yyyy, 7, 1)  
                                : ind === 9 ? new Date(yyyy, 8, 1)  : ind === 10 ? new Date(yyyy, 9, 1)  : ind === 11 ? new Date(yyyy, 10, 1)  : new Date(yyyy, 11, 1) , y: MONTH4[0]?.length }); 
                    data22.push({ x: ind === 1 ? 'Gennaio' : ind === 2 ? 'Febbraio' : ind === 3 ? 'Marzo' : ind === 4 ? 'Aprile' : ind === 5 ? 'Maggio' : ind === 6 ? 'Giugno' 
                                : ind === 7 ? 'Luglio' : ind === 8 ? 'Agosto' : ind === 9 ? 'Settembre' : ind === 10 ? 'Ottobre' : ind === 11 ? 'Novembre' : 'Dicembre', y: MONTH4[0]?.length }); 
                    let MONTH5 = _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + ind.toString().padStart(2,'0') }))});
                    data20.push({ x: ind === 1 ? new Date(yyyy, 0, 1) : ind === 2 ? new Date(yyyy, 1, 1)  : ind === 3 ? new Date(yyyy, 2, 1)  : ind === 4 ? new Date(yyyy, 3, 1)
                                : ind === 5 ? new Date(yyyy, 4, 1)  : ind === 6 ? new Date(yyyy, 5, 1) : ind === 7 ? new Date(yyyy, 6, 1)  : ind === 8 ? new Date(yyyy, 7, 1)  
                                : ind === 9 ? new Date(yyyy, 8, 1)  : ind === 10 ? new Date(yyyy, 9, 1)  : ind === 11 ? new Date(yyyy, 10, 1)  : new Date(yyyy, 11, 1) , y: MONTH5[0]?.length }); 
                    data23.push({ x: ind === 1 ? 'Gennaio' : ind === 2 ? 'Febbraio' : ind === 3 ? 'Marzo' : ind === 4 ? 'Aprile' : ind === 5 ? 'Maggio' : ind === 6 ? 'Giugno' 
                                : ind === 7 ? 'Luglio' : ind === 8 ? 'Agosto' : ind === 9 ? 'Settembre' : ind === 10 ? 'Ottobre' : ind === 11 ? 'Novembre' : 'Dicembre', y: MONTH5[0]?.length }); 
                    let MONTH6 = _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + ind.toString().padStart(2,'0') }))});
                    data21.push({ x: ind === 1 ? new Date(yyyy, 0, 1) : ind === 2 ? new Date(yyyy, 1, 1)  : ind === 3 ? new Date(yyyy, 2, 1)  : ind === 4 ? new Date(yyyy, 3, 1)
                                : ind === 5 ? new Date(yyyy, 4, 1)  : ind === 6 ? new Date(yyyy, 5, 1) : ind === 7 ? new Date(yyyy, 6, 1)  : ind === 8 ? new Date(yyyy, 7, 1)  
                                : ind === 9 ? new Date(yyyy, 8, 1)  : ind === 10 ? new Date(yyyy, 9, 1)  : ind === 11 ? new Date(yyyy, 10, 1)  : new Date(yyyy, 11, 1) , y: MONTH6[0]?.length }); 
                    data24.push({ x: ind === 1 ? 'Gennaio' : ind === 2 ? 'Febbraio' : ind === 3 ? 'Marzo' : ind === 4 ? 'Aprile' : ind === 5 ? 'Maggio' : ind === 6 ? 'Giugno' 
                                : ind === 7 ? 'Luglio' : ind === 8 ? 'Agosto' : ind === 9 ? 'Settembre' : ind === 10 ? 'Ottobre' : ind === 11 ? 'Novembre' : 'Dicembre', y: MONTH6[0]?.length }); 
                } 
                let totaleSinistriChiusi = (anno, mese) => { let totale = 0; for (let ind=1; ind<=mese;ind++) { totale += _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === anno.toString() + ind.toString().padStart(2,'0') }))})[0] , elm => elm.id_sinistro).length; } return totale; };
                let totaleIncarichiChiusi = (anno, mese) => { let totale = 0; for (let ind=1; ind<=mese;ind++) { totale += CustomJS.isNullEmpty(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === anno.toString() + ind.toString().padStart(2,'0') }))})[0])? 0 :
                                                                                                                    _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === anno.toString() + ind.toString().padStart(2,'0') }))})[0].length; } return totale; };
                GridData2.push({ rowDescr: `Sinistri Chiusi FD ${(yyyy-2).toString()}`,
                                GEN: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '01' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                                FEB: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '02' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                                MAR: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '03' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                                APR: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '04' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                                MAG: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '05' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                                GIU: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '06' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                                LUG: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '07' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                                AGO: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '08' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                                SET: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '09' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                                OTT: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '10' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                                NOV: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '11' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                                DIC: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '12' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                                TOT: totaleSinistriChiusi(yyyy-2, 12).toLocaleString() });  
                GridData2.push({ rowDescr: `Sinistri Chiusi FD ${(yyyy-1).toString()}`,
                                GEN: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '01' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                                FEB: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '02' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                                MAR: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '03' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                                APR: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '04' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                                MAG: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '05' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                                GIU: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '06' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                                LUG: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '07' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                                AGO: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '08' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                                SET: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '09' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                                OTT: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '10' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                                NOV: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '11' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                                DIC: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '12' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                                TOT: totaleSinistriChiusi(yyyy-1, 12).toLocaleString() });   
                GridData2.push({ rowDescr: `Sinistri Chiusi FD ${yyyy.toString()}`,
                                GEN: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '01' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                                FEB: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '02' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                                MAR: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '03' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                                APR: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '04' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                                MAG: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '05' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                                GIU: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '06' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                                LUG: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '07' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                                AGO: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '08' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                                SET: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '09' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                                OTT: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '10' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'),
                                NOV: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '11' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                                DIC: _.uniq(_.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '12' }))})[0] , elm => elm.id_sinistro).length.toLocaleString('it-IT'), 
                                TOT: totaleSinistriChiusi(yyyy, 12).toLocaleString() });   
                GridData2.push({ rowDescr: `Incarichi Chiusi FD ${(yyyy-2).toString()}`,
                                GEN: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '01' }))})[0]?.length.toLocaleString('it-IT'), 
                                FEB: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '02' }))})[0]?.length.toLocaleString('it-IT'), 
                                MAR: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '03' }))})[0]?.length.toLocaleString('it-IT'),
                                APR: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '04' }))})[0]?.length.toLocaleString('it-IT'), 
                                MAG: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '05' }))})[0]?.length.toLocaleString('it-IT'),
                                GIU: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '06' }))})[0]?.length.toLocaleString('it-IT'), 
                                LUG: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '07' }))})[0]?.length.toLocaleString('it-IT'),
                                AGO: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '08' }))})[0]?.length.toLocaleString('it-IT'),
                                SET: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '09' }))})[0]?.length.toLocaleString('it-IT'),
                                OTT: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '10' }))})[0]?.length.toLocaleString('it-IT'),
                                NOV: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '11' }))})[0]?.length.toLocaleString('it-IT'), 
                                DIC: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-2).toString() + '12' }))})[0]?.length.toLocaleString('it-IT'), 
                                TOT: totaleIncarichiChiusi(yyyy-2, 12).toLocaleString() });  
                GridData2.push({ rowDescr: `Incarichi Chiusi FD ${(yyyy-1).toString()}`,
                                GEN: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '01' }))})[0]?.length.toLocaleString('it-IT'), 
                                FEB: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '02' }))})[0]?.length.toLocaleString('it-IT'), 
                                MAR: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '03' }))})[0]?.length.toLocaleString('it-IT'),
                                APR: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '04' }))})[0]?.length.toLocaleString('it-IT'), 
                                MAG: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '05' }))})[0]?.length.toLocaleString('it-IT'),
                                GIU: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '06' }))})[0]?.length.toLocaleString('it-IT'), 
                                LUG: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '07' }))})[0]?.length.toLocaleString('it-IT'),
                                AGO: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '08' }))})[0]?.length.toLocaleString('it-IT'),
                                SET: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '09' }))})[0]?.length.toLocaleString('it-IT'),
                                OTT: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '10' }))})[0]?.length.toLocaleString('it-IT'),
                                NOV: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '11' }))})[0]?.length.toLocaleString('it-IT'), 
                                DIC: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + '12' }))})[0]?.length.toLocaleString('it-IT'), 
                                TOT: totaleIncarichiChiusi(yyyy-1, 12).toLocaleString() });  
                GridData2.push({ rowDescr: `Incarichi Chiusi FD ${yyyy.toString()}`,
                                GEN: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '01' }))})[0]?.length.toLocaleString('it-IT'), 
                                FEB: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '02' }))})[0]?.length.toLocaleString('it-IT'), 
                                MAR: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '03' }))})[0]?.length.toLocaleString('it-IT'),
                                APR: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '04' }))})[0]?.length.toLocaleString('it-IT'), 
                                MAG: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '05' }))})[0]?.length.toLocaleString('it-IT'),
                                GIU: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '06' }))})[0]?.length.toLocaleString('it-IT'), 
                                LUG: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '07' }))})[0]?.length.toLocaleString('it-IT'),
                                AGO: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '08' }))})[0]?.length.toLocaleString('it-IT'),
                                SET: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '09' }))})[0]?.length.toLocaleString('it-IT'),
                                OTT: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '10' }))})[0]?.length.toLocaleString('it-IT'),
                                NOV: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '11' }))})[0]?.length.toLocaleString('it-IT'), 
                                DIC: _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + '12' }))})[0]?.length.toLocaleString('it-IT'), 
                                TOT: totaleIncarichiChiusi(yyyy, 12).toLocaleString() }); 
                GridData2.push({ rowDescr: `Delta % Sinistri Chiusi ${yyyy.toString()}/${(yyyy-1).toString()}`, TOT: `${parseFloat(parseFloat(((totaleSinistriChiusi(yyyy, mm) - totaleSinistriChiusi(yyyy-1, mm)) / totaleSinistriChiusi(yyyy-1, mm)) * 100).toFixed(2)).toLocaleString()}%` });
                GridData2.push({ rowDescr: `Delta % Sinistri Chiusi ${yyyy.toString()}/${(yyyy-2).toString()}`, TOT: `${parseFloat(parseFloat(((totaleSinistriChiusi(yyyy, mm) - totaleSinistriChiusi(yyyy-2, mm)) / totaleSinistriChiusi(yyyy-2, mm)) * 100).toFixed(2)).toLocaleString()}%` });
                GridData2.push({ rowDescr: `Delta % Incarichi Chiusi ${yyyy.toString()}/${(yyyy-1).toString()}`, TOT: `${parseFloat(parseFloat(((totaleIncarichiChiusi(yyyy, mm) - totaleIncarichiChiusi(yyyy-1, mm)) / totaleIncarichiChiusi(yyyy-1, mm)) * 100).toFixed(2)).toLocaleString()}%` });
                GridData2.push({ rowDescr: `Delta % Incarichi Chiusi ${yyyy.toString()}/${(yyyy-2).toString()}`, TOT: `${parseFloat(parseFloat(((totaleIncarichiChiusi(yyyy, mm) - totaleIncarichiChiusi(yyyy-2, mm)) / totaleIncarichiChiusi(yyyy-2, mm)) * 100).toFixed(2)).toLocaleString()}%` });
    
                const containerFormaDirettaAccord1 = document.getElementById('contenutoDinamicoFormaDirettaAccordion1');
                const rootFormaDirettaAccord1 = createRoot(containerFormaDirettaAccord1);
                rootFormaDirettaAccord1.render(<CallbackWrapper callback={() => {
                    this.chartInstance5.series[0].dataSource = data13;
                    this.chartInstance5.series[1].dataSource = data14; 
                    this.chartInstance5.series[2].dataSource = data15; 
                    this.chartInstance5.series[0].fill = materialColors[4 % 10];
                    this.chartInstance5.series[1].fill = materialColors[5 % 10];
                    this.chartInstance5.series[2].fill = materialColors[6 % 10];
                    this.chartInstance6.series[0].dataSource = data19;
                    this.chartInstance6.series[1].dataSource = data20; 
                    this.chartInstance6.series[2].dataSource = data21; 
                    this.chartInstance6.series[0].fill = materialColors[4 % 10];
                    this.chartInstance6.series[1].fill = materialColors[5 % 10];
                    this.chartInstance6.series[2].fill = materialColors[6 % 10];
                    this.chartInstance7.series[0].dataSource = data16;
                    this.chartInstance7.series[1].dataSource = data17; 
                    this.chartInstance7.series[2].dataSource = data18; 
                    this.chartInstance7.series[0].fill = materialColors[4 % 10];
                    this.chartInstance7.series[1].fill = materialColors[5 % 10];
                    this.chartInstance7.series[2].fill = materialColors[6 % 10];
                    this.chartInstance8.series[0].dataSource = data22;
                    this.chartInstance8.series[1].dataSource = data23; 
                    this.chartInstance8.series[2].dataSource = data24; 
                    this.chartInstance8.series[0].fill = materialColors[4 % 10];
                    this.chartInstance8.series[1].fill = materialColors[5 % 10];
                    this.chartInstance8.series[2].fill = materialColors[6 % 10];
                    document.getElementById('divChartBarre3').style.display = 'none';
                    document.getElementById('divChartBarre4').style.display = 'none';
                    this.gridInstance2.dataSource = GridData2;
                }}>{this.accordContent1Render()}</CallbackWrapper>);    

                carica3().then(retData3 => { 
                    let GridData3 = [], GridData4 = [], GridData5 = [], groupedArr3 = [], dynaCols = []; 
                    dynaCols.push({ field: 'NominativoPerito', headerText: 'Perito', headerTemplate: headerTemplate2, template: fieldTemplate2, clipMode: 'EllipsisWithTooltip', width: '10%' });   
                    groupedArr3 = _.groupBy(retData3, 'id_compagnia');
                    Object.values(groupedArr3).forEach((elm, idx) => {
                        elm.forEach(elm3 => { 
                            if (elm3.gestFormaDiretta === 1 && (elm3.id_compagnia === 103 || elm3.id_compagnia === 131)) {
                                if (CustomJS.isNullEmpty(_.find(GridData3, elm2 => { return elm2.IdPerito === elm3.id_perito; }))) {
                                    GridData3.push({ IdPerito: elm3.id_perito, NominativoPerito: elm3.NomePerito, IdTeamLeader: `${elm3.id_team_leader?.toString()}${elm3.descr_squadra}`, NomeTeamLeader: elm3.NomeTeamLeader, DescrSquadra: elm3.descr_squadra });
                                    GridData3[GridData3.length-1][`pervenutiCompagnia${elm3.id_compagnia}`] = _.filter(_.uniq(elm, obj => obj.id_sinistro), obj2 => { return obj2.id_perito === elm3.id_perito; }).length.toLocaleString('it-IT');
                                    GridData3[GridData3.length-1][`gestibiliCompagnia${elm3.id_compagnia}`] = _.filter(_.uniq(elm, obj => obj.id_sinistro), obj2 => { return obj2.gestFormaDiretta === 1 && obj2.id_perito === elm3.id_perito; }).length.toLocaleString('it-IT');
                                    GridData3[GridData3.length-1][`gestitiCompagnia${elm3.id_compagnia}`] = _.filter(elm, obj => { return obj.gestFormaDiretta === 1 && obj.id_perito === elm3.id_perito && !CustomJS.isNullEmpty(obj.id_incarico); }).length.toLocaleString('it-IT');
                                    GridData3[GridData3.length-1][`percGestitiCompagnia${elm3.id_compagnia}`] = `${parseFloat(parseFloat((_.filter(elm, obj => { return obj.gestFormaDiretta === 1 && obj.id_perito === elm3.id_perito && !CustomJS.isNullEmpty(obj.id_incarico); }).length / _.filter(_.uniq(elm, obj => obj.id_sinistro), obj2 => { return obj2.gestFormaDiretta === 1 && obj2.id_perito === elm3.id_perito; }).length) * 100).toFixed(2)).toLocaleString()}%`;
                                    if (CustomJS.isNullEmpty(_.find(dynaCols, obj => { return !CustomJS.isNullEmpty(obj.columns) && obj.columns[0]?.field === `pervenutiCompagnia${elm3.id_compagnia}` })))
                                        dynaCols.push({ columns: [{ field: `pervenutiCompagnia${elm3.id_compagnia}`, headerText: 'Pervenuti', textAlign: 'Center', allowFiltering: false, template: fieldTemplate2, headerTemplate: headerTemplate2, headerTextAlign: 'Center', allowSorting: false },
                                                                  { field: `gestibiliCompagnia${elm3.id_compagnia}`, headerText: 'Gestibili', textAlign: 'Center', template: fieldTemplate2, allowFiltering: false, headerTemplate: headerTemplate2, headerTextAlign: 'Center' },
                                                                  { field: `gestitiCompagnia${elm3.id_compagnia}`, headerText: 'Gestiti', textAlign: 'Center', template: fieldTemplate2, allowFiltering: false, headerTemplate: headerTemplate2, headerTextAlign: 'Center' },
                                                                  { field: `percGestitiCompagnia${elm3.id_compagnia}`, headerText: 'Perc. Gestiti', textAlign: 'Center', template: fieldTemplate2, allowFiltering: false, headerTemplate: headerTemplate2, headerTextAlign: 'Center' }], headerText: elm3.compagnia, headerTemplate: headerTemplate2, template: fieldTemplate2, textAlign: 'Center' });   
                                }
                                else if (elm3.gestFormaDiretta === 1 && CustomJS.isNullEmpty(_.find(GridData3, elm2 => { return elm2.IdPerito === elm3.id_perito && !CustomJS.isNullEmpty(elm2[`pervenutiCompagnia${elm3.id_compagnia}`]) }))) {
                                    _.find(GridData3, elm2 => { return elm2.IdPerito === elm3.id_perito })[`pervenutiCompagnia${elm3.id_compagnia}`] = _.filter(_.uniq(elm, obj => obj.id_sinistro), obj2 => { return obj2.id_perito === elm3.id_perito; }).length.toLocaleString('it-IT');
                                    _.find(GridData3, elm2 => { return elm2.IdPerito === elm3.id_perito })[`gestibiliCompagnia${elm3.id_compagnia}`] = _.filter(_.uniq(elm, obj => obj.id_sinistro), obj2 => { return obj2.gestFormaDiretta === 1 && obj2.id_perito === elm3.id_perito; }).length.toLocaleString('it-IT');
                                    _.find(GridData3, elm2 => { return elm2.IdPerito === elm3.id_perito })[`gestitiCompagnia${elm3.id_compagnia}`] = _.filter(elm, obj => { return obj.gestFormaDiretta === 1 && obj.id_perito === elm3.id_perito && !CustomJS.isNullEmpty(obj.id_incarico); }).length.toLocaleString('it-IT');
                                    _.find(GridData3, elm2 => { return elm2.IdPerito === elm3.id_perito })[`percGestitiCompagnia${elm3.id_compagnia}`] = `${parseFloat(parseFloat((_.filter(elm, obj => { return obj.gestFormaDiretta === 1 && obj.id_perito === elm3.id_perito && !CustomJS.isNullEmpty(obj.id_incarico); }).length / _.filter(_.uniq(elm, obj => obj.id_sinistro), obj2 => { return obj2.gestFormaDiretta === 1 && obj2.id_perito === elm3.id_perito; }).length) * 100).toFixed(2)).toLocaleString()}%`;
                                    if (CustomJS.isNullEmpty(_.find(dynaCols, obj => { return !CustomJS.isNullEmpty(obj.columns) && obj.columns[0]?.field === `pervenutiCompagnia${elm3.id_compagnia}` })))
                                        dynaCols.push({ columns: [{ field: `pervenutiCompagnia${elm3.id_compagnia}`, headerText: 'Pervenuti', textAlign: 'Center', allowFiltering: false, template: fieldTemplate2, headerTemplate: headerTemplate2, headerTextAlign: 'Center', allowSorting: false },
                                                                  { field: `gestibiliCompagnia${elm3.id_compagnia}`, headerText: 'Gestibili', textAlign: 'Center', template: fieldTemplate2, allowFiltering: false, headerTemplate: headerTemplate2, headerTextAlign: 'Center' },
                                                                  { field: `gestitiCompagnia${elm3.id_compagnia}`, headerText: 'Gestiti', textAlign: 'Center', template: fieldTemplate2, allowFiltering: false, headerTemplate: headerTemplate2, headerTextAlign: 'Center' },
                                                                  { field: `percGestitiCompagnia${elm3.id_compagnia}`, headerText: 'Perc. Gestiti', textAlign: 'Center', template: fieldTemplate2, allowFiltering: false, headerTemplate: headerTemplate2, headerTextAlign: 'Center' }], headerText: elm3.compagnia, headerTemplate: headerTemplate2, template: fieldTemplate2, textAlign: 'Center' });   
                                }   
                            }
                        });
                    }); 
                    GridData3.push({ NominativoPerito: 'TOTALI' });
                    GridData3[GridData3.length-1][dynaCols[1].columns[0].field] = GridData3.sum2(null, 5).toLocaleString();
                    GridData3[GridData3.length-1][dynaCols[1].columns[1].field] = GridData3.sum2(null, 6).toLocaleString();
                    GridData3[GridData3.length-1][dynaCols[1].columns[2].field] = GridData3.sum2(null, 7).toLocaleString();
                    GridData3[GridData3.length-1][dynaCols[1].columns[3].field] = `${parseFloat(parseFloat((GridData3.sum2(null, 7) / GridData3.sum2(null, 6)) * 100).toFixed(2)).toLocaleString()}%`;
                    GridData3[GridData3.length-1][dynaCols[2].columns[0].field] = GridData3.sum2(null, 9).toLocaleString();
                    GridData3[GridData3.length-1][dynaCols[2].columns[1].field] = GridData3.sum2(null, 10).toLocaleString();
                    GridData3[GridData3.length-1][dynaCols[2].columns[2].field] = GridData3.sum2(null, 11).toLocaleString();
                    GridData3[GridData3.length-1][dynaCols[2].columns[3].field] = `${parseFloat(parseFloat((GridData3.sum2(null, 11) / GridData3.sum2(null, 10)) * 100).toFixed(2)).toLocaleString()}%`;
                    GridData4 = _.filter(_.uniq(GridData3, obj => obj.IdTeamLeader), obj2 => { return obj2.IdTeamLeader?.indexOf('undefined') === -1; })?.map(elm => { return { IdTeamLeader: elm.IdTeamLeader, NomeSquadraTeamLeader: `${elm.DescrSquadra} - ${elm.NomeTeamLeader}` } });
                    GridData5 = _.filter(GridData3, obj => { return !CustomJS.isNullEmpty(obj.IdTeamLeader); });

                    const containerFormaDirettaAccord2 = document.getElementById('contenutoDinamicoFormaDirettaAccordion2');
                    const rootFormaDirettaAccord2 = createRoot(containerFormaDirettaAccord2);
                    rootFormaDirettaAccord2.render(<CallbackWrapper callback={() => {
                        this.gridInstance3.columns = dynaCols; 
                        this.gridInstance3.refreshColumns();   
                        setTimeout(() => { this.gridInstance3.dataSource = GridData3 }, 300); 
                        this.gridInstance4.childGrid.columns = dynaCols; 
                        this.gridInstance4.refreshColumns();
                        setTimeout(() => { 
                            this.gridInstance4.dataSource = GridData4; 
                            this.gridInstance4.childGrid.dataSource = GridData5; 
                        }, 500);
                    }}>{this.accordContent2Render()}</CallbackWrapper>);    
    
                    carica4().then(retData4 => { 
                        let GridData6 = [], GridData7 = [], GridData8 = [], groupedArr4 = [], dynaCols2 = []; 
                        dynaCols2.push({ field: 'NominativoPerito', headerText: 'Perito', headerTemplate: headerTemplate2, template: fieldTemplate2, clipMode: 'EllipsisWithTooltip', width: '10%' });   
                        groupedArr4 = _.groupBy(retData4, 'id_compagnia');
                        Object.values(groupedArr4).forEach((elm, idx) => {
                            elm.forEach(elm3 => { 
                                if (elm3.gestFormaDiretta === 1 && (elm3.id_compagnia === 103 || elm3.id_compagnia === 131)) {
                                    if (CustomJS.isNullEmpty(_.find(GridData6, elm2 => { return elm2.IdPerito === elm3.id_perito; }))) {
                                        GridData6.push({ IdPerito: elm3.id_perito, NominativoPerito: elm3.NomePerito, IdTeamLeader: `${elm3.id_team_leader?.toString()}${elm3.descr_squadra}`, NomeTeamLeader: elm3.NomeTeamLeader, DescrSquadra: elm3.descr_squadra });
                                        GridData6[GridData6.length-1][`pervenutiCompagnia${elm3.id_compagnia}`] = _.filter(_.uniq(elm, obj => obj.id_sinistro), obj2 => { return obj2.id_perito === elm3.id_perito; }).length.toLocaleString('it-IT');
                                        GridData6[GridData6.length-1][`gestibiliCompagnia${elm3.id_compagnia}`] = _.filter(_.uniq(elm, obj => obj.id_sinistro), obj2 => { return obj2.gestFormaDiretta === 1 && obj2.id_perito === elm3.id_perito; }).length.toLocaleString('it-IT');
                                        GridData6[GridData6.length-1][`gestitiCompagnia${elm3.id_compagnia}`] = _.filter(elm, obj => { return obj.gestFormaDiretta === 1 && obj.id_perito === elm3.id_perito && !CustomJS.isNullEmpty(obj.id_incarico); }).length.toLocaleString('it-IT');
                                        GridData6[GridData6.length-1][`percGestitiCompagnia${elm3.id_compagnia}`] = `${parseFloat(parseFloat((_.filter(elm, obj => { return obj.gestFormaDiretta === 1 && obj.id_perito === elm3.id_perito && !CustomJS.isNullEmpty(obj.id_incarico); }).length / _.filter(_.uniq(elm, obj => obj.id_sinistro), obj2 => { return obj2.gestFormaDiretta === 1 && obj2.id_perito === elm3.id_perito; }).length) * 100).toFixed(2)).toLocaleString()}%`;
                                        if (CustomJS.isNullEmpty(_.find(dynaCols2, obj => { return !CustomJS.isNullEmpty(obj.columns) && obj.columns[0]?.field === `pervenutiCompagnia${elm3.id_compagnia}` })))
                                            dynaCols2.push({ columns: [{ field: `pervenutiCompagnia${elm3.id_compagnia}`, headerText: 'Pervenuti', textAlign: 'Center', allowFiltering: false, template: fieldTemplate2, headerTemplate: headerTemplate2, headerTextAlign: 'Center', allowSorting: false },
                                                                      { field: `gestibiliCompagnia${elm3.id_compagnia}`, headerText: 'Gestibili', textAlign: 'Center', template: fieldTemplate2, allowFiltering: false, headerTemplate: headerTemplate2, headerTextAlign: 'Center' },
                                                                      { field: `gestitiCompagnia${elm3.id_compagnia}`, headerText: 'Gestiti', textAlign: 'Center', template: fieldTemplate2, allowFiltering: false, headerTemplate: headerTemplate2, headerTextAlign: 'Center' },
                                                                      { field: `percGestitiCompagnia${elm3.id_compagnia}`, headerText: 'Perc. Gestiti', textAlign: 'Center', template: fieldTemplate2, allowFiltering: false, headerTemplate: headerTemplate2, headerTextAlign: 'Center' }], headerText: elm3.compagnia, headerTemplate: headerTemplate2, template: fieldTemplate2, textAlign: 'Center' });   
                                    }
                                    else if (elm3.gestFormaDiretta === 1 && CustomJS.isNullEmpty(_.find(GridData6, elm2 => { return elm2.IdPerito === elm3.id_perito && !CustomJS.isNullEmpty(elm2[`pervenutiCompagnia${elm3.id_compagnia}`]) }))) {
                                        _.find(GridData6, elm2 => { return elm2.IdPerito === elm3.id_perito })[`pervenutiCompagnia${elm3.id_compagnia}`] = _.filter(_.uniq(elm, obj => obj.id_sinistro), obj2 => { return obj2.id_perito === elm3.id_perito; }).length.toLocaleString('it-IT');
                                        _.find(GridData6, elm2 => { return elm2.IdPerito === elm3.id_perito })[`gestibiliCompagnia${elm3.id_compagnia}`] = _.filter(_.uniq(elm, obj => obj.id_sinistro), obj2 => { return obj2.gestFormaDiretta === 1 && obj2.id_perito === elm3.id_perito; }).length.toLocaleString('it-IT');
                                        _.find(GridData6, elm2 => { return elm2.IdPerito === elm3.id_perito })[`gestitiCompagnia${elm3.id_compagnia}`] = _.filter(elm, obj => { return obj.gestFormaDiretta === 1 && obj.id_perito === elm3.id_perito && !CustomJS.isNullEmpty(obj.id_incarico); }).length.toLocaleString('it-IT');
                                        _.find(GridData6, elm2 => { return elm2.IdPerito === elm3.id_perito })[`percGestitiCompagnia${elm3.id_compagnia}`] = `${parseFloat(parseFloat((_.filter(elm, obj => { return obj.gestFormaDiretta === 1 && obj.id_perito === elm3.id_perito && !CustomJS.isNullEmpty(obj.id_incarico); }).length / _.filter(_.uniq(elm, obj => obj.id_sinistro), obj2 => { return obj2.gestFormaDiretta === 1 && obj2.id_perito === elm3.id_perito; }).length) * 100).toFixed(2)).toLocaleString()}%`;
                                        if (CustomJS.isNullEmpty(_.find(dynaCols2, obj => { return !CustomJS.isNullEmpty(obj.columns) && obj.columns[0]?.field === `pervenutiCompagnia${elm3.id_compagnia}` })))
                                            dynaCols2.push({ columns: [{ field: `pervenutiCompagnia${elm3.id_compagnia}`, headerText: 'Pervenuti', textAlign: 'Center', allowFiltering: false, template: fieldTemplate2, headerTemplate: headerTemplate2, headerTextAlign: 'Center', allowSorting: false },
                                                                      { field: `gestibiliCompagnia${elm3.id_compagnia}`, headerText: 'Gestibili', textAlign: 'Center', template: fieldTemplate2, allowFiltering: false, headerTemplate: headerTemplate2, headerTextAlign: 'Center' },
                                                                      { field: `gestitiCompagnia${elm3.id_compagnia}`, headerText: 'Gestiti', textAlign: 'Center', template: fieldTemplate2, allowFiltering: false, headerTemplate: headerTemplate2, headerTextAlign: 'Center' },
                                                                      { field: `percGestitiCompagnia${elm3.id_compagnia}`, headerText: 'Perc. Gestiti', textAlign: 'Center', template: fieldTemplate2, allowFiltering: false, headerTemplate: headerTemplate2, headerTextAlign: 'Center' }], headerText: elm3.compagnia, headerTemplate: headerTemplate2, template: fieldTemplate2, textAlign: 'Center' });   
                                    }   
                                }
                            });
                        }); 
                        GridData6.push({ NominativoPerito: 'TOTALI' });
                        GridData6[GridData6.length-1][dynaCols2[1].columns[0].field] = GridData6.sum2(null, 5).toLocaleString();
                        GridData6[GridData6.length-1][dynaCols2[1].columns[1].field] = GridData6.sum2(null, 6).toLocaleString();
                        GridData6[GridData6.length-1][dynaCols2[1].columns[2].field] = GridData6.sum2(null, 7).toLocaleString();
                        GridData6[GridData6.length-1][dynaCols2[1].columns[3].field] = `${parseFloat(parseFloat((GridData6.sum2(null, 7) / GridData6.sum2(null, 6)) * 100).toFixed(2)).toLocaleString()}%`;
                        GridData6[GridData6.length-1][dynaCols2[2].columns[0].field] = GridData6.sum2(null, 9).toLocaleString();
                        GridData6[GridData6.length-1][dynaCols2[2].columns[1].field] = GridData6.sum2(null, 10).toLocaleString();
                        GridData6[GridData6.length-1][dynaCols2[2].columns[2].field] = GridData6.sum2(null, 11).toLocaleString();
                        GridData6[GridData6.length-1][dynaCols2[2].columns[3].field] = `${parseFloat(parseFloat((GridData6.sum2(null, 11) / GridData6.sum2(null, 10)) * 100).toFixed(2)).toLocaleString()}%`;
                        GridData7 = _.filter(_.uniq(GridData6, obj => obj.IdTeamLeader), obj2 => { return obj2.IdTeamLeader?.indexOf('undefined') === -1; })?.map(elm => { return { IdTeamLeader: elm.IdTeamLeader, NomeSquadraTeamLeader: `${elm.DescrSquadra} - ${elm.NomeTeamLeader}` } });
                        GridData8 = _.filter(GridData6, obj => { return !CustomJS.isNullEmpty(obj.IdTeamLeader); });

                        const containerFormaDirettaAccord3 = document.getElementById('contenutoDinamicoFormaDirettaAccordion3');
                        const rootFormaDirettaAccord3 = createRoot(containerFormaDirettaAccord3);
                        rootFormaDirettaAccord3.render(<CallbackWrapper callback={() => {
                            this.gridInstance5.columns = dynaCols2; 
                            this.gridInstance5.refreshColumns();   
                            setTimeout(() => { this.gridInstance5.dataSource = GridData6 }, 300); 
                            this.gridInstance6.childGrid.columns = dynaCols2; 
                            this.gridInstance6.refreshColumns();
                            setTimeout(() => { 
                                this.gridInstance6.dataSource = GridData7; 
                                this.gridInstance6.childGrid.dataSource = GridData8; 
                            }, 500);
                            this.toggleBlocking();
                        }}>{this.accordContent3Render()}</CallbackWrapper>);    
                    }).catch((error) => {
                        let messaggio = (_.isString(error) && error.indexOf('Not Found')) > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        toast.warn(messaggio, {
                            //onClose: () => toast.dismiss(),
                            position: "top-right",
                            autoClose: 8000,
                            hideProgressBar: false,            
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        })
                    });  
                }).catch((error) => {
                    let messaggio = (_.isString(error) && error.indexOf('Not Found')) > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        //onClose: () => toast.dismiss(),
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    })
                });
            }).catch((error) => {
                let messaggio = (_.isString(error) && error.indexOf('Not Found')) > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    //onClose: () => toast.dismiss(),
                    position: "top-right",
                    autoClose: 8000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                })
            });
        }).catch((error) => {
            let messaggio = (_.isString(error) && error.indexOf('Not Found')) > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                //onClose: () => toast.dismiss(),
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            })
        });
    }
    operazioniDopoCarica(args) {           
    }    
}