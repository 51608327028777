/* eslint-disable no-useless-constructor */
/* eslint-disable default-case */
import * as React from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Category, ColumnSeries, DataLabel, Tooltip, BarSeries} from '@syncfusion/ej2-react-charts';
import { SampleBase } from './sample-base';
import { Browser } from '@syncfusion/ej2-base';
import { fabricColors, bootstrapColors, materialColors, highContrastColors } from './theme-color';
import * as CustomJS from '../Custom';
import { random, isUndefined } from 'underscore';
import _ from 'underscore/underscore-min';

let data1 = [];
const waitingTime = Browser.userAgent.indexOf('Edg') > -1 ? 2000000000 : 2000000000;

CustomJS.getRemoteData2(CustomJS.APIUrlProd,
    `token=${CustomJS.tokenProd}&azione=segreteria&tipo=ACS`, 
    'POST', "application/x-www-form-urlencoded", (retData) => {   
        retData.forEach((elm, idx) => {  
            data1.push({ x: 'Sinistri in attesa autorizzazione' , y: elm.NumSinistriAttesaAutorizzazione });
            data1.push({ x: 'Sinistri autorizzati (anno corrente)' , y: elm.NumSinistriAutorizzati });           
        });        
 }, true, false); 
 for (let ind=0;ind<=waitingTime;ind++) {}

let labelRender = (args) => {
    let themes = ['fabric', 'material', 'highcontrast', 'bootstrap'];
    let selectedTheme = themes[random(3)];
    if (selectedTheme && selectedTheme.indexOf('fabric') > -1) {
        args.fill = fabricColors[args.point.index % 10];
    }
    else if (selectedTheme === 'material') {
        args.fill = materialColors[args.point.index % 10];
    }
    else if (selectedTheme === 'highcontrast') {
        args.fill = highContrastColors[args.point.index % 10];
    }
    else {
        args.fill = bootstrapColors[args.point.index % 10];
    }
};

export default class PaginaSegreteria extends SampleBase {
    constructor() {
        super(...arguments); 
    }    
    onQueryCellInfo(args) { 
    }    
    onDatabound(args) {       
        for (let ind=0;ind<=8;ind++)
             document.getElementById('GridCorrettori').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
        document.getElementById('TestoDatiAggiornati').innerText =  'Dati aggiornati al ' + new Date().getDate().toString().padStart(2, '0') + '/' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '/' + new Date().getFullYear().toString() + ' alle ore '  + new Date().getHours().toString() + ':' + new Date().getMinutes().toString().padStart(2, '0');            
    }
    onRowDatabound(args) {             
        if (args.data.NominativoCorrettore.indexOf('Totali') > -1) {
            args.row.className = 'e-row';
            args.row.bgColor = 'lightpink';
        }         
    }        
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
    }
    render() {
        return (<React.Fragment>
                <br/>
                <div className='container-fluid'> 
                    <div className="row"> 
                        <ChartComponent id='ChartSegereteriaACS' style={{ textAlign: "center" }}
                                className='col-xs-12 col-sm-12 col-lg-12 col-md-12'
                                primaryXAxis={{ valueType: 'Category', interval: 1, majorGridLines: { width: 0 },
                                labelStyle: { color: '#133B95', fontWeight: 'bolder' } }}
                                primaryYAxis={{ majorGridLines: { width: 0 }, majorTickLines: { width: 0 }, lineStyle: { width: 0 }, labelStyle: { color: 'transparent' } }}
                                chartArea={{ border: { width: 0 } }}
                                //load={this.load}
                                loaded={this.operazioniDopoCarica.bind(this)}
                                title='' height={CustomJS.chartHeight}                       
                                legendSettings={{ visible: false }}
                                width={CustomJS.chartWidth3} 
                                tooltip={{ enable: true }}
                                pointRender={labelRender}>
                                <Inject services={[ColumnSeries, DataLabel, Category, BarSeries, Tooltip]} />
                                <SeriesCollectionDirective>
                                    <SeriesDirective dataSource={data1} xName='x' width={2} yName='y' type='Bar' 
                                        cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }}
                                        marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                                    </SeriesDirective>
                                </SeriesCollectionDirective>
                        </ChartComponent>    
                    </div>            
                </div>
            </React.Fragment>);
    }
    operazioniDopoCarica(args) {                
    }
}