/* eslint-disable default-case */
import * as React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, PdfExport, Page, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { SampleBase } from './sample-base';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { random, isUndefined } from 'underscore';
//import '../Custom.css';
import BlockUi from '@availity/block-ui';

let GridData = [], ddlData = [], dynaCols = [];
let CompagnieVisual = CustomJS.compagnieVisualDefault;

CustomJS.getRemoteData2(CustomJS.APIUrlProd,
    `token=${CustomJS.tokenProd}&azione=compagnie`, 
    'POST', "application/x-www-form-urlencoded", (retData) => {  
        retData.forEach(elm => {
            elm.IdCompagnia = elm.IdCompagnia.toString();
        });
        ddlData = retData;         
}, true, false);

function headerTemplate(props) {   
    let fontSize =  props.headerText === 'Perito' || props.headerText === 'Scarico Totale' ? '145%' : '125%';
    return (<div>
         <span style={{ fontSize: fontSize, fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate(props) {       
    let colore = props.column.field === 'NominativoPerito' ? '#133B95' : 'purple';  
    let fontSize1 = '145%';  
    let fontSize2 = '76%';
    let renderDiv = props.column.field !== 'NominativoPerito' ? <div><span style={{ fontSize: fontSize1, color: colore, fontWeight: 'bold'}}>{props[props.column.field]?.substring(0, props[props.column.field]?.indexOf('('))}</span><br/><span style={{ fontSize: fontSize2, color: colore, fontWeight: 'bold'}}>{props[props.column.field]?.substring(props[props.column.field]?.indexOf('('))}</span></div>
                    : <div><span style={{ fontSize: '150%', color: colore, fontWeight: 'bold'}}>{props[props.column.field]}</span></div>;
    return (        
         renderDiv
    );
}
export default class GridScaricoCompagnie extends SampleBase {
    constructor() {
        super(...arguments);  
        this.toolbarOptions = ['ExcelExport', 'PdfExport', 'Search'];              
        this.gridData = GridData;       
        this.ddlData = ddlData;  
        this.fields = { text: 'DescrizioneCompagnia', value: 'IdCompagnia' };
        this.ddlValue = CompagnieVisual;        
        this.toggleBlocking = this.toggleBlocking.bind(this);
    }    
    state = {
        blocking: false        
    } 
    toggleBlocking() {
        this.setState({blocking: !this.state.blocking});
    }
    onTagging(e) {  
        e.setClass(CustomJS.colorsData[random(26)].Color.toLowerCase());
    }    
    onQueryCellInfo(args) {         
    }    
    onDatabound(args) {       
        for (let ind=0;ind<=CompagnieVisual.length+1;ind++)
            setTimeout(() => {
               document.getElementById('GridScaricoCompagnie').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
            }, 500);
        document.getElementById('TestoDatiAggiornati').innerText =  'Dati aggiornati al ' + new Date().getDate().toString().padStart(2, '0') + '/' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '/' + new Date().getFullYear().toString() + ' alle ore '  + new Date().getHours().toString() + ':' + new Date().getMinutes().toString().padStart(2, '0');         
    }    
    onRowDatabound(args) {  
        if (args.data.NominativoPerito.indexOf('STUDIO') > -1 || args.data.NominativoPerito.indexOf('TOTALI') > -1) {
            args.row.className = 'e-row';
            args.row.bgColor = 'lightpink'; 
        }
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
        }
    }
    render() {
        return (<React.Fragment>
                <BlockUi tag='div' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                    <div className='control-section'>
                        <div className='row'>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                <MultiSelectComponent id="compagnieVisualizzate" dataSource={this.ddlData} cssClass='e-outline' mode="Box" value={this.ddlValue} 
                                        fields={this.fields} ref={ddlComp => this.ddlCompInstance = ddlComp} 
                                        placeholder="Compagnie Visualizzate" floatLabelType='always' tagging={this.onTagging.bind(this)} />
                            </div>
                        </div>
                        <ButtonComponent id='BtnRicaricaCompagnie' className='m-1' alt='Ricaricamento tabella scarico Compagnie' style={{color: 'green'}} onClick={this.clickBottoneRicaricaCompagnie.bind(this)}>Ricarica Tabella Scarico Compagnie</ButtonComponent>
                        <GridComponent id="GridScaricoCompagnie" dataSource={this.gridData} ref={grid => this.gridInstance = grid} columns={dynaCols} toolbar={this.toolbarOptions} allowSelection={false} enableHover={false} allowTextWrap={true} 
                                allowPaging={true} allowSorting={false} allowFiltering={false} allowExcelExport={true} allowPdfExport={true} queryCellInfo={this.onQueryCellInfo.bind(this)} pageSettings={{ pageCount: 5, pageSize: 10, pageSizes: ['10', '50', 'Tutti'] }}
                                dataBound={this.onDatabound.bind(this)} rowDataBound={this.onRowDatabound.bind(this)} toolbarClick={this.toolbarClick.bind(this)} enableAltRow={true} gridLines='Both'>
                            <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Page]}/>                    
                        </GridComponent>
                    </div>
                    <div id='waitingpopup' className='waitingpopup'>
                        <span id='gif' className='image'></span>
                    </div>
                </BlockUi>                
            </React.Fragment>);
    }   
    rendereComplete() {
        this.toggleBlocking();
        this.caricaDati();
    }
    caricaDati() {
        CustomJS.getRemoteData2(CustomJS.APIUrlProd,
            `token=${CustomJS.tokenProd}&azione=scaricoCompagnie`, 'POST', "application/x-www-form-urlencoded", (retData) => {   
                GridData = [];
                retData.forEach((elm, idx) => {
                    if (CompagnieVisual.length > 0) {
                        let peritoSel = _.find(GridData, (obj) => { return obj.NominativoPerito === elm.NominativoPerito; });
                        if (isUndefined(peritoSel)) {
                            if (_.find(CompagnieVisual, (obj) => { return obj === elm.IdCompagnia; })) {
                                let objPerito = {};
                                objPerito.NominativoPerito = elm.NominativoPerito;
                                let percChiusi = ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100) < 100 && ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100) > 0 
                                                   && (((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100) - Math.floor(((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100))) !== 0
                                                  ? ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100).toFixed(2)
                                                  : ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100).toFixed(0);
                                objPerito[elm.IdCompagnia] = `${parseFloat(percChiusi).toLocaleString()}% (${elm.NumSinistriChiusi.toString()}/${elm.NumSinistriEntrati.toString()})`;
                                objPerito.TotSinistriChiusi = elm.NumSinistriChiusi;
                                objPerito.TotSinistriEntrati = elm.NumSinistriEntrati;
                                GridData.push(objPerito);
                            }
                        }
                        else {
                            if (_.find(CompagnieVisual, (obj) => { return obj === elm.IdCompagnia; })) {  
                                let percChiusi = ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100) < 100 && ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100) > 0 
                                                   && (((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100) - Math.floor(((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100))) !== 0
                                                  ? ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100).toFixed(2)
                                                  : ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100).toFixed(0);                                                      
                                peritoSel[elm.IdCompagnia] = `${parseFloat(percChiusi).toLocaleString()}% (${elm.NumSinistriChiusi.toString()}/${elm.NumSinistriEntrati.toString()})`;
                                peritoSel.TotSinistriChiusi += elm.NumSinistriChiusi;
                                peritoSel.TotSinistriEntrati += elm.NumSinistriEntrati;
                            }
                        }
                    }                       
                });    
                GridData.forEach((elm, idx) => {   
                    let percChiusi = ((elm.TotSinistriChiusi / elm.TotSinistriEntrati) * 100) < 100 && ((elm.TotSinistriChiusi / elm.TotSinistriEntrati) * 100) > 0 
                                                   && (((elm.TotSinistriChiusi / elm.TotSinistriEntrati) * 100) - Math.floor(((elm.TotSinistriChiusi / elm.TotSinistriEntrati) * 100))) !== 0
                                                  ? ((elm.TotSinistriChiusi / elm.TotSinistriEntrati) * 100).toFixed(2)
                                                  : ((elm.TotSinistriChiusi / elm.TotSinistriEntrati) * 100).toFixed(0);  
                    elm.PercentChiusi = parseFloat(percChiusi);           
                    elm.ScaricoTotale = `${parseFloat(percChiusi).toLocaleString()}% (${elm.TotSinistriChiusi.toString()}/${elm.TotSinistriEntrati.toString()})`;
                });
                GridData = _.sortBy(GridData, 'PercentChiusi');
                let elmTotale = _.find(GridData, (elm) => { return elm.NominativoPerito === 'STUDIO' || elm.NominativoPerito === 'TOTALI'; });
                GridData = _.filter(GridData, (elm) => { return elm.NominativoPerito !== 'STUDIO' && elm.NominativoPerito !== 'TOTALI'; });   
    
                let GridDataTemp = GridData;
                GridData = [];
                GridDataTemp.forEach((elm,idx) => {            
                    if (idx % 9 === 0) {
                        GridData.push(elmTotale);
                        GridData.push(elm);
                    }
                    else
                        GridData.push(elm);
                });  
    
                dynaCols = []; 
                dynaCols.push({ field: 'NominativoPerito', headerText: 'Perito', headerTemplate: headerTemplate, template: fieldTemplate, clipMode: 'EllipsisWithTooltip', width: '10%' });   
                dynaCols.push({ field: 'ScaricoTotale', headerText: 'Scarico Totale', headerTemplate: headerTemplate, template: fieldTemplate, clipMode: 'EllipsisWithTooltip', textAlign: 'Center', width: '6%' });   
                CompagnieVisual.forEach((elm, idx) => {
                    dynaCols.push({ field: elm, headerText: _.find(ddlData, (obj) => { return obj.IdCompagnia.toString() === elm; })?.DescrizioneCompagnia, clipMode: 'EllipsisWithTooltip', textAlign: 'Center', headerTemplate: headerTemplate, template: fieldTemplate });
                });
                setTimeout(() => { document.getElementById('GridScaricoCompagnie').ej2_instances[0].columns = dynaCols }, 100); 
                setTimeout(() => { document.getElementById('GridScaricoCompagnie').ej2_instances[0].refreshColumns() }, 300);   
                setTimeout(() => { document.getElementById('GridScaricoCompagnie').ej2_instances[0].dataSource = GridData }, 500);  
                setTimeout(() => { document.getElementById('GridScaricoCompagnie').ej2_instances[0].refresh() }, 700);  
                setTimeout(() => { window.scrollTo(0,document.body.scrollHeight); }, 900);
                this.toggleBlocking();
        }, true, false);    
    }
    clickBottoneRicaricaCompagnie(args) {   
        CompagnieVisual = this.ddlCompInstance.value;
        this.toggleBlocking();
        this.caricaDati();
    }    
}