/* eslint-disable default-case */
import * as React from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, LineSeries, DateTime, Tooltip, Legend, Trendlines, TrendlinesDirective, TrendlineDirective } from '@syncfusion/ej2-react-charts';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, PdfExport, Page, Filter, Inject as Inject2, Sort } from '@syncfusion/ej2-react-grids';
import { SampleBase } from './sample-base';
import * as CustomJS from '../Custom';
import { random, isUndefined } from 'underscore';
import _ from 'underscore/underscore-min';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var dataCorrente = new Date();
var dd = String(dataCorrente.getDate()).padStart(2, '0');
var mm = String(dataCorrente.getMonth() + 1).padStart(2, '0');
//dd = '30'; //solo test - da rimuovere
//mm = '04'; //solo test - da rimuovere
var mmNum = parseInt(mm);
var yyyy = dataCorrente.getFullYear();
//yyyy = 2019; //solo test - da rimuovere
dataCorrente = yyyy + mm + dd;  
let data1 = [], dsGriglia = [];
const waitingTime = 1000000000;

const CaricaDati = () => {
    CustomJS.callAPI(`${CustomJS.APIMonitorURLProd}/api/Monitor/leggiTotaliGiorniTUS`, '', 'GET',
            '', retData => {
                retData.reverse().forEach(elm => {
                    data1.push({ x: new Date(elm.GIORNO), y: elm.TOTALE_TUS });
                });
            }, error => {
                let messaggio = (_.isString(error) && error.indexOf('Not Found')) > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    position: "top-right",
                    autoClose: 8000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                })
            }
    , true, false);   

    CustomJS.getRemoteData2(CustomJS.APIUrlProd,
        `token=${CustomJS.tokenProd}&azione=sinistriTUS&tipo=tabella`, 
        'POST', "application/x-www-form-urlencoded", (retData) => { 
            dsGriglia = _.sortBy(retData, 'GiorniTUS').reverse();
            dsGriglia = _.first(dsGriglia, 100);
            dsGriglia.forEach((elm, idx) => { 
                elm.GiorniTUS = elm.GiorniTUS.toLocaleString('it-IT');                             
            }); 
    }, true, false);      
}

function headerTemplate(props) {    
    return (<div>
         <span style={{ fontSize: '0.95vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate(props) {       
    let colore = props.column.field === 'DescrizioneCompagnia' ? '#133B95' : 'purple';
    return (        
         <div><span style={{ fontSize: '1.0vw', color: colore, fontWeight: 'bold'}}>{props[props.column.field]}</span></div>
    );
}

CaricaDati();

for (let ind=0;ind<=waitingTime;ind++) {};

export default class PaginaSinistriTUS extends SampleBase {
    constructor() {
        super(...arguments); 
        this.toolbarOptions = ['ExcelExport', 'PdfExport', 'Search']; 
    }   
    state= {
        dataSerie1: data1,
        dsGriglia1: dsGriglia     
    } 
    onQueryCellInfo(args) { 
    }    
    onDatabound(args) {       
        for (let ind=0;ind<=7;ind++)
             document.getElementById('GridSinistriTUS').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
        document.getElementById('TestoDatiAggiornati').innerText =  'Dati aggiornati al ' + new Date().getDate().toString().padStart(2, '0') + '/' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '/' + new Date().getFullYear().toString() + ' alle ore '  + new Date().getHours().toString() + ':' + new Date().getMinutes().toString().padStart(2, '0');            
    }
    onRowDatabound(args) {  
    }        
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
        }
    }
    render() {        
        return (<React.Fragment>    
                <div className='container-fluid'> 
                    <div className="row">   
                        <div className="col-xs-12 col-sm-12 col-lg-5 col-md-5" style={{ marginTop: '10px' }}>
                            <span style={{ fontSize: '1.6vw', fontWeight: 'bold', color:'#133B95'}}>ANDAMENTO TOTALE GIORNI IN TUS<span style={{ fontSize: '1.0vw', fontWeight: 'bold', color:'#133B95'}}> (ultimi 60 giorni)</span></span>
                        </div>               
                    </div>  
                    <div className="row"></div>
                    <div className="row"> 
                        <ChartComponent id='ChartSinistriTUS' style={{ textAlign: "center" }}
                            className='col-xs-12 col-sm-12 col-lg-12 col-md-12'
                            primaryXAxis={{
                                valueType: 'DateTime',
                                labelFormat: 'dd/MM/yyyy',
                                intervalType: 'Days',
                                edgeLabelPlacement: 'Hide',
                                majorGridLines: { width: 0 }                
                            }}
                            primaryYAxis={{
                                labelFormat: '{value}',
                                rangePadding: 'None',                                
                                interval: 2000,
                                lineStyle: { width: 0 },
                                majorTickLines: { width: 0 },
                                minorTickLines: { width: 0 }
                            }}
                            chartArea={{ border: { width: 0 } }}
                            tooltip={{ enable: true }}
                            width={CustomJS.chartWidth}
                            title='' loaded={this.operazioniDopoCarica.bind(this)}
                            legendSettings={{ visible: true }}>
                            <Inject services={[LineSeries, DateTime, Legend, Tooltip, Trendlines]} />
                            <SeriesCollectionDirective>
                                <SeriesDirective dataSource={this.state.dataSerie1} xName='x' yName='y' name='Totale giorni in TUS'
                                    width={3} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                                        {/* <TrendlinesDirective>
                                            <TrendlineDirective type='Linear' width={3} marker={{ visible: false }} name='Linea Tendenza' fill='#C64A75'>
                                            </TrendlineDirective>
                                        </TrendlinesDirective> */}
                                </SeriesDirective>
                            </SeriesCollectionDirective>
                        </ChartComponent> 
                    </div>                      
                    <div className="row"></div>
                    <div className="row">   
                        <div className="col-xs-12 col-sm-12 col-lg-4 col-md-4" style={{ marginTop: '10px' }}>
                            <span style={{ fontSize: '1.6vw', fontWeight: 'bold', color:'#133B95'}}>SITUAZIONE SINISTRI IN TUS<span style={{ fontSize: '1.0vw', fontWeight: 'bold', color:'#133B95'}}> (solo primi 100)</span></span>
                        </div>               
                    </div> 
                    <div className="row"></div>             
                    <div className='row'>
                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>                                            
                            <GridComponent id="GridSinistriTUS" dataSource={this.state.dsGriglia1} ref={grid => this.gridInstance = grid} toolbar={this.toolbarOptions} allowSelection={false} enableHover={false} allowTextWrap={true}
                                    allowPaging={true} allowSorting={false} allowFiltering={false} allowExcelExport={true} allowPdfExport={true} queryCellInfo={this.onQueryCellInfo.bind(this)} 
                                    dataBound={this.onDatabound.bind(this)} rowDataBound={this.onRowDatabound.bind(this)} toolbarClick={this.toolbarClick.bind(this)} 
                                    enableAltRow={true} pageSettings={{ pageCount: 4, pageSize: 10, pageSizes: ['10', '50', '100', '500', 'Tutti'] }}>
                                <ColumnsDirective>      
                                    <ColumnDirective field='IdSinistroSIPA' headerText='ID Sinistro' headerTemplate={headerTemplate} width='10%' template={fieldTemplate} textAlign='Left' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='DescrCompagnia' headerText='Compagnia' headerTemplate={headerTemplate} width='20%' template={fieldTemplate} textAlign='Left' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='NominativoReferenteCompagnia' headerText='Referente Compagnia' headerTemplate={headerTemplate} width='20%' template={fieldTemplate} textAlign='Left' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='NominativoReferenteTecnico' headerText='Referente Tecnico' headerTemplate={headerTemplate} width='20%' template={fieldTemplate} textAlign='Left' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='NominativoPerito' headerText='Perito' headerTemplate={headerTemplate} width='20%' template={fieldTemplate} textAlign='Left' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='TipoSinistroSIPA' headerText='Tipo sinistro' headerTemplate={headerTemplate} width='20%' template={fieldTemplate} textAlign='Left' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='GradoSinistroSIPA' headerText='Grado sinistro' headerTemplate={headerTemplate} width='10%' template={fieldTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='GiorniTUS' headerText='Giorni in TUS' headerTemplate={headerTemplate} width='10%' template={fieldTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                </ColumnsDirective>                            
                                <Inject2 services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Page]}/>                    
                            </GridComponent>
                        </div>
                    </div>                                                    
                </div>
            </React.Fragment>);
    }
    rendereComplete() {        
    }
    componentDidMount() {           
    }
    operazioniDopoCarica(args) {                
    }
}